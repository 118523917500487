import EditIcon from "@mui/icons-material/Edit";
import { Box, Chip, IconButton, Switch, Typography } from "@mui/material";
import React, { useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import AddNewCustomFieldModal, { CUSTOM_FIELD_TYPE } from "./AddNewCustomFieldModal";
import { CustomFieldInfo } from "./CustomFields";

interface CustomFieldItemProps {
  customField: CustomFieldInfo;
  setAllCustomFields: React.Dispatch<React.SetStateAction<CustomFieldInfo[]>>;
}

export default function CustomFieldItem({ customField, setAllCustomFields }: CustomFieldItemProps) {
  const [showAddNewCustomFieldModal, setShowAddNewCustomFieldModal] = useState<boolean>(false);
  const [isToggleActive, setIsToggleActive] = useState<boolean>(customField.isActive);

  const toggleCustomFieldHandler = () => {
    useFetch(API_ENDPOINTS.TOGGLE_CUSTOM_FIELD_STATUS(customField?.id).url, "PUT", {
      showSuccessToast: true,
      failureMessage: API_ENDPOINTS.TOGGLE_CUSTOM_FIELD_STATUS(customField?.id).failureMessage,
      catchCallBack: () => {
        setIsToggleActive((prev) => !prev);
      },
    });
  };
  return (
    <Box
      key={customField.id}
      sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 1 }}
    >
      <Box>
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-start", flexDirection: "row", gap: 1 }}>
          <Typography variant="subtitle1">{customField.name}</Typography>
          {customField.isLogical && (
            <Chip
              sx={{
                "& .MuiChip-label": {
                  fontSize: "13px",
                  fontWeight: 400,
                  color: "rgba(0, 0, 0, 0.87)",
                },
              }}
              label="Logic Applied"
              size="small"
              variant="outlined"
            />
          )}
        </Box>
        <Typography color={"rgba(0, 0, 0, 0.60)"} variant="subtitle2" className="fw_400 fs_14">
          {customField.type === CUSTOM_FIELD_TYPE.List
            ? `${
                customField.listOptions.isMultiSelect ? "Multi-select List: " : "Single-select List: "
              }${customField.listOptions.values.join(", ")}`
            : customField.type}
        </Typography>
      </Box>
      <Box>
        <IconButton
          onClick={() => {
            setShowAddNewCustomFieldModal(true);
          }}
        >
          <EditIcon fontSize="small" />
        </IconButton>
        <Switch
          checked={isToggleActive}
          onChange={() => {
            toggleCustomFieldHandler();
            setIsToggleActive((prev) => !prev);
          }}
        />
      </Box>
      {showAddNewCustomFieldModal && (
        <AddNewCustomFieldModal
          open={showAddNewCustomFieldModal}
          setOpen={setShowAddNewCustomFieldModal}
          fieldDetails={customField}
          setAllCustomFields={setAllCustomFields}
        />
      )}
    </Box>
  );
}
