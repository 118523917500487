/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, Box, Button, FormControl, IconButton, TextField, Typography } from "@mui/material";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { TagsInputUniqueKeys } from "src/Components/Recon360/ReconMIS/AutoCompletes";
import {
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaHistory,
} from "src/entity/recon-entity/ReconInterfaces";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { DateFilter } from "../../BalanceConfirmationBeta/BalanceConfirmationBetaFilter";
// import "../Styles/LedgerRequestFilter.scss";
import { Close } from "@mui/icons-material";
import { userContext } from "src/Components/Contexts/userContext";
import { BranchInfo, CompanyInfo } from "../../CommonLegacy/CommonComponents";
import { NdButton } from "../../MsmePartnerPortal/CommonComponents";
import $ from "../../MsmePartnerPortal/PartnerPortal.module.scss";
import { FilterKeysNameMap, keys } from "../Common/Components";
import { IntegratedDropDown } from "../Common/IntegratedDropdown";

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

type dateTypes = "Date of Closing balance" | "Date As of Last Balance Confirmed";
type setAppliedFilters = StateDispatch<{
  [key in keyof typeof FilterKeysNameMap | dateTypes]: Array<string>;
}>;

interface BalanceConfirmationFilterProps {
  allCategories: string[];
  storeRowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  rowsDataBalanceConfirmation: BusinessPartnerListForBcBeta[];
  setRowsDataBalanceConfirmation: StateDispatch<BusinessPartnerListForBcBeta[]>;
  setAppliedFilters: setAppliedFilters;
  setIsFilterApplied: StateDispatch<boolean>;
  openFilter: boolean;
  setOpenFilter: StateDispatch<boolean>;
  filterObj: Record<keys, string[]>;
  setFilterObj: StateDispatch<Record<keys, string[]>>;
  reFilter: boolean;
  setReFilter: StateDispatch<boolean>;
  newMailingConsumer?: boolean;
  historyConsumer?: boolean;
  companyIdRef: React.MutableRefObject<string>;
  branchCodeRef: React.MutableRefObject<string>;
}

const BalanceConfirmationNewFilter = (props: BalanceConfirmationFilterProps) => {
  const [_selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [_selectedStatus, setSelectedStatus] = useState<string[]>([]);

  const { actor } = useContext(userContext);

  const [fromDateOfMailInitiation, setFromDateOfMailInitiation] = useState<string>(null);
  const [toDateOfMailInitiation, setToDateOfMailInitiation] = useState<string>(null);
  const [fromDateOfClosingBalance, setFromDateOfClosingBalance] = useState<string>(null);
  const [toDateOfClosingBalance, setToDateOfClosingBalance] = useState<string>(null);
  const [fromDateAsOfLastBalanceConfirmed, setFromDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [toDateAsOfLastBalanceConfirmed, setToDateAsOfLastBalanceConfirmed] = useState<string>(null);
  const [_partnerNames, setPartnerNames] = useState<string[]>([]);
  const [_vendorCodes, setVendorCodes] = useState<string[]>([]);
  const [_taskLabel, setTaskLabel] = useState<string[]>([]);

  const [selectedCompany, setSelectedCompany] = useState<Partial<CompanyInfo>>({} as any);
  const [selectedBranch, setSelectedBranch] = useState<Partial<BranchInfo>>({} as any);

  const { filterObj, setFilterObj } = props;

  const BalanceConfirmationStatus = Array.from(new Set(Object.values(BalanceConfirmationBetaStatuses))); // only unique values

  const handleClose = () => {
    props.setOpenFilter(false);
  };

  const setDataFromObj = () => {
    setPartnerNames(filterObj?.partnerNames || []);
    setVendorCodes(filterObj?.vendorCodes || []);
    setSelectedCategory(filterObj?.category || []);
    setSelectedStatus(filterObj?.status || []);
    setTaskLabel(filterObj?.taskLabel || []);
  };

  useEffect(() => {
    setDataFromObj();
  }, [props.openFilter]);

  useEffect(() => {
    setFilterObj({
      company: [selectedCompany.companyName],
      branch: [selectedBranch?.branchName],
      partnerNames: _partnerNames,
      category: _selectedCategory,
      status: _selectedStatus,
      vendorCodes: _vendorCodes,
      taskLabel: _taskLabel,
    });
  }, [_partnerNames, _selectedCategory, _selectedStatus, _vendorCodes, _taskLabel, selectedCompany, selectedBranch]);

  const filterBalanceConfirmation = () => {
    let tempRowData = props.storeRowsDataBalanceConfirmation;

    if (selectedCompany?.companyId) {
      props.setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedCompany?.companyId === item.companyId;
      });
    }

    if (selectedBranch?.branchCode) {
      props.setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return selectedBranch?.branchCode === item.branchCode;
      });
    }

    if (filterObj.partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;

        filterObj.partnerNames.forEach((name) => {
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterApplied(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase() === partnerNameStr?.trim()) {
            found = true;
          }
        });
        return found;
      });
    }

    if (filterObj.vendorCodes?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;

        filterObj.vendorCodes.forEach((name) => {
          const vendorCodeWithoutMultiSpace = name.replace(/\s+/g, " ");
          const vendorCodeStr = vendorCodeWithoutMultiSpace.toLowerCase();
          props.setIsFilterApplied(true);
          if (partnerRow.vendorCode?.replace(/\s+/g, " ").toLowerCase() === vendorCodeStr?.trim()) {
            found = true;
          }
        });
        return found;
      });
    }

    if (filterObj.category?.length > 0) {
      props.setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return filterObj.category?.every((val) => item.category?.includes(val));
      });
    }
    if (filterObj.status?.length > 0) {
      props.setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return filterObj.status?.some((val) => item.status?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (filterObj.taskLabel?.length > 0) {
      props.setIsFilterApplied(true);
      tempRowData = tempRowData?.filter((item) => {
        return filterObj.taskLabel?.some((val) => item.label?.toLowerCase()?.includes(val?.toLowerCase()));
      });
    }

    if (props.historyConsumer && (fromDateOfMailInitiation || toDateOfMailInitiation)) {
      props.setIsFilterApplied(true);
      tempRowData = DateFilter<BusinessPartnersListForBcBetaHistory>(
        fromDateOfMailInitiation,
        toDateOfMailInitiation,
        tempRowData,
        "emailInitiationDate"
      );
    }

    if (fromDateOfClosingBalance || toDateOfClosingBalance) {
      props.setIsFilterApplied(true);
      tempRowData = DateFilter<BusinessPartnerListForBcBeta>(
        fromDateOfClosingBalance,
        toDateOfClosingBalance,
        tempRowData,
        "closingBalanceDate"
      );
    }

    props.setAppliedFilters({
      Company: selectedCompany?.companyName ? [selectedCompany?.companyName] : [],
      Branch: selectedBranch?.branchName ? [selectedBranch?.branchName] : [],
      "Partner Names": filterObj.partnerNames?.map((name) => name?.trim()),
      "Vendor Codes": filterObj.vendorCodes?.map((code) => code?.trim()),
      "Selected Category": filterObj.category,
      "Selected Status": filterObj.status,
      "Task Label": filterObj.taskLabel,
      "Date of Closing balance":
        fromDateOfClosingBalance && toDateOfClosingBalance
          ? [fromDateOfClosingBalance, toDateOfClosingBalance]
          : fromDateOfClosingBalance
          ? [fromDateOfClosingBalance, null]
          : toDateOfClosingBalance
          ? [null, toDateOfClosingBalance]
          : [],
      "Date As of Last Balance Confirmed":
        fromDateAsOfLastBalanceConfirmed && toDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, toDateAsOfLastBalanceConfirmed]
          : fromDateAsOfLastBalanceConfirmed
          ? [fromDateAsOfLastBalanceConfirmed, null]
          : toDateAsOfLastBalanceConfirmed
          ? [null, toDateAsOfLastBalanceConfirmed]
          : [],
    });

    props.setRowsDataBalanceConfirmation(tempRowData);
    handleClose();
  };

  useEffect(() => {
    if (props.reFilter) {
      filterBalanceConfirmation();
      props?.setReFilter(false);
    }
    // eslint-disable-next-line
  }, [props.reFilter]);

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setSelectedStatus([]);

    // All the Date states reset
    setFromDateOfClosingBalance(null);
    setToDateOfClosingBalance(null);
    setFromDateAsOfLastBalanceConfirmed(null);
    setToDateAsOfLastBalanceConfirmed(null);

    setPartnerNames([]);
    setVendorCodes([]);

    setFilterObj({
      company: [],
      branch: [],
      partnerNames: [],
      category: [],
      status: [],
      vendorCodes: [],
      taskLabel: [],
    });

    props.setAppliedFilters({} as any);

    handleClose();
    setTimeout(() => {
      props.setOpenFilter(true);
      props.setRowsDataBalanceConfirmation(props.storeRowsDataBalanceConfirmation);
      props.setIsFilterApplied(false);
    }, 700);
  };

  const companyNameSelect = (e: Record<string, any>, option: Partial<CompanyInfo>) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      if (props.companyIdRef) props.companyIdRef.current = option.companyId;
      setSelectedCompany(option);
    } else if (!option) {
      setSelectedCompany({} as any);
      setSelectedBranch({} as any);
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: Partial<BranchInfo>) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      if (props.branchCodeRef) props.branchCodeRef.current = option.branchCode;
      setSelectedBranch(option);
    } else if (!option) {
      setSelectedBranch({} as any);
    }
  };

  return (
    <>
      <Dialog open={props.openFilter} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth keepMounted>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"90%"}>
            Apply Filter
          </DialogTitle>
          <IconButton
            onClick={() => {
              setDataFromObj();

              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent
          sx={{
            display: "flex",
            flexFlow: "column",
            gap: 1,
            px: 3,
            py: 2,
            "& .MuiFormControl-root, & .MuiInputBase-root.MuiOutlinedInput-root": {
              borderRadius: "4px",
            },
          }}
        >
          {actor.integration === true && (
            <Box display={"grid"} gridTemplateColumns={"1fr 1fr"}>
              <Box display={"flex"} flexDirection={"column"} gap={1} justifyContent={"space-around"}>
                <Typography variant="subtitle2">Company </Typography>
                {actor.integration === true && actor.branchLevelReconcilation === true && (
                  <Typography variant="subtitle2">Branch </Typography>
                )}
              </Box>

              <FormControl sx={{ width: 360, ml: "auto", gap: 1 }}>
                <IntegratedDropDown companyNameSelect={companyNameSelect} branchNameSelect={branchNameSelect} />
              </FormControl>
            </Box>
          )}
          <div className="vertical_center_align ">
            <Typography variant="subtitle2">Partner Names </Typography>
            <FormControl sx={{ width: 360, ml: "auto" }}>
              <TagsInputUniqueKeys
                tags={_partnerNames}
                setTags={setPartnerNames}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Names"
                splitter="newline"
                limitTags={3}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={props.storeRowsDataBalanceConfirmation}
                accessor={"businessPartnerName"}
                uniqueKeyAccessor={"businessPartnerId"}
              />
            </FormControl>
          </div>
          <div className="vertical_center_align ">
            <Typography variant="subtitle2">Vendor Codes </Typography>
            <FormControl sx={{ width: 360, ml: "auto" }}>
              <TagsInputUniqueKeys
                tags={_vendorCodes}
                setTags={setVendorCodes}
                className="partnerNamesAutocomplete"
                fullWidth={true}
                label=""
                placeholder="Newline separated Codes"
                splitter="newline"
                limitTags={3}
                textFieldProps={{ multiline: true, maxRows: 4 }}
                options={props.storeRowsDataBalanceConfirmation.filter(
                  (item) => !(item.vendorCode === null || item.vendorCode === "")
                )}
                accessor={"vendorCode"}
                uniqueKeyAccessor={"businessPartnerId"}
              />
            </FormControl>
          </div>
          <div className="vertical_center_align ">
            <Typography variant="subtitle2">Category </Typography>
            <FormControl sx={{ width: 360, ml: "auto" }}>
              <Autocomplete
                // limitTags={3}
                value={_selectedCategory}
                fullWidth={true}
                onChange={(_, value) => {
                  setSelectedCategory(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={props.allCategories || []}
                getOptionLabel={(option) => option}
                // defaultValue={[top100Films[13]]}
                filterSelectedOptions={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select category"
                    // placeholder="Favorites"
                  />
                )}
              />
            </FormControl>
          </div>
          {!props.newMailingConsumer && (
            <div className="vertical_center_align ">
              <Typography variant="subtitle2">Label </Typography>
              <FormControl sx={{ width: 360, ml: "auto" }}>
                <Autocomplete
                  // limitTags={3}
                  value={_taskLabel}
                  fullWidth={true}
                  onChange={(_, value) => {
                    setTaskLabel(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={[
                    ...new Set(
                      props.storeRowsDataBalanceConfirmation
                        .filter((item) => !(item.label === null || item.label === ""))
                        .map((item) => item.label)
                    ),
                  ]}
                  getOptionLabel={(option) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Label"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </FormControl>
            </div>
          )}
          <div className="vertical_center_align ">
            <Typography variant="subtitle2">Status </Typography>
            <FormControl sx={{ width: 360, ml: "auto" }}>
              <Autocomplete
                // limitTags={3}
                value={_selectedStatus}
                fullWidth={true}
                onChange={(_, value) => {
                  setSelectedStatus(value);
                }}
                size="small"
                multiple={true}
                id="tags-outlined"
                options={BalanceConfirmationStatus || []}
                getOptionLabel={(option) => option}
                // defaultValue={[top100Films[13]]}
                filterSelectedOptions={true}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select status"
                    // placeholder="Favorites"
                  />
                )}
              />
            </FormControl>
          </div>

          {props.historyConsumer && (
            <div className="vertical_center_align ">
              <Typography variant="subtitle2">Date of Email Initiation </Typography>
              <FormControl sx={{ width: 360, ml: "auto" }}>
                <TextField
                  className="mr_20"
                  label="From"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={fromDateOfMailInitiation || ""}
                  onChange={(e) => {
                    setFromDateOfMailInitiation(e.target.value);
                  }}
                />
                <TextField
                  label="To"
                  InputLabelProps={{ shrink: true }}
                  id="outlined-size-small"
                  size="small"
                  type="date"
                  value={toDateOfMailInitiation || ""}
                  onChange={(e) => {
                    setToDateOfMailInitiation(e.target.value);
                  }}
                />
              </FormControl>
            </div>
          )}
          <div className="vertical_center_align ">
            <Typography variant="subtitle2">Date of closing balance </Typography>
            <FormControl sx={{ width: 360, ml: "auto", flexFlow: "row", justifyContent: "space-between" }}>
              <TextField
                label="From"
                InputLabelProps={{ shrink: true }}
                id="outlined-size-small"
                size="small"
                type="date"
                value={fromDateOfClosingBalance || ""}
                onChange={(e) => {
                  setFromDateOfClosingBalance(e.target.value);
                }}
              />
              <TextField
                label="To"
                InputLabelProps={{ shrink: true }}
                id="outlined-size-small"
                size="small"
                type="date"
                value={toDateOfClosingBalance || ""}
                onChange={(e) => {
                  setToDateOfClosingBalance(e.target.value);
                }}
              />
            </FormControl>
          </div>
          <div className="vertical_center_align "></div>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <Button
            color="inherit"
            onClick={() => {
              clearAllAppliedFilters();
            }}
          >
            Clear All
          </Button>
          <NdButton
            variant="contained"
            id="filterBalanceConfirmation_button"
            onClick={() => {
              // if (!props.historyConsumer) {
              // }
              setDataFromObj();
              filterBalanceConfirmation();
            }}
          >
            Apply
          </NdButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BalanceConfirmationNewFilter;
