import { ValueFormatterParams } from "ag-grid-community";
import moment, { Moment } from "moment";

/** This is a column definition of the grid on Anchor page */
export function formatDate(dateString: string | any) {
  if (dateString.result) {
    return moment(dateString.result).format("DD/MM/YYYY");
  }
  return moment(dateString).format("DD/MM/YYYY");
}
export function formatDateToTime(dateString: string | Date) {
  return moment(dateString).format("DD/MM/YYYY HH:mm:ss");
}
interface DateFormats {
  "DD/MM/YYYY": "DD/MM/YYYY"; // 25/10/2023
  "MMMM Do YYYY, h:mm:ss a": ""; // October 25th 2023, 2:41:57 pm
  dddd: "dddd"; // Wednesday
  "MMM Do YY": "MMM Do YY"; // Oct 25th 23
  "YYYY [escaped] YYYY": ""; // 2023 escaped 2023
}

const DateFormat: DateFormats & { [k: OtherFormat]: string } = {
  "DD/MM/YYYY": "DD/MM/YYYY",
  "MMMM Do YYYY, h:mm:ss a": "",
  dddd: "dddd",
  "MMM Do YY": "MMM Do YY",
  "YYYY [escaped] YYYY": "",
} as const;

type OtherFormat = string & NonNullable<unknown>;

/**
 *
 * @param {moment.MomentInput} dateString
 * @param {keyof typeof DateFormat} [format="DD/MM/YYYY"] default `DD/MM/YYYY`
 * @param {string} [customDefaultFormatSeparator="/"] default `/`
 * @param {boolean} [returnObject=false] if true retuns `moment object`
 *
 * some of the supported formats:
 * - `DD/MM/YYYY`, --- `October 25th 2023, 12:00:00 pm`
 * - `MMMM Do YYYY, h:mm:ss a` --- `October 25th 2023, 12:00:00 pm`
 * - `dddd` --- `Wednesday`
 * - `MMM Do YY` --- `Oct 25th 23`
 * - `YYYY [escaped] YYYY` --- `2023 escaped 2023`
 *
 *  find more formats at: https://momentjs.com/docs/#/displaying/
 */
export default function FormatDate(
  dateString: moment.MomentInput,
  format: keyof typeof DateFormat = "DD/MM/YYYY",
  customDefaultFormatSeparator = "",
  returnObject = false
) {
  const sep = customDefaultFormatSeparator;
  if (returnObject) return moment(dateString);
  else if (customDefaultFormatSeparator) return moment(dateString).format(`DD${sep}MM${sep}YYYY`);
  return moment(dateString).format(format as string);
}

// /**
//  * return full name of the user
//  * @param   {string} firstName  First Name of the User
//  * @param   {string} lastName   Last Name of the User
//  * @return  {string}            Fullname of the user
//  */

export function dateValueFormatter(params: ValueFormatterParams) {
  return formatDate(params.value);
}

export function DateDifference(date1: Moment, date2: Moment, unit: moment.unitOfTime.Diff, precise = false) {
  return date1.diff(date2, unit, precise);
}

export function formatDateTime24Hours(date: moment.MomentInput) {
  return moment(date).format("DD/MM/YYYY HH:mm");
}

/**
 * FilterDateBetweenStartAndEndDate function for comparing 2 Dates.
 *
 * Only the format `YYYY-MM-DD` is string sortable and comparable.
 *
 * The date string should only be passed in formats that are parsable by moment
 * or alternatively, a Date object can be passed.
 *
 * @export
 * @param {(string | Date)} start
 * @param {(string | Date)} end
 * @param {(string | Date)} current
 * @return {*}
 */
export function filterDateBetweenStartAndEndDate(start: string | Date, end: string | Date, current: string | Date) {
  const startDate = start && moment(start).format("YYYY-MM-DD");
  const endDate = end && moment(end).format("YYYY-MM-DD");
  const currentDate = current && moment(current).format("YYYY-MM-DD");
  if (!startDate && !endDate && !currentDate) return true;
  if (!currentDate) return false;
  if (startDate && endDate) return currentDate >= startDate && currentDate <= endDate;
  if (startDate) return currentDate >= startDate;
  if (endDate) return currentDate <= endDate;
  return true;
}
