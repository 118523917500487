import { Box, Skeleton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import AddNewCustomFieldModal, { CUSTOM_FIELD_TYPE } from "./AddNewCustomFieldModal";
import CustomFieldItem from "./CustomFieldItem";

export interface ListOptions {
  isMultiSelect: boolean;
  values: string[];
}
export interface CustomFieldInfo {
  id: number;
  name: string;
  isActive: boolean;
  type?: CUSTOM_FIELD_TYPE;
  listOptions?: ListOptions;
  isLogical: boolean;
  formula?: string;
}

export default function CustomFields() {
  const [showAddNewCustomFieldModal, setShowAddNewCustomFieldModal] = useState<boolean>(false);
  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState<boolean>(false);

  const [allCustomFields, setAllCustomFields] = useState<CustomFieldInfo[]>([]);

  useEffect(() => {
    listAllCustomFields();
  }, []);

  const listAllCustomFields = () => {
    setIsLoadingCustomFields(true);
    useFetch<{ customFields: CustomFieldInfo[] }>(API_ENDPOINTS.GET_ALL_CUSTOM_FIELDS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ALL_CUSTOM_FIELDS.failureMessage,
      thenCallBack: (response) => {
        setAllCustomFields(response.data.customFields || []);
        setIsLoadingCustomFields(false);
      },
      catchCallBack: () => {
        setIsLoadingCustomFields(false);
      },
    });
  };

  return (
    <Box
      sx={{
        background: "#ffffff",
      }}
    >
      <Box
        sx={{
          padding: "16px",
          borderBottom: "1px solid #dadada",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography variant="h6">Custom Fields</Typography>
          <Typography variant="body2" color={"rgba(0, 0, 0, 0.60);"}>
            Set up custom fields to streamline your data, manage workflow and MIS
          </Typography>
        </Box>
        <NdButton
          variant="contained"
          onClick={() => {
            setShowAddNewCustomFieldModal(true);
          }}
        >
          Add Field
        </NdButton>
      </Box>
      <Box
        sx={{
          overflow: "auto",
          padding: 2,
          height: "40vh",
        }}
      >
        {isLoadingCustomFields ? (
          <Stack gap={2} my={2}>
            <Skeleton variant="rectangular" height={40} width={150} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} width={300} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} width={250} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
            <Skeleton variant="rectangular" height={40} />
          </Stack>
        ) : allCustomFields.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Typography variant="h6">No custom field added</Typography>
            <Typography variant="body2" color={"rgba(0, 0, 0, 0.60)"} mb={2}>
              Click the button below to add
            </Typography>
            <NdButton
              variant="contained"
              onClick={() => {
                setShowAddNewCustomFieldModal(true);
              }}
            >
              Add Field
            </NdButton>
          </Box>
        ) : (
          allCustomFields.map((customField) => (
            <CustomFieldItem key={customField.id} customField={customField} setAllCustomFields={setAllCustomFields} />
          ))
        )}
      </Box>
      {showAddNewCustomFieldModal && (
        <AddNewCustomFieldModal
          open={showAddNewCustomFieldModal}
          setOpen={setShowAddNewCustomFieldModal}
          fieldDetails={null}
          setAllCustomFields={setAllCustomFields}
        />
      )}
    </Box>
  );
}
