import { Box, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { a11yProps, TabPanel } from "src/Components/Recon360/ReconSettings/ReconSettings";
import ConsolidatedMISTemplates from "./ConsolidatedMISTemplates";
import MailMISTemplates from "./MailMISTemplates";

export default function ConsolidatedMIS() {
  const [value, setValue] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <LoggedInSkeleton topBarButtons={[]}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Consolidated MIS Template" {...a11yProps(0)} sx={{ textTransform: "uppercase" }} />
            <Tab label="Mail Reports" {...a11yProps(1)} sx={{ textTransform: "uppercase" }} />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0} key={0}>
          <ConsolidatedMISTemplates />
        </TabPanel>
        <TabPanel value={value} index={1} key={1}>
          <MailMISTemplates />
        </TabPanel>
      </Box>
    </LoggedInSkeleton>
  );
}
