import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import React from "react";
import { toast } from "react-toastify";
import { handleUnauthenticatedUser } from "src/App";
import { ErrorResponse } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import CustomToast from "../CustomToast";
const defaultErrorMsg = "Something went wrong. Please try again later.";

/**
 *
 * @interface DefaultRes
 * @key response?: string
 * @key message?: string
 */
interface DefaultRes {
  response?: string;
  message?: string;
  [key: string]: any;
}

/**
 * PURE Function *USE FETCH* to handle Axios Fetch operations with Alerts.
 * Supports GET & POST Methods Currently
 *
 * @export
 * @template T
 * @param {string} uri
 * @param {("GET" | "POST")} [method="GET"]
 * @param {{
 *     showSuccessToast?: boolean;
 *     config?: {};
 *     logResponse?: boolean;
 *     thenCallBack?: (response: AxiosResponse<T>) => void;
 *     catchCallBack?: (error: AxiosError<T>) => void;
 *     errorCallback?: (error: any) => void;
 *   }} prop
 */

type FetchMethod = "GET" | "POST" | "PUT" | "DELETE";

export interface PropertyBase<T extends DefaultRes> {
  readonly method?: FetchMethod;
  showSuccessToast?: boolean;
  logResponse?: boolean;
  thenCallBack?: (response: AxiosResponse<T & DefaultRes>) => void;
  catchCallBack?: (error: AxiosError<T & DefaultRes>) => void;
  errorCallback?: (error: any) => void;
  failureMessage: string;
}

type RecordType = {
  [key: string]: unknown;
  params?: never;
};

export interface NonDataMethods<T, M extends FetchMethod> extends PropertyBase<T> {
  readonly method?: M;
  config?: AxiosRequestConfig;
}
export interface DataMethods<T, M extends FetchMethod> extends PropertyBase<T> {
  readonly method?: M;
  config?: AxiosRequestConfig;
  data?: RecordType | FormData | URLSearchParams;
}

interface UseFetch {
  <R>(uri: string, method: "GET", props: NonDataMethods<R, "GET">): Promise<void>;
  <R>(uri: string, method: "DELETE", props: NonDataMethods<R, "DELETE">): Promise<void>;

  <R>(uri: string, method: "PUT", props: DataMethods<R, "PUT">): Promise<void>;
  <R>(uri: string, method: "POST", props: DataMethods<R, "POST">): Promise<void>;
}

const useFetch: UseFetch = async <T extends DefaultRes>(
  uri: string,
  method: "GET" | "POST" | "PUT" | "DELETE" = "GET",
  prop: {
    showSuccessToast?: boolean;
    data?: Record<string, unknown> | FormData | URLSearchParams;
    config?: Exclude<AxiosRequestConfig, "params">;
    logResponse?: boolean;
    thenCallBack?: (response: AxiosResponse<T & DefaultRes>) => void;
    catchCallBack?: (error: AxiosError<T & DefaultRes>) => void;
    errorCallback?: (error: any) => void;
    failureMessage: string;
  }
) => {
  const MethodMap = {
    GET: Axios.get,
    POST: Axios.post,
    PUT: Axios.put,
    DELETE: Axios.delete,
  };
  try {
    let promise: Promise<AxiosResponse<T & DefaultRes>>;
    if (method === "POST" || method === "PUT") {
      promise = MethodMap[method]<T & DefaultRes>(uri, prop.data, prop.config);
    } else {
      promise = MethodMap[method]<T & DefaultRes>(uri, prop.config);
    }
    return promise
      .then((response) => {
        if (prop.thenCallBack) prop.thenCallBack(response);
        if (prop.showSuccessToast) {
          toast.success(<CustomToast message={response.data.message || response.data.response} />);
        }
      })
      .catch((error: AxiosError<T & any>) => {
        if (prop.catchCallBack) prop.catchCallBack(error);

        if (uri !== API_ENDPOINTS.LOGIN.url) {
          handleUnauthenticatedUser(error);
        }

        if (
          error.response?.status === null ||
          error.response?.status === undefined ||
          error.response?.status >= 500 ||
          !error.response
        ) {
          // Handle cases where status code is null or a server error (500+)
          const message = prop.failureMessage ? prop.failureMessage : `${defaultErrorMsg}`;
          toast.error(<CustomToast message={message} />);
        } else if (error?.response?.data?.message !== undefined && error?.response?.data?.message) {
          const dataObj = error.response.data as ErrorResponse;
          toast.error(<CustomToast message={dataObj.message} />);
        } else {
          const message = prop.failureMessage ? prop.failureMessage : `${defaultErrorMsg}`;
          toast.error(<CustomToast message={message} />);
        }

        console.log(error);
      });
  } catch (error: any) {
    if (prop.errorCallback) prop.errorCallback(error);
    const message = prop.failureMessage ? prop.failureMessage : `${defaultErrorMsg}`;
    toast.error(<CustomToast message={message} />);
    console.log(error);
  }
};

export default useFetch;
