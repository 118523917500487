import { Close } from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { TaskBoardTableView } from "./Interfaces";

interface DeleteViewConfirmationModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  tableView: TaskBoardTableView;
  listAllViews: () => void;
  companyId: string;
  branchCode: string;
}
export default function DeleteViewConfirmationModal({
  open,
  setOpen,
  tableView,
  listAllViews,
  companyId,
  branchCode,
}: DeleteViewConfirmationModalProps) {
  const [isDeletingView, setIsDeletingView] = useState<boolean>(false);

  const handleDeleteTableView = () => {
    setIsDeletingView(true);
    useFetch(API_ENDPOINTS.DELETE_TABLE_VIEW(tableView.id).url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_TABLE_VIEW(tableView.id).failureMessage,
      showSuccessToast: true,
      thenCallBack: () => {
        setIsDeletingView(false);
        listAllViews();
        setOpen(false);
      },
      catchCallBack: () => {
        setIsDeletingView(false);
        listAllViews();
        setOpen(false);
      },
    });
  };
  return (
    <Dialog open={open} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"} width={"90%"}>
          Are you sure you want to delete this table view?
        </DialogTitle>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Alert severity="error" className="fw_500 fs_14">
          The table view {tableView.name} will be deleted and will not be recovered.
        </Alert>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="error"
          className={$.BR_fix}
          disabled={isDeletingView}
          onClick={() => {
            uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_CUSTOMVIEW_DELETE_CLICK.functionName, companyId, branchCode, {
              message: uiLoggerNamesTaskBoard.UI_WF_TB_CUSTOMVIEW_DELETE_CLICK.message,
            });
            handleDeleteTableView();
          }}
        >
          {isDeletingView && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
