import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";

export default function DeleteTemplateModal({ open, setOpen, templateName, templateId, listAllTemplates }) {
  const [isDeletingTemplate, setIsDeletingTemplate] = useState(false);
  const handleDeleteReport = () => {
    setIsDeletingTemplate(true);
    useFetch("/api/Admin/MIS/DeleteTemplate", "POST", {
      failureMessage: "Failed to delete template",
      data: { templateId: templateId },
      showSuccessToast: true,
      thenCallBack: () => {
        setIsDeletingTemplate(false);
        setOpen(false);
        listAllTemplates();
      },
      catchCallBack: () => {
        setIsDeletingTemplate(false);
        setOpen(false);
        listAllTemplates();
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure?</DialogTitle>
      <Divider />
      <DialogContent>
        <Alert severity="error">
          This will delete the {templateName} template permanently and it will not be recovered.
        </Alert>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: 2 }}>
        <Button
          color="error"
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingTemplate}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            handleDeleteReport();
          }}
        >
          {isDeletingTemplate && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
