import React, { useEffect, useRef, useState } from "react";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import useFetch from "../Common/useFetch";

interface AnnotationProps {
  data: {
    image_data: string;
    image_file_name: string;
    words_list: { word: string; boundingBox: number[] }[];
  };
}

const labelOptions = ["Junk", "Date", "Type", "Number", "Debit", "Credit", "Other"];

const labelKeyMapping: any = {
  ".": "Junk",
  "1": "Date",
  t: "Type",
  n: "Number",
  d: "Debit",
  c: "Credit",
  p: "Other",
};

const label2color: any = {
  Junk: "gray",
  Date: "blue",
  Type: "orange",
  Number: "purple",
  Debit: "red",
  Credit: "green",
  Other: "yellow",
};

const AnnotationTool: React.FC<AnnotationProps> = ({ data }) => {
  const currentWordIndex = useRef(0);
  const [annotations, setAnnotations] = useState<{ word: string; boundingBox: number[]; label: string }[]>([]);

  const [imageWidth, setImageWidth] = useState<number>(0);
  const [imageHeight, setImageHeight] = useState<number>(0);

  const [fileAnnotated, setFileAnnotated] = useState<boolean>(false);

  useEffect(() => {
    currentWordIndex.current = 0;
    setAnnotations([]);
    const img = new Image();

    img.onload = () => {
      setImageWidth(img.width * 0.3);
      setImageHeight(img.height * 0.3);
    };

    img.src = data.image_data;

    const handleKeyDown = (event: KeyboardEvent) => {
      const keyPressed = event.key; // Get the pressed key (convert to uppercase for consistency)

      // Check if the pressed key exists in the labelOptions dictionary
      if (keyPressed in labelKeyMapping) {
        const label = labelKeyMapping[keyPressed];

        const tempWordIndex = currentWordIndex.current;

        if (currentWordIndex.current === data.words_list.length - 1) {
          // All words annotated, do something with the annotations
          setFileAnnotated(true);
        } else {
          currentWordIndex.current += 1;
        }
        setAnnotations((prevAnnotations) => {
          const updatedAnnotations = [...prevAnnotations];
          updatedAnnotations[tempWordIndex] = {
            ...data.words_list[tempWordIndex],
            label,
          };
          return updatedAnnotations;
        });
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [data]);

  useEffect(() => {}, [annotations]);

  useEffect(() => {
    if (!fileAnnotated) return;

    const jsonPath = `${data.image_file_name}.json`;

    const dataToWrite = JSON.stringify({ words_list: annotations }, null, 2);
    const blob = new Blob([dataToWrite], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    // Create a download link for the JSON file
    const downloadLink = document.createElement("a");
    downloadLink.href = url;
    downloadLink.download = jsonPath;
    downloadLink.click();
    // eslint-disable-next-line
  }, [fileAnnotated]);

  const currentWord = data.words_list[currentWordIndex.current];

  return (
    <div style={{ display: "flex" }}>
      {imageWidth && imageHeight ? (
        <div style={{ position: "relative", marginRight: "20px" }}>
          <img src={data.image_data} alt="PDF Pic" height={imageHeight} width={imageWidth} />
          {currentWord && !fileAnnotated ? (
            <div
              style={{
                position: "absolute",
                border: "2px solid black",
                left: `${currentWord.boundingBox[0] * imageWidth}px`,
                top: `${currentWord.boundingBox[1] * imageHeight}px`,
                width: `${(currentWord.boundingBox[2] - currentWord.boundingBox[0]) * imageWidth}px`,
                height: `${(currentWord.boundingBox[3] - currentWord.boundingBox[1]) * imageHeight}px`,
              }}
            />
          ) : (
            ""
          )}
        </div>
      ) : null}
      {annotations?.map((word, index) => {
        const color = label2color[labelOptions[labelOptions.indexOf(annotations[index].label)]];
        return (
          <div
            key={index}
            style={{
              position: "absolute",
              border: `2px solid ${color}`,
              left: `${word.boundingBox[0] * imageWidth}px`,
              top: `${word.boundingBox[1] * imageHeight}px`,
              width: `${(word.boundingBox[2] - word.boundingBox[0]) * imageWidth}px`,
              height: `${(word.boundingBox[3] - word.boundingBox[1]) * imageHeight}px`,
            }}
          />
        );
      })}
      <h1>Annotation Tool : </h1>
      <div style={{ color: "teal" }}> &nbsp; {currentWord && currentWord.word}</div>
    </div>
  );
};

const AnnotationPage = () => {
  const [pdfAnnotationsData, setPdfAnnotationsData] = useState<any>(null);

  useEffect(() => {
    useFetch(API_ENDPOINTS.ANNOTATE.url, "GET", {
      failureMessage: API_ENDPOINTS.ANNOTATE.failureMessage,
      thenCallBack: (response) => {
        setPdfAnnotationsData(response.data);
      },
      catchCallBack: () => {
        alert("Error!");
        setPdfAnnotationsData(null);
      },
    });
  }, []);

  return pdfAnnotationsData ? <AnnotationTool data={pdfAnnotationsData} /> : <div>Annotation Tool</div>;
};

export default AnnotationPage;
