import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete, Box, IconButton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { ALL_OPTION, UserView } from "./MISTemplateModal";

export default function AccountSelectSection({
  selection,
  index,
  selectedAccounts,
  setSelectedAccounts,
  accountOptions,
  updateAccountView,
  loadingAccountOptions,
  defaultViewOptions,
}) {
  const [viewOptions, setViewOptions] = useState<UserView[]>([]);

  useEffect(() => {
    listAllViewsForAccount(selection.actor?.actorId);
  }, []);

  const listAllViewsForAccount = (actorId: number) => {
    useFetch("/api/Admin/MIS/ListAllUserView", "GET", {
      failureMessage: "Failed to fetch views",
      config: {
        params: {
          actorId,
        },
      },
      thenCallBack: (response) => {
        setViewOptions([
          ...defaultViewOptions,
          ...(response.data.viewDetails?.map((view) => ({ ...view, isDefault: false })) || []),
        ]);
      },
    });
  };

  const handleAccountChange = (index: number, value) => {
    const updatedAccounts = [...selectedAccounts];
    updatedAccounts[index].actor = value;
    updatedAccounts[index].view = null; // Reset view when account changes

    // If "All" is selected in the first row, reset to just that row
    if (index === 0 && value?.actorId === -1) {
      setSelectedAccounts([{ id: 1, actor: value, view: null }]);
    } else {
      setSelectedAccounts(updatedAccounts);
    }
  };

  const handleViewChange = (value) => {
    // Update the view in the parent component
    updateAccountView(selection.id, value);
  };

  const handleRemoveAccount = (index: number) => {
    const updatedAccounts = [...selectedAccounts];
    updatedAccounts.splice(index, 1);
    setSelectedAccounts(updatedAccounts);
  };

  // Get available account options excluding already selected ones
  const getAvailableAccountOptions = (index: number) => {
    // Add "All" option only for the first row and if there are 15 or fewer accounts (excluding All)
    const baseOptions = [...accountOptions];
    const shouldShowAllOption = index === 0 && accountOptions.length <= 15;

    if (shouldShowAllOption) {
      baseOptions.unshift(ALL_OPTION);
    }

    const allOptions = baseOptions.filter((option) => {
      return !selectedAccounts.some(
        (item, idx) => idx !== index && item.actor && item.actor.actorId === option.actorId
      );
    });

    if (allOptions.length === 1 && allOptions[0].actorId === -1) {
      return [];
    }

    return allOptions;
  };

  return (
    <Box key={selection.id} sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-end", gap: 2 }}>
      <Box sx={{ width: "45%" }}>
        <Typography variant="subtitle2">Select Account</Typography>
        <Autocomplete
          loading={loadingAccountOptions}
          fullWidth
          value={selection.actor}
          onChange={(_event, value) => {
            handleAccountChange(index, value);
            listAllViewsForAccount(value.actorId);
          }}
          isOptionEqualToValue={(option, value) => option.actorId === value.actorId}
          options={getAvailableAccountOptions(index)}
          getOptionLabel={(option) => option.actorName}
          renderInput={(params) => (
            <TextField
              fullWidth
              sx={{
                "& fieldset": {
                  borderRadius: "4px",
                },
              }}
              {...params}
              placeholder="Select account"
            />
          )}
        />
      </Box>
      <Box sx={{ width: "45%" }}>
        <Typography variant="subtitle2">Select View</Typography>
        <Autocomplete
          fullWidth
          value={selection.view}
          options={viewOptions}
          onChange={(_e, newValue) => handleViewChange(newValue)}
          disabled={!selection.actor}
          getOptionLabel={(option) => `${option.viewName} ${option.userName ? ` - ${option.userName}` : ""}`}
          sx={{
            "& fieldset": {
              borderRadius: "4px",
            },
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              sx={{
                "& fieldset": {
                  borderRadius: "4px",
                },
              }}
              {...params}
              placeholder="Select View"
            />
          )}
        />
      </Box>
      {selectedAccounts.length > 1 && (
        <IconButton onClick={() => handleRemoveAccount(index)} sx={{ mb: 1 }}>
          <DeleteIcon color="error" />
        </IconButton>
      )}
    </Box>
  );
}
