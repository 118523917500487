import { Clear } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { NAKAD_SERVICE } from "../../Tickets/TasksHistoryModal";

const getInvoiceDateForAllSelectedTasks = (selectedTasks, invoiceType): Date | null => {
  const invoiceDateType = invoiceType === NAKAD_SERVICE.Recon ? "reconInvoiceDate" : "pcInvoiceDate";

  if (selectedTasks.length === 0) return null;
  const uniqueDates = new Set(
    selectedTasks.map((task) => (task[invoiceDateType] ? new Date(task[invoiceDateType]).getTime() : null))
  );
  // If there is only one unique date, return it
  return uniqueDates.size === 1 ? (([...uniqueDates][0] as Date) ? new Date([...uniqueDates][0] as Date) : null) : null;
};

export default function InvoiceBulkTasksModal({
  open,
  setOpen,
  invoiceType,
  selectedTasks,
  setShowBulkTasksModal,
  refetchData,
  setClearRows,
  companyId,
  branchCode,
}) {
  const selectedDateInitial = getInvoiceDateForAllSelectedTasks(selectedTasks, invoiceType);

  const allTasksAreInvoiced = selectedTasks.every(
    (task) =>
      task.invoiceStatus === (invoiceType === NAKAD_SERVICE.Recon ? "Recon invoiced" : "PC invoiced") ||
      task.invoiceStatus === "PC and Recon invoiced"
  );

  const [isChecked, setIsChecked] = useState(allTasksAreInvoiced);
  const [selectedDate, setSelectedDate] = useState<Date | null>(selectedDateInitial);
  const [selectedDateErrorText, setSelectedDateErrorText] = useState("");
  const [isUpdatingInvoiceDetails, setIsUpdatingInvoiceDetails] = useState<boolean>(false);

  const updateInvoiceDetails = () => {
    uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_INVOICETASKS_SAVE_CLICK.functionName, companyId, branchCode, {
      message: uiLoggerNamesTaskBoard.UI_WF_TB_BULKTASKS_INVOICETASKS_SAVE_CLICK.message,
    });
    setIsUpdatingInvoiceDetails(true);
    const payloadObj = {};

    if (isChecked) {
      if (invoiceType === NAKAD_SERVICE.Recon) {
        payloadObj["reconInvoicingDate"] = selectedDate;
      } else if (invoiceType === NAKAD_SERVICE.PartnerCommunication) {
        payloadObj["pcInvoicingDate"] = selectedDate;
      }
    } else {
      if (invoiceType === NAKAD_SERVICE.Recon) {
        payloadObj["reconInvoicingDate"] = null;
      } else if (invoiceType === NAKAD_SERVICE.PartnerCommunication) {
        payloadObj["pcInvoicingDate"] = null;
      }
    }
    payloadObj["taskDetails"] = selectedTasks.map((task) => {
      return {
        isPcInvoiced: task.pcInvoiceDate ? true : false,
        isReconInvoiced: task.reconInvoiceDate ? true : false,
        ticketId: task.taskId,
        mappingId: task.mappingId,
      };
    });
    payloadObj["isInvoicing"] = isChecked;
    useFetch(API_ENDPOINTS.TICKETS_UPDATE_INVOICE_DETAILS.url, "POST", {
      failureMessage: API_ENDPOINTS.TICKETS_UPDATE_INVOICE_DETAILS.failureMessage,
      showSuccessToast: true,
      data: payloadObj,
      thenCallBack: () => {
        setShowBulkTasksModal(false);
        setIsUpdatingInvoiceDetails(false);
        setOpen(false);
        refetchData();
        setClearRows(true);
      },
      catchCallBack: () => {
        setIsUpdatingInvoiceDetails(false);
      },
    });
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>
        <Typography variant="h6">
          Mark {invoiceType === NAKAD_SERVICE.Recon ? "Recon" : "PC"} tasks as ready to invoice
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Alert
          severity="warning"
          sx={{
            ".MuiAlert-message": {
              fontSize: "14px",
              fontWeight: "400",
            },
            marginBottom: "16px",
          }}
        >
          The action will override the invoice status with the current action.
        </Alert>
        <Stack direction={"row"} gap={2} justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
            <Typography>{invoiceType === NAKAD_SERVICE.Recon ? "Recon" : "PC"} tasks as ready to invoice</Typography>
          </Stack>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              sx={{ "& fieldset": { borderRadius: "4px" } }}
              label="Invoice Date"
              onError={(error) => {
                if (error) setSelectedDateErrorText(error);
                else setSelectedDateErrorText("");
              }}
              onChange={(date: Date) => {
                setSelectedDate(date);
              }}
              disabled={!isChecked}
              value={selectedDate}
              format="dd/MM/yyyy"
              slotProps={{
                textField: {
                  helperText: selectedDateErrorText && "Invalid Date",
                  sx: { width: "200px" },
                  size: "small",
                  InputProps: {
                    style: { borderRadius: "4px" },
                    startAdornment: (
                      <InputAdornment position="start" sx={{ margin: 0 }}>
                        {selectedDate && (
                          <IconButton
                            disabled={!isChecked}
                            size="small"
                            sx={{ position: "absolute", right: "40px" }}
                            onClick={() => {
                              setSelectedDate(null);
                            }}
                          >
                            <Clear />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  },
                },
              }}
            />
          </LocalizationProvider>
        </Stack>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          variant="contained"
          disabled={(isChecked && (selectedDate === null || selectedDateErrorText !== "")) || isUpdatingInvoiceDetails}
          onClick={updateInvoiceDetails}
        >
          {isUpdatingInvoiceDetails && <LoadingIcon loading={true} />}
          Save
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
