import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Currency } from "dinero.js";
import React, { useContext } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { gapAccountedAsName, ManualReconHistoryRecordState } from "../../../Utils/Recon/Recon360/Constants";
import { Recon360Context } from "../Recon360";
import { ManualReconContext } from "./ManualRecon";

const ReconcileConfirmWarningModal = () => {
  const {
    isOpenReconcileConfirmWarningModal,
    setIsOpenReconcileConfirmWarningModal,
    reconcileEntries,
    absoluteReconcileTotalSum,
    selectedRowOwn,
    selectedRowBp,
    showLoadingIconReconcile,
    gapAccountedName,
    setGapAccountedName,
    reconcileTotalSum,
    reconcileTotalPercentage,
    reconcileRemarks,
    setReconcileRemarks,
    createAndFetchManualReconSuggestions,
    getSuggestedHistory,
  } = useContext(ManualReconContext);
  const { currency } = useContext(Recon360Context);

  const calculateAmountTolerance = (selectedRowOwn, selectedRowBp) => {
    // Ensure inputs are arrays; default to empty array if null/undefined/not an array
    selectedRowOwn = Array.isArray(selectedRowOwn) ? selectedRowOwn : [];
    selectedRowBp = Array.isArray(selectedRowBp) ? selectedRowBp : [];

    if (selectedRowOwn.length > 0 && selectedRowBp.length > 0) {
      const minEntryTypeTolerance = Math.min(
        ...selectedRowOwn.map((item) => item.entryTypeTolerance ?? Infinity),
        ...selectedRowBp.map((item) => item.entryTypeTolerance ?? Infinity)
      );
      return minEntryTypeTolerance === Infinity ? null : minEntryTypeTolerance;
    }

    if (selectedRowOwn.length > 0) {
      const minReversalTolerance = Math.min(...selectedRowOwn.map((item) => item.reversalTolerance ?? Infinity));
      return minReversalTolerance === Infinity ? null : minReversalTolerance;
    }

    if (selectedRowBp.length > 0) {
      const minReversalTolerance = Math.min(...selectedRowBp.map((item) => item.reversalTolerance ?? Infinity));
      return minReversalTolerance === Infinity ? null : minReversalTolerance;
    }

    return null; // Default case if no selection is made
  };

  const amountTolerance = calculateAmountTolerance(selectedRowOwn, selectedRowBp);

  const handleGapReasonChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGapAccountedName(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={isOpenReconcileConfirmWarningModal}
        aria-labelledby="reconcileConfirmWarningModal"
        maxWidth="md"
        fullWidth
        sx={{ width: "664px", margin: "auto" }}
      >
        <DialogTitle>
          {absoluteReconcileTotalSum > 0 ? "Where do you want to put the Gap?" : "Why do you want to reconcile?"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          disabled={showLoadingIconReconcile}
          onClick={() => {
            setIsOpenReconcileConfirmWarningModal(false);
            setGapAccountedName("");
            setReconcileRemarks("");
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          {" "}
          <CloseIcon />
        </IconButton>
        <DialogContent dividers sx={{ p: 2 }}>
          {absoluteReconcileTotalSum > 0 && (
            <Typography
              sx={{
                fontWeight: 500,
                backgroundColor: "#f3f3f3",
                padding: "8px",
                borderRadius: "4px",
                color: "#000000de",
              }}
            >
              Gap:{" "}
              {reconcileTotalSum !== null
                ? formatMoney(ToDineroObj(reconcileTotalSum, currency.current as Currency))
                : "-"}{" "}
              {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 ? `(${reconcileTotalPercentage} %)` : ""}
            </Typography>
          )}
          {(absoluteReconcileTotalSum > 0 || absoluteReconcileTotalSum > amountTolerance) && (
            <FormControl sx={{ mt: 2, mb: 2, width: "100%" }}>
              <RadioGroup value={gapAccountedName} onChange={handleGapReasonChange}>
                {selectedRowOwn?.length > 0 && selectedRowBp?.length > 0 && absoluteReconcileTotalSum > 0 && (
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="subtitle1" sx={{ pl: 1 }}>
                        TDS
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel value={gapAccountedAsName.tds} control={<Radio />} label="" />
                    </Grid>
                  </Grid>
                )}
                {absoluteReconcileTotalSum > 0 && (
                  <Grid container alignItems="center">
                    <Grid item xs>
                      <Typography variant="subtitle1" sx={{ pl: 1 }}>
                        Rounding Off
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel value={gapAccountedAsName.roundingError} control={<Radio />} label="" />
                    </Grid>
                  </Grid>
                )}

                {absoluteReconcileTotalSum > amountTolerance &&
                  !(selectedRowOwn?.length > 0 && selectedRowBp?.length > 0) && (
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="subtitle1" sx={{ pl: 1 }}>
                          Miscellaneous
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={gapAccountedAsName.miscellaneous} control={<Radio />} label="" />
                      </Grid>
                    </Grid>
                  )}

                {absoluteReconcileTotalSum > amountTolerance &&
                  selectedRowOwn?.length > 0 &&
                  selectedRowBp?.length > 0 && (
                    <Grid container alignItems="center">
                      <Grid item xs>
                        <Typography variant="subtitle1" sx={{ pl: 1 }}>
                          Amount-Mismatch
                        </Typography>
                      </Grid>
                      <Grid item>
                        <FormControlLabel value={gapAccountedAsName.mismatchOthers} control={<Radio />} label="" />
                      </Grid>
                    </Grid>
                  )}
              </RadioGroup>
            </FormControl>
          )}
          <TextField
            className="update-custom-border-radius"
            fullWidth
            variant="outlined"
            multiline
            placeholder="Remarks (This will help us improve the tool)"
            value={reconcileRemarks}
            onChange={(e) => setReconcileRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions className="dialog_footer">
          <div className="flex_end">
            <Button
              variant="contained"
              className={gapAccountedName === "" ? "grey_btn" : "green_btn"}
              disabled={showLoadingIconReconcile || gapAccountedName === ""}
              onClick={async () => {
                await reconcileEntries(gapAccountedName);
                await createAndFetchManualReconSuggestions("");
                await getSuggestedHistory(ManualReconHistoryRecordState.suggested);
              }}
              sx={{ marginRight: 1 }}
              startIcon={<LoadingIcon loading={showLoadingIconReconcile} />}
            >
              Reconcile
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ReconcileConfirmWarningModal;
