import { Explore } from "@mui/icons-material";
import { SpaceDashboardOutlined } from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CallMadeIcon from "@mui/icons-material/CallMade";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import HistoryIcon from "@mui/icons-material/History";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SettingsIcon from "@mui/icons-material/Settings";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import {
  Autocomplete,
  Box,
  Button,
  IconButton,
  InputAdornment,
  ListItemIcon,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import dayjs from "dayjs";
import saveAs from "file-saver";
import {
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ColumnPinningState,
  MRT_ColumnSizingState,
} from "material-react-table";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import ReactTable from "src/Components/ReactTable/ReactTable";
import { UserRole, UserTypes } from "src/entity/models/FrontendActor";
import { allTaskCategories, ListOwnTallyCompanies } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { filterDateBetweenStartAndEndDate, formatDate } from "src/Utils/DateUtils";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import DiscardTaskModal from "../DisputeResolution/ClosureTracker/DiscardTaskModal";
import SaveTaskModal from "../DisputeResolution/ClosureTracker/SaveTaskModal";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { CUSTOM_FIELD_TYPE } from "../ReconSettings/WorkflowSettings/AddNewCustomFieldModal";
import BulkTasksModal from "../Tickets/BulkTasksModal";
import EditTaskModal from "../Tickets/EditTaskModal";
import TaskAttachmentsModal from "../Tickets/TaskAttachmentsModal";
import TasksHistoryModal, { NAKAD_SERVICE } from "../Tickets/TasksHistoryModal";
import { renderGroupedOptions, TicketMetaStatuses } from "../Tickets/ViewTickets";
import CustomisedShowHideColumns from "./CustomisedShowHideColumns";
import DeleteViewConfirmationModal from "./DeleteViewConfirmationModal";
import DownloadCustomisedReportModal from "./DownloadCustomisedReportModal";
import { IntegratedDropDownIcons } from "./IntegratedDropdownIcons";
import {
  formatCurrencyCommaSeparated,
  mappingForTaskBoardStatuses,
  mappingForTaskBoardViews,
  TaskBoardTableView,
  TaskTrackerFilters,
  TASK_PHASES,
} from "./Interfaces";
import InvoicingSingleTaskModal from "./Invoicing/InvoicingSingleTaskModal";
import SaveViewModal from "./SaveViewModal";
import styles from "./TaskTracker.module.scss";
import TaskTrackerFilter from "./TaskTrackerFilter";
import TaskTrackerFiltersBars from "./TaskTrackerFiltersBars";

export const dateRangeColumns = [];
export const numberRangeColumns = [];
export const COLUMNS_UI_MAPPING = {};
export const textTypeColumns = [];
export const formatColumnFilters = (columnFilters: MRT_ColumnFiltersState) => {
  return columnFilters.map((filter) => {
    if (dateRangeColumns.includes(filter.id)) {
      const startDate = filter.value[0] ? dayjs(filter.value[0]) : null;
      const endDate = filter.value[1] ? dayjs(filter.value[1]) : null;
      return {
        id: filter.id,
        value: [startDate, endDate],
      };
    } else {
      return filter;
    }
  });
};

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;
interface ITaskTrackerContext {
  setColumnFilters: StateDispatch<MRT_ColumnFiltersState>;
  appliedFilters: TaskTrackerFilters;
  setAppliedFilters: StateDispatch<TaskTrackerFilters>;
  listAllTasks: () => void;
  appliedFiltersRef: React.MutableRefObject<TaskTrackerFilters>;
  selectedTableView: TaskBoardTableView;
}
export const TaskTrackerContext = createContext<ITaskTrackerContext>(null);

export default function TaskTracker() {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);

  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(DEFAULT_COMPANY_ID);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  const [allTasks, setAllTasks] = useState([]);
  const [isLoadingAllTasks, setIsLoadingAllTasks] = useState<boolean>(true);
  const [openTaskTrackerFilter, setOpenTaskTrackerFilter] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<TaskTrackerFilters>({});
  const appliedFiltersRef = useRef<TaskTrackerFilters>({});
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [grouping, setGrouping] = useState<string[]>([]);
  const [columnPinning, setColumnPinning] = useState<MRT_ColumnPinningState>({});
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnSizing, setColumnSizing] = useState<MRT_ColumnSizingState>({});

  const [allUsers, setAllUsers] = useState<{ id: number; name: string }[]>([]);
  const [taskCategories, setTaskCategories] = useState(allTaskCategories);
  const [allTaskLabels, setAllTaskLabels] = useState([]);
  const [allStatuses, setAllStatuses] = useState([]);
  const [partnerOptions, setPartnerOptions] = useState<{ partnerName: string }[]>([]);
  const [vendorCodeOptions, setVendorCodeOptions] = useState<{ vendorCode: string }[]>([]);
  const [taskIdsOptions, setTaskIdsOptions] = useState<{ taskId: string }[]>([]);
  const [showSaveTaskModal, setShowSaveTaskModal] = useState<boolean>(false);
  const [showDiscardTaskModal, setShowDiscardTaskModal] = useState<boolean>(false);
  const statusChangeTaskDetails = useRef<{ taskId: number; mappingId: number }>(null);
  const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);
  const currentSelectedTaskDetails = useRef<{ businessPartnerName: string; taskId: number; mappingId: number }>(null);
  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState(false);
  const updatingStatusBpId = useRef(null);

  const [showBulkTasksModal, setShowBulkTasksModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState([]);
  const [clearRows, setClearRows] = useState(false);

  const [isDownloadingReport, setIsDownloadingReport] = useState<boolean>(false);
  const [showDownloadCustomisedReportModal, setShowDownloadCustomisedReportModal] = useState<boolean>(false);
  const taskInvoicingDetails = useRef(null);
  const [showInvoicingTaskModal, setShowInvoicingTaskModal] = useState<boolean>(false);

  const [showSaveViewModal, setShowSaveViewModal] = useState<boolean>(false);
  const [showDeleteViewConfirmationModal, setShowDeleteViewConfirmationModal] = useState<boolean>(false);
  const deleteViewRef = useRef<TaskBoardTableView>(null);
  const [allTableViews, setAllTableViews] = useState<TaskBoardTableView[]>([]);
  const [selectedTableView, setSelectedTableView] = useState<TaskBoardTableView>(null);
  const [tableInstance, setTableInstance] = useState(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const [columnDefinitionData, setColumnDefinitionData] = useState([]);
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const getAllData = async () => {
    await listMainPageData();
    await getColumnDefinitionData();
    listAllViews();
    listAllTasks();
    getAllTaskLabels();
  };
  useEffect(() => {
    if (actor.integration === false) {
      getAllData();
    }
  }, []);

  const companyNameSelect = (e: Record<string, any>, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        getAllData();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        getAllData();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };
  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
  };

  const saveLastSelectedView = async (currentTableView) => {
    setSelectedTableView(currentTableView);
    setIsLoadingAllTasks(true);
    await useFetch(API_ENDPOINTS.SAVE_LAST_SELECTED_VIEW.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_LAST_SELECTED_VIEW.failureMessage,
      showSuccessToast: true,
      data: {
        isDefaultSelectedView: currentTableView.isDefault,
        selectedViewId: currentTableView.id,
      },
      thenCallBack: (res) => {
        setIsLoadingAllTasks(false);
        const colFilters = res.data.updatedViewDetails.columnFilters;
        if (res.data.updatedViewDetails.viewName === "My tasks") {
          colFilters.push({ id: "maker", value: [actor.userName] });
        }
        setColumnFilters(formatColumnFilters(colFilters));
        setGrouping(res.data.updatedViewDetails.grouping);
        setColumnVisibility(res.data.updatedViewDetails.columnVisibility);
        setColumnOrder(res.data.updatedViewDetails.columnOrder);
        setColumnPinning(res.data.updatedViewDetails.columnPinning);
        setColumnSizing(res.data.updatedViewDetails.columnSizing);
      },
      catchCallBack: () => {
        setIsLoadingAllTasks(false);
      },
    });
  };

  const getColumnDefinitionData = async () => {
    await useFetch("/api/Workflow/TaskBoard/Columns", "GET", {
      failureMessage: "Failed to fetch column definitions",
      thenCallBack: (response) => {
        setColumnDefinitionData(response.data.columns);
      },
    });
  };

  const listAllViews = () => {
    useFetch<{ allTableViews: TaskBoardTableView[] }>(API_ENDPOINTS.GET_ALL_VIEWS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ALL_VIEWS.failureMessage,
      thenCallBack: (response) => {
        setAllTableViews(response.data.allTableViews || []);
        const currentSelectedView = response.data.allTableViews.find((view) => {
          if (response.data.isSelectedDefaultView) {
            return view.isDefault === true && view.id === response.data.selectedViewId;
          } else {
            return view.isDefault === false && view.id === response.data.selectedViewId;
          }
        });
        setSelectedTableView(currentSelectedView);
        const colFilters = response.data.selectedViewDetails.columnFilters;
        if (response.data.selectedViewDetails.viewName === "My tasks") {
          colFilters.push({ id: "maker", value: [actor.userName] });
        }
        setColumnFilters(formatColumnFilters(colFilters));
        setGrouping(response.data.selectedViewDetails.grouping);
        setColumnVisibility(response.data.selectedViewDetails.columnVisibility);
        setColumnOrder(response.data.selectedViewDetails.columnOrder);
        setColumnPinning(response.data.selectedViewDetails.columnPinning);
        setColumnSizing(response.data.selectedViewDetails.columnSizing);
      },
    });
  };

  const listAllTasks = async () => {
    setIsLoadingAllTasks(true);
    await useFetch(API_ENDPOINTS.GET_ALL_TASKS_DATA.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_ALL_TASKS_DATA.failureMessage,
      data: {
        companyId: companyId.current,
        branchCode: branchCode.current,
        tableView: selectedTableView,
        tableFilters: columnFilters,
        mainFilters: appliedFiltersRef.current,
      },
      thenCallBack: (response) => {
        setAllTasks(response.data.allFinalTaskList || []);
        setIsLoadingAllTasks(false);
      },
      catchCallBack: () => {
        setIsLoadingAllTasks(false);
      },
    });
  };
  const getAllTaskLabels = () => {
    useFetch(API_ENDPOINTS.GET_TASK_LABELS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_TASK_LABELS.failureMessage,
      thenCallBack: (res) => {
        setAllTaskLabels(res.data.allLabels?.map((taskLabel) => ({ title: taskLabel })));
      },
    });
  };

  const listMainPageData = async () => {
    await useFetch(API_ENDPOINTS.GET_ALL_MAIN_PAGE_DATA.url, "GET", {
      config: {
        params: {
          companyId: companyId.current,
          branchCode: branchCode.current,
        },
      },
      failureMessage: API_ENDPOINTS.GET_ALL_MAIN_PAGE_DATA.failureMessage,
      thenCallBack: (response) => {
        setAllUsers(response.data.users || []);
        setTaskCategories(response.data.ticketCategories || allTaskCategories);
        setAllStatuses(response.data.statuses || []);
        setPartnerOptions(
          response.data.mainFilter.businessPartnerName?.map((partnerName) => ({ partnerName: partnerName })) || []
        );
        setVendorCodeOptions(
          response.data.mainFilter.vendorCode?.map((vendorCode) => ({ vendorCode: vendorCode })) || []
        );
        setTaskIdsOptions(response.data.mainFilter.taskId?.map((id) => ({ taskId: id.toString() })) || []);
      },
    });
  };

  const updateTaskMetaStatus = async (status: string, taskDetails: { taskId: number; mappingId: number }) => {
    uiLogger(mappingForTaskBoardStatuses[status].functionName, companyId.current, branchCode.current, {
      message: mappingForTaskBoardStatuses[status].functionName,
      businessPartnerId: updatingStatusBpId.current,
      ticketStatus: status,
      idOfTicket: taskDetails.taskId,
    });
    await useFetch(API_ENDPOINTS.UPDATE_TICKET_META_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_META_STATUS.failureMessage,
      showSuccessToast: true,
      data: {
        ticketId: taskDetails.taskId,
        mappingId: taskDetails.mappingId,
        metaStatus: status,
      },
      thenCallBack: () => {
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
      },
      catchCallBack: () => {
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
      },
    });
  };

  const updateTicketStatus = (statusId: number, ticketId: number, statusName: string, mappingId: number) => {
    const uiLogFunctionName =
      mappingForTaskBoardStatuses[statusName]?.functionName ||
      uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CUSTOMSTATUS_CLICK.functionName;
    const uiLogMessage =
      mappingForTaskBoardStatuses[statusName]?.message ||
      uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CUSTOMSTATUS_CLICK.message;
    uiLogger(uiLogFunctionName, companyId.current, branchCode.current, {
      message: uiLogMessage,
      ticketStatus: statusName,
      idOfTicket: ticketId,
      businessPartnerId: updatingStatusBpId.current,
      statusId: statusId,
    });
    useFetch(API_ENDPOINTS.UPDATE_TICKET_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_STATUS.failureMessage,
      data: {
        ticketId,
        mappingId,
        statusId,
      },
      thenCallBack: () => {
        listAllTasks();

        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
        toast.success(<CustomToast message="Successfully changed ticket status" />);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        listAllTasks();
        setShowSaveTaskModal(false);
        setShowDiscardTaskModal(false);
        toast.error(<CustomToast message={message} />);
      },
    });
  };

  const handleDownloadReport = async () => {
    uiLogger(
      uiLoggerNamesTaskBoard.UI_WF_TB_DOWNLOADTASKREPOSITORY_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message: uiLoggerNamesTaskBoard.UI_WF_TB_DOWNLOADTASKREPOSITORY_CLICK.message,
      }
    );
    const mergedFilters = {};

    // Iterate over columnFilters and merge values
    columnFilters.forEach((filter) => {
      const { id, value } = filter;
      const isDateRangeFilter = dateRangeColumns.includes(id);
      const isNumberRangeFilter = numberRangeColumns.includes(id);

      if (isDateRangeFilter) {
        const startDate = value[0] ? formatDate(dayjs(value[0]).toDate()) : null;
        const endDate = value[1] ? formatDate(dayjs(value[1]).toDate()) : null;
        if (startDate === null && endDate === null) return;
        mergedFilters[id] = [startDate, endDate];
      } else if (textTypeColumns.includes(id) || (value as any).length === 0) {
        return;
      } else if (isNumberRangeFilter) {
        const min = value[0] ? value[0] : null;
        const max = value[1] ? value[1] : null;
        if (min === null && max === null) return;
        mergedFilters[id] = [min, max];
      } else {
        mergedFilters[id] = value;
      }
    });
    setIsDownloadingReport(true);
    await useFetch(API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_DOWNLOAD_TASK_REPOSITORY_REPORT.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current,
        branchCode: branchCode.current,
        tableView: selectedTableView,
        tableFilters: mergedFilters,
        mainFilters: appliedFiltersRef.current,
      },
      thenCallBack: (response) => {
        const { workBookBase64, filename } = response.data;
        const excelData = Buffer.from(workBookBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
        setIsDownloadingReport(false);
      },
      catchCallBack: () => {
        setIsDownloadingReport(false);
      },
    });
  };

  const updateCustomFieldHandler = (taskId, columnId, value) => {
    useFetch(`/api/Workflow/Task/${taskId}/CustomField/${columnId}`, "PUT", {
      failureMessage: "Failed to update custom field",
      showSuccessToast: true,
      data: {
        value: value,
      },
      catchCallBack: () => {
        listAllTasks();
      },
    });
  };

  const columnsDefTasks = useMemo(() => {
    if (columnDefinitionData.length === 0) return [];

    return columnDefinitionData.map((currentColumn) => {
      if (currentColumn.filterVariant === "date-range") {
        dateRangeColumns.push(currentColumn.id);
      }
      if (currentColumn.filterVariant === "range") {
        numberRangeColumns.push(currentColumn.id);
      }
      if (currentColumn.filterVariant === "text") {
        textTypeColumns.push(currentColumn.id);
      }
      COLUMNS_UI_MAPPING[currentColumn.id] = currentColumn.header;
      const accessorFn = (row) => {
        if (currentColumn.id === "category" || currentColumn.customField?.listOptions?.isMultiSelect) {
          return row[currentColumn.id]?.join(", ") || "-";
        } else if (currentColumn.id === "taskId" || currentColumn.id === "partnerId") {
          return row[currentColumn.id]?.toString();
        } else if (currentColumn.filterVariant === "date-range") {
          return new Date(row[currentColumn.id]);
        } else if (currentColumn.filterVariant === "range") {
          return Number(row[currentColumn.id]) || "";
        }
        return row[currentColumn.id] || "-";
      };

      let filterFn: any = (row, _id, filterValue) => {
        if (filterValue.length === 0) return true;
        return filterValue.includes(row.original[currentColumn.id] ? row.original[currentColumn.id] : "-");
      };

      if (currentColumn.id === "category" || currentColumn.customField?.listOptions?.isMultiSelect) {
        filterFn = (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original[currentColumn.id]?.join(", ") || "-");
        };
      } else if (currentColumn.id === "taskId" || currentColumn.id === "partnerId") {
        filterFn = (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.taskId?.toString());
        };
      } else if (currentColumn.filterVariant === "date-range") {
        filterFn = (row, _id, filterValue) => {
          const startDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const endDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (startDate || endDate) {
            return filterDateBetweenStartAndEndDate(startDate, endDate, row.original[currentColumn.id]);
          }
        };
      } else if (currentColumn.filterVariant === "range") {
        filterFn = "betweenInclusive";
      } else if (currentColumn.filterVariant === "text") {
        filterFn = "contains";
      }

      const Cell = ({ row, column }) => {
        //if block => If the column is editable, return the editing component
        //else block => If the column is not editable, return the normal cell

        if (currentColumn.isEditable) {
          if (currentColumn.filterVariant === "date-range") {
            const [isEditing, setIsEditing] = useState(false);
            const [selectedDate, setSelectedDate] = useState(
              row.original[currentColumn.id]
                ? moment(new Date(row.original[currentColumn.id])).format("YYYY-MM-DD")
                : ""
            );
            return isEditing && row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <TextField
                value={selectedDate}
                fullWidth
                size="small"
                variant="standard"
                type="date"
                autoFocus
                onChange={(e) => {
                  setSelectedDate(e.target.value);
                }}
                onBlur={() => {
                  const newDateValue = moment(selectedDate).format("DD/MM/YYYY");
                  setAllTasks((prev) => {
                    return prev.map((task) => {
                      if (task.taskId === row.original.taskId) {
                        task[currentColumn.id] = selectedDate;
                      }
                      return task;
                    });
                  });
                  setIsEditing(false);
                  updateCustomFieldHandler(row.original.taskId, currentColumn.id, newDateValue);
                }}
              />
            ) : (
              <div
                onClick={() => {
                  setIsEditing(true);
                }}
                className={`textOverflow_hidden ${
                  row.original.metaStatus === TicketMetaStatuses.InProgress ? "editable_cell_hover" : ""
                }`}
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
              >
                {row.original[currentColumn.id] ? formatDate(row.original[currentColumn.id]) : "-"}
              </div>
            );
          } else if (currentColumn.filterVariant === "range") {
            const [isEditing, setIsEditing] = useState(false);

            return isEditing && row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <div className="textField_height">
                <MonetaryInput
                  variant="standard"
                  autoFocus
                  key={row.original[currentColumn.id]}
                  returnNull={true}
                  value={row.original[currentColumn.id]}
                  setValue={(value) => {
                    setAllTasks((prev) => {
                      return prev.map((task) => {
                        if (task.taskId === row.original.taskId) {
                          task[currentColumn.id] = value;
                        }
                        return task;
                      });
                    });
                    setIsEditing(false);
                    updateCustomFieldHandler(row.original.taskId, currentColumn.id, value);
                  }}
                  InputProps={{
                    startAdornment:
                      currentColumn.customField.type === CUSTOM_FIELD_TYPE.Currency ? (
                        <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                          {DefaultCurrency.INR}
                        </InputAdornment>
                      ) : null,
                  }}
                />
              </div>
            ) : (
              <div
                onClick={() => {
                  setIsEditing(true);
                }}
                className={`textOverflow_hidden ${
                  row.original.metaStatus === TicketMetaStatuses.InProgress ? "editable_cell_hover" : ""
                }`}
              >
                {currentColumn.customField.type === CUSTOM_FIELD_TYPE.Currency
                  ? row.original[currentColumn.id] === null
                    ? "-"
                    : formatMoney(ToDineroObj(row.original[currentColumn.id], "INR"))
                  : row.original[currentColumn.id] === null
                  ? "-"
                  : formatCurrencyCommaSeparated(row.original[currentColumn.id])}
              </div>
            );
          } else if (currentColumn.filterVariant === "text") {
            const [isEditing, setIsEditing] = useState(false);
            const [value, setValue] = useState(row.original[currentColumn.id]);
            return isEditing && row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <TextField
                value={value}
                fullWidth
                size="small"
                variant="standard"
                type="text"
                autoFocus
                onChange={(e) => {
                  setValue(e.target.value);
                }}
                onBlur={() => {
                  if (value?.length > 50) {
                    toast.error(<CustomToast message="Maximum length of the text is 50 characters" />);
                    return;
                  }
                  setAllTasks((prev) => {
                    return prev.map((task) => {
                      if (task.taskId === row.original.taskId) {
                        task[currentColumn.id] = value;
                      }
                      return task;
                    });
                  });
                  setIsEditing(false);
                  updateCustomFieldHandler(row.original.taskId, currentColumn.id, value);
                }}
              />
            ) : (
              <div
                onClick={() => {
                  setIsEditing(true);
                }}
                className={`textOverflow_hidden ${
                  row.original.metaStatus === TicketMetaStatuses.InProgress ? "editable_cell_hover" : ""
                }`}
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
              >
                {row.original[currentColumn.id] ? row.original[currentColumn.id] : "-"}
              </div>
            );
          } else if (
            currentColumn.filterVariant === "multi-select" &&
            currentColumn.customField.listOptions.isMultiSelect
          ) {
            const [isEditing, setIsEditing] = useState(false);

            const [selectedValues, setSelectedValues] = useState<string[]>(
              row.original[currentColumn.id] ? row.original[currentColumn.id] : []
            );
            return isEditing && row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <Autocomplete
                autoFocus
                limitTags={1}
                value={selectedValues}
                fullWidth={true}
                onChange={(_, value) => {
                  setSelectedValues(value);
                }}
                onBlur={() => {
                  setAllTasks((prev) => {
                    return prev.map((task) => {
                      if (task.taskId === row.original.taskId) {
                        task[currentColumn.id] = selectedValues;
                      }
                      return task;
                    });
                  });
                  setIsEditing(false);
                  updateCustomFieldHandler(row.original.taskId, currentColumn.id, selectedValues);
                }}
                size="small"
                multiple={true}
                options={currentColumn.customField.listOptions.values}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField autoFocus fullWidth {...params} variant="standard" />}
              />
            ) : (
              <div
                onClick={() => {
                  setIsEditing(true);
                }}
                className={`textOverflow_hidden ${
                  row.original.metaStatus === TicketMetaStatuses.InProgress ? "editable_cell_hover" : ""
                }`}
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
              >
                {row.original[currentColumn.id]?.length > 0 ? row.original[currentColumn.id].join(", ") : "-"}
              </div>
            );
          } else {
            const [isEditing, setIsEditing] = useState(false);
            const [selectedValue, setSelectedValue] = useState(
              row.original[currentColumn.id] ? row.original[currentColumn.id] : null
            );

            return isEditing && row.original.metaStatus === TicketMetaStatuses.InProgress ? (
              <Select
                variant="standard"
                size="small"
                fullWidth
                autoFocus
                value={selectedValue}
                onChange={(e) => {
                  setSelectedValue(e.target.value);
                }}
                onBlur={() => {
                  setAllTasks((prev) => {
                    return prev.map((task) => {
                      if (task.taskId === row.original.taskId) {
                        task[currentColumn.id] = selectedValue;
                      }
                      return task;
                    });
                  });
                  setIsEditing(false);
                  updateCustomFieldHandler(row.original.taskId, currentColumn.id, selectedValue);
                }}
              >
                {currentColumn.customField.listOptions.values.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            ) : (
              <div
                onClick={() => {
                  setIsEditing(true);
                }}
                className={`textOverflow_hidden ${
                  row.original.metaStatus === TicketMetaStatuses.InProgress ? "editable_cell_hover" : ""
                }`}
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
              >
                {row.original[currentColumn.id] ? row.original[currentColumn.id] : "-"}
              </div>
            );
          }
        } else {
          if (currentColumn.id === "businessPartnerName" || currentColumn.id === "taskId") {
            return (
              <div
                style={{ width: "inherit" }}
                className="textOverflow_hidden"
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
              >
                {row.original.metaStatus === TicketMetaStatuses.InProgress &&
                row.original.service === NAKAD_SERVICE.Recon ? (
                  <a
                    onClick={() => {
                      const { partnerId, businessPartnerName, taskId, status } = row.original;
                      uiLogger(
                        uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERSELECTFROMTABLE_CLICK.message,
                          businessPartnerId: partnerId,
                          idOfTicket: Number(taskId),
                          ticketStatus: status,
                        }
                      );
                      const params = `bpId=${partnerId}&bpName=${btoa(encodeURIComponent(businessPartnerName))}`;
                      const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                      window.open(uri, "_blank")?.focus();
                    }}
                    className="textOverflow_hidden_anchor"
                  >
                    {row.original[currentColumn.id]}
                  </a>
                ) : (
                  <span>{row.original[currentColumn.id]}</span>
                )}
              </div>
            );
          }
          if (currentColumn.id === "category") {
            return (
              <div
                className="textOverflow_hidden"
                ref={(th) => {
                  if (th && isOverFlowing(th)) th.title = th.innerText;
                }}
                style={{ width: "inherit" }}
              >
                {row.original.category?.length > 0 ? row.original.category?.join(", ") : "-"}
              </div>
            );
          }
          if (currentColumn.id === "emailStatus") {
            <>
              {row.original.emailStatus ? (
                <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                  <Button
                    style={{
                      width: "100%",
                      overflowWrap: "break-word",
                      color: BalanceConfirmationBetaColorMap[row.original.emailStatus]?.color || "#000",
                      backgroundColor: BalanceConfirmationBetaColorMap[row.original.emailStatus]?.bgColor || "#D7D7D7",
                    }}
                    className="status_theme_btn"
                    disabled={true}
                  >
                    {row.original.emailStatus === BalanceConfirmationBetaStatuses.ReminderSent
                      ? row.original.balanceConfirmationStatusWithReminderCount || row.original.emailStatus
                      : row.original.emailStatus}
                  </Button>
                </div>
              ) : (
                "-"
              )}
            </>;
          }
          if (currentColumn.id === "status") {
            const [anchorElDispute, setAnchorElDispute] = React.useState(null);
            const openDispute = Boolean(anchorElDispute);

            const handleClickDispute = (event) => {
              setAnchorElDispute(event.currentTarget);
            };
            const handleCloseDispute = () => {
              setAnchorElDispute(null);
            };
            const onChangeHandler = (e) => {
              const newValue = e.target.value;
              row.original.statusId = newValue;
              const statusName = allStatuses.find((status) => status.id === newValue).status;
              updateTicketStatus(newValue, row.original.taskId, statusName, row.original.mappingId);
            };
            return (
              <Select
                open={openDispute}
                onOpen={handleClickDispute}
                onClose={handleCloseDispute}
                disableUnderline={true}
                renderValue={() => row.original.status}
                displayEmpty={true}
                sx={{
                  width: "inherit",
                }}
                onFocus={() => {
                  updatingStatusBpId.current = row.original.partnerId;
                }}
                disabled={
                  column.getIsGrouped() ||
                  row.original.metaStatus !== TicketMetaStatuses.InProgress ||
                  selectedRow.length > 0 ||
                  row.original.service === NAKAD_SERVICE.PartnerCommunication
                }
                variant="standard"
                value={row.original.statusId}
                onChange={onChangeHandler}
                MenuProps={{
                  PaperProps: {
                    sx: {
                      "& .MuiMenu-list": {
                        paddingBottom: 0,
                        paddingTop: 0,
                        maxHeight: 450,
                      },
                    },
                  },
                }}
              >
                {renderGroupedOptions(taskCategories, allStatuses)}

                <div
                  style={{
                    position: "sticky",
                    bottom: "0",
                    backgroundColor: "#fff",
                    zIndex: 100,
                    display: "flex",
                    flexDirection: "column",
                    padding: "16px",
                    gap: "16px",
                    borderTop: "2px solid #e7e7e7",
                    marginTop: "8px",
                  }}
                >
                  <Button
                    variant="contained"
                    className="theme_btn fs_14 fw_500"
                    sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                    onClick={() => {
                      statusChangeTaskDetails.current = {
                        taskId: row.original.taskId,
                        mappingId: row.original.mappingId,
                      };
                      setAnchorElDispute(null);
                      setShowSaveTaskModal(true);
                    }}
                  >
                    SAVE/SIGN OFF TASK
                  </Button>
                  <Button
                    className="fs_14 fw_500"
                    variant="outlined"
                    color="error"
                    sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                    onClick={() => {
                      statusChangeTaskDetails.current = {
                        taskId: row.original.taskId,
                        mappingId: row.original.mappingId,
                      };
                      setAnchorElDispute(null);
                      setShowDiscardTaskModal(true);
                    }}
                  >
                    DISCARD TASK
                  </Button>
                </div>
              </Select>
            );
          }
          if (currentColumn.filterVariant === "date-range") {
            return (
              <div className="textOverflow_hidden">
                {row.original[currentColumn.id] ? formatDate(row.original[column.id]) : "-"}
              </div>
            );
          } else if (currentColumn.filterVariant === "range") {
            return row.original[currentColumn.id] === null || row.original[currentColumn.id] === undefined
              ? "-"
              : formatCurrencyCommaSeparated(row.original[currentColumn.id]);
          }
          return (
            <div
              style={{ width: "inherit" }}
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
            >
              {row.original[currentColumn.id] ? row.original[currentColumn.id] : "-"}
            </div>
          );
        }
      };

      return {
        id: currentColumn.id,
        header: currentColumn.header,
        size: currentColumn.size,
        minSize: currentColumn.minSize,
        accessorFn,
        accessorKey: currentColumn.id,
        Cell,
        filterFn,
        filterVariant: currentColumn.filterVariant,
        meta: {
          category: currentColumn.category,
        },
      };
    });
  }, [columnDefinitionData]);

  const TaskTrackerContextValue: ITaskTrackerContext = {
    setColumnFilters: setColumnFilters,
    appliedFilters: appliedFilters,
    setAppliedFilters: setAppliedFilters,
    listAllTasks: listAllTasks,
    appliedFiltersRef: appliedFiltersRef,
    selectedTableView: selectedTableView,
  };
  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <TaskTrackerContext.Provider value={TaskTrackerContextValue}>
        <Box className={styles.container}>
          <Box className={styles.header}>
            <Explore sx={{ color: "#4A148C" }} />
            <Typography variant="h6" className="fw_400 fs_18" color={"#4A148C"}>
              Quick Navigation
            </Typography>
          </Box>
          <Box className={styles.content}>
            <Box className={styles.section}>
              <Typography variant="subtitle2" className={styles.sectionTitle}>
                Automated Reconciliation
              </Typography>
              {actor.integration && (
                <Typography variant="caption" className={styles.sectionSubtitle}>
                  {lastCompany && lastCompany.companyName}
                  {lastBranch && ` , ${lastBranch.branchName}`}
                </Typography>
              )}
              <Box className={styles.sectionDropdowns}>
                <IntegratedDropDownIcons
                  tallyCompanyNameSelect={companyNameSelect}
                  AfterListOwnTallyCompanies={null}
                  companyNameSelect={companyNameSelect}
                  AfterListCompanies={null}
                  branchNameSelect={branchNameSelect}
                  AfterListBranches={null}
                  companyId={companyId.current}
                  branchCode={branchCode.current}
                />
              </Box>
            </Box>
            <Box className={styles.section}>
              <Typography variant="subtitle2" className={styles.sectionTitle}>
                Partner Communication
              </Typography>
              <Button
                variant="text"
                className={styles.linkButton}
                endIcon={<CallMadeIcon />}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERCOMMUNICATION_OPEN_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_PARTNERCOMMUNICATION_OPEN_CLICK.message,
                    }
                  );
                  const uri = `/${actor.name}/partnerCommunication/balanceConfirmBeta`;
                  window.open(uri, "_blank")?.focus();
                }}
              >
                OPEN
              </Button>
            </Box>
          </Box>
        </Box>
        <Box className={styles.tableContainer}>
          <Box className={styles.tableCustomHeader}>
            <Stack direction={"row"}>
              <Typography variant="h5">Task Board</Typography>
              <Box className={styles.selectContainer}>
                <Select
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  size="small"
                  value={selectedTableView}
                  onChange={(event) => {
                    const [isDefaultViewStr, viewIdStr] = (event.target.value as string).split(" ");

                    const isDefaultView = isDefaultViewStr === "true"; // Convert to boolean
                    const viewId = Number(viewIdStr);

                    const currentTableView = allTableViews.find((view) => {
                      if (isDefaultView) {
                        return view.isDefault === true && view.id === viewId;
                      } else {
                        return view.isDefault === false && view.id === viewId;
                      }
                    });

                    if (isDefaultView) {
                      uiLogger(
                        mappingForTaskBoardViews[currentTableView.name].functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: mappingForTaskBoardViews[currentTableView.name].message,
                        }
                      );
                    } else {
                      uiLogger(
                        uiLoggerNamesTaskBoard.UI_WF_TB_CUSTOMVIEW_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesTaskBoard.UI_WF_TB_CUSTOMVIEW_CLICK.message,
                        }
                      );
                    }
                    saveLastSelectedView(currentTableView);
                  }}
                  variant="outlined"
                  className={styles.select}
                  startAdornment={
                    <InputAdornment position="start">
                      <SpaceDashboardOutlined className={styles.icon} />
                    </InputAdornment>
                  }
                  renderValue={(value) => value.name}
                >
                  {allTableViews.map((view, index) => (
                    <MenuItem
                      key={`${view.isDefault} ${view.id}`}
                      value={`${view.isDefault} ${view.id}`}
                      sx={{ borderBottom: index === 3 && allTableViews.length > 4 && "1px solid #dbdbdb" }}
                    >
                      {view.name}
                      {view.isDefault === false && (
                        <IconButton
                          size="small"
                          sx={{ marginLeft: "auto" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handleClose();
                            deleteViewRef.current = view;
                            setShowDeleteViewConfirmationModal(true);
                          }}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Button
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_CLICK.message,
                    }
                  );
                  setShowSaveViewModal(true);
                }}
              >
                Save View
              </Button>
            </Stack>
            <Stack direction="row" gap={1}>
              <Stack
                direction="row"
                sx={{
                  borderRadius: "4px",
                  border: "1px solid #541C4C",
                }}
              >
                <Button
                  variant="outlined"
                  disabled={isDownloadingReport}
                  startIcon={isDownloadingReport ? <LoadingIcon loading={true} /> : <FileDownloadIcon />}
                  sx={{
                    fontSize: "15px",
                    fontWeight: "500",
                    padding: "6px 16px",
                    color: "rgba(84, 28, 76, 1)",
                    borderColor: "#fff",
                    "&:hover, &.Mui-focusVisible": { borderColor: "#fff", backgroundColor: "#fff" },
                    "&:disabled": { borderColor: "#fff", backgroundColor: "#fff" },
                  }}
                  onClick={() => {
                    handleDownloadReport();
                  }}
                >
                  Report
                </Button>
                <IconButton
                  sx={{ color: "#541C4C", borderLeft: "1px solid #541C4C", borderRadius: 0 }}
                  onClick={() => {
                    setShowDownloadCustomisedReportModal(true);
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Stack>
              <Button
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    { message: uiLoggerNamesTaskBoard.UI_WF_TB_FILTER_CLICK.message }
                  );
                  setOpenTaskTrackerFilter(true);
                }}
                startIcon={<FilterAltIcon />}
                variant="outlined"
                className={styles.filterButton}
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  borderColor: "rgba(0, 0, 0, 0.87)",
                  "&:hover, &.Mui-focusVisible": { borderColor: "rgba(0, 0, 0, 0.87)" },
                }}
              >
                Filter
              </Button>
              <NdButton onClick={() => setShowBulkTasksModal(true)} variant="contained" className="fs_15 fw_500">
                Bulk Actions
              </NdButton>
            </Stack>
          </Box>
          <TaskTrackerFiltersBars appliedFilters={appliedFilters} columnFilters={columnFilters} />

          <ReactTable
            customShowHideButton={
              <Tooltip title="Show/Hide Columns" key="customisedShowHideColumns">
                <IconButton
                  onClick={(e) => {
                    setAnchorEl(e.currentTarget);
                  }}
                >
                  <ViewColumnIcon />
                </IconButton>
              </Tooltip>
            }
            enableRowSelection={true}
            enableRowVirtualization={true}
            columns={columnsDefTasks}
            rows={allTasks}
            enableFacetedValues={true}
            setSelectedRow={setSelectedRow}
            clearRowsSelection={clearRows}
            setClearRowsSelection={setClearRows}
            enableAggregations={true}
            loading={isLoadingAllTasks}
            onColumnFiltersChange={setColumnFilters}
            onGroupingChange={setGrouping}
            onColumnSizingChange={setColumnSizing}
            onColumnOrderChange={setColumnOrder}
            onColumnVisibilityChange={setColumnVisibility}
            onColumnPinningChange={setColumnPinning}
            additionalState={{ columnFilters, grouping, columnOrder, columnPinning, columnSizing, columnVisibility }}
            additionalInitialState={{ showColumnFilters: true }}
            internalActionsOrder={[
              "globalFilterToggle",
              "filterToggle",
              "customShowHideColumnsButton",
              "fullScreenToggle",
            ]}
            isSaving={isEditing}
            setTableInstance={setTableInstance}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key="editTask"
                disabled={row.original.metaStatus !== TicketMetaStatuses.InProgress}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_EDIT_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_EDIT_CLICK.message,
                    }
                  );
                  setShowEditTaskModal(true);
                  setSelectedRowForEdit(row.original);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                Edit Task
              </MenuItem>,
              <MenuItem
                key="viewAttachments"
                onClick={() => {
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.taskId,
                    mappingId: row.original.mappingId,
                  };
                  setShowTaskAttachmentsModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <AttachFileIcon />
                </ListItemIcon>
                View Attachments
              </MenuItem>,
              <MenuItem
                key={"viewHistory"}
                onClick={() => {
                  uiLogger(
                    uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWHISTORY_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_VIEWHISTORY_CLICK.message,
                    }
                  );
                  currentSelectedTaskDetails.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.taskId,
                    mappingId: row.original.mappingId,
                  };
                  setShowTasksHistoryModal(true);
                  closeMenu();
                }}
              >
                <ListItemIcon>
                  <HistoryIcon />
                </ListItemIcon>
                View History
              </MenuItem>,
              actor.userType === UserTypes.CustomerSuccess &&
                (actor.userRole === UserRole.CSM || actor.userRole === UserRole.KAM) && (
                  <MenuItem
                    key={"invoiceTask"}
                    onClick={() => {
                      uiLogger(
                        uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesTaskBoard.UI_WF_TB_ACTIONS_INVOICETASK_CLICK.message,
                        }
                      );
                      const currentTaskPhase =
                        row.original.service === NAKAD_SERVICE.PartnerCommunication
                          ? TASK_PHASES.partnerCommunication
                          : row.original.ticketCreatedInPC
                          ? TASK_PHASES.pcRecon
                          : TASK_PHASES.recon;
                      if (actor.userType === UserTypes.CustomerSuccess && actor.userRole === UserRole.CSM) {
                        if (currentTaskPhase === TASK_PHASES.partnerCommunication && row.original.isPcInvoiced) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                        if (currentTaskPhase === TASK_PHASES.recon && row.original.isReconInvoiced) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                        if (
                          currentTaskPhase === TASK_PHASES.pcRecon &&
                          row.original.isPcInvoiced &&
                          row.original.isReconInvoiced
                        ) {
                          toast.error(
                            <CustomToast message="You are restricted to only add Invoicing status and not edit it." />
                          );
                          return;
                        }
                      }
                      taskInvoicingDetails.current = {
                        mappingId: row.original.mappingId,
                        businessPartnerName: row.original.businessPartnerName,
                        taskId: row.original.taskId,
                        invoiceStatus: row.original.invoiceStatus,
                        pcInvoiceDate: row.original.pcInvoiceDate,
                        reconInvoiceDate: row.original.reconInvoiceDate,
                        isReconInvoiced: row.original.isReconInvoiced,
                        isPcInvoiced: row.original.isPcInvoiced,
                        taskPhase: currentTaskPhase,
                      };
                      setShowInvoicingTaskModal(true);
                      closeMenu();
                    }}
                  >
                    <ListItemIcon>
                      <ReceiptIcon />
                    </ListItemIcon>
                    Invoice Task
                  </MenuItem>
                ),
            ]}
          />
        </Box>

        {openTaskTrackerFilter && (
          <TaskTrackerFilter
            open={openTaskTrackerFilter}
            setOpen={setOpenTaskTrackerFilter}
            listAllTasks={listAllTasks}
            partnerOptions={partnerOptions}
            vendorCodeOptions={vendorCodeOptions}
            taskIdsOptions={taskIdsOptions}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
        {showSaveTaskModal && (
          <SaveTaskModal
            open={showSaveTaskModal}
            setOpen={setShowSaveTaskModal}
            currentTaskDetails={statusChangeTaskDetails.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}
        {showDiscardTaskModal && (
          <DiscardTaskModal
            open={showDiscardTaskModal}
            setOpen={setShowDiscardTaskModal}
            currentTaskDetails={statusChangeTaskDetails.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}
        {showTaskAttachmentsModal && (
          <TaskAttachmentsModal
            open={showTaskAttachmentsModal}
            setOpen={setShowTaskAttachmentsModal}
            businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
            ticketId={currentSelectedTaskDetails.current.taskId}
            companyId={companyId.current}
            branchCode={branchCode.current}
            serviceType="taskBoard"
            mappingId={currentSelectedTaskDetails.current.mappingId}
          />
        )}
        {showEditTaskModal && (
          <EditTaskModal
            type="taskBoard"
            open={showEditTaskModal}
            setOpen={setShowEditTaskModal}
            allUsers={allUsers}
            row={selectedRowForEdit}
            fetchRowsData={listAllTasks}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            companyId={companyId.current}
            branchCode={branchCode.current}
            allTaskLabels={allTaskLabels}
            getAllTaskLabels={getAllTaskLabels}
          />
        )}
        {showTasksHistoryModal && (
          <TasksHistoryModal
            showTasksHistoryModal={showTasksHistoryModal}
            setShowTasksHistoryModal={setShowTasksHistoryModal}
            businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
            taskId={currentSelectedTaskDetails.current.taskId}
            mappingId={currentSelectedTaskDetails.current.mappingId}
          />
        )}
        {showBulkTasksModal && (
          <BulkTasksModal
            type="taskBoard"
            showBulkTasksModal={showBulkTasksModal}
            setShowBulkTasksModal={setShowBulkTasksModal}
            selectedTasks={selectedRow}
            allUsers={allUsers}
            allStatuses={allStatuses.map((status) => {
              return {
                id: status.id,
                name: status.status,
              };
            })}
            refetchData={listAllTasks}
            setClearRows={setClearRows}
            allTaskLabels={allTaskLabels}
            getAllTaskLabels={getAllTaskLabels}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
        {showInvoicingTaskModal && (
          <InvoicingSingleTaskModal
            open={showInvoicingTaskModal}
            setOpen={setShowInvoicingTaskModal}
            taskInvoicingDetails={taskInvoicingDetails.current}
            listAllTasks={listAllTasks}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
      </TaskTrackerContext.Provider>
      {showDownloadCustomisedReportModal && (
        <DownloadCustomisedReportModal
          open={showDownloadCustomisedReportModal}
          setOpen={setShowDownloadCustomisedReportModal}
          handleDownloadReport={handleDownloadReport}
        />
      )}
      {showSaveViewModal && (
        <SaveViewModal
          open={showSaveViewModal}
          setOpen={setShowSaveViewModal}
          selectedTableView={selectedTableView}
          tableInstance={tableInstance}
          listAllViews={listAllViews}
          mainFilter={appliedFiltersRef.current}
          saveLastSelectedView={saveLastSelectedView}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
      )}
      {showDeleteViewConfirmationModal && (
        <DeleteViewConfirmationModal
          open={showDeleteViewConfirmationModal}
          setOpen={setShowDeleteViewConfirmationModal}
          tableView={deleteViewRef.current}
          listAllViews={listAllViews}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
      )}
      {anchorEl && (
        <CustomisedShowHideColumns
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          columns={() => {
            const columns = tableInstance
              .getAllColumns()
              .map((column) => {
                return {
                  id: column.id,
                  isVisible: column.getIsVisible(),
                  header: column.columnDef.header,
                  category: column.columnDef.meta?.category,
                };
              })
              .filter(
                (column) =>
                  column.id !== "mrt-row-select" && column.id !== "mrt-row-actions" && column.id !== "mrt-row-spacer"
              );

            const columnsMap = new Map(columns.map((col) => [col.id, col]));

            const orderedColumns = [];
            const remainingColumns = [];

            columnOrder.forEach((id) => {
              if (columnsMap.has(id)) {
                orderedColumns.push(columnsMap.get(id));
                columnsMap.delete(id);
              }
            });

            columns.forEach((column) => {
              if (columnsMap.has(column.id)) {
                remainingColumns.push(column);
              }
            });
            return [...orderedColumns, ...remainingColumns];
          }}
          setColumnOrder={setColumnOrder}
          setColumnVisibility={setColumnVisibility}
        />
      )}
    </LoggedInSkeleton>
  );
}
