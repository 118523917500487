import { Button, Chip, FormControl, FormLabel, MenuItem, Select, Stack } from "@mui/material";
import React, { useContext, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesRecon } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import { Recon360Context } from "../Recon360";

interface SuggestedTdsGstProps {
  openProbablyTdsGstModal: boolean;
  setOpenProbablyTdsGstModal: StateDispatch<boolean>;
  probablyTdsGstArr: React.MutableRefObject<{ TDS: number; GST: number; count: number; selectedOption: string }[]>;
  compareLedgers: () => Promise<void>;
  setOpenManualChangesConfirmModal: StateDispatch<boolean>;
  manualChangesExist: boolean;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

const SuggestedTdsGstDialog = ({ setOpenProbablyTdsGstModal, probablyTdsGstArr, ...props }: SuggestedTdsGstProps) => {
  const { actor } = useContext(userContext);

  const [disableProbablyTdsGstSave, setDisableProbablyTdsGstSave] = useState(false);
  const probablyTdsGstOptions = ["Confirm", "Ignore this time", "Never ask Again for this Partner"] as const;
  const [showSaveTdsGstLoadingIcon, setShowSaveTdsGstLoadingIcon] = useState(false);
  const [showSaveTdsGstReconcileLoadingIcon, setShowSaveTdsGstReconcileLoadingIcon] = useState(false);

  const {
    businessPartnerSelected,
    businessPartnerSelectedRef,
    //   businessPartnerSelectedCompanyIdRef,
    companyId,
    branchCode,
    assignBusinessPartnerNameRef,
    getSummaryForReconciliationCommonFunction,
    mappingId,
  } = useContext(Recon360Context);

  const calculateCombinationsOfTdsGst = (selectedOption: string) => {
    const combinations = [];

    for (const item of probablyTdsGstArr.current) {
      if (item.selectedOption === selectedOption) {
        const TDS = item.TDS;
        const GST = item.GST;
        const combinationValue = (TDS / (1 + GST / 100))?.toFixed(2);
        combinations.push(combinationValue);
      }
    }

    return combinations;
  };

  const updateTdsPercentage = (from: string) => {
    if (from === "Save and reconcile again") {
      setShowSaveTdsGstReconcileLoadingIcon(true);
    } else {
      setShowSaveTdsGstLoadingIcon(true);
    }
    useFetch(API_ENDPOINTS.UPDATE_TDS_PERCENTAGE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TDS_PERCENTAGE.failureMessage,
      data: {
        mappingId: mappingId.current,
        companyId: companyId.current,
        branchCode: branchCode.current,
        businessPartnerId: businessPartnerSelected,
        acceptableTdsPercentage: calculateCombinationsOfTdsGst("Confirm"),
        unacceptableTdsPercentage: calculateCombinationsOfTdsGst("Never ask Again for this Partner"),
      },
      thenCallBack: () => {
        setOpenProbablyTdsGstModal(false);
        setShowSaveTdsGstReconcileLoadingIcon(false);
        setShowSaveTdsGstLoadingIcon(false);
        if (from === "Save and reconcile again") {
          props.compareLedgers();
        } else {
          probablyTdsGstArr.current = [];
          if (props.manualChangesExist) {
            props.setOpenManualChangesConfirmModal(true);
          } else {
            getSummaryForReconciliationCommonFunction();
          }
        }
      },
      catchCallBack: () => {
        setShowSaveTdsGstReconcileLoadingIcon(false);
        setShowSaveTdsGstLoadingIcon(false);
      },
    });
  };

  return (
    <>
      <Dialog open={props.openProbablyTdsGstModal} onClose={() => setOpenProbablyTdsGstModal(true)} width70Per={false}>
        <header className="modal-card-head">
          <div style={{ width: "100%" }}>
            <small>
              Reconciliation of {actor.name}-{assignBusinessPartnerNameRef.current}
            </small>
            <p className="modal-card-title config_dialog_title_" style={{ fontSize: 18 }}>
              You probably missed these combinations of TDS and GST %
            </p>
          </div>
          <button
            className="delete"
            aria-label="close"
            onClick={() => {
              setOpenProbablyTdsGstModal(false);
              if (props.manualChangesExist) {
                props.setOpenManualChangesConfirmModal(true);
              } else {
                getSummaryForReconciliationCommonFunction();
              }
            }}
          />
        </header>
        <section className="modal-card-body">
          <Stack direction={"row"} gap={1} className="">
            <FormLabel sx={{ mx: "auto", textAlign: "center" }}>TDS case found</FormLabel>
            <FormLabel sx={{ minWidth: 85, textAlign: "center" }}>Count</FormLabel>
            <FormLabel sx={{ minWidth: 270, textAlign: "center" }}>Option</FormLabel>
          </Stack>
          {probablyTdsGstArr.current?.map((item, index) => (
            <div className="space_between mb_25" key={index} style={{ gap: 8, alignItems: "center" }}>
              <div className="column_header_name">
                <p>{`${item.TDS}% TDS and ${item.GST}% GST`}</p>
              </div>
              <Chip
                label={item.count}
                sx={{
                  fontSize: 20,
                  px: 2,
                  height: 40,
                  minWidth: 85,
                  fontWeight: "bold",
                  background: "#541c4c1a",
                  borderRadius: 16,
                  "*": {
                    overflow: "visible",
                  },
                }}
              />
              <div style={{ minWidth: 270 }}>
                <FormControl fullWidth={true}>
                  <Select
                    size="small"
                    name="columnHeader"
                    variant="outlined"
                    fullWidth={true}
                    defaultValue={probablyTdsGstOptions[1]}
                    displayEmpty={true}
                    onChange={(e) => {
                      setDisableProbablyTdsGstSave(false);
                      probablyTdsGstArr.current[index].selectedOption = e.target.value;
                    }}
                  >
                    <MenuItem disabled={true}>Select option </MenuItem>
                    {probablyTdsGstOptions.map((val, ind) => (
                      <MenuItem key={val + ind} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                  {/* <FormHelperText>{item?.errorFlag ? "please select column header" : ""}</FormHelperText> */}
                </FormControl>
              </div>
            </div>
          ))}
        </section>
        <footer className="modal-card-foot flex_end">
          <div>
            <Button
              className="theme_btn mr_20 mw_90"
              onClick={() => {
                updateTdsPercentage("Save");
                uiLogger(
                  uiLoggerNamesRecon.UI_RL_MISSED_COMBINATION_SAVE_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesRecon.UI_RL_MISSED_COMBINATION_SAVE_CLICK.message,
                    businessPartnerId: businessPartnerSelectedRef.current,
                  }
                );
              }}
              disabled={showSaveTdsGstLoadingIcon || showSaveTdsGstReconcileLoadingIcon || disableProbablyTdsGstSave}
            >
              <LoadingIcon loading={showSaveTdsGstLoadingIcon} />
              Save
            </Button>
            <Button
              className="theme_btn mw_250"
              onClick={() => {
                updateTdsPercentage("Save and reconcile again");
                uiLogger(
                  uiLoggerNamesRecon.UI_RL_MISSED_COMBINATION_SAVE_AND_RECONCILE_CLICK.functionName,
                  companyId.current,
                  branchCode.current,
                  {
                    message: uiLoggerNamesRecon.UI_RL_MISSED_COMBINATION_SAVE_AND_RECONCILE_CLICK.message,
                    businessPartnerId: businessPartnerSelectedRef.current,
                  }
                );
              }}
              disabled={showSaveTdsGstLoadingIcon || showSaveTdsGstReconcileLoadingIcon || disableProbablyTdsGstSave}
            >
              <LoadingIcon loading={showSaveTdsGstReconcileLoadingIcon} />
              Save and reconcile again
            </Button>
          </div>
        </footer>
      </Dialog>
    </>
  );
};

export default SuggestedTdsGstDialog;
