import { Box, Divider, Typography } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router";
import useFetch from "src/Components/Common/useFetch";
import { UseCaseType } from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import ErrorPage from "../MsmePartnerPortal/ErrorPage";
import { NavBar } from "../MsmePartnerPortal/PartnerPortal.common";
import $ from "../MsmePartnerPortal/PartnerPortal.module.scss";
export enum FileTypeInLink {
  confirmationDocument = "confirmationDocument",
  ledgers = "ledgers",
  mailThread = "mailThread",
}

const CommonReport = ({
  useCaseType,
}: {
  useCaseType: UseCaseType.balanceConfirmationBeta | UseCaseType.ledgerRequestBeta;
}) => {
  const [loading, setLoading] = useState(false);
  const location = useLocation<any>();
  const params = new URLSearchParams(location.search);
  const encryptedData = params.get("data");
  const partnerName = decodeURIComponent(atob(params.get("businessPartnerName")));
  const ownName = decodeURIComponent(atob(params.get("ownName")));
  const fileType = params.get("fileType") as FileTypeInLink;
  const history = params.get("history");
  const newMailInitiationCount = params.get("newMailInitiationCount");

  const downloadFileThroughExcel = () => {
    const endPoint =
      useCaseType === UseCaseType.balanceConfirmationBeta
        ? API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL.url
        : API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_LR.url;

    const failureMessage =
      useCaseType === UseCaseType.balanceConfirmationBeta
        ? API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL.failureMessage
        : API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_LR.failureMessage;

    return useFetch<{ signedUrls: string[] }>(endPoint, "GET", {
      failureMessage,
      config: {
        params: {
          data: encryptedData,
          type: fileType,
        },
      },
      thenCallBack: (res) => {
        res.data?.signedUrls?.forEach((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      },
    });
  };

  const downloadFileThroughExcelForHistoryReport = () => {
    const endPoint = UseCaseType.balanceConfirmationBeta
      ? API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY.url
      : API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY_LR.url;
    const failureMessage = UseCaseType.balanceConfirmationBeta
      ? API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY.failureMessage
      : API_ENDPOINTS.DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY_LR.failureMessage;
    return useFetch<{ signedUrls: string[] }>(endPoint, "GET", {
      failureMessage,
      config: {
        params: {
          data: encryptedData,
          type: fileType,
          newMailInitiationCount: newMailInitiationCount,
        },
      },
      thenCallBack: (res) => {
        res.data?.signedUrls?.forEach((url) => {
          const link = document.createElement("a");
          link.href = url;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        });
      },
    });
  };
  const downloadMailThread = () => {
    const endPoint = UseCaseType.balanceConfirmationBeta
      ? API_ENDPOINTS.DOWNLOAD_MAIL_THREAD.url
      : API_ENDPOINTS.DOWNLOAD_MAIL_THREAD_LR.url;
    const failureMessage = UseCaseType.balanceConfirmationBeta
      ? API_ENDPOINTS.DOWNLOAD_MAIL_THREAD.failureMessage
      : API_ENDPOINTS.DOWNLOAD_MAIL_THREAD_LR.failureMessage;
    return useFetch(endPoint, "GET", {
      failureMessage,
      config: {
        params: {
          data: encryptedData,
          type: fileType,
          newMailInitiationCount: newMailInitiationCount,
        },
        responseType: "arraybuffer",
      },
      thenCallBack: (res) => {
        /* 
          res.setHeader( 
            "Content-Disposition", 
            `attachment; filename="thread-${decryptedDataAndContext.businessPartnerActorInfo.actorName}.eml"` 
          ); 
          res.setHeader("Content-Type", "application/octet-stream"); 
          // Send the concatenated MIME content as a Blob response 
          res.end(Buffer.from(concatenatedMIME, "utf-8")); 
        */

        const data: Buffer = res.data as Buffer;
        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `thread-${partnerName}.pdf`;
        link.click();
      },
    });
  };

  const downloadMailThreadForHistory = () =>
    useFetch(API_ENDPOINTS.DOWNLOAD_MAIL_THREAD.url, "GET", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_MAIL_THREAD.failureMessage,
      config: {
        params: {
          data: encryptedData,
          type: fileType,
          newMailInitiationCount: newMailInitiationCount,
        },
        responseType: "arraybuffer",
      },
      thenCallBack: (res) => {
        const data: Buffer = res.data as Buffer;
        const blob = new Blob([data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `thread-${partnerName}.pdf`;
        link.click();
      },
    });

  const handleClick = async () => {
    setLoading(true);
    try {
      if (FileTypeInLink.mailThread === fileType) {
        if (history === "true") await downloadMailThreadForHistory();
        else await downloadMailThread();
      } else {
        if (history === "true") await downloadFileThroughExcelForHistoryReport();
        else await downloadFileThroughExcel();
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ErrorPage>
        <NavBar companyName={ownName} companyLogo={""} />
        <Divider className={$.borderColor} />
        <Box py={3}>
          <Box py={3} sx={{ border: "1px solid #D7D7D7", maxWidth: "900px", marginX: "auto" }}>
            <Box py={3} sx={{ textAlign: "center" }}>
              <Typography mb={3} variant="h5" fontWeight={700}>
                Download {FileTypeInLink.mailThread === fileType ? "MailThread" : "Report"} for {partnerName}
              </Typography>
              <NdAsyncButton
                variant="contained"
                onClick={handleClick}
                disabled={loading}
                startIcon={
                  <svg className="icon" style={{ height: "30px", width: "30px", fill: "#fff" }}>
                    <use xlinkHref="#icon-downloadReport" />
                  </svg>
                }
              >
                Download
              </NdAsyncButton>
            </Box>
          </Box>
        </Box>
      </ErrorPage>
    </>
  );
};

export default CommonReport;
