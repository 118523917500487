/* eslint-disable react-hooks/exhaustive-deps */
import { OutlinedTextFieldProps, TextField, TextFieldVariants } from "@mui/material";
import { Currency } from "dinero.js";
import React, { useCallback, useEffect, useState } from "react";
import { DefaultCurrency, ToDineroObj } from "../../Utils/MoneyUtils";

type Modify<T, R> = Omit<T, keyof R>;
type focusEvent = React.FocusEvent<HTMLInputElement>;

type TextFieldProps = Modify<
  OutlinedTextFieldProps,
  {
    onBlur?: (e: focusEvent, value: number) => void;
    variant?: string;
  }
>;

interface MonetaryInputProps extends TextFieldProps {
  value: string | number;
  setValue: (value: number) => void;
  allowNegative?: boolean;
  disableFormatting?: boolean;
  onBlur?: (e: focusEvent, value: number) => void;
  onKeyUp?: TextFieldProps["onKeyUp"];
  onInput?: TextFieldProps["onInput"];
  reformatMoney?: (fn: (allowNegative?: boolean) => void) => void;
  currency?: Currency;
  returnNull?: boolean;
  variant?: TextFieldVariants;
}

const MonetaryInput = (props: MonetaryInputProps) => {
  const currency = props.currency || DefaultCurrency.INR;
  const format = currency === DefaultCurrency.INR ? "en-IN" : "en-US";

  const [balance, setBalance] = useState(props.value?.toLocaleString(format) || "");

  const formatMoney = useCallback(
    (allowNegative = true) => {
      if (props.disableFormatting) return;

      let newBal = "";
      if (allowNegative) {
        newBal = balance.toString().replace(/(?:-)[^\d.]+/g, "") || "";
        newBal = newBal.replace(/[^\-\d.]+/g, "") || "";
      } else {
        newBal = balance.toString().replace(/[^\d.]+/g, "") || "";
      }

      const dineroObject = ToDineroObj(parseFloat(newBal), currency);

      const bal = parseFloat(newBal).toLocaleString(dineroObject.currency === DefaultCurrency.INR ? "en-IN" : "en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: newBal.includes(".") ? 1 : 0,
        style: "decimal",
      });

      if (newBal.endsWith(".")) {
        newBal.match(/\./g).length > 1 ? setBalance(bal) : setBalance(newBal);
      } else if (newBal) {
        if (parseFloat(newBal)) newBal = bal;

        setBalance(newBal);
      } else {
        setBalance("");
      }
    },
    [balance]
  );

  useEffect(() => {
    if (props.reformatMoney) props.reformatMoney(formatMoney);
    setBalance(props.value?.toLocaleString(format) || "");
  }, [props, props.value]);

  const parseBalance = (): number => {
    const parsedBalance = parseFloat(balance.replace(/[^\-\d.]+/g, "")) || 0;
    return parsedBalance;
  };

  const deleteKEYS = (objectRef: MonetaryInputProps, keys: (keyof MonetaryInputProps)[]) => {
    keys.forEach((key: string) => delete objectRef[key]);
    return objectRef;
  };

  const modifiedProps = deleteKEYS({ ...props }, ["allowNegative", "disableFormatting", "setValue", "returnNull"]);

  return (
    <TextField
      data-monetary-input={true}
      {...modifiedProps}
      type={"text"}
      value={balance}
      variant={props.variant || "outlined"}
      onInput={(e) => {
        setBalance((e.target as HTMLInputElement).value);
        if (props.onInput) props.onInput(e);
      }}
      onKeyUp={(e) => {
        formatMoney(props.allowNegative);
        if (props.onKeyUp) props.onKeyUp(e);
      }}
      onBlur={(e) => {
        const newBalance = e.target.value;

        if (props.returnNull && newBalance === "") {
          setBalance("");
          props.setValue(null);
          if (props.onBlur) props.onBlur(e as focusEvent, null);
          return;
        }

        if (props.value === null && newBalance === "") return;

        isNaN(parseFloat(newBalance)) ? setBalance("0") : setBalance(newBalance);

        if (newBalance.endsWith(".")) {
          const format = currency === DefaultCurrency.INR ? "en-IN" : "en-US";
          setBalance(parseFloat(newBalance) ? parseFloat(newBalance.slice(0, -1)).toLocaleString(format) : "0");
        }
        const validBalance = parseBalance();
        props.setValue(validBalance);

        if (props.onBlur) props.onBlur(e as focusEvent, validBalance);
      }}
    />
  );
};

export default MonetaryInput;
