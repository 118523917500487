import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import {
  BranchInfo,
  CompanyInfo,
  GetOptionsList,
  ListBranchesRes,
  ListCompaniesRes,
  RequireAtLeastOne,
} from "../../CommonLegacy/CommonComponents";

interface IntegratedDropDownProps {
  companyNameSelect: (e: any, option: Partial<CompanyInfo>) => void;
  AfterListCompanies?: (res: ListCompaniesRes) => void;
  branchNameSelect: (e: any, option: Partial<BranchInfo>) => void;
  AfterListBranches?: (res: ListBranchesRes) => void;
  isDisabled?: boolean;
}

export const IntegratedDropDown = ({ companyNameSelect, branchNameSelect, ...props }: IntegratedDropDownProps) => {
  const { actor } = useContext(userContext);

  const [companiesLoaded, setCompaniesLoaded] = useState<boolean>(false);
  const [listCompanies, setListCompanies] = useState<CompanyInfo[]>([]);
  // const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const [selectedCompany, setSelectedCompany] = useState<Partial<CompanyInfo>>({} as any);

  const [branchesLoaded, setBranchesLoaded] = useState<boolean>(true);
  const [listBranches, setListBranches] = useState<BranchInfo[]>([]);
  // const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const [selectedBranch, setSelectedBranch] = useState<Partial<BranchInfo>>({} as any);

  // const optionsListOwnTallyCompanies = GetOptionsList(listTallyCompanies, "companyName");
  const optionsListCompanies = GetOptionsList(listCompanies, "companyName");
  const optionsListBranches = GetOptionsList(listBranches, "branchName");

  const _internalCompanyNameSelect = (e: any, option: Partial<CompanyInfo>) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyNameSelect && companyNameSelect(e, option);
      setSelectedCompany(option);
      if (actor.integration && actor.branchLevelReconcilation) {
        // const companyIds = option.companyId;
        if (option) ListBranches(option?.companyId);
      }
    }
  };

  const _internalBranchNameSelect = (e: any, option: Partial<BranchInfo>) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchNameSelect && branchNameSelect(e, option);
      setSelectedBranch(option);
    }
  };

  const _internalCompanySave = (e: any, option: Partial<CompanyInfo>) => {
    // Reset Branch before selecting new company
    setSelectedBranch({} as any);
    branchNameSelect(e, {} as any);

    _internalCompanyNameSelect(e, option);
  };

  const _internalBranchSave = (e: any, option: Partial<BranchInfo>) => {
    _internalBranchNameSelect(e, option);
  };

  const ListCompanies = async () => {
    setCompaniesLoaded(false);
    useFetch<ListCompaniesRes>(API_ENDPOINTS.LIST_COMPANIES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_COMPANIES.failureMessage,
      thenCallBack: (res) => {
        setListCompanies(res?.data?.companies);
        setCompaniesLoaded(true);
        const lastCompID = res?.data?.lastSelectedCompanyId;
        if (lastCompID) {
          const foundCompany = res.data.companies.find((c) => c.companyId === lastCompID);
          const foundLastCompany = {
            firstLetter: foundCompany?.companyName[0].toUpperCase(),
            ...foundCompany,
          };
          if (foundCompany) {
            setSelectedCompany(foundLastCompany);
            _internalCompanyNameSelect({}, foundLastCompany);
          }
        } else {
          const topCompany = res.data.companies[0];
          setSelectedCompany(topCompany);
          _internalCompanyNameSelect({}, topCompany);
        }
        if (props.AfterListCompanies) props.AfterListCompanies(res.data);
      },
    });
  };

  const ListBranches = async (company: string) => {
    setBranchesLoaded(false);
    await useFetch<ListBranchesRes>(API_ENDPOINTS.LIST_BRANCHES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_BRANCHES.failureMessage,
      config: {
        params: {
          companyId: company,
        },
      },
      thenCallBack: (res) => {
        setListBranches(res?.data?.branches);
        setBranchesLoaded(true);
        const lastBranch = res?.data?.lastSelectedbranch;
        if (lastBranch) {
          const foundBranch = res.data.branches.find((c) => c.branchCode === lastBranch.branchCode);
          const foundLastBranch = {
            firstLetter: foundBranch?.branchName[0]?.toUpperCase(),
            ...foundBranch,
          };
          if (foundBranch) {
            setSelectedBranch(foundLastBranch);
            _internalBranchNameSelect({}, foundLastBranch);
          }
        } else {
          const topBranch = res.data.branches[0];
          setSelectedBranch(topBranch);
          _internalBranchNameSelect({}, topBranch);
        }
        if (props.AfterListBranches) props.AfterListBranches(res.data);
      },
    });
  };

  // const ListAllBranches = async (companyIds: string[]) => {
  //   type companyAndBranches = { companyId: string; branches: BranchInfo[] };

  //   setBranchesLoaded(false);

  //   return useFetch<{ companies: companyAndBranches[] }>("/api/ListBranchesForMultipleCompanies", "GET", {
  //     failureMessage: "Failed to list branches",
  //     config: {
  //       params: {
  //         companyIds: companyIds,
  //       },
  //     },
  //     thenCallBack: (res) => {
  //       if (res?.data?.companies) {
  //         const allBranches = res.data.companies.reduce<BranchInfo[]>((acc, curr) => [...acc, ...curr.branches], []);
  //         setListBranches(allBranches);
  //         setBranchesLoaded(true);
  //       }
  //     },
  //   });
  // };

  useEffect(() => {
    if (actor.integration) {
      ListCompanies();
    }
  }, [actor]);

  return (
    <>
      {/* Company Select Drop Down */}
      {actor.integration === true && (
        <div className="company-select">
          <CommonAutoComplete<CompanyInfo>
            selected={selectedCompany}
            onChangeSelectFn={_internalCompanySave}
            optionsList={optionsListCompanies}
            optionLabelKey={"companyName"}
            loaded={companiesLoaded}
            label="Company Name"
            placeholder={"Select Company"}
            isDisabled={props.isDisabled}
          />
        </div>
      )}

      {/* Any Acc Software Branch Drop Down */}
      {actor.integration === true && actor.branchLevelReconcilation === true && (
        <div className="branch-select">
          <CommonAutoComplete<BranchInfo>
            selected={selectedBranch}
            onChangeSelectFn={_internalBranchSave}
            optionsList={optionsListBranches}
            optionLabelKey={"branchName"}
            loaded={branchesLoaded}
            label="Branch Name"
            placeholder="Select Branch"
          />
        </div>
      )}
    </>
  );
};

interface CommonAutoCompletePropsBase<T> {
  selected: Partial<T>;
  onChangeSelectFn: (e: any, value: Partial<T>) => void;
  optionsList: (T & { firstLetter: string })[];
  optionLabelKey: keyof T;
  loaded: boolean;
  label?: string;
  placeholder?: string;
  id?: string;
  isDisabled?: boolean;
}

type CommonAutoCompleteProps<T> = RequireAtLeastOne<CommonAutoCompletePropsBase<T>, "label" | "placeholder">;

type TypeWithFirstLetter = { firstLetter?: string; [k: string]: any };

export function CommonAutoComplete<T extends TypeWithFirstLetter>({
  selected,
  onChangeSelectFn,
  optionsList,
  optionLabelKey,
  loaded,
  label,
  id,
  isDisabled = false,
}: CommonAutoCompleteProps<T>) {
  return (
    <>
      <Autocomplete
        multiple={false}
        sx={{
          width: "inherit",
          backgroundColor: "#fff",
        }}
        disabled={isDisabled}
        value={selected}
        size="small"
        onChange={(e, value) => onChangeSelectFn(e, value)}
        id={id || label}
        options={optionsList.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter)) || []}
        loading={!loaded}
        disableClearable={true}
        groupBy={(option) => option?.firstLetter}
        getOptionLabel={(option) => (option as T)[optionLabelKey] || ""}
        isOptionEqualToValue={(option, value) => {
          if (typeof value === "string") return option[optionLabelKey] === value;
          else return option[optionLabelKey] === value[optionLabelKey];
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Type Name"
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {!loaded ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </>
  );
}
