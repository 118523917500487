import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesReportsMailAutomation } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID } from "../../PartnerCommunication/CommonLegacy/CommonComponents";

export default function DeleteTemplateConfirmationModal({
  open,
  setOpen,
  emailTemplateName,
  emailTemplateId,
  listAllAutomatedMailTemplates,
  setCurrentMailSetupType,
  useCaseType,
}) {
  const [isDeletingReport, setIsDeletingReport] = useState(false);
  const handleDeleteReport = () => {
    setIsDeletingReport(true);
    const endpoint =
      useCaseType === "MIS"
        ? API_ENDPOINTS.MAIL_REPORTS_DELETE_AUTOMATED_MAIL(emailTemplateId).url
        : `/api/Admin/MISMail/DeleteMailTemplate/${emailTemplateId}`;
    useFetch(endpoint, "DELETE", {
      failureMessage: API_ENDPOINTS.MAIL_REPORTS_DELETE_AUTOMATED_MAIL(emailTemplateId).failureMessage,
      showSuccessToast: true,
      thenCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
        listAllAutomatedMailTemplates();
        setCurrentMailSetupType(null);
      },
      catchCallBack: () => {
        setIsDeletingReport(false);
        setOpen(false);
        listAllAutomatedMailTemplates();
        setCurrentMailSetupType(null);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "600px" } }}>
      <DialogTitle>Are you sure you want to delete {emailTemplateName}?</DialogTitle>
      <DialogActions sx={{ padding: 2 }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          disabled={isDeletingReport}
          variant="contained"
          color="error"
          sx={{ borderRadius: "4px !important" }}
          onClick={() => {
            if (useCaseType === "MIS") {
              uiLogger(
                uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_DELETE_DELETE_CLICK.functionName,
                DEFAULT_COMPANY_ID,
                DEFAULT_BRANCH_CODE,
                {
                  message: uiLoggerNamesReportsMailAutomation.UI_MIS_RMA_VIEWAUTOMATEDEMAIL_DELETE_DELETE_CLICK.message,
                }
              );
            }
            handleDeleteReport();
          }}
        >
          {isDeletingReport && <LoadingIcon loading={true} />}
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}
