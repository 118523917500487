import AddIcon from "@mui/icons-material/Add";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "src/Components/Common/Loader";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { NdButton } from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { ClientType, ClientTypeStrings } from "src/entity/models/FrontendActor";
import AccountSelectSection from "./AccountSelectSection";

// Special "All" option for the first row
export const ALL_OPTION = { actorId: -1, actorName: "All" };

// Maximum number of accounts that can be selected
export const MAX_ACCOUNTS = 15;

export interface UserView {
  id: number;
  userId: number;
  viewName: string;
  isDefault: boolean;
  userName: string;
}

interface AccountSelection {
  id: number;
  actor: {
    actorId: number;
    actorName: string;
  };
  view: UserView;
}

export type ClientsByType = {
  [key in ClientType]?: {
    clientName: string;
    clientId: number;
  }[];
};

export default function MISTemplateModal({
  open,
  setOpen,
  clientTypeOptions,
  allClients,
  templateId,
  listAllTemplates,
  defaultViewOptions,
}) {
  const [selectedClientTypes, setSelectedClientTypes] = useState<ClientTypeStrings[]>([]);
  const [clientOptions, setClientOptions] = useState<{ clientId: number; clientName: string }[]>([]);
  const [selectedClients, setSelectedClients] = useState<{ clientId: number; clientName: string }[]>([]);
  const [accountOptions, setAccountOptions] = useState<{ actorId: number; actorName: string }[]>([]);
  const [selectedAccounts, setSelectedAccounts] = useState<AccountSelection[]>([{ id: 1, actor: null, view: null }]);
  const [templateName, setTemplateName] = useState<string>("");
  const [loadingAccountOptions, setLoadingAccountOptions] = useState<boolean>(false);
  const [isCreatingTemplate, setIsCreatingTemplate] = useState<boolean>(false);
  const [isFetchingTemplateDetails, setIsFetchingTemplateDetails] = useState<boolean>(templateId ? true : false);

  const isAllSelected = selectedAccounts[0]?.actor?.actorId === ALL_OPTION.actorId;
  const isMaxAccountsReached = selectedAccounts.length >= MAX_ACCOUNTS;

  useEffect(() => {
    if (templateId) getTemplateDetailsById();
  }, []);

  const getTemplateDetailsById = () => {
    useFetch("/api/Admin/MIS/TemplateDetails", "GET", {
      failureMessage: "Failed to fetch template details",
      config: {
        params: {
          templateId,
        },
      },
      thenCallBack: (response) => {
        setTemplateName(response.data.templateDetails.templateName);
        setSelectedClientTypes(response.data.templateDetails.clientType);
        setSelectedClients(response.data.templateDetails.clientDetails || []);
        const clients = response.data.templateDetails.clientType.flatMap((clientType) => allClients[clientType] || []);
        setClientOptions(clients.map((client) => ({ clientName: client.clientName, clientId: client.clientId })));
        setSelectedAccounts(
          response.data.templateDetails.reportDetails.map((actor, index) => ({
            id: index + 1,
            actor: {
              actorId: actor.actorId,
              actorName: actor.actorName,
            },
            view: {
              id: actor.viewId,
              userId: actor.userId,
              viewName: actor.viewName,
              userName: actor.userName,
              isDefault: actor.isDefault,
            },
          }))
        );
        setAccountOptions(
          response.data.templateDetails.allAccounts?.map((account) => {
            return { actorId: account.actorId, actorName: account.actorName };
          }) || []
        );
        setIsFetchingTemplateDetails(false);
      },
      catchCallBack: () => {
        setIsFetchingTemplateDetails(false);
      },
    });
  };

  const listAllAccounts = (clientIds: number[]) => {
    setLoadingAccountOptions(true);
    useFetch("/api/Admin/MIS/ListAllAccounts", "GET", {
      failureMessage: "Failed to fetch accounts",
      config: {
        params: {
          clientIds: JSON.stringify(clientIds),
        },
      },
      thenCallBack: (response) => {
        setAccountOptions(
          response.data.accountDetails?.map((account) => {
            return { actorId: account.actorId, actorName: account.actorName };
          }) || []
        );
        setLoadingAccountOptions(false);
      },
      catchCallBack: () => {
        setLoadingAccountOptions(false);
      },
    });
  };

  const resetAccountSelections = () => {
    setSelectedAccounts([{ id: 1, actor: null, view: null }]);
  };

  const handleAddAccount = () => {
    // Check if maximum accounts limit is reached
    if (selectedAccounts.length >= MAX_ACCOUNTS) {
      toast.error(<CustomToast message="Maximum 15 accounts can be added" />);
    }

    setSelectedAccounts([...selectedAccounts, { id: selectedAccounts.length + 1, actor: null, view: null }]);
  };

  const updateAccountView = (actorId: number, view: any) => {
    setSelectedAccounts(
      selectedAccounts.map((selection) => {
        if (selection.id === actorId) {
          return { ...selection, view };
        }
        return selection;
      })
    );
  };

  const handleCreateTemplate = () => {
    // Check if template name is provided
    if (!templateName.trim()) {
      toast.error(<CustomToast message="Please enter a template name" />);
      return;
    }

    // Check if at least one account is selected
    const hasSelectedAccount = selectedAccounts.some((item) => item.actor !== null);
    if (!hasSelectedAccount) {
      toast.error(<CustomToast message="Please select at least one account" />);
      return;
    }

    // Check if all selected accounts have a view
    const allAccountsHaveView = selectedAccounts.every((item) => item.actor === null || item.view !== null);
    if (!allAccountsHaveView) {
      toast.error(<CustomToast message="Please select a view for each account" />);
      return;
    }
    let selectedAccountsData = null;
    if (isAllSelected) {
      const viewId = selectedAccounts[0].view?.id;
      selectedAccountsData = accountOptions
        .filter((item) => item.actorId !== -1)
        .map((item) => ({
          actorId: item.actorId,
          viewId: viewId,
          isDefault: true,
        }));
    } else
      selectedAccountsData = selectedAccounts
        .filter((item) => item.actor !== null)
        .map((item) => ({
          actorId: item.actor?.actorId,
          viewId: item.view?.id,
          isDefault: item.view?.isDefault,
        }));

    setIsCreatingTemplate(true);

    if (templateId) {
      useFetch("api/Admin/MIS/UpdateTemplate", "PUT", {
        failureMessage: "Failed to update template",
        showSuccessToast: true,
        data: {
          templateId: templateId,
          templateName,
          clientType: selectedClientTypes,
          clientIds: selectedClients.map((client) => client.clientId),
          reportDetails: selectedAccountsData,
        },
        thenCallBack: () => {
          listAllTemplates();
          setIsCreatingTemplate(false);
          setOpen(false);
        },
        catchCallBack: () => {
          setIsCreatingTemplate(false);
        },
      });
    } else {
      useFetch("/api/Admin/MIS/SaveNewTemplate", "POST", {
        failureMessage: "Failed to create template",
        showSuccessToast: true,
        data: {
          templateId: templateId,
          templateName,
          clientType: selectedClientTypes,
          clientIds: selectedClients.map((client) => client.clientId),
          reportDetails: selectedAccountsData,
        },
        thenCallBack: () => {
          listAllTemplates();
          setIsCreatingTemplate(false);
          setOpen(false);
        },
        catchCallBack: () => {
          setIsCreatingTemplate(false);
        },
      });
    }
  };

  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "900px", minHeight: "500px" } }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 2,
          background: "rgba(0, 0, 0, 0.04)",
        }}
      >
        <Typography variant="h6">{templateId ? "Edit" : "Create"} Template</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      {isFetchingTemplateDetails ? (
        <Loader />
      ) : (
        <>
          <DialogContent sx={{ padding: 0 }}>
            <Stack spacing={2} padding={3}>
              <Box>
                <Typography variant="subtitle2">Enter Consolidated Template Name</Typography>
                <TextField
                  fullWidth
                  placeholder="Enter Text"
                  value={templateName}
                  onChange={(e) => setTemplateName(e.target.value)}
                  sx={{
                    "& fieldset": {
                      borderRadius: "4px",
                    },
                  }}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2">Select Client Type</Typography>
                <Autocomplete
                  value={selectedClientTypes}
                  limitTags={2}
                  fullWidth
                  multiple
                  options={clientTypeOptions}
                  disableCloseOnSelect
                  onChange={(_event, value) => {
                    const selectedClientTypes = value as ClientTypeStrings[];
                    setSelectedClientTypes(value);
                    const clients = selectedClientTypes.flatMap((clientType) => allClients[clientType] || []);
                    setClientOptions(
                      clients.map((client) => ({ clientName: client.clientName, clientId: client.clientId }))
                    );
                    setSelectedClients([]);
                    resetAccountSelections(); // Reset account selections when client type changes
                  }}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props as any;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{
                        "& fieldset": {
                          borderRadius: "4px",
                        },
                      }}
                      {...params}
                      placeholder="Select client type"
                    />
                  )}
                />
              </Box>
              <Box>
                <Typography variant="subtitle2">Select Client</Typography>
                <Autocomplete
                  limitTags={2}
                  fullWidth
                  multiple
                  value={selectedClients}
                  onChange={(_event, value) => {
                    setSelectedClients(value);
                    resetAccountSelections();
                  }}
                  onBlur={() => {
                    listAllAccounts(selectedClients.map((client) => client.clientId));
                  }}
                  isOptionEqualToValue={(option, value) => option.clientId === value.clientId}
                  options={clientOptions}
                  disableCloseOnSelect
                  getOptionLabel={(option) => `${option.clientName} (${option.clientId})`}
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props as any;
                    return (
                      <li key={key} {...optionProps}>
                        <Checkbox
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {`${option.clientName} (${option.clientId})`}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      sx={{
                        "& fieldset": {
                          borderRadius: "4px",
                        },
                      }}
                      {...params}
                      placeholder="Select client"
                    />
                  )}
                />
              </Box>
            </Stack>
            <Divider />
            <Stack paddingX={4} paddingY={2} spacing={2}>
              {selectedAccounts.map((selection, index) => (
                <AccountSelectSection
                  key={selection.id}
                  selection={selection}
                  index={index}
                  accountOptions={accountOptions}
                  selectedAccounts={selectedAccounts}
                  setSelectedAccounts={setSelectedAccounts}
                  updateAccountView={updateAccountView}
                  loadingAccountOptions={loadingAccountOptions}
                  defaultViewOptions={defaultViewOptions}
                />
              ))}
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddAccount}
                  disabled={isAllSelected || isMaxAccountsReached}
                  sx={{ mt: 1 }}
                >
                  Add Account
                </Button>
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ padding: 1, background: "#F1F1F1" }}>
            <Button
              variant="text"
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancel
            </Button>
            <NdButton variant="contained" disabled={isCreatingTemplate} onClick={handleCreateTemplate}>
              {isCreatingTemplate && <LoadingIcon loading={true} />}
              {templateId ? "Save" : "Add"} Template
            </NdButton>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}
