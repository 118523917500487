import AutorenewIcon from "@mui/icons-material/Autorenew";
import DescriptionIcon from "@mui/icons-material/Description";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import {
  Box,
  Chip,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { NdButton } from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/CommonComponents";
import AutomatedMailsListLoading from "src/Components/Recon360/ReconMIS/MailMISReports/AutomatedMailsListLoading";
import {
  AUTO_MAIL_TEMPLATE_STATUS,
  generateEmailSchedule,
  MIS_MAIL_TYPES,
  TemplateDetails,
} from "src/Components/Recon360/ReconMIS/MailMISReports/Interfaces";
import NoAutomatedMailsUI from "src/Components/Recon360/ReconMIS/MailMISReports/NoAutomatedMailsUI";
import SetupMail from "src/Components/Recon360/ReconMIS/MailMISReports/SetupMail";

export default function MailMISTemplates() {
  const [isLoadingAutomatedMails, setIsLoadingAutomatedMails] = useState(false);
  const [automatedMailTemplatesList, setAutomatedMailTemplatesList] = useState<TemplateDetails[]>([]);
  const [currentMailSetupType, setCurrentMailSetupType] = useState<string>(null);
  const [isEditingMailTemplateFlag, setIsEditingMailTemplateFlag] = useState<boolean>(false);
  const [currentEditingTemplateDetails, setCurrentEditingTemplateDetails] = useState<any>(null);

  useEffect(() => {
    listAllAutomatedMails();
  }, []);

  const handlePauseMailToggle = (templateId, isActive) => {
    setAutomatedMailTemplatesList((prevTemplates) =>
      prevTemplates.map((template) => {
        if (template.id === templateId) {
          template.isActive = !isActive;
          template.status = isActive ? AUTO_MAIL_TEMPLATE_STATUS.Paused : AUTO_MAIL_TEMPLATE_STATUS.Active;
        }
        return template;
      })
    );
    useFetch("/api/Admin/MISMail/ToggleActiveStatus", "POST", {
      failureMessage: "Failed to toggle active status",
      showSuccessToast: true,
      data: { isActive: !isActive, templateId: templateId },
      catchCallBack: () => {
        listAllAutomatedMails();
      },
    });
  };

  const listAllAutomatedMails = () => {
    setIsLoadingAutomatedMails(true);
    useFetch("/api/Admin/MISMail/ListAllMailTemplates", "GET", {
      failureMessage: "Failed to fetch automated mail templates",
      thenCallBack: (response) => {
        setAutomatedMailTemplatesList(response.data.templatesDetail || []);
        setIsLoadingAutomatedMails(false);
      },
      catchCallBack: () => {
        setIsLoadingAutomatedMails(false);
      },
    });
  };

  return currentMailSetupType ? (
    <SetupMail
      useCaseType="AdminMIS"
      setCurrentMailSetupType={setCurrentMailSetupType}
      currentMailSetupType={currentMailSetupType}
      isEditingMailTemplateFlag={isEditingMailTemplateFlag}
      currentEditingTemplateDetails={currentEditingTemplateDetails}
      listAllAutomatedMailTemplates={listAllAutomatedMails}
    />
  ) : (
    <Stack sx={{ width: "100%", background: "#fff" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          backgroundColor: "#ffffff",
          padding: 2,
        }}
      >
        <Typography variant="h6">Automated Email</Typography>
        <NdButton
          variant="contained"
          onClick={() => {
            setCurrentMailSetupType(MIS_MAIL_TYPES.automatedEmail);
            setIsEditingMailTemplateFlag(false);
          }}
        >
          Send Automated email
        </NdButton>
      </Box>
      <Divider />

      {isLoadingAutomatedMails ? (
        <AutomatedMailsListLoading />
      ) : automatedMailTemplatesList.length > 0 ? (
        <Stack padding={2} gap={2}>
          {automatedMailTemplatesList.map((item) => (
            <Paper
              key={item.id}
              sx={{
                padding: "8px 16px",
                borderRadius: "4px",
                backgroundColor: "#ffffff",
                cursor: "pointer",
              }}
              onClick={() => {
                setCurrentEditingTemplateDetails(item);
                setIsEditingMailTemplateFlag(true);
                setCurrentMailSetupType(MIS_MAIL_TYPES.automatedEmail);
              }}
            >
              <ListItem sx={{ padding: 0 }}>
                <ListItemIcon sx={{ minWidth: 2, marginRight: 2 }}>
                  <MailOutlineIcon />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                      <Typography variant="h6" className="fs_16 fw_400" color="#1976D2">
                        {item.name}
                      </Typography>
                      {item.status !== AUTO_MAIL_TEMPLATE_STATUS.Active && (
                        <Tooltip
                          title={
                            item.status === AUTO_MAIL_TEMPLATE_STATUS.Error
                              ? "All reports added in this mail are deleted and mail cannot be sent"
                              : null
                          }
                        >
                          <Chip
                            label={item.status}
                            color={
                              item.status === AUTO_MAIL_TEMPLATE_STATUS.Error
                                ? "error"
                                : item.status === AUTO_MAIL_TEMPLATE_STATUS.Completed
                                ? "success"
                                : "warning"
                            }
                            sx={{
                              "& .MuiChip-label": { fontSize: "13px", fontWeight: 400 },
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
                {item.status !== AUTO_MAIL_TEMPLATE_STATUS.Completed && (
                  <Tooltip title={item.isActive ? "Click to Pause Email" : "Click to Resume Email"}>
                    <Switch
                      checked={item.isActive}
                      onClick={(e) => {
                        e.stopPropagation();
                        handlePauseMailToggle(item.id, item.isActive);
                      }}
                      disabled={item.status === AUTO_MAIL_TEMPLATE_STATUS.Error}
                    />
                  </Tooltip>
                )}
              </ListItem>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: 2,
                }}
              >
                <Box sx={{ marginLeft: 4 }}>
                  {item.attachedReports.map((report) => (
                    <Chip
                      key={report.id}
                      icon={<DescriptionIcon sx={{ color: "#0000008f" }} />}
                      label={report.name}
                      sx={{
                        backgroundColor: "#00000014",
                        borderRadius: "100px",
                        padding: "4px",
                        margin: "4px",
                      }}
                    />
                  ))}
                </Box>

                {(item.status === AUTO_MAIL_TEMPLATE_STATUS.Active ||
                  item.status === AUTO_MAIL_TEMPLATE_STATUS.Paused) && (
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 1, flexShrink: 0 }}>
                    <AutorenewIcon sx={{ color: "#0000008f" }} />
                    <Typography
                      variant="subtitle2"
                      color={item.isActive ? "rgba(0, 0, 0, 0.60)" : "rgba(0, 0, 0, 0.38)"}
                      className="fw_500 fs_14"
                    >
                      {generateEmailSchedule(item.startDate, item.endDate, item.frequency)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Paper>
          ))}
        </Stack>
      ) : (
        <NoAutomatedMailsUI
          setCurrentMailSetupType={setCurrentMailSetupType}
          setIsEditingMailTemplateFlag={setIsEditingMailTemplateFlag}
        />
      )}
    </Stack>
  );
}
