import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import dayjs from "dayjs";
import React, { useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { formatDate } from "src/Utils/DateUtils";
import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { TaskBoardTableView, TaskTrackerFilters } from "./Interfaces";
import { dateRangeColumns, numberRangeColumns, textTypeColumns } from "./TaskTracker";

interface SaveViewModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTableView: TaskBoardTableView;
  tableInstance: any;
  listAllViews: () => void;
  mainFilter: TaskTrackerFilters;
  saveLastSelectedView: (view: TaskBoardTableView) => void;
  companyId: string;
  branchCode: string;
}
export default function SaveViewModal({
  open,
  setOpen,
  selectedTableView,
  tableInstance,
  listAllViews,
  mainFilter,
  saveLastSelectedView,
  companyId,
  branchCode,
}: SaveViewModalProps) {
  const [viewName, setViewName] = useState<string>(selectedTableView.isDefault ? "" : selectedTableView.name);
  const [tableViewAction, setTableViewAction] = useState<string>("");

  const formatColumnFilters = (columnFilters) => {
    if (viewName.length < 3) {
      toast.error(<CustomToast message="View name should be atleast 3 characters long" />);
      return;
    }
    if (viewName.length > 40) {
      toast.error(<CustomToast message="View name should be atmost 40 characters long" />);
      return;
    }
    const columnFiltersObj = {};

    // Iterate over columnFilters and merge values
    columnFilters.forEach((filter) => {
      const { id, value } = filter;
      const isDateRangeFilter = dateRangeColumns.includes(id);
      const isNumberRangeFilter = numberRangeColumns.includes(id);

      if (isDateRangeFilter) {
        const startDate = value[0] ? formatDate(dayjs(value[0]).toDate()) : null;
        const endDate = value[1] ? formatDate(dayjs(value[1]).toDate()) : null;
        if (startDate === null && endDate === null) return;
        columnFiltersObj[id] = [startDate, endDate];
      } else if (textTypeColumns.includes(id) || (value as any).length === 0) {
        return;
      } else if (isNumberRangeFilter) {
        const min = value[0] ? value[0] : null;
        const max = value[1] ? value[1] : null;
        if (min === null && max === null) return;
        columnFiltersObj[id] = [min, max];
      } else {
        columnFiltersObj[id] = value;
      }
    });

    return Object.keys(columnFiltersObj).map((key) => ({
      id: key,
      value: columnFiltersObj[key],
    }));
  };
  const handleUpdateTableView = () => {
    uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_UPDATEEXISTING_CLICK.functionName, companyId, branchCode, {
      message: uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_UPDATEEXISTING_CLICK.message,
    });

    setTableViewAction("Updating");
    const currentState = tableInstance?.getState();
    const { columnOrder, columnSizing, columnVisibility, columnPinning, columnFilters, grouping } = currentState;

    const columnFiltersArray = formatColumnFilters(columnFilters);
    useFetch(API_ENDPOINTS.UPDATE_TABLE_VIEW.url, "POST", {
      showSuccessToast: true,
      failureMessage: API_ENDPOINTS.UPDATE_TABLE_VIEW.failureMessage,
      data: {
        viewId: selectedTableView.id,
        viewName: viewName,
        columnOrder,
        columnSizing,
        columnVisibility,
        columnPinning,
        columnFilters: columnFiltersArray,
        grouping,
        mainFilter,
      },
      thenCallBack: () => {
        setTableViewAction("");
        listAllViews();
        setOpen(false);
      },
      catchCallBack: () => {
        setTableViewAction("");
        setOpen(false);
      },
    });
  };

  const handleCreateTableView = async () => {
    uiLogger(uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_SAVEASNEW_CLICK.functionName, companyId, branchCode, {
      message: uiLoggerNamesTaskBoard.UI_WF_TB_SAVEVIEW_SAVEASNEW_CLICK.message,
    });

    setTableViewAction("Creating");
    const currentState = tableInstance?.getState();
    const { columnOrder, columnSizing, columnVisibility, columnPinning, columnFilters, grouping } = currentState;
    const columnFiltersArray = formatColumnFilters(columnFilters);

    useFetch(API_ENDPOINTS.SAVE_TABLE_VIEW.url, "POST", {
      showSuccessToast: true,
      failureMessage: API_ENDPOINTS.SAVE_TABLE_VIEW.failureMessage,
      data: {
        viewName: viewName,
        columnOrder,
        columnSizing,
        columnVisibility,
        columnPinning,
        columnFilters: columnFiltersArray,
        grouping,
        mainFilter,
      },
      thenCallBack: async (res) => {
        setTableViewAction("");
        const newCreatedView = {
          id: res.data.createViewDetails.id,
          name: res.data.createViewDetails.viewName,
          isDefault: false,
        };
        setOpen(false);
        await saveLastSelectedView(newCreatedView);
        listAllViews();
      },
      catchCallBack: () => {
        setTableViewAction("");
        setOpen(false);
      },
    });
  };
  return (
    <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: $.BR_fix }} maxWidth="sm" fullWidth>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle component={"div"} width={"90%"}>
          Save Table View
        </DialogTitle>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1 }}>
        <TextField
          className={$.BR_fix}
          label="Enter table view name"
          value={viewName}
          onChange={(e) => setViewName(e.target.value)}
          variant="outlined"
          size="medium"
          sx={{ ".MuiInputBase-root": { borderRadius: "4px" } }}
        />
      </DialogContent>
      <DialogActions className="dialog_footer">
        {selectedTableView.isDefault === false ? (
          <>
            <Button
              className={$.BR_fix}
              variant="outlined"
              disabled={tableViewAction !== ""}
              onClick={() => {
                handleCreateTableView();
              }}
              sx={{
                color: "rgba(84, 28, 76, 1)",
                borderColor: "rgba(84, 28, 76, 0.50)",
                "&:hover, &.Mui-focusVisible": { borderColor: "rgba(84, 28, 76, 1)" },
              }}
            >
              {tableViewAction === "Creating" && <LoadingIcon loading={true} />}
              Save as New
            </Button>
            <NdButton
              className={$.BR_fix}
              variant="contained"
              disabled={tableViewAction !== ""}
              onClick={() => {
                handleUpdateTableView();
              }}
            >
              {tableViewAction === "Updating" && <LoadingIcon loading={true} />}
              Update Existing
            </NdButton>
          </>
        ) : (
          <NdButton
            className={$.BR_fix}
            variant="contained"
            disabled={tableViewAction !== ""}
            onClick={() => {
              handleCreateTableView();
            }}
          >
            {tableViewAction === "Creating" && <LoadingIcon loading={true} />}
            Save as New
          </NdButton>
        )}
      </DialogActions>
    </Dialog>
  );
}
