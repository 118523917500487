import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HelpIcon from "@mui/icons-material/Help";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { NdButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { CustomFieldInfo } from "./CustomFields";
import LogicFieldSection from "./LogicFieldSection";

const filter = createFilterOptions<any>();

export enum CUSTOM_FIELD_TYPE {
  Number = "Number",
  Currency = "Currency",
  Text = "Text",
  Date = "Date",
  List = "List",
}

interface AddNewCustomFieldModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fieldDetails: CustomFieldInfo | null;
  setAllCustomFields: React.Dispatch<React.SetStateAction<CustomFieldInfo[]>>;
}

export default function AddNewCustomFieldModal({
  open,
  setOpen,
  fieldDetails,
  setAllCustomFields,
}: AddNewCustomFieldModalProps) {
  const [isAddingNewField, setIsAddingNewField] = useState<boolean>(false);
  const [fieldName, setFieldName] = useState<string>(fieldDetails ? fieldDetails.name : "");
  const [fieldType, setFieldType] = useState<CUSTOM_FIELD_TYPE | "">(fieldDetails ? fieldDetails.type : "");
  const fixedOptions = fieldDetails ? fieldDetails.listOptions?.values : [];
  const [values, setValues] = useState<string[]>(fieldDetails ? fieldDetails.listOptions?.values : []);
  const [multiSelectEnabled, setMultiSelectEnabled] = useState<boolean>(
    fieldDetails ? fieldDetails.listOptions?.isMultiSelect : false
  );
  const [isLogicFieldSectionExpanded, setIsLogicFieldSectionExpanded] = useState<boolean>(
    fieldDetails ? fieldDetails.isLogical : false
  );
  const [excelFunctions, setExcelFunctions] = useState<string[]>([]);
  const [expressions, setExpressions] = useState<{ id: number; name: string; category: string }[]>([]);
  const [taskIdOptions, setTaskIdOptions] = useState<number[]>([]);
  const [formula, setFormula] = useState<string>(fieldDetails ? fieldDetails.formula || "" : "");

  useEffect(() => {
    getExcelFunctionsAndExpressions();
  }, []);

  const addNewCustomFieldHandler = () => {
    const payloadObj = {
      name: fieldName,
      type: fieldType,
    };
    if (fieldType === CUSTOM_FIELD_TYPE.List) {
      payloadObj["listOptions"] = {
        values: values,
        isMultiSelect: multiSelectEnabled,
      };
    }
    setIsAddingNewField(true);
    useFetch<{ customField: CustomFieldInfo }>(API_ENDPOINTS.ADD_NEW_CUSTOM_FIELD.url, "POST", {
      showSuccessToast: true,
      failureMessage: API_ENDPOINTS.ADD_NEW_CUSTOM_FIELD.failureMessage,
      data: payloadObj,
      thenCallBack: (response) => {
        setIsAddingNewField(false);
        setOpen(false);
        setAllCustomFields((prev) => [...prev, response.data.customField]);
      },
      catchCallBack: () => {
        setIsAddingNewField(false);
      },
    });
  };

  const editCustomFieldHandler = () => {
    const payloadObj = {
      id: fieldDetails?.id,
      name: fieldName,
    };
    if (fieldType === CUSTOM_FIELD_TYPE.List) {
      payloadObj["values"] = values;
    }
    setIsAddingNewField(true);
    useFetch<{ customField: CustomFieldInfo }>(API_ENDPOINTS.UPDATE_CUSTOM_FIELD(fieldDetails?.id).url, "PUT", {
      showSuccessToast: true,
      failureMessage: API_ENDPOINTS.UPDATE_CUSTOM_FIELD(fieldDetails?.id).failureMessage,
      data: payloadObj,
      thenCallBack: (response) => {
        setIsAddingNewField(false);
        setOpen(false);
        setAllCustomFields((prev) =>
          prev.map((field) => {
            if (field.id === response.data.customField.id) {
              return response.data.customField;
            }
            return field;
          })
        );
      },
      catchCallBack: () => {
        setIsAddingNewField(false);
      },
    });
  };

  const getExcelFunctionsAndExpressions = () => {
    useFetch("/api/Workflow/Customisation/Field/Logical/Data", "GET", {
      failureMessage: "Failed to get excel functions and expressions",
      thenCallBack: (response) => {
        setTaskIdOptions(response.data.taskIds || []);
        setExcelFunctions(response.data.functions || []);
        setExpressions(response.data.expressions || []);
      },
    });
  };

  const createLogicalCustomField = () => {
    setIsAddingNewField(true);
    useFetch("/api/Workflow/Customisation/Field/Logical", "POST", {
      failureMessage: "Failed to create logical custom field",
      showSuccessToast: true,
      data: {
        name: fieldName,
        type: fieldType,
        formula: formula,
      },
      thenCallBack: (response) => {
        setIsAddingNewField(false);
        setOpen(false);
        setAllCustomFields((prev) => [...prev, response.data.customField]);
      },
      catchCallBack: () => {
        setIsAddingNewField(false);
        setOpen(false);
      },
    });
  };
  return (
    <Dialog open={open} sx={{ "& .MuiDialog-paper": { borderRadius: "4px", minWidth: "900px" } }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: 2 }}>
        <Typography variant="h6">{fieldDetails === null ? "Add" : "Edit"} Custom Field</Typography>
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      <DialogContent>
        <Stack spacing={2}>
          <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingX: 1 }}>
            <Typography variant="subtitle2" className="fw_500 fs_14">
              Field Name
            </Typography>
            <TextField
              sx={{ width: "350px" }}
              InputProps={{ sx: { borderRadius: "4px !important" } }}
              label="Name"
              variant="outlined"
              size="small"
              value={fieldName}
              onChange={(e) => setFieldName(e.target.value)}
            />
          </Box>
          {fieldDetails === null && (
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingX: 1 }}>
              <Typography variant="subtitle2" className="fw_500 fs_14">
                Field Type
              </Typography>
              <FormControl size="small">
                <InputLabel>Select</InputLabel>
                <Select
                  sx={{ width: "350px", borderRadius: "4px !important" }}
                  variant="outlined"
                  size="small"
                  label="Select"
                  placeholder="Select"
                  value={fieldType}
                  onChange={(e) => setFieldType(e.target.value as CUSTOM_FIELD_TYPE)}
                >
                  <MenuItem value={CUSTOM_FIELD_TYPE.Number}>Number</MenuItem>
                  <MenuItem value={CUSTOM_FIELD_TYPE.Currency}>Currency</MenuItem>
                  <MenuItem value={CUSTOM_FIELD_TYPE.Text}>Text</MenuItem>
                  <MenuItem value={CUSTOM_FIELD_TYPE.Date}>Date</MenuItem>
                  <MenuItem value={CUSTOM_FIELD_TYPE.List}>List</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}

          {fieldType === CUSTOM_FIELD_TYPE.List ? (
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", paddingX: 1 }}>
              <Typography variant="subtitle2" className="fw_500 fs_14">
                List Options
              </Typography>
              <Box>
                <Autocomplete
                  sx={{ "& fieldset": { borderRadius: "4px" } }}
                  size="small"
                  multiple
                  value={values}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    if (inputValue.trim().length === 0) {
                      return filtered;
                    }
                    const isExisting = options.some((option) => inputValue.toLowerCase() === option.toLowerCase());
                    if (inputValue !== "" && !isExisting) {
                      filtered.push(`Add "${inputValue}"`);
                    }

                    return filtered;
                  }}
                  onChange={(_event, newValue) => {
                    if (newValue.length > 0) {
                      const lastItem = newValue[newValue.length - 1]; // Get the last item
                      if (lastItem.startsWith("Add ")) {
                        newValue[newValue.length - 1] = lastItem.replace("Add ", "").replace(/^"(.*)"$/, "$1"); // Replace only the last item
                      }
                    }
                    setValues([...fixedOptions, ...newValue.filter((option) => !fixedOptions.includes(option))]);
                  }}
                  options={values}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => {
                      const { key, ...tagProps } = getTagProps({ index });
                      return <Chip key={key} label={option} {...tagProps} disabled={fixedOptions.includes(option)} />;
                    })
                  }
                  renderInput={(params) => (
                    <TextField size="small" {...params} placeholder="Enter values" sx={{ width: "350px" }} />
                  )}
                />
                <FormControlLabel
                  disabled={fieldDetails !== null}
                  control={
                    <Checkbox
                      checked={multiSelectEnabled}
                      onChange={() => {
                        setMultiSelectEnabled((prev) => !prev);
                      }}
                    />
                  }
                  label={<Typography color={"rgba(0, 0, 0, 0.60)"}>Multi-select values</Typography>}
                  labelPlacement="end"
                />
              </Box>
            </Box>
          ) : (
            <Stack spacing={2}>
              {(fieldDetails === null || fieldDetails?.isLogical) && (
                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Button
                    sx={{
                      textTransform: "none",
                    }}
                    variant="text"
                    color="primary"
                    endIcon={isLogicFieldSectionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    onClick={() => {
                      setIsLogicFieldSectionExpanded((prev) => !prev);
                    }}
                  >
                    Add Logic(Optional)
                  </Button>
                  <HelpIcon color="primary" />
                </Box>
              )}
              {isLogicFieldSectionExpanded && (
                <LogicFieldSection
                  excelFunctions={excelFunctions}
                  allColumns={expressions}
                  formula={formula}
                  setFormula={setFormula}
                  taskIdOptions={taskIdOptions}
                  fieldType={fieldType}
                />
              )}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: 1, background: "#F1F1F1" }}>
        <Button
          variant="text"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <NdButton
          disabled={
            isAddingNewField ||
            fieldName.trim().length === 0 ||
            fieldType.trim().length === 0 ||
            (fieldType === CUSTOM_FIELD_TYPE.List && values.length === 0) ||
            (fieldDetails !== null && fieldDetails.isLogical === true)
          }
          variant="contained"
          onClick={() => {
            if (fieldDetails === null) {
              if (fieldType !== CUSTOM_FIELD_TYPE.List && formula.trim().length > 0) {
                createLogicalCustomField();
              } else {
                addNewCustomFieldHandler();
              }
            } else {
              editCustomFieldHandler();
            }
          }}
        >
          {isAddingNewField && <LoadingIcon loading={true} />}
          {fieldDetails === null ? "Add" : "Save"}
        </NdButton>
      </DialogActions>
    </Dialog>
  );
}
