import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Box, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { MRT_ColumnFiltersState } from "material-react-table";
import React, { useEffect, useRef, useState } from "react";
import { formatDate } from "src/Utils/DateUtils";
import FilterAutocomplete from "./FilterAutocomplete";
import { TaskTrackerFilters } from "./Interfaces";
import { dateRangeColumns, numberRangeColumns, textTypeColumns } from "./TaskTracker";
import styles from "./TaskTrackerFiltersBars.module.scss";

const TaskTrackerFiltersBars = ({
  appliedFilters,
  columnFilters,
}: {
  appliedFilters: TaskTrackerFilters;
  columnFilters: MRT_ColumnFiltersState;
}) => {
  const mergedFilters = { ...appliedFilters };

  // Iterate over columnFilters and merge values
  columnFilters.forEach((filter) => {
    const { id, value } = filter;

    const isDateRangeFilter = dateRangeColumns.includes(id);
    const isNumberRangeFilter = numberRangeColumns.includes(id);

    // If key exists in appliedFilters, merge and remove duplicates
    if (mergedFilters[id]) {
      mergedFilters[id] = Array.from(new Set([...mergedFilters[id], ...(value as string)]));
    } else if (isDateRangeFilter) {
      const startDate = value[0] ? formatDate(dayjs(value[0]).toDate()) : null;
      const endDate = value[1] ? formatDate(dayjs(value[1]).toDate()) : null;
      if (startDate === null && endDate === null) return;
      mergedFilters[id] = [startDate, endDate];
    } else if (textTypeColumns.includes(id) || (value as any).length === 0) {
      return;
    } else if (isNumberRangeFilter) {
      const min = value[0] ? value[0] : null;
      const max = value[1] ? value[1] : null;
      if (min === null && max === null) return;
      mergedFilters[id] = [min, max];
    } else {
      mergedFilters[id] = value;
    }
  });

  const mergedFiltersArray = Object.entries(mergedFilters);
  if (mergedFiltersArray.length === 0) return null;

  const containerRef = useRef<HTMLDivElement>(null);
  const [disableLeftButton, setDisableLeftButton] = useState(true);
  const [disableRightButton, setDisableRightButton] = useState(true);

  const updateButtonsState = () => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      setDisableLeftButton(scrollLeft <= 0);
      setDisableRightButton(scrollLeft + clientWidth >= scrollWidth);
    }
  };

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      updateButtonsState();
      container.addEventListener("scroll", updateButtonsState);
    }
    window.addEventListener("resize", updateButtonsState);
    return () => {
      if (container) {
        container.removeEventListener("scroll", updateButtonsState);
      }
      window.removeEventListener("resize", updateButtonsState);
    };
  }, []);

  const scroll = (direction: "left" | "right") => {
    if (containerRef.current) {
      const { clientWidth } = containerRef.current;
      const scrollAmount = direction === "left" ? -clientWidth / 1.2 : clientWidth / 1.2;
      containerRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  return (
    <Box className={styles.filtersWrapper}>
      <IconButton onClick={() => scroll("left")} disabled={disableLeftButton} className={styles.scrollButton}>
        <ChevronLeftIcon />
      </IconButton>
      <Box className={styles.filtersContainer} ref={containerRef}>
        {mergedFiltersArray.map(([key, filteredValues]) => (
          <FilterAutocomplete key={key} filterName={key} filteredValues={filteredValues} />
        ))}
      </Box>
      <IconButton onClick={() => scroll("right")} disabled={disableRightButton} className={styles.scrollButton}>
        <ChevronRightIcon />
      </IconButton>
    </Box>
  );
};

export default TaskTrackerFiltersBars;
