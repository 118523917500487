import { Stack } from "@mui/material";
import React from "react";
import CustomFields from "./CustomFields";
import StatusCustomisation from "./StatusCustomisation";

export default function WorkflowSettings() {
  return (
    <Stack gap={3}>
      <StatusCustomisation />
      <CustomFields />
    </Stack>
  );
}
