import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, ListItemIcon, MenuItem, Typography } from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isOverFlowing } from "src/Components/Common/SideBar";
import useFetch from "src/Components/Common/useFetch";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import { NdButton } from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/CommonComponents";
import { ClientTypeStrings } from "src/entity/models/FrontendActor";
import DeleteTemplateModal from "./DeleteTemplateModal";
import MISTemplateModal, { ClientsByType, UserView } from "./MISTemplateModal";

export interface TemplateDetails {
  templateId: number;
  templateName: string;
  actorNames: string[];
  clientDetails: { clientName: string; clientId: number }[];
}

export default function ConsolidatedMISTemplates() {
  const [showMISTemplateModal, setShowMISTemplateModal] = useState<boolean>(false);
  const editingTemplateId = useRef<number>(null);
  const deleteTemplateDetails = useRef<{ templateId: number; templateName: string }>(null);
  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState<boolean>(false);
  const [allTemplates, setAllTemplates] = useState<TemplateDetails[]>([]);
  const [isLoadingTemplates, setIsLoadingTemplates] = useState<boolean>(false);

  const [clientTypeOptions, setClientTypeOptions] = useState<ClientTypeStrings[]>([]);
  const [allClients, setAllClients] = useState<ClientsByType>({});
  const [defaultViewOptions, setDefaultViewOptions] = useState<UserView[]>([]);

  useEffect(() => {
    listAllTemplates();
    listAllClientDetails();
    listAllDefaultViews();
  }, []);

  const listAllClientDetails = () => {
    useFetch("/api/Admin/MIS/ListAllClientDetails", "GET", {
      failureMessage: "Failed to fetch client details",
      thenCallBack: (response) => {
        setClientTypeOptions(response.data.clientTypeValues || []);
        setAllClients(response.data.clientGroupedOnClientType || {});
      },
    });
  };

  const listAllTemplates = () => {
    setIsLoadingTemplates(true);
    useFetch("/api/Admin/MIS/ListAllTemplates", "GET", {
      failureMessage: "Failed to fetch MIS Templates",
      thenCallBack: (response) => {
        setAllTemplates(response.data.templateDetails || []);
        setIsLoadingTemplates(false);
      },
      catchCallBack: () => {
        setIsLoadingTemplates(false);
      },
    });
  };

  const listAllDefaultViews = () => {
    useFetch("api/Admin/MIS/ListAllDefaultView", "GET", {
      failureMessage: "Failed to fetch views",
      thenCallBack: (response) => {
        setDefaultViewOptions(response.data.viewDetails?.map((view) => ({ ...view, isDefault: true })) || []);
      },
    });
  };
  const columnDefTemplates = useMemo(
    (): ColDef<TemplateDetails>[] => [
      {
        header: "Template Name",
        accessorFn: (row) => row.templateName || "-",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.templateName ? row.original.templateName : "-"}
          </div>
        ),
        size: 300,
        minSize: 300,
        filterVariant: "multi-select",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.templateName ? row.original.templateName : "-");
        },
      },
      {
        header: "Client",
        accessorFn: (row) => row.clientDetails?.map((client) => client.clientName).join(", ") || "-",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.clientDetails?.map((client) => client.clientName).join(", ") || "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.clientDetails && row.original.clientDetails.length > 0
              ? row.original.clientDetails.map((client) => `${client.clientName} (${client.clientId})`).join(", ")
              : "-"}
          </div>
        ),
        size: 400,
        minSize: 400,
        filterVariant: "multi-select",
      },
      {
        header: "Accounts",
        accessorFn: (row) => row.actorNames?.join(", ") || "-",
        filterFn: (row, _id, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.actorNames?.join(", ") || "-");
        },
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.actorNames?.length > 0 ? row.original.actorNames.join(", ") : "-"}
          </div>
        ),
        size: 400,
        minSize: 400,
        filterVariant: "multi-select",
      },
    ],
    []
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#fff",
          padding: 2,
        }}
      >
        <Typography variant="h6">Consolidated MIS Templates</Typography>
        <NdButton
          variant="contained"
          onClick={() => {
            editingTemplateId.current = null;
            setShowMISTemplateModal(true);
          }}
        >
          Create Template
        </NdButton>
      </Box>
      <Box
        sx={{
          background: "#FAFAFA",
          padding: 3,
        }}
      >
        <ReactTable
          columns={columnDefTemplates}
          rows={allTemplates}
          loading={isLoadingTemplates}
          enableRowSelection={false}
          enableRowActions={true}
          enableColumnOrdering={false}
          enableHiding={false}
          internalActionsOrder={["filterToggle", "fullScreenToggle"]}
          renderRowActionMenuItems={({ row, closeMenu }) => [
            <MenuItem
              key="editTask"
              onClick={() => {
                editingTemplateId.current = row.original.templateId;
                setShowMISTemplateModal(true);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <EditIcon fontSize="small" />
              </ListItemIcon>
              Edit Template Details
            </MenuItem>,
            <MenuItem
              key="viewAttachments"
              onClick={() => {
                deleteTemplateDetails.current = {
                  templateId: row.original.templateId,
                  templateName: row.original.templateName,
                };
                setShowDeleteTemplateModal(true);
                closeMenu();
              }}
            >
              <ListItemIcon>
                <DeleteIcon color="error" />
              </ListItemIcon>
              Delete Template
            </MenuItem>,
          ]}
        />
      </Box>
      {showMISTemplateModal && (
        <MISTemplateModal
          open={showMISTemplateModal}
          setOpen={setShowMISTemplateModal}
          clientTypeOptions={clientTypeOptions}
          allClients={allClients}
          templateId={editingTemplateId.current}
          listAllTemplates={listAllTemplates}
          defaultViewOptions={defaultViewOptions}
        />
      )}
      {showDeleteTemplateModal && (
        <DeleteTemplateModal
          open={showDeleteTemplateModal}
          setOpen={setShowDeleteTemplateModal}
          templateId={deleteTemplateDetails.current?.templateId}
          templateName={deleteTemplateDetails.current?.templateName}
          listAllTemplates={listAllTemplates}
        />
      )}
    </Box>
  );
}
