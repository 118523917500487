import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import { TagsInputUniqueKeys } from "../ReconMIS/AutoCompletes";
import "./PartnerUseCaseMasterFilter.scss";

type setAppliedFilters = React.Dispatch<
  React.SetStateAction<{
    [key: string]: string;
  }>
>;
const PartnerUseCaseMasterFilter = (props: { [key: string]: any; setAppliedFilters: setAppliedFilters }) => {
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [partnerNames, setPartnerNames] = useState<string[]>([]);
  const [balanceConfirmation, setBalanceConfirmation] = useState<boolean>(null);
  const [balanceConfirmationValue, setBalanceConfirmationValue] = useState<string>(null);
  const [msmeConfirm, setMsmeConfirm] = useState<boolean>(null);
  const [msmeConfirmValue, setMsmeConfirmValue] = useState<string>(null);

  const handleClose = () => {
    props.setOpenPartnerUseCaseMasterFilter(false);
  };

  const filterLedgerRequest = () => {
    let tempRowData = props.storeRowsDataPartnerUseCaseMaster;

    if (partnerNames?.length) {
      tempRowData = tempRowData?.filter((partnerRow) => {
        let found = false;
        partnerNames.forEach((name, _ind) => {
          if (_ind === 0) found = false;
          const partnerNameWithoutMultiSpace = name.replace(/\s+/g, " ");
          const partnerNameStr = partnerNameWithoutMultiSpace.toLowerCase();
          props.setIsFilterAppliedPartnerUseCaseMaster(true);
          if (partnerRow.businessPartnerName?.replace(/\s+/g, " ").toLowerCase()?.includes(partnerNameStr?.trim())) {
            found = true;
          }
        });
        return found;
      });
    }

    if (selectedCategory.length > 0) {
      props.setIsFilterAppliedPartnerUseCaseMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return selectedCategory?.some((val) => item.category?.includes(val));
      });
    }
    if (balanceConfirmationValue) {
      props.setIsFilterAppliedPartnerUseCaseMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.balanceConfirmation === balanceConfirmation;
      });
    }

    if (msmeConfirmValue) {
      props.setIsFilterAppliedPartnerUseCaseMaster(true);
      tempRowData = tempRowData?.filter((item: any) => {
        return item.msmeConfirmation === msmeConfirm;
      });
    }

    props.setAppliedFilters({
      "Partner Names": partnerNames?.map((name) => name?.trim()).join(", "),
      "Selected Category": selectedCategory.join(","),
      "Balance Confirmation Value": balanceConfirmationValue,
      "MSME Confirmation Value": msmeConfirmValue,
    });

    props.setRowsDataPartnerUseCaseMaster(tempRowData);
    handleClose();
  };

  const clearAllAppliedFilters = () => {
    setSelectedCategory([]);
    setPartnerNames([]);
    setBalanceConfirmation(null);
    setBalanceConfirmationValue(null);
    setMsmeConfirm(null);
    setMsmeConfirmValue(null);

    props.setAppliedFilters({});

    handleClose();
    setTimeout(() => {
      props.setOpenPartnerUseCaseMasterFilter(true);
      props.setRowsDataPartnerUseCaseMaster(props.storeRowsDataPartnerUseCaseMaster);
      props.setIsFilterAppliedPartnerUseCaseMaster(false);
    }, 700);
  };

  return (
    <div>
      <Dialog
        open={props.openPartnerUseCaseMasterFilter}
        aria-labelledby="PartnerUseCaseMasterFilter"
        className="partnerUseCaseMasterFilter"
      >
        <DialogTitle className="dialog_header">
          <p className="fs_24">Apply Filter</p>
          <button className="delete ml_20" aria-label="close" onClick={() => handleClose()} />
        </DialogTitle>
        <DialogContent>
          <div>
            <div className="d_flex m_15">
              <div className="w_300 vertical_center_align fw_600">Partner Names:</div>
              <div className="w_400 d_flex category_textField_width_390 ">
                <TagsInputUniqueKeys
                  tags={partnerNames}
                  setTags={setPartnerNames}
                  className="partnerNamesAutocomplete"
                  fullWidth={true}
                  label=""
                  placeholder="Newline separated Names"
                  splitter="newline"
                  limitTags={3}
                  textFieldProps={{ multiline: true, maxRows: 4 }}
                  options={props.storeRowsDataPartnerUseCaseMaster || []}
                  accessor={"businessPartnerName"}
                  uniqueKeyAccessor={"businessPartnerId"}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Category :</div>
              <div className="w_400 category_textField_width_390">
                <Autocomplete
                  // limitTags={3}
                  value={selectedCategory}
                  fullWidth={true}
                  onChange={(_, value: any) => {
                    setSelectedCategory(value);
                  }}
                  size="small"
                  multiple={true}
                  id="tags-outlined"
                  options={props.allCategories || []}
                  getOptionLabel={(option: any) => option}
                  // defaultValue={[top100Films[13]]}
                  filterSelectedOptions={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select category"
                      // placeholder="Favorites"
                    />
                  )}
                />
              </div>
            </div>
            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">Balance Confirmation :</div>
              <div className="w_400 category_textField_width_390">
                <FormControl>
                  <RadioGroup
                    row={true}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={balanceConfirmationValue}
                    onChange={(e) => {
                      if (e.target.value === "Active") {
                        setBalanceConfirmation(true);
                        setBalanceConfirmationValue("Active");
                      } else if (e.target.value === "Inactive") {
                        setBalanceConfirmation(false);
                        setBalanceConfirmationValue("Inactive");
                      }
                    }}
                  >
                    <>
                      <FormControlLabel value="Active" control={<Radio />} label="Active" />
                      <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                    </>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="d_flex m_15 ">
              <div className="w_300 vertical_center_align fw_600">MSME Confirmation:</div>
              <div className="w_400 category_textField_width_390">
                <FormControl>
                  <RadioGroup
                    row={true}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={msmeConfirmValue}
                    onChange={(e) => {
                      if (e.target.value === "Active") {
                        setMsmeConfirm(true);
                        setMsmeConfirmValue("Active");
                      } else if (e.target.value === "Inactive") {
                        setMsmeConfirm(false);
                        setMsmeConfirmValue("Inactive");
                      }
                    }}
                  >
                    <>
                      <FormControlLabel value="Active" control={<Radio />} label="Active" />
                      <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                    </>
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions className="dialog_footer space_between">
          <div>
            <Button
              className="theme_outline_btn"
              onClick={() => {
                clearAllAppliedFilters();
              }}
            >
              Clear All
            </Button>
          </div>
          <div>
            <Button
              className="theme_btn"
              id="filterPartnerUseCaseMaster_button"
              onClick={() => {
                filterLedgerRequest();
              }}
            >
              Apply
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PartnerUseCaseMasterFilter;
