import { ArrowRightRounded } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import {
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  createFilterOptions,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import TemplateDialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import { NestedMenuItem } from "mui-nested-menu";
import React, { createContext, MutableRefObject, useContext, useEffect, useRef, useState } from "react";
import { clarity } from "react-microsoft-clarity";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import uiLogger from "src/Utils/UiLogger";
import saveTemplate from "../../../assets/save template.svg";
import { ActorTypes, UserTypes } from "../../../entity/models/FrontendActor";
import {
  ActorTypesForRecon,
  CodesWithSign,
  Group,
  LedgerConfiguration,
  TallyAcceptedConfigColumnsResponse,
} from "../../../entity/recon-entity/ReconInterfaces";
import {
  columnHeaderDescription,
  groupLabels,
  labels,
  saveAsNewTemplate,
} from "../../../Utils/Recon/ColumnHeaderConfig/Constants";
import {
  columnHeaderNameLabel,
  compulsoryColumnHeader,
  Customized,
  DOCUMENT_TYPE_NAMES,
  EMPTY_CONFIG,
  ledgerUploadName,
  RECON360_SUB_TAB,
  showSanitizerOption,
} from "../../../Utils/Recon/Recon360/Constants";
import { uiLoggerName, uiLoggerNamesRecon } from "../../../Utils/Recon/UiLogger/Constants";
import useFetch from "../../Common/useFetch";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import SanitizerRules from "../DataSanitizer/SanitizerRules";
import { TallyOrSapCompanyId } from "../PartnerCommunication/CommonLegacy/CommonComponents";
import { Recon360Context } from "../Recon360";
import "../Recon360.scss";
import EditBusinessPartner from "../ReconDashboard/EditBusinessPartner";
import { ConfigTemplateRes } from "../ReconHome";

export interface Element {
  name: string;
  value: string[];
}

export interface LedgerConfiguration2 {
  [key: string]: any;
  documentDate: string[];
  documentType: string[];
  otherUniqueId: string[];
  paymentId: string[];
  dncnId: string[];
  invoiceIdForDncn: string[];
  particulars: string[];
  debitAmount: string[];
  creditAmount: string[];
  referenceNumber: string[];
  postingDate: string[];
  documentNumber: string[];
  clearingDocument: string[];
  clearingDate: string[];
  miscellaneous: string[];
  taxAmount: string[];
  closingBalance: number;

  invoice: CodesWithSign[];
  payment: CodesWithSign[];
  debitNote: CodesWithSign[];
  debitAndCreditNotes: CodesWithSign[];
  creditNote: CodesWithSign[];
  reversal: CodesWithSign[];
  tds: CodesWithSign[];
  interDocument: CodesWithSign[];
  garbageDoctype: any[];
}

export interface ColumnsToSanitize {
  columnToSanitize: string;
  sanitizationRules: SanitizeConfiguration[];
}

export interface SanitizeConfiguration {
  ruleId: number;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
}

export interface ListAnchorLedgerConfigResponse {
  ledgerConfigTemplate: {
    templateName: string;
    config: LedgerConfiguration;
    columnsToSanitize: any;
    groups: Group[];
    templateDescription?: string;
  };
}

export type removeitem = {
  nthIndex: number;
  ruleId: number;
  text: string;
  textToReplaceWith: string;
};

export type Item = {
  category: string;
  n: false;
  rule: string;
  ruleId: number;
  string1: true;
  string2: false;
  nthIndex: number;
  text: string;
  textToReplaceWith: string;
};

export type rule = {
  category: string;
  n: false;
  rule: string;
  ruleId: number;
  string1: true;
  string2: false;
};

export interface ConfigpoupRecon {
  saveRules: (rulesSaveRules: Item[], deleted: boolean) => void;
  columnName: string;
  labelName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  items: Item[];
  rules: rule[];
  setItems: (items: Item[]) => void;
  rulesWithVal: any;
  setRulesWithVal: (rulesWithVal: any) => void;
  isDelete: boolean;
  handleRemoveClick: (row: removeitem, ind: number) => void;
  closeSanitizerLogger: () => void;
  fromWhichSide: string;
  applyClickSanitizerLogger: () => void;
  groupsListRef: MutableRefObject<Group[]>; // type for a mutable ref object
}

const options: any[] = [];
const filter = createFilterOptions<any>();
export const ConfigPopupAnchorRecon360Context = createContext<ConfigpoupRecon | null>(null);

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface ColHeaderConfigProps {
  allRules?: ColumnsToSanitize[];
  businessPartnerId?: number;
  configList: {
    templateId: number;
    templateName: string;
    IsRuConfigTemplate?: boolean;
    IsPartnerConfigTemplate?: boolean;
    IsERPConfigTemplate?: boolean;
  }[];
  creditAmountSignReverseFlag: boolean;
  debitAmountSignReverseFlag: boolean;
  taxAmountSignReverseFlag: boolean;
  disableSpecificConfigCheckbox: boolean;
  getConfig?: () => Promise<void>;
  headerName: Record<string, any[]> | LedgerConfiguration;
  heading: string;
  integration?: boolean;
  name?: string;
  newconfig?: boolean;
  newMapping?: boolean;
  openNextDialog: (value: any, header: LedgerConfiguration | any, template: string) => void;
  removeExistingTemplate?: () => void;
  rules: rule[];
  sanitizeCol: ColumnsToSanitize[];
  saveRulesApi: (header: LedgerConfiguration) => void;
  setColumnHeaderConfigDialog: StateDispatch<boolean>;
  setCreditAmountSignReverseFlag: StateDispatch<boolean>;
  setDebitAmountSignReverseFlag: StateDispatch<boolean>;
  setTaxAmountSignReverseFlag: StateDispatch<boolean>;
  setHeaderName: StateDispatch<LedgerConfiguration>;
  setNewMapping: StateDispatch<boolean>;
  setOpenDialog: StateDispatch<boolean>;
  setShowCustomOption?: StateDispatch<boolean>;
  setSpecificConfig: StateDispatch<boolean>;
  showCustomOption?: boolean;
  showSettingsIcon: boolean;
  specificConfig: boolean;
  subTab: string;
  template: boolean;
  templateName: string;
  setTemplateName: StateDispatch<string>;
  uiLogger?: (functionName: string) => any;
  isUsedAsTemplate?: boolean;
  editingTemplate?: boolean;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
  groupsListRef: MutableRefObject<Group[]>; // type for a mutable ref object
}

const ColumnHeaderConfig = (props: ColHeaderConfigProps) => {
  const { actor } = useContext(userContext);
  const context = useContext(Recon360Context) || {
    getLedgerConfigList: null,
    ListAllConfigTemplateNamesRu: null,
    configMappedOwn: null,
    configMappedBp: null,
    businessPartnerSelectedRef: null,
    companyId: null,
    branchCode: null,
    partnerConfigTemplatesOfReconUser: null,
    listPartnerAllLedgerConfigTemplateForCurrentReconUser: null,
    mappingId: null,
  };
  const {
    getLedgerConfigList,
    ListAllConfigTemplateNamesRu,
    configMappedOwn,
    configMappedBp,
    businessPartnerSelectedRef,
    companyId,
    branchCode,
    partnerConfigTemplatesOfReconUser,
    listPartnerAllLedgerConfigTemplateForCurrentReconUser,
    mappingId,
  } = context;
  const [saveValueColumnHeader, setSaveValueColumnHeader] = useState<string[]>([]);
  const [addElementIndex, setAddElementIndex] = useState<number>(null);
  const [openAddDialog, setOpenAddDialog] = useState<boolean>(false);
  const [templateName, setTemplateName] = useState<string>(props.templateName || "");
  const editedTemplateName = useRef("");
  const [configHeaderName, setConfigHeaderName] = useState<LedgerConfiguration>(
    (props.headerName as LedgerConfiguration) || ({} as LedgerConfiguration)
  );
  const [headerName, setHeaderName] = useState(
    Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] }))
  );
  const [sanitizeCol, setSanitizeCol] = useState<ColumnsToSanitize[]>(props.allRules || props.sanitizeCol || []);
  const [open, setOpen] = React.useState(false);
  const [columnName, setColumnName] = useState<string>("");
  const [labelName, setLabelName] = useState("");
  const [items, setItems] = React.useState([]);
  const [rulesWithVal, setRulesWithVal] = React.useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<number>(null);
  const [isDelete, setIsDelete] = React.useState(false);
  const [selectedTemplateName, setSelectedTemplateName] = useState<string>("");
  // const [isExpanded, setIsExpanded] = useState(false);
  const [duplicateTagName, setDuplicateTagName] = useState("");
  const [tallyHeaderNames, setTallyHeaderNames] = useState<string[]>([]);
  const [openBusinessPopup, setOpenBusinessPopup] = useState(false);
  const [openSaveAsTemplate, setOpenSaveAsTemplate] = useState(false);
  const [saveAsTemplateSelectedSection, setSaveAsTemplateSelectedSection] = useState("initial");
  const [startUpdateLoader, setStartUpdateLoader] = useState<boolean>(false);
  const [startSaveLoader, setStartSaveLoader] = useState<boolean>(false);
  const [inputValueTemplateName, setInputValueTemplateName] = useState("");
  const [inputValueTemplateDescription, setInputValueTemplateDescription] = useState("");
  const [errTemplateName, setErrTemplateName] = useState(false);
  const [errTemplateNameMsg, setErrTemplateNameMsg] = useState("");
  const [selectedTemplateForUpdate, setSelectedTemplateForUpdate] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openTemplate = Boolean(anchorEl);
  const enterPressedRef = useRef(false);

  useEffect(() => {
    editedTemplateName.current = props.templateName;
  }, []);

  useEffect(() => {
    const tempHeaderNames = Object.values(columnHeaderNameLabel)?.map((name) => {
      const value = (props.headerName && props.headerName[name]) || [];
      return {
        name,
        value,
      };
    });
    setHeaderName(tempHeaderNames);
  }, [props.headerName]);

  useEffect(() => {
    if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
      const nonCustomizeTemplate = props.configList.some((template: any) => template.templateName === templateName);

      if (nonCustomizeTemplate) {
        setSelectedTemplateName(templateName);
        setTemplateName(templateName);
      } else {
        if (props.setShowCustomOption) props.setShowCustomOption(true);
        setSelectedTemplateName(Customized);
        setTemplateName(Customized);
      }
    }
    // eslint-disable-next-line
  }, [selectedTemplateName, templateName, props.configList]);

  const sortArraysInObject = (obj: LedgerConfiguration) => {
    DOCUMENT_TYPE_NAMES.forEach((key) => {
      if (obj[key]) {
        obj[key].sort((a: any, b: any) => a.sign - b.sign);
      }
    });
  };

  const onNextClickError = (from) => {
    const configPayload = Object.fromEntries(headerName.map((ele) => [ele.name, ele.value]));
    let tempObj: LedgerConfiguration;
    if (selectedTemplate > 0) {
      tempObj = configHeaderName;
      sortArraysInObject(tempObj);
      Object.assign(tempObj, configPayload);
    } else {
      tempObj = props.headerName as LedgerConfiguration;
      sortArraysInObject(tempObj);
      Object.assign(tempObj, configPayload);
    }
    if (from === saveAsNewTemplate.SAVE_AS_NEW) {
      if (isEmptyObject(tempObj)) {
        setStartSaveLoader(false);
        toast.error(<CustomToast message="The config is empty. Cannot save as new template." />);
      } else {
        insertNewConfig(tempObj, inputValueTemplateName, sanitizeCol);
      }
    } else if (from === saveAsNewTemplate.UPDATE_EXISTING) {
      if (isEmptyObject(tempObj)) {
        setStartUpdateLoader(false);
        toast.error(<CustomToast message="The config is empty. Cannot update template." />);
      } else {
        updateConfig(tempObj, selectedTemplateForUpdate, sanitizeCol);
      }
    } else {
      if (!tempObj.documentType?.[0]?.trim() && props.subTab === RECON360_SUB_TAB.PAYMENT_ADVICE) {
        // if there is no documentType added in payment advice case do not show doctype modal
        const emptyDocumentsObject = Object.fromEntries(DOCUMENT_TYPE_NAMES.map((key) => [key, []]));
        Object.assign(tempObj, emptyDocumentsObject);
        props.saveRulesApi(tempObj);
      } else {
        if (templateName?.trim().length < 1 && (actor.actorType === ActorTypes.Admin || props.editingTemplate)) {
          document.getElementById("templateNameError").innerHTML = "Template Name is required";
        } else {
          setSanitizeCol(sanitizeCol);
          props.openNextDialog(sanitizeCol, tempObj, templateName);
        }
      }
    }
  };

  const rules = props.rules;

  useEffect(() => {
    const getRules = (name: string) => {
      const rulesGetRules = sanitizeCol?.filter((val: { columnToSanitize: string }) => name === val.columnToSanitize);
      if (rulesGetRules?.length > 0) {
        const sanitizationRules = rulesGetRules[0].sanitizationRules;
        return sanitizationRules;
      } else {
        return [];
      }
    };
    const result = getRules(columnName);
    setItems(result);
  }, [columnName, sanitizeCol]);

  const changeTemplate = (templateId: number, isRuConfigTemplate = false) => {
    if (Number(templateId) === 0) {
      setTemplateName("");
      setHeaderName(Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] })));
      setSanitizeCol([]);
      setSelectedTemplate(templateId);
    } else {
      if (props.editingTemplate && isRuConfigTemplate) ListConfigTemplate(templateId.toString());
      else getConfigTemplate(parseInt(templateId.toString(), 10));
      setSelectedTemplate(templateId);
    }
  };
  const getConfigTemplate = async (id: number) => {
    await useFetch<ListAnchorLedgerConfigResponse>(API_ENDPOINTS.LIST_LEDGER_CONFIG_TEMPLATE(id).url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_LEDGER_CONFIG_TEMPLATE(id).failureMessage,
      thenCallBack: (response) => {
        const preValue = response.data.ledgerConfigTemplate.config;
        const newTemp = Object.values(columnHeaderNameLabel).map((name) => {
          const value = preValue[name] || [];
          return {
            name,
            value,
          };
        });
        props.setNewMapping(false);
        setHeaderName(newTemp);
        props.setDebitAmountSignReverseFlag(
          Boolean(response?.data?.ledgerConfigTemplate?.config?.debitAmountSignReverseFlag)
        );
        props.setCreditAmountSignReverseFlag(
          Boolean(response?.data?.ledgerConfigTemplate?.config?.creditAmountSignReverseFlag)
        );
        props.setTaxAmountSignReverseFlag(
          Boolean(response?.data?.ledgerConfigTemplate?.config?.taxAmountSignReverseFlag)
        );

        setConfigHeaderName(preValue);
        setSanitizeCol(response.data.ledgerConfigTemplate.columnsToSanitize);

        setTemplateName(response.data.ledgerConfigTemplate?.templateName);
        if (response.data.ledgerConfigTemplate.groups?.length > 0) {
          props.groupsListRef.current = response.data.ledgerConfigTemplate.groups;
        } else {
          props.groupsListRef.current = [];
        }
      },
    });
  };

  const ListConfigTemplate = (_templateId: string) => {
    useFetch<ConfigTemplateRes>(API_ENDPOINTS.GET_RU_CONFIG_TEMPLATE.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_RU_CONFIG_TEMPLATE.failureMessage,
      config: {
        params: {
          templateId: _templateId,
        },
      },
      thenCallBack: (response) => {
        if (response.data.template) {
          const preValue = response.data.template.config;
          const newTemplate = Object.values(columnHeaderNameLabel).map((name) => {
            const value = preValue[name] || [];
            return {
              name,
              value,
            };
          });
          props.setNewMapping(false);
          setHeaderName(newTemplate);
          props.setDebitAmountSignReverseFlag(Boolean(response?.data?.template?.config?.debitAmountSignReverseFlag));
          props.setCreditAmountSignReverseFlag(Boolean(response?.data?.template?.config?.creditAmountSignReverseFlag));
          props.setTaxAmountSignReverseFlag(Boolean(response?.data?.template?.config?.taxAmountSignReverseFlag));
          setConfigHeaderName(preValue);
          setSanitizeCol(response.data.template.columnsToSanitize);
          if (response.data.template.groups?.length > 0) {
            props.groupsListRef.current = response.data.template.groups;
          } else {
            props.groupsListRef.current = [];
          }

          setTemplateName(response.data.template.templateName);
        }
      },
    });
  };

  const handleRemoveClick = (_: removeitem, index: any) => {
    const rowArray = items.filter((_, ind) => {
      return ind !== index;
    });
    setItems(rowArray);
    setIsDelete(true);
  };

  const onChangeTemplateName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value.length > 16) {
      document.getElementById("templateNameError").innerHTML = "You can't use more than 16 characters";
      setTimeout(() => {
        document.getElementById("templateNameError").innerHTML = "";
      }, 3000);
    } else {
      setTemplateName(event.target.value);
      // props.setTemplateName(event.target.value);
      document.getElementById("templateNameError").innerHTML = "";
    }
  };

  const saveRules = (rulesSaveRules: SanitizeConfiguration[]) => {
    const sanCol = [...sanitizeCol];

    const payload = {
      columnToSanitize: columnName,
      sanitizationRules: rulesSaveRules,
    };

    if (sanCol.length > 0) {
      let columnFound = false;
      for (const [i] of sanCol.entries()) {
        if (sanCol[i].columnToSanitize === columnName) {
          sanCol[i].sanitizationRules = rulesSaveRules;
          columnFound = true;
          break;
        }
      }

      if (columnFound === false) {
        sanCol.push(payload);
      }
    } else if (sanCol.length === 0) {
      sanCol.push(payload);
    }
    setSanitizeCol(sanCol);
  };

  const closeSanitizerLogger = () => {
    props.uiLogger(
      props.name === ledgerUploadName.ownSide ? uiLoggerName.ui_OwnSanitizerClose : uiLoggerName.ui_BpSanitizerClose
    );
    uiLogger(
      props.name === ledgerUploadName.ownSide
        ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLOSE_CLICK.functionName
        : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLOSE_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message:
          props.name === ledgerUploadName.ownSide
            ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLOSE_CLICK.message
            : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLOSE_CLICK.message,
        businessPartnerId: businessPartnerSelectedRef.current,
      }
    );
    props.name === ledgerUploadName.ownSide
      ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLOSE_CLICK.functionName)
      : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLOSE_CLICK.functionName);
  };
  const applyClickSanitizerLogger = () => {
    uiLogger(
      props.name === ledgerUploadName.ownSide
        ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_APPLY_CLICK.functionName
        : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_APPLY_CLICK.functionName,
      companyId.current,
      branchCode.current,
      {
        message:
          props.name === ledgerUploadName.ownSide
            ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_APPLY_CLICK.message
            : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_APPLY_CLICK.message,
        businessPartnerId: businessPartnerSelectedRef.current,
      }
    );
    props.name === ledgerUploadName.ownSide
      ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_APPLY_CLICK.functionName)
      : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_APPLY_CLICK.functionName);
  };

  const providerValue: ConfigpoupRecon = {
    saveRules,
    columnName,
    labelName,
    open,
    setOpen,
    rules,
    items,
    setItems,
    rulesWithVal,
    setRulesWithVal,
    isDelete,
    handleRemoveClick,
    closeSanitizerLogger,
    fromWhichSide: props.name,
    applyClickSanitizerLogger,
    groupsListRef: props.groupsListRef,
  };

  const delRow = (element: Element, ind: number, _index: number) => {
    element.value.splice(ind, 1);
    const newData = headerName;
    const user = newData.find((d) => d.name === element.name);
    Object.assign(user, element);
    setHeaderName([...newData]);
    if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
      setSelectedTemplateName(Customized);
      setTemplateName(Customized);
    }
    // if (headerName[index].value.length === 0) {
    //   headerName[index].value = [];
    // }
  };

  const handleChangeReverseSign = (e: any, ele: any) => {
    if (ele?.name === "debitAmount") {
      props.setDebitAmountSignReverseFlag(e.target.checked);
    } else if (ele?.name === "creditAmount") {
      props.setCreditAmountSignReverseFlag(e.target.checked);
    } else if (ele?.name === "taxAmount") {
      props.setTaxAmountSignReverseFlag(e.target.checked);
    }
  };

  const closeConfigModal = async () => {
    if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
      await props.getConfig();
      props.setShowCustomOption(false);
    }
    props.setOpenDialog(false);
    if (props.uiLogger)
      props.uiLogger(
        props.name === ledgerUploadName.ownSide ? uiLoggerName.ui_OwnConfigClose : uiLoggerName.ui_BpConfigClose
      );

    props.name === ledgerUploadName.ownSide
      ? window.clarity("event", uiLoggerNamesRecon.UI_RL_OWN_CONFIG_CLOSE.functionName)
      : window.clarity("event", uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_CLOSE.functionName);
  };

  // called from editing recon config
  const handleChangeSelectTemplate = (event: { target: { value: number | string } }, isRuConfigTemplate = false) => {
    const foundTemplate = props.configList.find((template) => template.templateId === event.target.value);
    setSelectedTemplateName(foundTemplate?.templateName || (event.target.value as string));
    setTemplateName(foundTemplate?.templateName || (event.target.value as string));

    if (event.target.value === Customized) {
      props.getConfig();
    } else if (event.target.value === "Remove existing template") {
      setSelectedTemplateName(Customized);
      setTemplateName(Customized);
      props.removeExistingTemplate();
      setHeaderName(Object.values(columnHeaderNameLabel).map((name) => ({ name, value: [] })));
      setConfigHeaderName(EMPTY_CONFIG as LedgerConfiguration);
      props.setDebitAmountSignReverseFlag(false);
      props.setCreditAmountSignReverseFlag(false);
      props.setTaxAmountSignReverseFlag(false);
      setSanitizeCol([]);
      props.groupsListRef.current = [];
    } else {
      const selectedTemplateVal = props.configList.find((template) => template.templateId === event.target.value);
      const selectedTemplateId = selectedTemplateVal.templateId;
      setSelectedTemplate(selectedTemplateId);
      if (isRuConfigTemplate) ListConfigTemplate(selectedTemplateId.toString());
      else getConfigTemplate(selectedTemplateId);
    }
    props.name === ledgerUploadName.ownSide
      ? window.clarity("event", "UI_RL_OWN_TEMPLATE_SELECT")
      : window.clarity("event", "UI_RL_PARTNER_TEMPLATE_SELECT");
  };

  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  // };

  const checkColumnToSanitize = (jsonData: any, name: string) => {
    return jsonData?.some((obj: any) => obj.columnToSanitize === name && obj.sanitizationRules?.length > 0);
  };
  useEffect(() => {}, [sanitizeCol]);

  const checkIsDuplicateHeader = (newColumns: string[], allColumns: Element) => {
    let isDuplicate = false;
    isDuplicate = newColumns.some((column) => {
      return allColumns.value.some((value) => value.toLowerCase() === column.toLowerCase());
    });

    isDuplicate =
      isDuplicate ||
      newColumns.some((column, _i) =>
        newColumns.some((col, i) => col.toLowerCase() === column.toLowerCase() && i !== _i)
      );

    return {
      isDuplicate,
      // DuplicateTagName: allColumns.name[0].toUpperCase() + allColumns.name.slice(1),
      DuplicateTagName: allColumns.name,
    };
  };

  useEffect(() => {
    // fetch tally column on ComponentDidMount
    if (props.integration) {
      useFetch<TallyAcceptedConfigColumnsResponse>(API_ENDPOINTS.INTEGRATION_CONFIG_COLUMN_HEADERS.url, "GET", {
        failureMessage: API_ENDPOINTS.INTEGRATION_CONFIG_COLUMN_HEADERS.failureMessage,
        thenCallBack: (response) => {
          if (response.data?.columnHeaders) setTallyHeaderNames(response.data?.columnHeaders);
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event) => {
    return setAnchorEl(event.currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleCloseSaveAsTemplate = () => {
    setOpenSaveAsTemplate(false);
    setSaveAsTemplateSelectedSection("initial");
    setInputValueTemplateName("");
    setInputValueTemplateDescription("");
    setSelectedTemplateForUpdate(null);
    setErrTemplateName(false);
  };

  const handleChangeUpdateTemplate = (e) => {
    setSelectedTemplateForUpdate(e.target.value);
    setInputValueTemplateDescription(e.target.value.templateDescription);
  };

  const insertNewConfig = async (
    configData: LedgerConfiguration,
    _templateName: string,
    _rules: ColumnsToSanitize[]
  ) => {
    Object.keys(configData).map((k) => {
      if (k === "debitAmountSignReverseFlag") {
        configData.debitAmountSignReverseFlag = props.debitAmountSignReverseFlag;
      } else if (k === "creditAmountSignReverseFlag") {
        configData.creditAmountSignReverseFlag = props.creditAmountSignReverseFlag;
      } else if (k === "taxAmountSignReverseFlag") {
        configData.taxAmountSignReverseFlag = props.taxAmountSignReverseFlag;
      }
      return configData;
    });
    if (!Object.prototype.hasOwnProperty.call(configData, "debitAmountSignReverseFlag")) {
      configData.debitAmountSignReverseFlag = props.debitAmountSignReverseFlag;
    }
    if (!Object.prototype.hasOwnProperty.call(configData, "creditAmountSignReverseFlag")) {
      configData.creditAmountSignReverseFlag = props.creditAmountSignReverseFlag;
    }
    if (!Object.prototype.hasOwnProperty.call(configData, "taxAmountSignReverseFlag")) {
      configData.taxAmountSignReverseFlag = props.taxAmountSignReverseFlag;
    }
    if (props.name === ledgerUploadName.ownSide) {
      useFetch<ConfigTemplateRes>(API_ENDPOINTS.ADD_RU_CONFIG_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_RU_CONFIG_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          templateName: _templateName,
          config: configData,
          columnsToSanitize: _rules,
          groups: props.groupsListRef.current,
        },
        thenCallBack: async () => {
          if (getLedgerConfigList) {
            await getLedgerConfigList();
            await ListAllConfigTemplateNamesRu();
          }
          setSelectedTemplateName(_templateName);
          setTemplateName(_templateName);

          const updatedTemplateName = _templateName; // Update this as per your requirement
          setSelectedTemplateName(updatedTemplateName);
          setTemplateName(updatedTemplateName);
          setStartSaveLoader(false);
          handleCloseSaveAsTemplate();
        },
        catchCallBack: () => {
          setStartSaveLoader(false);
        },
      });
    } else if (props.name === ledgerUploadName.bpSide) {
      useFetch<ConfigTemplateRes>(API_ENDPOINTS.CREATE_TEMPLATE_FOR_REUSE_CONFIG.url, "POST", {
        failureMessage: API_ENDPOINTS.CREATE_TEMPLATE_FOR_REUSE_CONFIG.failureMessage,
        showSuccessToast: true,
        data: {
          businessPartnerId: businessPartnerSelectedRef.current,
          companyId: companyId.current,
          branchCode: branchCode.current,
          mappingId: mappingId.current,
          templateName: _templateName,
          config: configData,
          columnsToSanitize: _rules,
          groups: props.groupsListRef.current,
          templateDescription: inputValueTemplateDescription,
          type: props.name === ledgerUploadName.ownSide ? ActorTypesForRecon.ReconUser : ActorTypesForRecon.Partner,
        },
        thenCallBack: async () => {
          if (getLedgerConfigList) {
            await getLedgerConfigList();
          }
          if (listPartnerAllLedgerConfigTemplateForCurrentReconUser) {
            await listPartnerAllLedgerConfigTemplateForCurrentReconUser();
          }
          setSelectedTemplateName(_templateName);
          setTemplateName(_templateName);

          const updatedTemplateName = _templateName; // Update this as per your requirement
          setSelectedTemplateName(updatedTemplateName);
          setTemplateName(updatedTemplateName);
          setStartSaveLoader(false);
          handleCloseSaveAsTemplate();
        },
        catchCallBack: () => {
          setStartSaveLoader(false);
        },
      });
    }
  };

  const updateConfig = async (configData: LedgerConfiguration, _template, _rules: ColumnsToSanitize[]) => {
    Object.keys(configData).map((k) => {
      if (k === "debitAmountSignReverseFlag") {
        configData.debitAmountSignReverseFlag = props.debitAmountSignReverseFlag;
      } else if (k === "creditAmountSignReverseFlag") {
        configData.creditAmountSignReverseFlag = props.creditAmountSignReverseFlag;
      } else if (k === "taxAmountSignReverseFlag") {
        configData.taxAmountSignReverseFlag = props.taxAmountSignReverseFlag;
      }
      return configData;
    });
    if (!Object.prototype.hasOwnProperty.call(configData, "debitAmountSignReverseFlag")) {
      configData.debitAmountSignReverseFlag = props.debitAmountSignReverseFlag;
    }
    if (!Object.prototype.hasOwnProperty.call(configData, "creditAmountSignReverseFlag")) {
      configData.creditAmountSignReverseFlag = props.creditAmountSignReverseFlag;
    }
    if (!Object.prototype.hasOwnProperty.call(configData, "taxAmountSignReverseFlag")) {
      configData.taxAmountSignReverseFlag = props.taxAmountSignReverseFlag;
    }
    if (props.name === ledgerUploadName.ownSide) {
      useFetch(API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.url, "PUT", {
        failureMessage: API_ENDPOINTS.UPDATE_RU_CONFIG_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          templateName: _template.templateName,
          config: configData,
          columnsToSanitize: _rules,
          templateId: _template.templateId,
          groups: props.groupsListRef.current,
        },
        thenCallBack: async () => {
          if (getLedgerConfigList) {
            await getLedgerConfigList();
            await ListAllConfigTemplateNamesRu();
          }

          setSelectedTemplateName(_template.templateName);
          setTemplateName(_template.templateName);

          const updatedTemplateName = _template.templateName; // Update this as per your requirement
          setSelectedTemplateName(updatedTemplateName);
          setTemplateName(updatedTemplateName);
          setStartUpdateLoader(false);
          handleCloseSaveAsTemplate();
        },
        catchCallBack: () => {
          setStartUpdateLoader(false);
        },
      });
    } else if (props.name === ledgerUploadName.bpSide) {
      useFetch(API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CONFIG_TEMPLATE.url, "POST", {
        failureMessage: API_ENDPOINTS.UPDATE_BUSINESS_PARTNER_CONFIG_TEMPLATE.failureMessage,
        showSuccessToast: true,
        data: {
          businessPartnerId: businessPartnerSelectedRef.current,
          companyId: companyId.current,
          branchCode: branchCode.current,
          templateName: _template.templateName,
          config: configData,
          columnsToSanitize: _rules,
          templateId: _template.templateId,
          groups: props.groupsListRef.current,
          templateDescription: inputValueTemplateDescription,
          type: props.name === ledgerUploadName.ownSide ? ActorTypesForRecon.ReconUser : ActorTypesForRecon.Partner,
        },
        thenCallBack: async () => {
          if (getLedgerConfigList) {
            await getLedgerConfigList();
          }
          if (listPartnerAllLedgerConfigTemplateForCurrentReconUser) {
            await listPartnerAllLedgerConfigTemplateForCurrentReconUser();
          }

          setSelectedTemplateName(_template.templateName);
          setTemplateName(_template.templateName);

          const updatedTemplateName = _template.templateName; // Update this as per your requirement
          setSelectedTemplateName(updatedTemplateName);
          setTemplateName(updatedTemplateName);
          setStartUpdateLoader(false);
          handleCloseSaveAsTemplate();
        },
        catchCallBack: () => {
          setStartUpdateLoader(false);
        },
      });
    }
  };
  const getTagBackgroundColor = (tag, ele) => {
    if (configMappedBp || configMappedOwn) {
      const config = props.name === ledgerUploadName.ownSide ? configMappedOwn : configMappedBp;
      if (config && ele.name in config) {
        // Convert the tag to lower case
        const lowerCaseTag = tag.toLowerCase();
        // Convert the config values to lower case and check if the lowerCaseTag is included
        const lowerCaseConfigTags = config[ele.name].map((configTag) => configTag.toLowerCase());
        if (lowerCaseConfigTags.includes(lowerCaseTag)) {
          return "secondary"; // Secondary color for matching tags
        }
      }
    }
    return "default"; // Default color for non-matching tags
  };

  const isEmptyObject = (obj) => {
    return Object.values(obj).every((arr) => Array.isArray(arr) && arr.length === 0);
  };

  return (
    <ConfigPopupAnchorRecon360Context.Provider value={providerValue}>
      <>
        <>
          <header className="modal-card-head">
            <div className="space_between w_100_per">
              <div className="create_config">Create Configuration</div>
              <div className="step_text">Step 1 of 2 - Map Column Headers</div>
              <div>
                <button
                  className="delete"
                  aria-label="close"
                  onClick={() => {
                    closeConfigModal();
                  }}
                />
              </div>
            </div>
          </header>
          <section className="modal-card-body">
            <div className="container">
              <div className="column" style={{ paddingLeft: "0" }}>
                <div className="mb_8">
                  <Typography
                    style={{
                      fontSize: "12px",
                      fontWeight: 600,
                      display: "flex",
                      alignItems: "center",
                    }}
                    className="vertical_center_align mb_8"
                  >
                    <span>*&nbsp;</span> Compulsory columns which need to be mapped to proceed for reconciliation
                  </Typography>
                </div>
                {actor.actorType === ActorTypes.Admin || props.editingTemplate ? (
                  <div className="field">
                    {props.template && (
                      <div className="temp_data_row">
                        {props.newconfig ? (
                          ""
                        ) : (
                          <div className="config_data">
                            <FormControl sx={{ minWidth: "300px" }} className="closingblc_input">
                              <label>
                                <b>Templates</b>
                              </label>
                              <Select
                                name="configfile"
                                id="configfile"
                                size="small"
                                className="mw_250"
                                defaultValue={0}
                              >
                                <MenuItem value={0} selected={true}>
                                  Select Template
                                </MenuItem>
                                {props.configList?.map((option) => (
                                  <MenuItem
                                    key={option.templateId + option.templateName}
                                    value={option.templateId}
                                    onClick={(_evt) => changeTemplate(option.templateId, option?.IsRuConfigTemplate)}
                                  >
                                    {option.templateName}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </div>
                        )}
                        <div className="temp_name">
                          <label>
                            <b>Configuration Name</b>
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type="text"
                              name="templateName"
                              value={templateName || ""}
                              onChange={onChangeTemplateName}
                            />
                            <label id="templateNameError" style={{ color: "red" }} />
                          </div>
                        </div>
                      </div>
                    )}
                    <div style={{ marginTop: "10px" }}>
                      <label style={{ fontSize: "22px" }}>{props.heading}</label>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="vertical_center_align gap_10">
                      <div className="vertical_center_align">
                        <FormControl className="NestedMenuItem update-custom-border-radius">
                          <Select
                            labelId="my-select-label"
                            id="my-select"
                            value={selectedTemplateName}
                            open={openTemplate}
                            onOpen={handleClick}
                            onClose={handleClose}
                            onChange={null}
                            size="small"
                            // className="mw_250"
                            style={{ width: "250px" }}
                          >
                            {props.configList?.map((option) => (
                              <MenuItem
                                key={option.templateId + option.templateName}
                                value={option.templateName}
                                sx={{
                                  display: "none",
                                }}
                              >
                                {option.templateName}
                              </MenuItem>
                            ))}
                            {((props.name === ledgerUploadName.ownSide &&
                              actor.userType === UserTypes.CustomerSuccess) ||
                              props.name === ledgerUploadName.bpSide) && (
                              <NestedMenuItem
                                label={"ERP Templates"}
                                parentMenuOpen={openTemplate}
                                rightIcon={<ArrowRightRounded />}
                              >
                                {(props.configList?.length === 0 ||
                                  props.configList?.every((item) => !item.IsERPConfigTemplate)) && (
                                  <MenuItem disabled style={{ minWidth: "220px" }}>
                                    No Templates
                                  </MenuItem>
                                )}
                                {props.configList
                                  .filter((item) => item.IsERPConfigTemplate) // Filter items with IsERPConfigTemplate true
                                  .map((item) => (
                                    <MenuItem
                                      key={item.templateId + item.templateName}
                                      value={item.templateName}
                                      style={{ minWidth: "220px" }}
                                      onClick={(_evt) => {
                                        handleChangeSelectTemplate(
                                          { target: { value: item.templateId } },
                                          item?.IsRuConfigTemplate
                                        );
                                        handleClose();
                                      }}
                                    >
                                      {item.templateName}
                                    </MenuItem>
                                  ))}
                              </NestedMenuItem>
                            )}
                            <NestedMenuItem
                              label={"Custom Templates"}
                              parentMenuOpen={openTemplate}
                              rightIcon={<ArrowRightRounded />}
                            >
                              {(props.configList?.length === 0 ||
                                props.configList?.every((item) => {
                                  if (props.name === ledgerUploadName.ownSide) {
                                    return !item.IsRuConfigTemplate;
                                  } else if (props.name === ledgerUploadName.bpSide) {
                                    return !item.IsPartnerConfigTemplate;
                                  }
                                  return false;
                                })) && (
                                <MenuItem disabled style={{ minWidth: "220px" }}>
                                  No Templates
                                </MenuItem>
                              )}
                              {props.configList
                                .filter((item) => {
                                  if (props.name === ledgerUploadName.ownSide) {
                                    return item.IsRuConfigTemplate;
                                  } else if (props.name === ledgerUploadName.bpSide) {
                                    return item.IsPartnerConfigTemplate;
                                  }
                                  return true; // Include all items if name doesn't match any specific condition
                                })
                                .map((item) => (
                                  <MenuItem
                                    key={item.templateId + item.templateName}
                                    value={item.templateName}
                                    style={{ minWidth: "220px" }}
                                    onClick={(_evt) => {
                                      handleChangeSelectTemplate(
                                        { target: { value: item.templateId } },
                                        item?.IsRuConfigTemplate
                                      );
                                      handleClose();
                                    }}
                                  >
                                    {item.templateName}
                                  </MenuItem>
                                ))}
                            </NestedMenuItem>

                            {props.showCustomOption && (
                              <MenuItem
                                // onClick={() => setChangedTemplateName('Customized')}
                                onClick={() => handleChangeSelectTemplate({ target: { value: Customized } })}
                                key="Custom-template"
                                value={Customized}
                                // style={{border: selectedTemplateName === Customized ?'1px solid':''}}
                              >
                                {Customized}
                              </MenuItem>
                            )}
                            <MenuItem
                              // onClick={() => setChangedTemplateName('Customized')}
                              onClick={() =>
                                handleChangeSelectTemplate({ target: { value: "Remove existing template" } })
                              }
                              key="Remove-template"
                              value="Remove existing template"
                              style={{ borderTop: "1px solid rgb(216 210 210)", marginTop: "5px" }}
                            >
                              <span
                                style={{
                                  border: "1px solid rgba(211, 47, 47, 0.50)",
                                  color: "#D32F2F",
                                  padding: "10px",
                                  borderRadius: "4px",
                                  marginTop: "10px",
                                }}
                              >
                                CLEAR EXISTING TEMPLATE
                              </span>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div>
                        {props.subTab === RECON360_SUB_TAB.LEDGER &&
                          (actor.actorType as string) !== ActorTypes.Admin && (
                            <>
                              {(props.name === ledgerUploadName.ownSide ||
                                (props.name === ledgerUploadName.bpSide &&
                                  actor.userType === UserTypes.CustomerSuccess)) && (
                                <span
                                  className="d_flex cursor_pointer"
                                  onClick={() => {
                                    setOpenSaveAsTemplate(true);
                                    if (props.name === ledgerUploadName.ownSide) {
                                      uiLogger(
                                        uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_CLICK.functionName,
                                        companyId.current,
                                        branchCode.current,
                                        {
                                          message: uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_CLICK.message,
                                          businessPartnerId: businessPartnerSelectedRef.current,
                                        }
                                      );
                                    } else {
                                      uiLogger(
                                        uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_CLICK.functionName,
                                        companyId.current,
                                        branchCode.current,
                                        {
                                          message:
                                            uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_CLICK.message,
                                          businessPartnerId: businessPartnerSelectedRef.current,
                                        }
                                      );
                                    }
                                  }}
                                >
                                  <span className="vertical_center_align mr_6">
                                    <img src={saveTemplate} alt="" />
                                  </span>
                                  <span className="saveAsTemp">save as template</span>
                                </span>
                              )}
                            </>
                          )}
                      </div>
                    </div>
                    <div className="mt_16 mb_8">
                      <Typography style={{ fontSize: "12px" }} className="vertical_center_align">
                        {" "}
                        <span
                          style={{
                            display: "inline-block",
                            width: "16px",
                            height: "16px",
                            backgroundColor: "#9C27B0", // or any color you want
                            marginLeft: "4px", // optional, adds some space between text and rectangle
                            marginRight: "8px",
                            borderRadius: "2px",
                          }}
                        ></span>
                        Column Headers which are being used for the file(s)
                      </Typography>
                    </div>
                  </>
                )}
              </div>
              <div className="columns is-mobile">
                <div className="column ">
                  {headerName &&
                    headerName.map((element: Element, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <Grid>
                            {(element.name === columnHeaderNameLabel.documentDate ||
                              element.name === columnHeaderNameLabel.debitAmount ||
                              element.name === columnHeaderNameLabel.referenceNumber ||
                              element.name === columnHeaderNameLabel.miscellaneous) && (
                              <Grid className="theme_bg_col_header">
                                <h3 className="fw_900">{groupLabels[index]}</h3>
                              </Grid>
                            )}
                            <Grid className="config_box_card">
                              <Grid className="space_between">
                                <h3 className="fw_900 center_align_ver_horiz">
                                  {labels[index]} {compulsoryColumnHeader.includes(element.name) && " *"}
                                </h3>
                                {(element?.name === "debitAmount" ||
                                  element?.name === "creditAmount" ||
                                  element?.name === "taxAmount") && (
                                  <div className="center_align_ver_horiz ">
                                    <span>
                                      <Checkbox
                                        size="small"
                                        checked={
                                          element?.name === "debitAmount"
                                            ? props.debitAmountSignReverseFlag
                                            : element?.name === "creditAmount"
                                            ? props.creditAmountSignReverseFlag
                                            : element?.name === "taxAmount"
                                            ? props.taxAmountSignReverseFlag
                                            : null
                                        }
                                        onChange={(e) => {
                                          handleChangeReverseSign(e, element);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        sx={{
                                          color: "black",
                                        }}
                                      />
                                    </span>
                                    <span
                                      className="fw_400"
                                      style={{ color: "rgba(0, 0, 0, 0.87)", fontFamily: "Roboto" }}
                                    >
                                      Reverse Sign
                                    </span>
                                  </div>
                                )}
                              </Grid>
                              <Grid className="desc_name">{columnHeaderDescription[element.name]}</Grid>
                              <Grid container spacing={2}>
                                <Grid item xs={10.7}>
                                  <Autocomplete
                                    disablePortal
                                    className="update-custom-border-radius"
                                    size="small"
                                    multiple
                                    id="tags-outlined"
                                    // options={element.value.length > 0 && element.value[0] !== "" ? element.value : []}
                                    options={
                                      props.integration && tallyHeaderNames?.length
                                        ? tallyHeaderNames
                                        : element.value.length > 0 && element.value[0] !== ""
                                        ? element.value
                                        : []
                                    }
                                    getOptionLabel={(option) => option}
                                    value={
                                      element.value ? [...element.value.filter((value) => value.trim() !== "")] : []
                                    }
                                    // defaultValue={[top100Films[13]]}
                                    clearIcon={null} // Set clearIcon prop to null to hide the clear icon
                                    filterSelectedOptions
                                    onChange={(_event, value, reason, itemInd) => {
                                      if (reason === "removeOption") {
                                        const removedTagIndex = element.value.indexOf(itemInd.option);
                                        // The tag was removed by the user clicking the cross button
                                        // Call delRow function here with the deleted tag value
                                        // Assuming the delRow function takes the deleted tag value as an argument
                                        delRow(element, removedTagIndex, index);
                                      } else if (reason === "selectOption" && !enterPressedRef.current) {
                                        if (value.length > 0) {
                                          if (value[value.length - 1].includes("Add a new column name => ")) {
                                            value[value.length - 1] = value[value.length - 1].replace(
                                              'Add a new column name => "',
                                              ""
                                            );
                                            value[value.length - 1] = value[value.length - 1].replace('"', "");
                                          }
                                        }
                                        if (
                                          value[value.length - 1].length > 0 &&
                                          value[value.length - 1].trim().length > 0
                                        ) {
                                          const newValue = value.slice(-1);
                                          const { isDuplicate, DuplicateTagName } = checkIsDuplicateHeader(
                                            newValue,
                                            headerName[index]
                                          );
                                          if (isDuplicate) {
                                            setDuplicateTagName(DuplicateTagName);
                                          } else {
                                            if (newValue.length > 0) {
                                              headerName[index].value = headerName[index].value.concat(newValue);
                                              if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
                                                setSelectedTemplateName(Customized);
                                                setTemplateName(Customized);
                                              }
                                            }
                                            if (headerName[index].value.length > 1) {
                                              const removeEmptyVal = headerName[index].value.filter((el) => {
                                                return el !== "";
                                              });
                                              headerName[index].value = removeEmptyVal;
                                            }
                                            setOpenAddDialog(false);
                                            setSaveValueColumnHeader([]);
                                          }
                                        }
                                      }
                                    }}
                                    filterOptions={(_optionsFilter, params) => {
                                      // const filtered = filter(options, params);
                                      const filtered =
                                        props.integration && tallyHeaderNames?.length
                                          ? tallyHeaderNames
                                          : filter(options, params);
                                      // Suggest the creation of a new value
                                      if (
                                        params.inputValue !== "" &&
                                        (!props.integration || tallyHeaderNames?.length === 0)
                                      ) {
                                        filtered.push(`Add a new column name => "${params.inputValue.trim()}"`);
                                      }
                                      return filtered;
                                    }}
                                    renderTags={(tagValue, getTagProps) =>
                                      tagValue.map((option, index) => (
                                        <Chip
                                          key={index}
                                          size="medium"
                                          label={option}
                                          {...getTagProps({ index })}
                                          color={getTagBackgroundColor(option, element)}
                                        />
                                      ))
                                    }
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        placeholder="Enter new column name"
                                        //  style={{ border: duplicateTagName === element.name ? '1px solid red' : '' }}
                                        error={duplicateTagName === element.name ? true : false}
                                        onKeyDown={(e) => {
                                          if (
                                            e.key === "Enter" &&
                                            (!props.integration || tallyHeaderNames?.length === 0)
                                          ) {
                                            enterPressedRef.current = true;
                                            e.preventDefault();
                                            // Extract the new value from the input
                                            const newValue: any[] = [params.inputProps.value];
                                            if (
                                              newValue.length > 0 &&
                                              newValue[newValue.length - 1].trim().length > 0
                                            ) {
                                              const { isDuplicate, DuplicateTagName } = checkIsDuplicateHeader(
                                                newValue,
                                                headerName[index]
                                              );

                                              if (isDuplicate) {
                                                setDuplicateTagName(DuplicateTagName);
                                              } else {
                                                if (newValue.length > 0) {
                                                  headerName[index].value = headerName[index].value.concat(newValue);
                                                  if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
                                                    setSelectedTemplateName(Customized);
                                                    setTemplateName(Customized);
                                                  }
                                                }
                                                if (headerName[index].value.length > 1) {
                                                  const removeEmptyVal = headerName[index].value.filter((el) => {
                                                    return el !== "";
                                                  });
                                                  headerName[index].value = removeEmptyVal;
                                                }
                                                setOpenAddDialog(false);
                                                setSaveValueColumnHeader([]);
                                              }
                                            }
                                          } else {
                                            enterPressedRef.current = false;
                                            setDuplicateTagName("");
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                  {duplicateTagName === element.name ? (
                                    <div className="error_text_config">
                                      Duplicate tags exists, please remove duplicate to continue
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </Grid>
                                <Grid item xs={1.3}>
                                  {props.showSettingsIcon && showSanitizerOption.includes(element.name) && (
                                    <Button
                                      className="sanitizer_btn sanitizerButton" // sanitizerButton class with ID docId to open sanitizer dialog
                                      id={index === 0 ? "docId" : ""}
                                      onClick={() => {
                                        clarity.setTag(
                                          `${props.name === ledgerUploadName.ownSide ? "Own" : "BP"} Sanitizer`,
                                          `Open`
                                        );
                                        setOpen(true);
                                        setColumnName(element.name);
                                        setLabelName(element.name);
                                        props.uiLogger(
                                          props.name === ledgerUploadName.ownSide
                                            ? uiLoggerName.ui_OwnSanitizerOpen
                                            : uiLoggerName.ui_BpSanitizerOpen
                                        );
                                        uiLogger(
                                          props.name === ledgerUploadName.ownSide
                                            ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLICK.functionName
                                            : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLICK.functionName,
                                          companyId.current,
                                          branchCode.current,
                                          {
                                            message:
                                              props.name === ledgerUploadName.ownSide
                                                ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLICK.message
                                                : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLICK.message,
                                            businessPartnerId: businessPartnerSelectedRef.current,
                                          }
                                        );
                                        props.name === ledgerUploadName.ownSide
                                          ? window.clarity(
                                              "event",
                                              uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SANITIZER_CLICK.functionName
                                            )
                                          : window.clarity(
                                              "event",
                                              uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SANITIZER_CLICK.functionName
                                            );
                                      }}
                                      style={{
                                        color: checkColumnToSanitize(sanitizeCol, element?.name) ? "#FFF" : "#2E7D32",
                                        borderColor: checkColumnToSanitize(sanitizeCol, element?.name)
                                          ? ""
                                          : "rgba(46, 125, 50, 0.50)",
                                        background: checkColumnToSanitize(sanitizeCol, element?.name) ? "#2E7D32" : "",
                                      }}
                                    >
                                      {" "}
                                      Sanitizer{" "}
                                    </Button>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            {columnName === element.name && <SanitizerRules />}
                          </Grid>
                        </React.Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="mt_30">
              <p className="note_config">
                {" "}
                Note : “The column mappings are done left to right, top to bottom on the inputs given”.
              </p>
            </div>
          </section>
          <footer className="modal-card-foot flex_end">
            <div className="d_flex" style={{ width: "100%" }}>
              {actor.actorType !== ActorTypes.Admin && !props.editingTemplate ? (
                <Button
                  className="tds_btn"
                  onClick={() => {
                    setOpenBusinessPopup(true);
                    props.name === ledgerUploadName.ownSide
                      ? window.clarity(
                          "event",
                          uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_CLICK.functionName
                        )
                      : window.clarity(
                          "event",
                          uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_CLICK.functionName
                        );
                    uiLogger(
                      props.name === ledgerUploadName.ownSide
                        ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_CLICK.functionName
                        : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_CLICK.functionName,
                      companyId.current,
                      branchCode.current,
                      {
                        message:
                          props.name === ledgerUploadName.ownSide
                            ? uiLoggerNamesRecon.UI_RL_OWN_CONFIG_TDS_TCS_APPLICABLE_CLICK.message
                            : uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_TDS_TCS_APPLICABLE_CLICK.message,
                        businessPartnerId: businessPartnerSelectedRef.current,
                      }
                    );
                  }}
                  style={{ marginRight: "auto" }}
                >
                  TDS/TCS Applicable
                </Button>
              ) : (
                <div style={{ marginRight: "auto" }} />
              )}
              <Button
                className="green_btn"
                style={{ width: "143px" }}
                onClick={() => {
                  const allConfigListToCheck = props.newconfig
                    ? props.configList.filter((config) => config.templateName !== editedTemplateName.current)
                    : props.configList;
                  const hasRuConfig =
                    props.name === ledgerUploadName.reconHome
                      ? allConfigListToCheck.some(
                          (config) => config.IsRuConfigTemplate && config.templateName === templateName
                        )
                      : allConfigListToCheck.some((config) => config.templateName === templateName);

                  if (
                    hasRuConfig &&
                    props.name !== ledgerUploadName.ownSide &&
                    props.name !== ledgerUploadName.bpSide
                  ) {
                    document.getElementById("templateNameError").innerHTML = "Template with name exists";
                  } else {
                    onNextClickError("Next");
                  }
                }}
              >
                Next
              </Button>
            </div>
          </footer>
          <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(true)}>
            <header className="modal-card-head">
              <p className="modal-card-title">Select column name</p>
              <button className="delete" aria-label="close" onClick={() => setOpenAddDialog(false)} />
            </header>
            <section className="modal-card-body">
              {duplicateTagName ? (
                <div className="mb_15 error_text_config">Some of the tags are already mapped in {duplicateTagName}</div>
              ) : (
                ""
              )}
              <Grid>
                <div className="mb_15">
                  <p className="vertical_center_align">
                    <span className="mr_10">
                      <InfoIcon color="primary" />
                    </span>
                    {/* {columnHeaderDescription[addElementIndex]} */}
                  </p>
                </div>
              </Grid>
              <Grid>
                {props.integration && tallyHeaderNames?.length ? (
                  <>
                    <FormControl fullWidth={true}>
                      <InputLabel id="select-header-label">Select Column Header</InputLabel>
                      <Select
                        labelId="select-header-label"
                        id="select-header"
                        value={saveValueColumnHeader}
                        label="Select Column Header"
                        multiple={false}
                        onChange={(e) => {
                          setSaveValueColumnHeader([e.target.value as string]);
                        }}
                        sx={{
                          width: "100%",
                          "& span": {
                            fontSize: "inherit",
                          },
                        }}
                      >
                        {tallyHeaderNames.map((header) => (
                          <MenuItem key={header} value={header}>
                            {header}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <Autocomplete
                    onChange={(_, value) => {
                      if (value.length > 0) {
                        if (value[value.length - 1].includes("Add a new column name => ")) {
                          value[value.length - 1] = value[value.length - 1].replace('Add a new column name => "', "");
                          value[value.length - 1] = value[value.length - 1].replace('"', "");
                          const removeEmptyVal = value.filter((el) => {
                            return el !== "";
                          });
                          value = removeEmptyVal;
                        }
                      }
                      setSaveValueColumnHeader(value);
                    }}
                    noOptionsText={"Add a new column name"}
                    multiple={true}
                    id="tags-outlined"
                    options={options}
                    getOptionLabel={(option) => option}
                    // defaultValue={[options[13]]}
                    filterSelectedOptions={true}
                    filterOptions={(_optionsFilter, params) => {
                      const filtered = filter(options, params);
                      // Suggest the creation of a new value
                      if (params.inputValue !== "") {
                        filtered.push(`Add a new column name => "${params.inputValue.trim()}"`);
                      }
                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter a new column name"
                        placeholder="Enter a new column name"
                        autoFocus={true}
                      />
                    )}
                    sx={{
                      "& span": {
                        fontSize: "inherit",
                      },
                    }}
                  />
                )}
              </Grid>
            </section>
            <footer className="modal-card-foot right_align">
              <div>
                <Button
                  className="button is-success"
                  onClick={() => {
                    const { isDuplicate, DuplicateTagName } = checkIsDuplicateHeader(
                      saveValueColumnHeader,
                      headerName[addElementIndex]
                    );

                    if (isDuplicate) {
                      setDuplicateTagName(DuplicateTagName);
                    } else {
                      if (saveValueColumnHeader.length > 0) {
                        headerName[addElementIndex].value =
                          headerName[addElementIndex].value.concat(saveValueColumnHeader);
                        if (actor.actorType !== ActorTypes.Admin && !props.editingTemplate) {
                          setSelectedTemplateName(Customized);
                          setTemplateName(Customized);
                        }
                      }
                      if (headerName[addElementIndex].value.length > 1) {
                        const removeEmptyVal = headerName[addElementIndex].value.filter((el) => {
                          return el !== "";
                        });
                        headerName[addElementIndex].value = removeEmptyVal;
                      }
                      setOpenAddDialog(false);
                      setSaveValueColumnHeader([]);
                      setAddElementIndex(null);
                    }
                  }}
                >
                  Save
                </Button>
              </div>
            </footer>
          </Dialog>
        </>
        {openBusinessPopup && (
          <EditBusinessPartner
            openBusinessPopup={openBusinessPopup}
            setOpenBusinessPopup={setOpenBusinessPopup}
            businessPartnerId={props.businessPartnerId || null}
            companyId={props.companyId}
            branchCode={props.branchCode}
            consumerComponent="Recon360"
            consumerComponentWhichSide={props.name}
          />
        )}
      </>
      {openSaveAsTemplate && (
        <TemplateDialog
          open={openSaveAsTemplate}
          disableEnforceFocus={true}
          maxWidth={false}
          fullWidth={true}
          PaperProps={{
            style: { width: 400, margin: "auto" },
          }}
        >
          <DialogTitle className="dialog_header dialog_header_padding">
            <p className="fs_24">
              {saveAsTemplateSelectedSection === saveAsNewTemplate.UPDATE_EXISTING
                ? "Update existing template"
                : saveAsTemplateSelectedSection === saveAsNewTemplate.SAVE_AS_NEW
                ? "Save as new template"
                : "Save as Template"}
            </p>
            <button className="delete ml_20" aria-label="close" onClick={() => handleCloseSaveAsTemplate()} />
          </DialogTitle>
          <DialogContent>
            <div className="mt_25 mb_10 w_100_per">
              {saveAsTemplateSelectedSection === saveAsNewTemplate.UPDATE_EXISTING ? (
                <div className="mt_20 w_100_per">
                  <FormControl className="w_100_per update-custom-border-radius" style={{ borderRadius: "4px" }}>
                    <InputLabel id="demo-simple-select-label">Select Template</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTemplateForUpdate}
                      onChange={handleChangeUpdateTemplate}
                      label="Select Template"
                    >
                      {(props.name === ledgerUploadName.ownSide
                        ? props.configList.filter((item) => item.IsRuConfigTemplate)
                        : props.name === ledgerUploadName.bpSide
                        ? partnerConfigTemplatesOfReconUser // No need for filtering since all are for bpSide
                        : []
                      )?.map((option: any) => (
                        <MenuItem key={option.templateId} value={option}>
                          {option.templateName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {props.name === ledgerUploadName.bpSide && (
                    <TextField
                      className="update-custom-border-radius mt_15"
                      variant="outlined"
                      fullWidth={true}
                      label="Template Description"
                      name="templateDescription"
                      value={inputValueTemplateDescription}
                      onChange={(e) => {
                        setInputValueTemplateDescription(e.target.value);
                      }}
                    />
                  )}
                </div>
              ) : saveAsTemplateSelectedSection === saveAsNewTemplate.SAVE_AS_NEW ? (
                <div>
                  <TextField
                    className="update-custom-border-radius"
                    autoFocus
                    variant="outlined"
                    fullWidth={true}
                    label="Template Name"
                    name="templateName"
                    error={errTemplateName}
                    helperText={errTemplateName ? errTemplateNameMsg : ""}
                    value={inputValueTemplateName}
                    onChange={(e) => {
                      if (e.target.value.length > 16) {
                        setErrTemplateNameMsg("You can't use more than 16 characters");
                        setErrTemplateName(true);
                      } else {
                        setErrTemplateName(false);
                        setInputValueTemplateName(e.target.value);
                      }
                    }}
                    InputLabelProps={{
                      style: {
                        color: "#541C4C", // Set label color to red
                      },
                    }}
                    sx={{
                      borderRadius: "0px",
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#541C4C", // Set border color to red
                      },
                    }}
                  />
                  {props.name === ledgerUploadName.bpSide && (
                    <TextField
                      className="update-custom-border-radius mt_15"
                      variant="outlined"
                      fullWidth={true}
                      label="Template Description"
                      name="templateDescription"
                      value={inputValueTemplateDescription}
                      onChange={(e) => {
                        setInputValueTemplateDescription(e.target.value);
                      }}
                      InputLabelProps={{
                        style: {
                          color: "#541C4C", // Set label color to red
                        },
                      }}
                      sx={{
                        borderRadius: "0px",
                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#541C4C", // Set border color to red
                        },
                      }}
                    />
                  )}
                </div>
              ) : (
                <p>Do you want to save this as a new template or update an existing one?</p>
              )}
            </div>
          </DialogContent>
          <DialogActions className="dialog_footer dialog_footer_padding space_around">
            {saveAsTemplateSelectedSection === saveAsNewTemplate.UPDATE_EXISTING ? (
              <Button
                className={
                  props.name === ledgerUploadName.bpSide
                    ? inputValueTemplateDescription?.trim().length > 0 &&
                      selectedTemplateForUpdate?.templateName?.length > 0
                      ? "save_enable"
                      : "save_disable"
                    : selectedTemplateForUpdate?.templateName?.length > 0
                    ? "save_enable"
                    : "save_disable"
                }
                onClick={() => {
                  setStartUpdateLoader(true);
                  onNextClickError(saveAsNewTemplate.UPDATE_EXISTING);
                  uiLogger(
                    uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_UPDATE_EXISTING_CLICK.functionName,
                    companyId.current,
                    branchCode.current,
                    {
                      message: uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_UPDATE_EXISTING_CLICK.message,
                      businessPartnerId: businessPartnerSelectedRef.current,
                    }
                  );
                }}
                startIcon={<LoadingIcon loading={startUpdateLoader} />}
                disabled={
                  props.name === ledgerUploadName.bpSide
                    ? startUpdateLoader ||
                      selectedTemplateForUpdate === null ||
                      inputValueTemplateDescription?.trim().length === 0
                    : startUpdateLoader || selectedTemplateForUpdate === null
                }
              >
                update
              </Button>
            ) : saveAsTemplateSelectedSection === saveAsNewTemplate.SAVE_AS_NEW ? (
              <Button
                className={
                  props.name === ledgerUploadName.bpSide
                    ? inputValueTemplateName?.trim().length > 0 && inputValueTemplateDescription?.trim().length > 0
                      ? "save_enable"
                      : "save_disable"
                    : inputValueTemplateName?.trim().length > 0
                    ? "save_enable"
                    : "save_disable"
                }
                onClick={() => {
                  const existingTemplate =
                    props.name === ledgerUploadName.ownSide
                      ? props.configList?.find(
                          (template) =>
                            template.templateName === inputValueTemplateName && template.IsRuConfigTemplate === true
                        )
                      : null;

                  if (existingTemplate) {
                    // Template with the same name and IsRuConfigTemplate true already exists
                    setErrTemplateNameMsg("Template with name exists");
                    setErrTemplateName(true);
                  } else {
                    // No existing template with the same name and IsRuConfigTemplate true
                    setErrTemplateNameMsg("");
                    setErrTemplateName(false);
                    setStartSaveLoader(true);
                    onNextClickError(saveAsNewTemplate.SAVE_AS_NEW);
                    if (props.name === ledgerUploadName.ownSide) {
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_OWN_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    } else {
                      uiLogger(
                        uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK.functionName,
                        companyId.current,
                        branchCode.current,
                        {
                          message: uiLoggerNamesRecon.UI_RL_PARTNER_CONFIG_SAVE_AS_TEMPLATE_SAVE_NEW_CLICK.message,
                          businessPartnerId: businessPartnerSelectedRef.current,
                        }
                      );
                    }
                  }
                }}
                startIcon={<LoadingIcon loading={startSaveLoader} />}
                disabled={
                  props.name === ledgerUploadName.bpSide
                    ? inputValueTemplateName?.trim().length === 0 ||
                      inputValueTemplateDescription?.trim().length === 0 ||
                      startSaveLoader
                    : inputValueTemplateName?.trim().length === 0 || startSaveLoader
                }
              >
                save
              </Button>
            ) : (
              <>
                <Button
                  className="saveAsNew"
                  onClick={() => {
                    setSaveAsTemplateSelectedSection(saveAsNewTemplate.SAVE_AS_NEW);
                    // handleCloseSaveTemplate();
                  }}
                  // disabled={}
                >
                  save as new
                </Button>
                {(props.name === ledgerUploadName.ownSide
                  ? props.configList.filter((item) => item.IsRuConfigTemplate)?.length > 0
                  : partnerConfigTemplatesOfReconUser?.length > 0) && (
                  <Button
                    className="updateExisting"
                    onClick={() => {
                      setSaveAsTemplateSelectedSection(saveAsNewTemplate.UPDATE_EXISTING);
                      // handleCloseSaveTemplate();
                    }}
                  >
                    Update Existing
                  </Button>
                )}
              </>
            )}
          </DialogActions>
        </TemplateDialog>
      )}
    </ConfigPopupAnchorRecon360Context.Provider>
  );
};

export default ColumnHeaderConfig;
