import { DateRange, OpenInNew, People, Send } from "@mui/icons-material";
import { Box, BoxProps, Button, LinearProgress, Popover, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import $ from "src/Components/Recon360/PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import FormatDate from "src/Utils/DateUtils";
import { StopAutoReminder } from "../../CommonDialogs";
import { AutoReminder } from "../../PartnerCommsInterfaces";
import { DateCalendarServerDay } from "../CommonComponents";

const SingleAutoReminder = ({
  autoReminder,
  listAction,
  companyId,
  branchCode,
}: {
  autoReminder: AutoReminder;
  listAction: () => Promise<void>;
  companyId: string;
  branchCode: string;
}) => {
  const startDate = FormatDate(autoReminder.reminderDates[0]).toString();
  const endDate = FormatDate(autoReminder.reminderDates[autoReminder.reminderDates.length - 1]).toString();
  const nextReminderDate = FormatDate(autoReminder.reminderDates[autoReminder.reminderSent]).toString();
  const { reminderSent, totalReminderCount, nextReminderPartners, communicationPartners } = autoReminder;
  const remindersLeft = autoReminder.totalReminderCount - autoReminder.reminderSent;

  const [openStopReminder, setOpenStopReminder] = useState(false);

  const stopAutoReminder = async () => {
    return useFetch(API_ENDPOINTS.STOP_AUTO_REMINDER.url, "POST", {
      failureMessage: API_ENDPOINTS.STOP_AUTO_REMINDER.failureMessage,
      data: {
        companyId: companyId,
        branchCode: branchCode,
        activeReminderId: autoReminder.id,
      },
      thenCallBack: async (_res) => {
        // console.log(_res);
        await listAction();
      },
    });
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleViewDatesBtnClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openPopover = Boolean(anchorEl);

  return (
    <>
      <Stack sx={{ border: "1px solid #0002", borderRadius: 2 }}>
        {/* Top Section */}
        <Box className="space_between" alignItems="center" p={2}>
          <Box>
            <Typography variant="subtitle1">{autoReminder?.communicationTypeName}</Typography>
            <Typography variant="caption" sx={{ color: "#00000099" }}>
              Started on {startDate} — Ending on {endDate}
            </Typography>
          </Box>

          <Button size="small" variant="outlined" sx={{ borderRadius: 100, cursor: "default" }}>
            Next Reminder: {nextReminderDate}
          </Button>
        </Box>

        {/* Mid Section */}
        <Box className="d_flex" borderTop={"1px solid  rgba(0, 0, 0, 0.12)"}>
          {/* Left Stack */}
          <Stack width={"50%"} gap={1} p={2}>
            {/* Top Sent Count */}
            <LeftRightItemsBox>
              <LeftIconWithText
                text={`Auto-reminders sent: ${reminderSent} out of ${totalReminderCount} total`}
                icon={<Send color="action" />}
              />
              <Button startIcon={<DateRange />} onClick={handleViewDatesBtnClick}>
                VIEW
              </Button>
              <Popover
                open={openPopover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <DateCalendarServerDay allHighlightedDays={autoReminder.reminderDates} />
              </Popover>
            </LeftRightItemsBox>
            {/* Bottom Progress Bar */}
            <LeftRightItemsBox gap={2}>
              <LinearProgress
                variant="determinate"
                value={(reminderSent / totalReminderCount) * 100}
                sx={{
                  width: "100%",
                }}
              />
              <Typography flex={1} variant="body2" sx={{ textWrap: "nowrap" }}>
                {remindersLeft} reminders left
              </Typography>
            </LeftRightItemsBox>
          </Stack>

          {/* Right Stack */}
          <Stack width={"50%"} gap={1} p={2} borderLeft={"1px solid  rgba(0, 0, 0, 0.12)"}>
            <LeftRightItemsBox>
              <LeftIconWithText text={"Partners for next reminder"} icon={<Send color="action" />} />
              <Button endIcon={<OpenInNew fontSize="small" />}>{nextReminderPartners.length} PARTNERS</Button>
            </LeftRightItemsBox>
            <LeftRightItemsBox>
              <LeftIconWithText text={"Partners in this communication"} icon={<People color="action" />} />
              <Button endIcon={<OpenInNew fontSize="small" />}>{communicationPartners.length} PARTNERS</Button>
            </LeftRightItemsBox>
          </Stack>
        </Box>

        {/* Bottom Section */}
        <Box className="space_between" alignItems={"center"} borderTop={"1px solid  rgba(0, 0, 0, 0.12)"} p={2}>
          {/* Left Stack */}
          <Stack width={"50%"}>
            <Typography variant="caption">Reminder template</Typography>
            <Typography variant="subtitle2">{autoReminder.name || autoReminder.autoReminderName}</Typography>
          </Stack>
          <Button
            className={$.BR_fix}
            size="small"
            variant="outlined"
            color="error"
            onClick={() => setOpenStopReminder(true)}
          >
            STOP AUTO REMINDER
          </Button>
        </Box>
      </Stack>
      <StopAutoReminder open={openStopReminder} setOpen={setOpenStopReminder} stopAction={stopAutoReminder} />
    </>
  );
};

export default SingleAutoReminder;

const LeftRightItemsBox = (props: BoxProps) => {
  return (
    <Box className="space_between" alignItems="center" style={{ height: 36 }} {...props}>
      {props.children}
    </Box>
  );
};

const LeftIconWithText = ({ text, icon }) => {
  return (
    <Box className="d_flex" gap={1}>
      {icon}
      <Typography variant="subtitle2">{text}</Typography>
    </Box>
  );
};
