import CachedIcon from "@mui/icons-material/Cached";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  Box,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";

export default function LogicFieldSection({
  excelFunctions,
  allColumns,
  formula,
  setFormula,
  taskIdOptions,
  fieldType,
}) {
  const uniqueCategories: string[] = ["All", ...new Set(allColumns.map((column) => column.category) as string)];
  const [excelFunctionOptions, setExcelFunctionOptions] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [columnSearchTerm, setColumnSearchTerm] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("All");
  const [filteredColumns, setFilteredColumns] = useState(allColumns);
  const [selectedTaskId, setSelectedTaskId] = useState<number | null>(null);
  const [previewValue, setPreviewValue] = useState(null);
  const [errorInPreview, setErrorInPreview] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);

  const getCustomColumnPreview = (taskId: number) => {
    if (taskId === null) {
      return;
    }
    setIsLoadingPreview(true);
    useFetch("/api/Workflow/Customisation/Field/Logical/Preview", "POST", {
      failureMessage: "Failed to get custom column preview",
      showSuccessToast: true,
      data: {
        type: fieldType,
        taskId,
        formula: formula,
      },
      thenCallBack: (response) => {
        setPreviewValue(response.data.value);
        setErrorInPreview(response.data.isError ? true : false);
        setIsLoadingPreview(false);
      },
      catchCallBack: () => {
        setIsLoadingPreview(false);
      },
    });
  };

  useEffect(() => {
    setExcelFunctionOptions(excelFunctions);
  }, [excelFunctions]);

  // Filter columns based on selected category and search term
  useEffect(() => {
    let result = allColumns;

    // Filter by category if not "All"
    if (selectedCategory !== "All") {
      result = result.filter((column) => column.category === selectedCategory);
    }

    // Then filter by search term if present
    if (columnSearchTerm) {
      result = result.filter((column) => column.name.toLowerCase().includes(columnSearchTerm.toLowerCase()));
    }

    setFilteredColumns(result);
  }, [selectedCategory, columnSearchTerm, allColumns]);

  const handleCopy = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success(<CustomToast message="Copied to clipboard" />);
      })
      .catch(() => {
        toast.error(<CustomToast message="Failed to copy" />);
      });
  };

  const handleColumnSearchChange = (event) => {
    setColumnSearchTerm(event.target.value);
  };

  const handleColumnSearchKeyDown = (event) => {
    if (event.key === "s" || event.key === "S") {
      event.stopPropagation();
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          gap: 2,
        }}
      >
        <Box sx={{ width: "60%" }}>
          <TextField
            value={formula}
            onChange={(event) => setFormula(event.target.value)}
            fullWidth
            sx={{
              "& fieldset": {
                borderRadius: "4px",
              },
            }}
            placeholder="Enter Logic/Expression"
            multiline
            rows={7}
          />
        </Box>
        <Box
          sx={{
            background: "#EEE",
            width: "40%",
            padding: "12px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              variant="body2"
              textTransform="uppercase"
              sx={{
                background: "#616161",
                color: "#FFF",
                padding: "4px",
                borderRadius: "4px",
              }}
            >
              Output Preview
            </Typography>
            <Box sx={{ marginY: 1, maxHeight: "90px", overflow: "auto" }}>
              <Typography variant="body2" color={errorInPreview ? "error" : "primary"}>
                {previewValue}
              </Typography>
            </Box>
          </Box>
          <Box flexDirection="row" display="flex" gap={1} alignItems="center">
            <Autocomplete
              value={selectedTaskId}
              size="small"
              onChange={(_e, newValue) => {
                if (newValue === null) {
                  return;
                }
                setSelectedTaskId(newValue);
                getCustomColumnPreview(newValue);
              }}
              options={taskIdOptions}
              getOptionLabel={(option) => option.toString()}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& fieldset": {
                      borderRadius: "4px",
                    },
                    width: "150px",
                  }}
                  label="Task ID"
                  variant="outlined"
                />
              )}
            />
            <IconButton onClick={() => getCustomColumnPreview(selectedTaskId)} disabled={isLoadingPreview}>
              {isLoadingPreview ? <LoadingIcon loading={true} /> : <CachedIcon />}
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Stack direction={"row"} gap={2}>
        <Box sx={{ width: "60%", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px" }}>
          <Stack gap={2} padding={2}>
            <Typography variant="subtitle2">Supported Columns & Values</Typography>
            <Box display={"flex"} flexDirection={"row"} gap={1}>
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select
                  size="small"
                  sx={{ borderRadius: " 4px !important" }}
                  label="Category"
                  value={selectedCategory}
                  onChange={(event) => {
                    setSelectedCategory(event.target.value);
                  }}
                >
                  {uniqueCategories.map((category) => (
                    <MenuItem value={category} key={category}>
                      {category}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                size="small"
                fullWidth
                label="Search Column/Value"
                placeholder="Search Column/Value"
                value={columnSearchTerm}
                onChange={handleColumnSearchChange}
                onKeyDown={handleColumnSearchKeyDown}
                InputProps={{
                  sx: { borderRadius: "4px !important" },
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Stack>
          <Divider />
          <Box
            sx={{
              height: "150px",
              overflow: "auto",
            }}
          >
            {filteredColumns.map((column) => (
              <Box
                key={column.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: 2,
                }}
              >
                <Typography variant="body2">{column.name}</Typography>
                <IconButton onClick={() => handleCopy(`{${column.name}}`)}>
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
        <Box sx={{ width: "40%", border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "4px" }}>
          <Stack gap={2} padding={2}>
            <Typography variant="subtitle2">Supported Excel functions</Typography>
            <TextField
              size="small"
              fullWidth
              label="Search functions"
              placeholder="Search functions"
              value={searchTerm}
              onChange={(event) => {
                const newSearchTerm = event.target.value;
                setSearchTerm(newSearchTerm);
                const filteredExcelFunctions = excelFunctions.filter((option) =>
                  option.toLowerCase().includes(newSearchTerm.toLowerCase())
                );
                setExcelFunctionOptions(filteredExcelFunctions);
              }}
              onKeyDown={(event) => {
                if (event.key === "s" || event.key === "S") {
                  event.stopPropagation();
                }
              }}
              InputProps={{
                sx: { borderRadius: "4px !important" },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Divider />
          <Box
            sx={{
              maxHeight: "150px",
              overflow: "auto",
            }}
          >
            {excelFunctionOptions.map((excelFunction) => (
              <Box
                key={excelFunction}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: 2,
                }}
              >
                <Typography variant="body2">{excelFunction}</Typography>
                <IconButton
                  onClick={() => {
                    handleCopy(excelFunction);
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Box>
      </Stack>
    </>
  );
}
