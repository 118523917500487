export const API_ENDPOINTS = {
  // Common API's
  LOGOUT: {
    url: "/logout",
    failureMessage: "There was a problem logging out.",
  },
  REGISTER: {
    url: "/register",
    failureMessage: "Could not create your account.",
  },
  RESET_PASSWORD: (token, accountType) => ({
    url: `/api/Reset/${token}/${accountType}`,
    failureMessage: "There was an error resetting your account.",
  }),
  FETCH_USER_EMAIL_ACCOUNTS: {
    url: "/api/FetchUserEmailAccounts",
    failureMessage: "Could not retrieve your email accounts.",
  },
  LOGIN: {
    url: "/login",
    failureMessage: "Could not log you in.",
  },
  FIRST_LOGIN_PASSWORD_CHANGE: {
    url: "/api/ChangePasswordOnFirstLogin",
    failureMessage: "Could not change password.",
  },
  GENERATE_QR_CODE_FOR_2FA: {
    url: "/api/generateQRCodeFor2FA",
    failureMessage: "Could not generate a QR code.",
  },
  VERIFY_REGISTRATION_OF_2FA: {
    url: "/api/verifyRegistrationOf2FA",
    failureMessage: "Could not verify 2FA registration.",
  },
  LOGIN_VERIFICATION_CHALLENGE_2FA: {
    url: "/api/loginVerificationChallenge2FA",
    failureMessage: "There was a problem with 2FA.",
  },
  RESET_API: {
    url: "/api/Reset",
    failureMessage: "Could not reset the data.",
  },
  UI_LOGGER: {
    url: "/api/uiLogger",
    failureMessage: "Could not log UI data.",
  },
  UI_PORTAL_LOGGER: {
    url: "/api/UiPortalLogger",
    failureMessage: "Could not fetch UI portal logger data.",
  },

  // Admin API's
  LIST_ALL_ACTOR_DETAILS: {
    url: "/api/listAllActorDetails",
    failureMessage: "Could not retrieve the actor list.",
  },
  UPDATE_CLOSING_BALANCE_BULK: {
    url: "/api/UpdateClosingBalanceInBulk",
    failureMessage: "There was a problem updating balances.",
  },
  DOWNLOAD_CLOSING_BALANCE_TEMPLATE: {
    url: "/api/DownloadUploadClosingBalanceTemplate",
    failureMessage: "Could not download the template.",
  },
  UPDATE_RU_CONFIG_TEMPLATE: {
    url: "/api/UpdateRUConfigTemplate",
    failureMessage: "Could not update the template.",
  },
  INSERT_LEDGER_CONFIG_TEMPLATE: {
    url: "/api/InsertLedgerConfigTemplate",
    failureMessage: "Could not insert the template.",
  },
  LIST_SANITIZE_RULE: {
    url: "/api/listSanitizeRule",
    failureMessage: "Could not retrieve sanitize rules.",
  },
  LIST_LEDGER_CONFIG_TEMPLATE: (id) => ({
    url: `/api/ListLedgerConfigTemplate/?templateId=${id}`,
    failureMessage: "Could not retrieve the template.",
  }),
  LIST_ALL_LEDGER_CONFIG_TEMPLATES: {
    url: "/api/ListAllLedgerConfigTemplate",
    failureMessage: "There was a problem retrieving templates.",
  },
  LIST_RU_CONFIG_TEMPLATES_BY_ADMIN: {
    url: "/api/listRuConfigTemplatesByAdmin",
    failureMessage: "Could not retrieve the templates.",
  },
  DELETE_LEDGER_CONFIG_TEMPLATE: {
    url: "/api/deleteLedgerConfigTemplate",
    failureMessage: "Could not delete the template.",
  },
  DELETE_RU_CONFIG_TEMPLATE: {
    url: "/api/DeleteRuConfigTemplate",
    failureMessage: "Could not delete the template.",
  },
  GET_RU_CONFIG_TEMPLATE: {
    url: "/api/GetRuConfigTemplate",
    failureMessage: "Could not retrieve the template.",
  },
  COPY_RU_CONFIG_TEMPLATE_BY_ADMIN: {
    url: "/api/copyRUConfigTemplateByAdmin",
    failureMessage: "Could not copy the template.",
  },
  LIST_OWN_LEDGER: {
    url: "/api/ListOwnLedger",
    failureMessage: "Could not retrieve the ledger.",
  },
  LIST_BUSINESS_PARTNER_LEDGER: {
    url: "/api/ListBusinessPartnerLedger",
    failureMessage: "Could not retrieve the ledger.",
  },
  DELETE_OWN_LEDGER: {
    url: "/api/DeleteOwnLedger",
    failureMessage: "Could not delete the ledger.",
  },
  DELETE_BUSINESS_PARTNER_LEDGER: {
    url: "/api/DeleteBusinessPartnerLedger",
    failureMessage: "Could not delete the ledger.",
  },
  ADD_PAYMENT_DETAILS_BULK: {
    url: "/api/AddPaymentDetailsInBulk",
    failureMessage: "There was a problem adding payment details.",
  },
  GET_PAYMENT_DETAILS_BULK_UPLOAD_EXCEL_TEMPLATE: {
    url: "/api/GetPaymentDetailsBulkUploadExcelTemplate",
    failureMessage: "Could not download the template.",
  },
  LIST_ALL_OWN_BUSINESS_PARTNER_MAPPINGS_BY_ADMIN: {
    url: "/api/ListAllOwnBusinessPartnerMappingsByAdmin",
    failureMessage: "There was a problem retrieving mappings.",
  },
  DELETE_OWN_BUSINESS_PARTNER_MAPPING: {
    url: "/api/DeleteOwnBusinessPartnerMapping",
    failureMessage: "Could not delete the mapping.",
  },
  ADD_ACTOR_USER_MAPPING: {
    url: "/api/AddActorUserMapping",
    failureMessage: "Could not add the mapping.",
  },
  LIST_LEDGER: {
    url: "/api/integration/sap/admin/getLedger",
    failureMessage: "Could not retrieve the ledger.",
  },
  DELETE_SAP_DATA: {
    url: "/api/integration/sap/admin/ledger",
    failureMessage: "Could not delete the SAP data.",
  },
  LIST_TALLY_LEDGER_BY_ADMIN: {
    url: "/api/integration/tally/admin/ledgers",
    failureMessage: "Could not retrieve the ledger.",
  },
  GET_TALLY_PROGRESS: {
    url: "/api/integration/tally/syncProgressStatus",
    failureMessage: "Could not get the progress.",
  },
  LIST_TALLY_COMPANY_BY_ADMIN: {
    url: "/api/integration/tally/admin/companies",
    failureMessage: "Could not retrieve the company list.",
  },
  GET_TALLY_METRICS: {
    url: "/api/integration/tally/admin/metrics",
    failureMessage: "Could not retrieve the metrics.",
  },
  LIST_TALLY_ERRORS: {
    url: "/api/integration/tally/errors",
    failureMessage: "Could not retrieve the error list.",
  },
  LIST_TALLY_ACCEPTED_VOUCHER_TYPES_BY_ADMIN: {
    url: "/api/integration/tally/admin/acceptedVoucherTypes",
    failureMessage: "Could not retrieve accepted voucher types.",
  },
  POST_ACCEPTED_TALLY_VOUCHER_TYPES: {
    url: "/api/integration/tally/uploadAcceptedVoucherTypes",
    failureMessage: "Could not update voucher types.",
  },
  LIST_TALLY_VOUCHER: {
    url: "/api/integration/tally/admin/vouchers",
    failureMessage: "Could not retrieve the voucher list.",
  },
  LIST_TALLY_RAW_VOUCHER_BY_ADMIN: {
    url: "/api/integration/tally/admin/rawVouchers",
    failureMessage: "Could not retrieve vouchers.",
  },
  ADD_UNPAID_INVOICES_IN_BULK: {
    url: "/api/AddUnpaidInvoicesInBulk",
    failureMessage: "There was a problem adding invoices.",
  },
  GET_UNPAID_INVOICES_BULK_UPLOAD_EXCEL_TEMPLATE: {
    url: "/api/GetUnpaidInvoicesBulkUploadExcelTemplate",
    failureMessage: "Could not download the template.",
  },
  LIST_ALL_ACTORS: {
    url: "/api/listAllActors",
    failureMessage: "Could not retrieve the actor list.",
  },
  LIST_ALL_USERS: {
    url: "/api/ListAllUsers",
    failureMessage: "Could not retrieve the user list.",
  },
  DELETE_ACTORS: {
    url: "/api/DeleteActors",
    failureMessage: "Could not delete actors.",
  },
  CREATE_MFA_MAPPING_FOR_ACTOR: {
    url: "/api/createMFAMappingForActor",
    failureMessage: "Could not create MFA mapping.",
  },
  DELETE_MFA_MAPPING_FOR_ACTOR: {
    url: "/api/deleteMFAMappingForActor",
    failureMessage: "Could not delete MFA mapping.",
  },
  REAUTHENTICATE_USER_2FA: {
    url: "/api/reAuthenticateUser2FA",
    failureMessage: "Could not re-authenticate with 2FA.",
  },
  GET_PARTNER_COMMUNICATION_FEEDBACK_IN_EXCEL: {
    url: "/api/partnerCommunication/FeedbackInExcel",
    failureMessage: "Could not retrieve feedback.",
  },
  GET_PARTNER_COMMUNICATION_METRICS: {
    url: "/api/partnerCommunication/GetPCMetrics",
    failureMessage: "Could not retrieve metrics.",
  },
  GET_RU_TEMPLATE_TRACKING: {
    url: "/api/GetRuTemplateTracking",
    failureMessage: "Could not retrieve tracking data.",
  },
  GET_ALL_CLIENT_TYPE: {
    url: "/api/GetAllClientType",
    failureMessage: "Could not retrieve client type.",
  },
  DOWNLOAD_SELECTED_REPORT: (apiUrl: string) => ({
    url: `/api/${apiUrl}`,
    failureMessage: "Could not download report.",
  }),
  GET_TASKS_REPORT: {
    url: "/api/tickets/GetReport",
    failureMessage: "Could not retrieve the report.",
  },
  LIST_ACTOR_LABELS: {
    url: "/api/ListActorLabels",
    failureMessage: "Could not retrieve actor labels.",
  },
  UPDATE_LABELS_FOR_ACTOR: {
    url: "/api/UpdateActorLabels",
    failureMessage: "Could not update labels for actor.",
  },
  GET_ALL_LABELS: {
    url: "/api/GetAllLabels",
    failureMessage: "Could not retrieve labels.",
  },
  UPDATE_CONFIG_TEMPLATE_LABELS: {
    url: "/api/UpdateConfigTemplateLabels",
    failureMessage: "Could not update config template labels.",
  },

  // Admin UserList Api's
  DELETE_ACTOR_USER_MAPPING: {
    url: "/api/DeleteActorUserMapping",
    failureMessage: "Could not delete the actor-user mapping.",
  },
  EDIT_USER_DETAILS: {
    url: "/api/EditUserDetails",
    failureMessage: "Could not edit the user details.",
  },

  // Admin Client Registration
  LIST_ALL_CLIENTS: {
    url: "/api/ListAllClients",
    failureMessage: "Could not retrieve the client list.",
  },
  ADD_NEW_CLIENT: {
    url: "/api/AddNewClient",
    failureMessage: "Could not add the new client.",
  },
  UPDATE_CLIENT_DETAILS: {
    url: "/api/UpdateClientDetails",
    failureMessage: "Could not update the client details.",
  },
  LIST_ALL_ACTORS_FOR_CLIENT: {
    url: "/api/ListAllActorsForClient",
    failureMessage: "Could not fetch the list of actors for the client.",
  },
  LINK_NEW_ACCOUNT_TO_CLIENT: {
    url: "/api/LinkNewAccountToClient",
    failureMessage: "Could not link the new account to the client.",
  },
  REMOVE_ACTOR_FROM_CLIENT: {
    url: "/api/RemoveActorFromClient",
    failureMessage: "Could not remove the actor from the client.",
  },

  //Dispute resolution -> Closure tracker
  GET_DR_TICKET_STATUS: {
    url: "/api/tickets/GetDRTicketStatus",
    failureMessage: "Could not retrieve ticket statuses.",
  },
  LIST_ALL_USERS_DR_EMAIL: {
    url: "/api/DisputeResolutionEmail/ListAllUsers",
    failureMessage: "Could not retrieve the user list.",
  },
  LIST_ALL_BUSINESS_PARTNERS_DR_EMAIL: {
    url: "/api/DisputeResolutionEmail/ListAllBusinessPartner",
    failureMessage: "Could not retrieve the partner list.",
  },
  GET_DISPUTE_RESOLUTION_REPORT: {
    url: "/api/tickets/GetDisputeResolutionReport",
    failureMessage: "Could not retrieve the report.",
  },
  UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES: {
    url: "/api/UpdateOwnAndBusinessPartnerMappingCategories",
    failureMessage: "Could not update categories.",
  },
  LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION: {
    url: "/api/partnerCommunication/ListDynamicContent",
    failureMessage: "Could not retrieve content.",
  },
  LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION: {
    url: "/api/partnerCommunication/ListUserEmailTemplate",
    failureMessage: "Could not retrieve templates.",
  },
  GET_BULK_EMAIL_STATUS_DR_EMAIL: {
    url: "/api/DisputeResolutionEmail/GetBulkEmailStatus",
    failureMessage: "Could not retrieve email status.",
  },
  REFRESH_STATUS_DR_EMAIL: {
    url: "/api/DisputeResolutionEmail/RefreshStatus",
    failureMessage: "Could not refresh status.",
  },
  IS_STATUS_FETCHED_DR_EMAIL: {
    url: "/api/DisputeResolutionEmail/IsStatusFetched",
    failureMessage: "Could not check status.",
  },
  //partner master
  ADD_DESIGNATIONS: {
    url: "/api/AddDesignations",
    failureMessage: "Could not add designations.",
  },
  ADD_BUSINESS_PARTNER_USER_WITH_EMAIL_SETTING: {
    url: "/api/AddBusinessPartnerUserWithEmailSetting",
    failureMessage: "Could not add business partner user with email setting.",
  },
  ADD_BUSINESS_PARTNER_USERS_IN_BULK: {
    url: "/api/AddBusinessPartnerUsersInBulk",
    failureMessage: "Could not add business partner users.",
  },
  LIST_INVALID_EMAILS: {
    url: "/api/ListInvalidEmails",
    failureMessage: "Could not retrieve invalid emails.",
  },
  LIST_POTENTIALLY_INVALID_EMAILS: {
    url: "/api/ListPotentiallyInvalidEmails",
    failureMessage: "Could not retrieve potentially invalid emails.",
  },
  SUBMIT_EDITED_EMAIL: {
    url: "/api/SubmitEditedEmail",
    failureMessage: "Could not submit the edited email.",
  },
  DOWNLOAD_INVALID_EMAILS_REPORT: {
    url: "/api/DownloadInvalidEmailsReport",
    failureMessage: "Could not download the report.",
  },
  LIST_BUSINESS_PARTNER_USERS: {
    url: "/api/ListBusinessPartnerUsers",
    failureMessage: "Could not retrieve business partner users.",
  },
  DELETE_BUSINESS_PARTNER_USER: {
    url: "/api/DeleteBusinessPartnerUser",
    failureMessage: "Could not delete business partner user.",
  },
  LIST_ALL_BUSINESS_PARTNERS_FOR_PARTNER_MASTER: {
    url: "/api/PartnerMaster/ListAllBusinessPartnersForPartnerMaster",
    failureMessage: "Could not retrieve business partners.",
  },
  LIST_ALL_BUSINESS_PARTNER_CONTACTS: {
    url: "/api/ListAllBusinessPartnerContacts",
    failureMessage: "Could not retrieve business partner contacts.",
  },
  GET_BUSINESS_PARTNER_USER_BULK_UPLOAD_EXCEL_TEMPLATE: {
    url: "/api/GetBusinessPartnerUserBulkUploadExcelTemplate",
    failureMessage: "Could not download the template.",
  },
  LIST_ALL_DESIGNATION: {
    url: "/api/ListAllDesignation",
    failureMessage: "Could not retrieve designations.",
  },
  UPLOAD_CATEGORIES_IN_BULK: {
    url: "/api/UploadCategoriesInBulk",
    failureMessage: "Could not upload categories.",
  },
  GET_CATEGORIES_BULK_UPLOAD_EXCEL_TEMPLATE: {
    url: "/api/GetCategoriesBulkUploadExcelTemplate",
    failureMessage: "Could not download the template.",
  },
  UPDATE_ACCOUNT_NICKNAME: {
    url: "/api/UpdateAccountNickname",
    failureMessage: "Could not update account nickname.",
  },
  GET_ACCOUNT_NICKNAME: {
    url: "/api/FetchAccountNickname",
    failureMessage: "Could not fetch account nickname.",
  },
  SET_DEFAULT_TDS_FOR_ACCOUNT: {
    url: "/api/PartnerMaster/SetDefaultTDSForAccount",
    failureMessage: "Could not set default TDS for account.",
  },

  GET_DEFAULT_TDS_FOR_ACCOUNT: {
    url: "/api/PartnerMaster/GetDefaultTDSForAccount",
    failureMessage: "Could not fetch default TDS for account.",
  },

  //recon settings
  GET_CLIENT_TYPE: {
    url: "/api/GetClientType",
    failureMessage: "Could not retrieve client type.",
  },
  UPDATE_CLIENT_TYPE: {
    url: "/api/UpdateClientType",
    failureMessage: "Could not update client type.",
  },
  GET_CUSTOMISED_SETTING: {
    url: "/api/GetCustomisedSetting",
    failureMessage: "Could not retrieve customized setting.",
  },
  UPDATE_RECONCILE_FUNCTION_SETTING: {
    url: "/api/UpdateReconcileFunctionSetting",
    failureMessage: "Could not update reconcile function setting.",
  },
  UPDATE_REVERSAL_FUNCTION_SETTING: {
    url: "/api/UpdateReversalFunctionSetting",
    failureMessage: "Could not update reversal function setting.",
  },
  GET_RECON_SETTINGS: {
    url: "/api/Recon/GetReconSettings",
    failureMessage: "Could not retrieve reconciliation settings.",
  },
  UPLOAD_RECON_SETTINGS: {
    url: "/api/Recon/UploadReconSettings",
    failureMessage: "Could not upload reconciliation settings.",
  },
  GET_CUSTOM_SUMMARY_DESCRIPTION: {
    url: "/api/getCustomSummaryDescription",
    failureMessage: "Could not retrieve custom summary description.",
  },
  INSERT_CUSTOM_SUMMARY_DESCRIPTION: {
    url: "/api/insertCustomSummaryDescription",
    failureMessage: "Could not insert custom summary description.",
  },
  LIST_EMAIL_AUTO_RESET_SETTING: {
    url: "/api/ListEmailAutoResetSetting",
    failureMessage: "Could not retrieve email auto-reset settings.",
  },
  UPDATE_EMAIL_AUTO_RESET_SETTING: {
    url: "/api/UpdateEmailAutoResetSetting",
    failureMessage: "Could not update email auto-reset setting.",
  },
  UPDATE_COLUMNS_COLOR: {
    url: "/api/recon/UpdateColumnsColor",
    failureMessage: "Could not update column colors.",
  },
  SET_DEFAULT_DOWNLOAD_SETTINGS: {
    url: "/api/recon/SetDefaultDownloadSettings",
    failureMessage: "Could not set default download settings.",
  },
  ADD_COLUMNS_IN_OWN_VS_BP_SHEET: {
    url: "/api/recon/AddColumnsInOwnVsBpSheet",
    failureMessage: "Could not add columns.",
  },
  ADD_COLUMNS_IN_RECONCILIATION_SUMMARY: {
    url: "/api/recon/AddColumnsInRecociliationSummary",
    failureMessage: "Could not add columns.",
  },
  ADD_COLUMNS_IN_ANNEXURES_SHEET: {
    url: "/api/recon/AddColumnsInAnnexuresSheet",
    failureMessage: "Could not add columns to Annexures Sheet.",
  },
  GET_COLUMNS_COLOR: {
    url: "/api/recon/GetColumnsColor",
    failureMessage: "Could not retrieve column colors.",
  },
  GET_ANNEXURES_ORDER: {
    url: "/api/recon/GetAnnexuresOrder ",
    failureMessage: "Could not retrieve annexures order.",
  },
  UPDATE_ANNEXURES_ORDER: {
    url: "/api/recon/UpdateAnnexuresOrder ",
    failureMessage: "Could not update annexures order.",
  },
  GET_DEFAULT_DOWNLOAD_SETTINGS: {
    url: "/api/recon/GetDefaultDownloadSettings",
    failureMessage: "Could not retrieve default download settings.",
  },
  GET_EXTRA_COLUMNS_IN_EXCEL_SHEET: {
    url: "/api/recon/GetExtraColumnsInExcelSheet",
    failureMessage: "Could not retrieve extra columns.",
  },
  LIST_USER_EMAIL_SETTING: {
    url: "/api/partnerCommunication/ListUserEmailSetting",
    failureMessage: "Could not retrieve user email settings.",
  },
  GET_AUTHORISATION_CODE_URL: {
    url: "/api/authenticationRoute/GetAuthorisationCodeUrl",
    failureMessage: "Could not retrieve authorization code URL.",
  },
  LIST_RECON_SUMMARY_ACCOUNTING_SETTING: {
    url: "/api/ListReconSummaryAccountingSetting",
    failureMessage: "Could not retrieve reconciliation summary settings.",
  },
  UPDATE_RECON_SUMMARY_TYPE: {
    url: "/api/UpdateReconSummaryType",
    failureMessage: "Could not update reconciliation summary type.",
  },
  LIST_RECON_SUMMARY_ACCOUNTING_SETTINGS_FOR_UI: {
    url: "/api/ListReconSummaryAccountingSettingsForUI",
    failureMessage: "Could not retrieve reconciliation summary settings.",
  },
  INSERT_RECON_SUMMARY_ACCOUNTING_SETTING: {
    url: "/api/InsertReconSummaryAccountingSetting",
    failureMessage: "Could not insert reconciliation summary setting.",
  },
  UPDATE_OUT_OF_PERIOD_SETTING: {
    url: "/api/UpdateOutOfPeriodSetting",
    failureMessage: "Could not update out-of-period setting.",
  },
  UPDATE_AMOUNT_ENTRY_TYPE: {
    url: "/api/updateAmountEntryType",
    failureMessage: "Could not update amount entry type.",
  },
  UPDATE_TASK_STATUSES: {
    url: "/api/tickets/CustomiseStatuses",
    failureMessage: "Could not update statuses.",
  },
  LIST_TASK_STATUSES: {
    url: "/api/tickets/CustomisableStatuses",
    failureMessage: "Could not retrieve task statuses.",
  },
  //workflow
  DOWNLOAD_BULK_CREATE_TASKS_TEMPLATE: {
    url: "/api/tickets/DownloadBulkCreateTasksTemplate",
    failureMessage: "Could not download the template.",
  },
  CREATE_TASKS_IN_BULK: {
    url: "/api/tickets/CreateTicketInBulk",
    failureMessage: "Could not create tasks.",
  },
  UPDATE_TASK_STATUS_IN_BULK: {
    url: "/api/tickets/UpdateTicketStatusInBulk",
    failureMessage: "Could not update task statuses.",
  },
  ADD_TASK_FOLLOW_UP: {
    url: "/api/tickets/AddTicketFollowUp",
    failureMessage: "Could not add task follow-up.",
  },
  UPDATE_TASK_REMARKS: {
    url: "/api/tickets/UpdateTicketRemarks",
    failureMessage: "Could not update task remarks.",
  },
  UPDATE_TICKET_STATUS: {
    url: "/api/tickets/UpdateTicketStatus",
    failureMessage: "Could not update task status.",
  },
  UPDATE_TICKET: {
    url: "/api/tickets/UpdateTicket",
    failureMessage: "Could not update task.",
  },
  LIST_ATTACHMENTS: (ticketId) => ({
    url: `/api/tickets/ListAttachments/${ticketId}`,
    failureMessage: "Could not retrieve attachments.",
  }),
  DOWNLOAD_ATTACHMENT: {
    url: "/api/tickets/DownloadAttachment",
    failureMessage: "Could not download attachment.",
  },
  DELETE_CUSTOM_ATTACHMENT: {
    url: "/api/tickets/DeleteCustomAttachment",
    failureMessage: "Could not delete attachment.",
  },
  UPLOAD_CUSTOM_ATTACHMENT: {
    url: "/api/tickets/UploadCustomAttachment",
    failureMessage: "Could not upload attachment.",
  },
  GET_TICKET_HISTORY: {
    url: "/api/tickets/GetTicketHistory",
    failureMessage: "Could not retrieve ticket history.",
  },
  UPDATE_BULK_TICKET: {
    url: "/api/tickets/UpdateBulkTicket",
    failureMessage: "Could not update tickets.",
  },
  GET_REPORT_FOR_ACCOUNT: (view) => ({
    url: `/api/tickets/GetReportForAccount?view=${view}`,
    failureMessage: "Could not retrieve the report.",
  }),

  //Recon MIS
  GET_RECON_INSIGHTS: {
    url: "/api/getReconInsights",
    failureMessage: "Could not retrieve reconciliation insights.",
  },
  ADD_AUTO_REPORT_TEMPLATE_MIS: {
    url: "/api/ClientReporting/AddAutoReportTemplateMIS",
    failureMessage: "Could not add the report template.",
  },
  UPDATE_AUTO_REPORT_TEMPLATE_MIS: {
    url: "/api/ClientReporting/UpdateAutoReportTemplateMIS",
    failureMessage: "Could not update the report template.",
  },
  TOGGLE_AUTO_REPORT_TEMPLATE_MIS: {
    url: "/api/ClientReporting/ToggleAutoReportTemplateMIS",
    failureMessage: "Could not toggle the report template.",
  },
  LIST_AUTO_REPORT_TEMPLATE_MIS: {
    url: "/api/ClientReporting/ListAutoReportTemplateMIS",
    failureMessage: "Could not retrieve report templates.",
  },
  LIST_AUTO_REPORT_MIS: {
    url: "/api/ClientReporting/ListAutoReportMIS",
    failureMessage: "Could not retrieve reports.",
  },
  GET_USER_EMAILS: {
    url: "/api/ClientReporting/GetUserEmails",
    failureMessage: "Could not retrieve user emails.",
  },
  GET_DOWNLOAD_TASK_REPOSITORY_REPORT: {
    url: "/api/tickets/DownloadTaskRepositoryReport",
    failureMessage: "Could not download task repository report.",
  },
  TRIGGER_AUTO_REPORT_TEMPLATE_MIS: {
    url: "/api/ClientReporting/TriggerAutoReportTemplateMIS",
    failureMessage: "Could not trigger the report template.",
  },
  LIST_OWN_TALLY_COMPANIES_FOR_RECON: {
    url: "/api/integration/tally/ownCompaniesForRecon",
    failureMessage: "Could not retrieve Tally companies.",
  },
  GET_CLOSURE_TRACKER_DATA: {
    url: "/api/MIS/GetClosureTrackerData",
    failureMessage: "Could not retrieve closure tracker data.",
  },
  GET_CLOSURE_TRACKER_OVERVIEW: {
    url: "/api/MIS/GetClosureTrackerOverview",
    failureMessage: "Could not retrieve closure tracker overview.",
  },
  UPDATE_STATUS_DONE_TRACKER: {
    url: "/api/MIS/UpdateStatusDoneTracker",
    failureMessage: "Could not update the status.",
  },
  UPDATE_REMARKS_TRACKER: {
    url: "/api/MIS/UpdateRemarksTracker",
    failureMessage: "Could not update remarks.",
  },
  DOWNLOAD_ACTIVITY_REPORT: (activityMonth, actorId, businessPartnerId) => ({
    url: `/api/download/${activityMonth}/${actorId}/${businessPartnerId}`,
    failureMessage: "Could not download the activity report.",
  }),
  UPLOAD_TRACKER_FILE: {
    url: "/api/MIS/UploadTrackerFile",
    failureMessage: "Could not upload the file.",
  },
  DOWNLOAD_TRACKER_FILE: {
    url: "/api/MIS/DownloadTrackerFile",
    failureMessage: "Could not download the file.",
  },
  DELETE_TRACKER_FILE: {
    url: "/api/MIS/DeleteTrackerFile",
    failureMessage: "Could not delete the file.",
  },
  UPLOAD_TRACKER_TARGET_EXCEL: {
    url: "/api/MIS/UploadTrackerTargetExcel",
    failureMessage: "Could not upload the file.",
  },
  GET_TRACKER_UPLOAD_EXCEL_TEMPLATE: {
    url: "/api/MIS/GetTrackerUploadExcelTemplate",
    failureMessage: "Could not download the template.",
  },

  //Partner Communication
  LIST_ALL_BUSINESS_PARTNER_IDS: {
    url: "/api/partnerCommunication/ListAllBusinessPartnerIds",
    failureMessage: "Could not retrieve the business partner IDs.",
  },
  IS_BALANCE_CONFIRMATION_STATUS_FETCHED: {
    url: "/api/partnerCommunication/isBalanceConfirmationStatusFetched",
    failureMessage: "Could not check balance confirmation status.",
  },
  GET_LAST_STATUS_REFRESH_DATE: {
    url: "/api/partnerCommunication/GetLastStatusRefreshDate",
    failureMessage: "Could not retrieve the last status refresh date.",
  },
  LIST_ALL_BUSINESS_PARTNERS_FOR_BALANCE_CONFIRMATION: {
    url: "/api/partnerCommunication/ListAllBusinessPartnersForBalanceConfirmation",
    failureMessage: "Could not retrieve business partners for balance confirmation.",
  },
  LIST_ALL_BUSINESS_PARTNERS_WITHOUT_STATUS_REFRESH: {
    url: "/api/partnerCommunication/ListAllBusinessPartnersForBalanceConfirmationWithoutStatusRefresh",
    failureMessage: "Could not retrieve business partners without status refresh.",
  },
  LIST_PDF_TEMPLATES: {
    url: "/api/partnerCommunication/ListPDFTemplates",
    failureMessage: "Could not retrieve PDF templates.",
  },
  UPDATE_CLOSING_BALANCE_FOR_CONFIRMATION: {
    url: "/api/partnerCommunication/UpdateClosingBalanceForBalanceConfirmation",
    failureMessage: "Could not update closing balance for confirmation.",
  },
  UPDATE_CLOSING_BALANCE_DATE_FOR_CONFIRMATION: {
    url: "/api/partnerCommunication/UpdateClosingBalanceDateForBalanceConfirmation",
    failureMessage: "Could not update the closing balance date for confirmation.",
  },
  DOWNLOAD_EMAIL_ATTACHMENTS: {
    url: "/api/partnerCommunication/DownloadEmailAttachments",
    failureMessage: "Could not download email attachments.",
  },
  UPDATE_CLOSING_BALANCE_BY_DATE: {
    url: "/api/UpdateClosingBalanceByClosingBalanceDate",
    failureMessage: "Could not update closing balance by date.",
  },
  RESET_STATES_FOR_DEMO_SALES: {
    url: "/api/partnerCommunication/ResetStatesForDemoSales",
    failureMessage: "Could not reset states for demo sales.",
  },
  UPDATE_BALANCE_CONFIRMATION_STATUS: {
    url: "/api/partnerCommunication/UpdateBalanceConfirmationStatus",
    failureMessage: "Could not update balance confirmation status.",
  },
  MANUALLY_REVERT_BALANCE_CONFIRMED_STATUS: {
    url: "/api/partnerCommunication/ManuallyRevertBalanceConfirmedStatus",
    failureMessage: "Could not revert balance confirmed status.",
  },
  DOWNLOAD_BALANCE_CONFIRMATION_REPORT: {
    url: "/api/partnerCommunication/DownloadBalanceConfirmationReport",
    failureMessage: "Could not download balance confirmation report.",
  },
  GET_EXCEL_REPORT_CUSTOMIZATION_SETTING: {
    uri: "/api/PartnerCommunicationPortal/GetExcelReportCustomisationSetting",
    failureMessage: "Could not retrieve excel report customisation setting.",
  },
  DELETE_USER_EMAIL_TEMPLATE: {
    url: "/api/partnerCommunication/DeleteUserEmailTemplate",
    failureMessage: "Could not delete user email template.",
  },
  LIST_FREQUENCY_TABLE: {
    url: "/api/partnerCommunication/ListFrequencyTable",
    failureMessage: "Could not retrieve frequency table.",
  },
  GET_ERP_CLOSING_BALANCE_STATUS: {
    url: "/api/integration/erp/closingBalanceStatus",
    failureMessage: "Could not fetch ERP closing balance status.",
  },
  GET_ERP_CLOSING_BALANCE_STATUS_NEW: {
    url: "/api/closingBalanceStatusNewFlow",
    failureMessage: "Could not fetch ERP closing balance status.",
  },
  GET_ERP_CLOSING_BALANCE_FOR_ALL_BP: {
    url: "/api/integration/erp/closingBalanceForAllBp",
    failureMessage: "Could not fetch ERP closing balance for all business partners.",
  },
  UPDATE_CLOSING_BALANCE_FOR_ALL_BP_USING_PC_DATE: {
    url: "/api/UpdateClosingBalanceForAllBpUsingPCDate",
    failureMessage: "Could not update closing balance for all BP using PC date.",
  },
  UPDATE_ERP_CLOSING_BALANCE_FOR_ALL_BP: {
    url: "/api/UpdateERPClosingBalanceForAllBp",
    failureMessage: "Could not update ERP closing balance for all BP.",
  },
  UPDATE_ERP_CLOSING_BALANCE_FOR_ALL_BP_NEW: {
    url: "/api/UpdateERPClosingBalanceForAllBpNewFlow",
    failureMessage: "Could not update ERP closing balance for all BP.",
  },
  UPDATE_ERP_CLOSING_BALANCE: {
    url: "/api/UpdateERPClosingBalance",
    failureMessage: "Could not update ERP closing balance.",
  },
  VIEW_EMAIL: {
    url: "/api/partnerCommunication/ViewEmail",
    failureMessage: "Could not retrieve email view.",
  },
  GET_EMAILS_SENT_IN_BULK_STATUS: {
    url: "/api/partnerCommunication/GetEmailsSentInBulkStatus",
    failureMessage: "Could not retrieve bulk email status.",
  },
  IS_BULK_EMAIL_COMPLETE: {
    url: "/api/partnerCommunication/IsBulkEmailComplete",
    failureMessage: "Could not verify bulk email completion status.",
  },
  LIST_EMAIL_AUTO_RESET_SETTING_PC: {
    url: "/api/partnerCommunication/ListEmailAutoResetSetting",
    failureMessage: "Could not retrieve email auto-reset settings.",
  },
  UPDATE_EMAIL_AUTO_RESET_SETTING_PC: {
    url: "/api/partnerCommunication/UpdateEmailAutoResetSetting",
    failureMessage: "Could not update email auto-reset setting.",
  },
  LIST_ALL_BALANCE_CONFIRMATION_SETTINGS: {
    url: "/api/partnerCommunication/ListAllBalanceConfirmationSettings",
    failureMessage: "Could not retrieve balance confirmation settings.",
  },
  UPDATE_LEDGER_REQUEST_SETTINGS: {
    url: "/api/partnerCommunication/UpdateLedgerRequestSettings",
    failureMessage: "Could not update ledger request settings.",
  },
  RESET_STATES_PARTNER_COMMUNICATION: {
    url: "/api/partnerCommunication/ResetStatesPartnerCommunication",
    failureMessage: "Could not reset partner communication states.",
  },
  LIST_EMAIL_AUTO_SEND_REMINDER_SETTING: {
    url: "/api/partnerCommunication/ListEmailAutoSendReminderSetting",
    failureMessage: "Could not retrieve email auto-send reminder settings.",
  },
  UPDATE_AUTO_REMINDER_SETTINGS: {
    url: "/api/partnerCommunication/UpdateAutoReminderSettings",
    failureMessage: "Could not update auto reminder settings.",
  },
  LIST_EMAIL_REMINDER_SETTINGS: {
    url: "/api/partnerCommunication/ListEmailReminderSettings",
    failureMessage: "Could not retrieve email reminder settings.",
  },
  LIST_AUTO_SEND_REMINDER_TEMPLATE: {
    url: "/api/partnerCommunication/ListAutoSendReminderTemplate",
    failureMessage: "Could not retrieve auto-send reminder template.",
  },
  GET_CONFIRM_BALANCE_BTN_ENABLED: {
    url: "/api/partnerCommunication/GetConfirmBalanceBtnEnabled",
    failureMessage: "Could not determine if confirm balance button is enabled.",
  },
  UPDATE_CONFIRM_BALANCE_BTN_ENABLED: {
    url: "/api/partnerCommunication/UpdateConfirmBalanceBtnEnabled",
    failureMessage: "Could not update the confirm balance button status.",
  },
  LIST_ALL_COMMUNICATION_TYPES: {
    url: "/api/CommunicationType/ListAllCommunicationTypes",
    failureMessage: "Could not retrieve communication types.",
  },
  DELETE_COMMUNICATION_TYPE: {
    url: "/api/CommunicationType/DeleteCommunicationType",
    failureMessage: "Could not delete the communication type.",
  },
  GET_COMMUNICATION_TYPE_DETAIL: {
    url: "/api/CommunicationType/GetCommunicationTypeDetail",
    failureMessage: "Could not retrieve communication type details.",
  },
  CREATE_OR_UPDATE_COMMUNICATION_TYPE: {
    url: "/api/CommunicationType/CreateOrUpdateCommunicationType",
    failureMessage: "Could not create or update the communication type.",
  },
  CHECK_IF_EMAIL_TEMPLATE_NEEDS_TO_BE_ADDED: {
    url: "/api/partnerCommunication/CheckIfEmailTemplateNeedsToBeAdded",
    failureMessage: "Could not check if email template needs to be added.",
  },
  ADD_USER_EMAIL_TEMPLATE: {
    url: "/api/partnerCommunication/AddUserEmailTemplate",
    failureMessage: "Could not add user email template.",
  },
  LIST_ALL_BUSINESS_PARTNERS_BCB: {
    url: "/api/PartnerCommunicationPortal/ListAllBusinessPartner",
    failureMessage: "Could not retrieve all business partners.",
  },
  LIST_ALL_BUSINESS_PARTNERS_FOR_SENDING_MAIL: {
    url: "/api/PartnerCommunicationPortal/ListAllBusinessPartnerForSendingMail",
    failureMessage: "Could not retrieve all business partners.",
  },
  LIST_ALL_MAILED_BUSINESS_PARTNERS: {
    url: "/api/PartnerCommunicationPortal/ListAllMailedBusinessPartners",
    failureMessage: "Could not retrieve all business partners.",
  },
  UPDATE_OWN_AND_PARTNER_MAPPING_CATEGORIES: {
    url: "/api/UpdateOwnAndBusinessPartnerMappingCategories",
    failureMessage: "Could not update own and business partner mapping categories.",
  },
  GET_BALANCE_CONFIRMATION_REPORT: {
    url: "/api/PartnerCommunicationPortal/GetBalanceConfirmationReport",
    failureMessage: "Could not retrieve the balance confirmation report.",
  },
  DOWNLOAD_EXCEL_REPORT_FOR_HISTORY: {
    url: "/api/PartnerCommunicationPortal/DownloadExcelReportForBalanceConfirmationHistory",
    failureMessage: "Could not download the report for balance confirmation history.",
  },
  GET_BULK_EMAIL_STATUS: {
    url: "/api/PartnerCommunicationPortal/GetBulkEmailStatus",
    failureMessage: "Could not retrieve bulk email status.",
  },
  SCHEDULED_BULK_EMAIL_STATUS: {
    url: "/api/PartnerCommunicationPortal/PortalCommunicationBulkEmailStatus",
    failureMessage: "Could not retrieve scheduled bulk email status.",
  },
  UPDATE_CLOSING_BALANCE: {
    url: "/api/PartnerCommunicationPortal/UpdateClosingBalance",
    failureMessage: "Could not update the closing balance.",
  },
  UPDATE_CLOSING_BALANCE_DATE: {
    url: "/api/PartnerCommunicationPortal/UpdateClosingBalanceDate",
    failureMessage: "Could not update the closing balance date.",
  },
  LIST_ALL_BUSINESS_PARTNER_REQUESTS: {
    url: "/api/PartnerCommunicationPortal/ListAllBusinessPartnerRequest",
    failureMessage: "Could not retrieve business partner requests.",
  },
  MARK_ISSUE_AS_RESOLVED: {
    url: "/api/PartnerCommunicationPortal/MarkIssueAsResolved",
    failureMessage: "Could not mark issue as resolved.",
  },
  SYNC_MAIL: {
    url: "/api/PartnerCommunicationPortal/syncMail",
    failureMessage: "Could not sync mail.",
  },
  SYNC_MAIL_STATUS: {
    url: "/api/PartnerCommunicationPortal/syncMailStatus",
    failureMessage: "Could not sync mail status.",
  },
  GET_ATTACHMENTS_ZIP: {
    url: "/api/PartnerCommunicationPortal/GetAttachmentsZip",
    failureMessage: "Could not retrieve attachments zip.",
  },
  APPROVE_STATUS: {
    url: "/api/PartnerCommunicationPortal/ApproveStatus",
    failureMessage: "Could not approve the status.",
  },
  RESET_STATES_ON_PARTNER_LEVEL: {
    url: "/api/PartnerCommunicationPortal/ResetStatesOnPartnerLevelForBalanceConfirmation",
    failureMessage: "Could not reset states for balance confirmation on partner level.",
  },
  GET_EXISTING_TICKETS: {
    url: "/api/tickets/GetExistingTickets",
    failureMessage: "Could not retrieve existing tickets.",
  },
  CLOSE_TICKET_AND_RESET_COMMUNICATION: {
    url: "/api/tickets/CloseTicketAndResetCommunication",
    failureMessage: "Could not close and reset communication for the ticket.",
  },
  RESET_STATES: {
    url: "/api/PartnerCommunicationPortal/ResetStates",
    failureMessage: "Could not reset states for balance confirmation.",
  },
  LIST_HISTORY_OF_ALL_BUSINESS_PARTNERS: {
    url: "/api/PartnerCommunicationPortal/ListHistoryOfAllBusinessPartnersForBalanceConfirmation",
    failureMessage: "Could not retrieve history of business partners for balance confirmation.",
  },
  VIEW_EMAIL_THREAD_FOR_HISTORY: {
    url: "/api/PartnerCommunicationPortal/ViewEmailThreadForBalanceConfirmationHistory",
    failureMessage: "Could not view email thread for balance confirmation history.",
  },
  DOWNLOAD_UPLOADED_FILES_FOR_HISTORY: {
    url: "/api/PartnerCommunicationPortal/DownloadUploadedFilesForBalanceConfirmationHistory",
    failureMessage: "Could not download uploaded files for balance confirmation history.",
  },
  GET_DEFAULT_OPTIONS: {
    url: "/api/tickets/GetDefaultOptions",
    failureMessage: "Could not retrieve default options for tickets.",
  },
  MOVE_TICKETS_TO_RECON: {
    url: "/api/tickets/MoveTicketsToRecon",
    failureMessage: "Could not move tickets to recon.",
  },
  GET_TASK_LABELS: {
    url: "/api/tickets/GetTaskLabels",
    failureMessage: "Could not retrieve task labels.",
  },
  GET_TASK_LABEL_VALIDATION: {
    url: "/api/tickets/GetTaskLabelValidationForPartnerComm",
    failureMessage: "Could not retrieve task label validation.",
  },
  GET_COMMUNICATION_DETAILS: {
    url: "/api/PartnerCommunicationPortal/GetCommunicationDetails",
    failureMessage: "Could not retrieve communication details.",
  },
  GET_DETAILS_FOR_UPLOADING_PAGE: {
    url: "/api/PartnerCommunicationPortal/GetDetailsForUploadingPage",
    failureMessage: "Could not retrieve details for uploading page.",
  },
  GET_ClOSING_BALANCE_DETAILS: {
    url: "/api/PartnerCommunicationPortal/GetClosingBalanceDetails",
    failureMessage: "Could not retrieve closing balance details.",
  },
  UPDATE_BALANCE_CONFIRMATION_STATUS_PORTAL: {
    url: "/api/PartnerCommunicationPortal/UpdateBalanceConfirmationStatus",
    failureMessage: "Could not update balance confirmation status.",
  },
  UPLOAD_FILES_BC_PORTAL: {
    url: "/api/PartnerCommunicationPortal/UploadFiles",
    failureMessage: "Could not upload files.",
  },
  VIEW_UPLOADED_FILES_BC_PORTAL: {
    url: "/api/PartnerCommunicationPortal/ViewUploadedFiles",
    failureMessage: "Could not retrieve uploaded files.",
  },
  DELETE_UPLOADED_FILES_BC_PORTAL: {
    url: "/api/PartnerCommunicationPortal/DeleteUploadedFiles",
    failureMessage: "Could not delete uploaded files.",
  },
  UPDATE_BUSINESS_PARTNER_CLOSING_BALANCE: {
    url: "/api/PartnerCommunicationPortal/updateBusinessPartnerClosingBalance",
    failureMessage: "Could not update business partner closing balance.",
  },
  CONFIRM_FINAL_SUBMISSION: {
    url: "/api/PartnerCommunicationPortal/ConfirmFinalSubmission",
    failureMessage: "Could not submit final submission.",
  },
  GET_PREVIOUSLY_RAISED_ISSUE: {
    url: "/api/PartnerCommunicationPortal/GetPreviouslyRaisedIssue",
    failureMessage: "Could not retrieve previously raised issues.",
  },
  SAVE_REMARKS: {
    url: "/api/PartnerCommunicationPortal/SaveRemarks",
    failureMessage: "Could not save remarks.",
  },
  GET_PDF_TO_DOWNLOAD: {
    url: "/api/PartnerCommunicationPortal/GetPdfToDownload",
    failureMessage: "Could not retrieve PDF to download.",
  },
  // GET_DETAILS_FOR_FINAL_PAGE: {
  //   url: "/api/PartnerCommunicationPortal/GetDetailsForFinalPage",
  //   failureMessage: "Could not retrieve details for the final page.",
  // },
  GET_PARTNER_NAME_AND_LOGO: {
    url: "/api/PartnerCommunicationPortal/GetPartnerNameAndLogo",
    failureMessage: "Could not retrieve partner name and logo.",
  },
  CHECK_AND_UPDATE_IF_EDITABLE: {
    url: "/api/PartnerCommunicationPortal/CheckAndUpdateIfEditable",
    failureMessage: "Could not check or update editability.",
  },
  RAISE_ISSUE: {
    url: "/api/PartnerCommunicationPortal/RaiseIssue",
    failureMessage: "Could not raise issue.",
  },
  SEND_BULK_EMAIL: {
    url: "/api/PartnerCommunicationPortal/SendBulkEmail",
    failureMessage: "Could not send bulk email.",
  },
  VIEW_EMAIL_THREAD: {
    url: "/api/PartnerCommunicationPortal/ViewEmailThread",
    failureMessage: "Could not retrieve the email thread.",
  },
  SEND_EMAIL: {
    url: "/api/PartnerCommunicationPortal/SendEmail",
    failureMessage: "Could not send email.",
  },
  DOWNLOAD_FILE_THROUGH_EXCEL: {
    url: "/api/PartnerCommunicationPortal/DownloadFileThroughExcel",
    failureMessage: "Could not download the file through Excel in Balance Confirmation Portal.",
  },
  DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY: {
    url: "/api/PartnerCommunicationPortal/DownloadFileThroughExcelForHistoryReport",
    failureMessage: "Could not download the history report through Excel in Balance Confirmation Portal.",
  },
  DOWNLOAD_MAIL_THREAD: {
    url: "/api/PartnerCommunicationPortal/DownloadMailThread",
    failureMessage: "Could not download mail thread in Balance Confirmation Portal.",
  },
  DOWNLOAD_FILE_THROUGH_EXCEL_LR: {
    url: "/api/PartnerCommunicationPortal/DownloadFileThroughExcel",
    failureMessage: "Could not download the file through Excel in Ledger Request Portal.",
  },
  DOWNLOAD_FILE_THROUGH_EXCEL_HISTORY_LR: {
    url: "/api/PartnerCommunicationPortal/DownloadFileThroughExcelForHistoryReport",
    failureMessage: "Could not download the history report through Excel in Ledger Request Portal.",
  },
  DOWNLOAD_MAIL_THREAD_LR: {
    url: "/api/PartnerCommunicationPortal/DownloadMailThread",
    failureMessage: "Could not download mail thread in Ledger Request Portal.",
  },
  ADD_PDF_TEMPLATES: {
    url: "/api/partnerCommunication/AddPDFTemplates",
    failureMessage: "Could not add PDF templates.",
  },
  GET_PDF_TEMPLATE_BODY: {
    url: "/api/partnerCommunication/GetPdfTemplateBody",
    failureMessage: "Could not retrieve PDF template body.",
  },
  LIST_DYNAMIC_CONTENT: {
    url: "/api/ListDynamicContent",
    failureMessage: "Could not list dynamic content.",
  },
  PREVIEW_PDF_TEMPLATE: {
    url: "/api/partnerCommunication/PreviewPDFTemplate",
    failureMessage: "Could not preview PDF template.",
  },
  VERIFY_EMAIL: {
    url: "/api/PartnerCommunicationPortal/verifyEmail",
    failureMessage: "Could not verify email.",
  },
  VERIFY_EMAIL_DETAILS: {
    url: "/api/PartnerCommunicationPortal/VerifyEmailDetails",
    failureMessage: "Could not verify email details.",
  },
  CREATE_OR_UPDATE_PRESET: {
    url: "/api/Preset/CreateOrUpdatePreset",
    failureMessage: "Could not create or update preset.",
  },
  LIST_ALL_PRESETS: {
    url: "/api/Preset/ListAllPresets",
    failureMessage: "Could not retrieve presets.",
  },
  DELETE_PRESET: {
    url: "/api/Preset/DeletePreset",
    failureMessage: "Could not delete preset.",
  },
  SAVE_ACTIVE_PRESET: {
    url: "/api/Preset/SaveActivePreset",
    failureMessage: "Could not save active preset.",
  },
  GET_ACTIVE_PRESET: {
    url: "/api/Preset/GetActivePreset",
    failureMessage: "Could not retrieve active preset.",
  },
  LIST_EMAIL_AUTO_SEND_REMINDER_SETTING_PORTAL: (endPointPrefix: string) => ({
    url: `${endPointPrefix}/ListEmailAutoSendReminderSetting`,
    failureMessage: "Could not retrieve email auto-send reminder settings.",
  }),
  UPDATE_AUTO_REMINDER_SETTINGS_PORTAL: (endPointPrefix: string) => ({
    url: `${endPointPrefix}/UpdateAutoReminderSettings`,
    failureMessage: "Could not update auto reminder settings.",
  }),
  LIST_EMAIL_REMINDER_SETTINGS_PORTAL: (endPointPrefix: string) => ({
    url: `${endPointPrefix}/ListEmailReminderSettings`,
    failureMessage: "Could not retrieve email reminder settings.",
  }),
  LIST_AUTO_SEND_REMINDER_TEMPLATE_PORTAL: (endPointPrefix: string) => ({
    url: `${endPointPrefix}/ListAutoSendReminderTemplate`,
    failureMessage: "Could not retrieve auto-send reminder template.",
  }),
  SAVE_SELECTED_COMPANY: {
    url: "/api/SaveSelectedCompany",
    failureMessage: "Could not save the selected company.",
  },
  SAVE_SELECTED_BRANCH: {
    url: "/api/SaveSelectedBranch",
    failureMessage: "Could not save the selected branch.",
  },
  LIST_COMPANIES: {
    url: "/api/ListCompanies",
    failureMessage: "Could not retrieve the list of companies.",
  },
  LIST_BRANCHES: {
    url: "/api/ListBranches",
    failureMessage: "Could not retrieve the list of branches.",
  },
  FEEDBACK_PARTNER_COMMUNICATION: {
    url: "/api/partnerCommunication/FeedbackPartnerCommunication",
    failureMessage: "Could not retrieve feedback for partner communication.",
  },
  SEND_MAIL: {
    url: "/api/partnerCommunication/SendMail",
    failureMessage: "Could not send the mail.",
  },
  SEND_EMAIL_FOR_MSME_CONFIRMATION: {
    url: "/api/partnerPortal/SendEmailForMsmeConfirmation",
    failureMessage: "Could not send the MSME confirmation email.",
  },
  SEND_MAIL_IN_BULK: {
    url: "/api/partnerCommunication/SendMailInBulk",
    failureMessage: "Could not send emails in bulk.",
  },
  SEND_BULK_EMAIL_FOR_MSME: {
    url: "/api/partnerPortal/SendBulkEmailForMsme",
    failureMessage: "Could not send bulk MSME emails.",
  },
  DELETE_PC_PDF_TEMPLATE: {
    url: "/api/PartnerCommunicationPortal/DeletePcPdfTemplate",
    failureMessage: "Could not delete the PC PDF template.",
  },
  SEND_EMAIL_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/SendEmailForLedgerRequest",
    failureMessage: "Could not send email.",
  },
  SEND_BULK_EMAIL_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/SendBulkEmailForLedgerRequest",
    failureMessage: "Could not send bulk email.",
  },
  CONFIRM_BALANCE_THROUGH_MAIL_BUTTON: {
    url: "/api/partnerCommunication/ConfirmBalanceThroughMailButton",
    failureMessage: "Could not confirm balance through mail button.",
  },
  IS_LEDGER_REQUEST_STATUS_FETCHED: {
    url: "/api/partnerCommunication/isLedgerRequestStatusFetched",
    failureMessage: "Could not check ledger request status.",
  },
  LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST: {
    url: "/api/partnerCommunication/ListAllBusinessPartnersForLedgerRequest",
    failureMessage: "Could not list business partners for ledger request.",
  },
  LIST_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST_WITHOUT_STATUS_REFRESH: {
    url: "/api/partnerCommunication/ListAllBusinessPartnersForLedgerRequestWithoutStatusRefresh",
    failureMessage: "Could not list business partners for ledger request without status refresh.",
  },
  DOWNLOAD_LEDGER_REQUEST_REPORT: {
    url: "/api/partnerCommunication/DownloadLedgerRequestReport",
    failureMessage: "Could not download ledger request report.",
  },
  LIST_ALL_LEDGER_REQUEST_SETTINGS: {
    url: "/api/partnerCommunication/ListAllLedgerRequestSettings",
    failureMessage: "Could not list all ledger request settings.",
  },
  LIST_ALL_BUSINESS_PARTNER_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ListAllBusinessPartnerForLedgerRequest",
    failureMessage: "Could not list business partners for ledger request.",
  },
  DOWNLOAD_EXCEL_REPORT_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/DownloadExcelReportForLedgerRequest",
    failureMessage: "Could not download Excel report for ledger request.",
  },
  VIEW_EMAIL_THREAD_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ViewEmailThread",
    failureMessage: "Could not view email thread for ledger request.",
  },
  DOWNLOAD_UPLOADED_LEDGER: {
    url: "/api/PartnerCommunicationPortal/DownloadUploadedLedger",
    failureMessage: "Could not download uploaded ledger.",
  },
  LIST_ALL_BUSINESS_PARTNER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ListAllBusinessPartnerRequest",
    failureMessage: "Could not list all business partner requests.",
  },
  MARK_ISSUE_AS_RESOLVED_LR: {
    url: "/api/PartnerCommunicationPortal/MarkIssueAsResolved",
    failureMessage: "Could not mark issue as resolved.",
  },
  GET_LEDGER_REQUEST_BULK_EMAIL_STATUS: {
    url: "/api/PartnerCommunicationPortal/GetLedgerRequestBulkEmailStatus",
    failureMessage: "Could not retrieve ledger request bulk email status.",
  },
  APPROVE_STATUS_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ApproveStatus",
    failureMessage: "Could not approve status for ledger request.",
  },
  RESET_STATES_ON_PARTNER_LEVEL_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ResetStatesOnPartnerLevelForLedgerRequest",
    failureMessage: "Could not reset states on partner level for ledger request.",
  },
  RESET_STATUS_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ResetStatusForLedgerRequest",
    failureMessage: "Could not reset status for ledger request.",
  },
  LIST_HISTORY_OF_ALL_BUSINESS_PARTNERS_FOR_LEDGER_REQUEST: {
    url: "/api/PartnerCommunicationPortal/ListHistoryOfAllBusinessPartnersForLedgerRequest",
    failureMessage: "Could not retrieve history of business partners for ledger request.",
  },
  DOWNLOAD_EXCEL_REPORT_FOR_LEDGER_REQUEST_HISTORY: {
    url: "/api/PartnerCommunicationPortal/DownloadExcelReportForLedgerRequestHistory",
    failureMessage: "Could not download Excel report for ledger request history.",
  },
  VIEW_EMAIL_THREAD_FOR_LEDGER_REQUEST_HISTORY: {
    url: "/api/PartnerCommunicationPortal/ViewEmailThreadForLedgerRequestHistory",
    failureMessage: "Could not view email thread for ledger request history.",
  },
  DOWNLOAD_UPLOADED_FILES_FOR_LEDGER_REQUEST_HISTORY: {
    url: "/api/PartnerCommunicationPortal/DownloadUploadedFilesForLedgerRequestHistory",
    failureMessage: "Could not download uploaded files for ledger request history.",
  },
  GET_UPLOADED_LEDGERS: {
    url: "/api/PartnerCommunicationPortal/GetUploadedLedgers",
    failureMessage: "Could not retrieve uploaded ledgers.",
  },
  // GET_DETAILS_FOR_FINAL_PAGE_LR: {
  //   url: "/api/PartnerCommunicationPortal/GetDetailsForFinalPage",
  //   failureMessage: "Could not retrieve details for the final page.",
  // },
  CHECK_AND_UPDATE_IF_EDITABLE_LR: {
    url: "/api/PartnerCommunicationPortal/CheckAndUpdateIfEditable",
    failureMessage: "Could not check or update if editable.",
  },
  // GET_ALL_PREVIOUSLY_RAISED_ISSUES: {
  //   url: "/api/PartnerCommunicationPortal/GetAllPreviouslyRaisedIssues",
  //   failureMessage: "Could not retrieve all previously raised issues.",
  // },
  PARTNER_MARK_ISSUE_AS_RESOLVED: {
    url: "/api/PartnerCommunicationPortal/PartnerMarkIssueAsResolved",
    failureMessage: "Could not mark issue as resolved.",
  },
  RAISE_ISSUE_LR: {
    url: "/api/PartnerCommunicationPortal/RaiseIssue",
    failureMessage: "Could not raise issue.",
  },
  GET_PARTNER_NAME_AND_LOGO_LR: {
    url: "/api/PartnerCommunicationPortal/GetPartnerNameAndLogo",
    failureMessage: "Could not retrieve partner name and logo.",
  },
  GET_PORTAL_DETAILS_STATUS: {
    url: "/api/PartnerCommunicationPortal/GetBCPortalStatus",
    failureMessage: "Could not retrieve Portal details status.",
  },
  GET_EMAIL_DETAILS: {
    url: "/api/PartnerCommunicationPortal/GetEmailDetails",
    failureMessage: "Could not retrieve email details.",
  },
  // SUBMIT_LEDGER_REQUEST: {
  //   url: "/api/PartnerCommunicationPortal/SubmitLedgerRequest",
  //   failureMessage: "Could not submit ledger request.",
  // },
  UPLOAD_LEDGER: {
    url: "/api/PartnerCommunicationPortal/UploadLedger",
    failureMessage: "Could not upload ledger.",
  },
  VIEW_UPLOADED_LEDGER: {
    url: "/api/PartnerCommunicationPortal/ViewUploadedLedger",
    failureMessage: "Could not view uploaded ledger.",
  },
  DELETE_UPLOADED_LEDGER: {
    url: "/api/PartnerCommunicationPortal/DeleteUploadedLedger",
    failureMessage: "Could not delete uploaded ledger.",
  },
  LIST_ALL_ACTIVE_AUTO_REMINDERS: {
    url: "/api/AutoReminder/ListAllActiveAutoReminders",
    failureMessage: "Failed to get active auto reminders.",
  },
  LIST_ALL_AUTO_REMINDER_TEMPLATES: {
    url: "/api/AutoReminder/ListAllAutoReminderTemplates",
    failureMessage: "Failed to get auto reminder templates.",
  },
  CREATE_NEW_AUTO_REMINDER: {
    url: "/api/AutoReminder/CreateNewAutoReminder",
    failureMessage: "Failed to create new auto reminder.",
  },
  STOP_AUTO_REMINDER: {
    url: "/api/AutoReminder/StopAutoReminder",
    failureMessage: "Failed to stop auto reminder.",
  },
  LIST_ALL_BUSINESS_PARTNER_FOR_MSME: {
    url: "/api/partnerPortal/ListAllBusinessPartnerForMsme",
    failureMessage: "Could not list all business partners for MSME.",
  },
  DOWNLOAD_EXCEL_REPORT_FOR_MSME: {
    url: "/api/partnerPortal/DownloadExcelReportForMsme",
    failureMessage: "Could not download the MSME Excel report.",
  },
  VIEW_EMAIL_THREAD_MSME: {
    url: "/api/partnerPortal/ViewEmailThread",
    failureMessage: "Could not view email thread.",
  },
  VIEW_UPLOADED_PARTNER_PDF: {
    url: "/api/partnerPortal/ViewUploadedPartnerPdf",
    failureMessage: "Could not view uploaded partner PDF.",
  },
  GET_MSME_BULK_EMAIL_STATUS: {
    url: "/api/partnerPortal/GetMsmeBulkEmailStatus",
    failureMessage: "Could not get MSME bulk email status.",
  },
  LIST_ALL_BUSINESS_PARTNER_REQUEST_MSME: {
    url: "/api/partnerPortal/ListAllBusinessPartnerRequest",
    failureMessage: "Could not list all business partner requests.",
  },
  MARK_ISSUE_AS_RESOLVED_MSME: {
    url: "/api/partnerPortal/MarkIssueAsResolved",
    failureMessage: "Could not mark issue as resolved.",
  },
  UPDATE_FIRM_AND_AUTHORISED_PERSON_DETAILS: {
    url: "/api/partnerPortal/UpdateFirmAndAuthorisedPersonDetails",
    failureMessage: "Could not update firm and authorised person details.",
  },
  UPLOAD_PDF: {
    url: "/api/partnerPortal/UploadPdf",
    failureMessage: "Could not upload PDF.",
  },
  VIEW_UPLOADED_PDF: {
    url: "/api/partnerPortal/ViewUploadedPdf",
    failureMessage: "Could not view uploaded PDF.",
  },
  DELETE_PDF: {
    url: "/api/partnerPortal/DeletePdf",
    failureMessage: "Could not delete PDF.",
  },
  GET_PREVIOUSLY_RAISED_ISSUE_MSME: {
    url: "/api/partnerPortal/GetPreviouslyRaisedIssue",
    failureMessage: "Could not retrieve previously raised issues.",
  },
  PARTNER_MARK_ISSUE_AS_RESOLVED_MSME: {
    url: "/api/partnerPortal/PartnerMarkIssueAsResolved",
    failureMessage: "Could not mark issue as resolved.",
  },
  RAISE_REQUEST: {
    url: "/api/partnerPortal/RaiseRequest",
    failureMessage: "Could not raise request.",
  },
  CONFIRM_FINAL_SUBMISSION_PARTNER_PORTAL: {
    url: "/api/partnerPortal/ConfirmFinalSubmission",
    failureMessage: "Could not confirm final submission in partner portal.",
  },
  GET_PDF_TO_UPLOAD: {
    url: "/api/partnerPortal/GetPdfToUpload",
    failureMessage: "Could not retrieve PDF to upload.",
  },

  // MIS -> Partner Risk Managment
  GET_REPORT_DETAILS_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/GetReport",
    failureMessage: "Could not retrieve report.",
  },
  GET_REPORT_PREVIEW_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/GetReportPreview",
    failureMessage: "Could not retrieve report preview.",
  },
  SAVE_MIS_REPORT_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/SaveMISReport",
    failureMessage: "Could not save MIS report.",
  },
  DELETE_MIS_REPORT_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/DeleteMISReport",
    failureMessage: "Could not delete MIS report.",
  },
  LIST_ALL_REPORTS_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/ListAllReports",
    failureMessage: "Could not list all reports.",
  },
  DOWNLOAD_REPORT_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/DownloadMISReport",
    failureMessage: "Could not download MIS report.",
  },
  DOWNLOAD_ALL_REPORTS_PARTNER_RISK_MANAGEMENT: {
    url: "/api/PartnerRiskManagement/DownloadAllReports",
    failureMessage: "Could not download all reports.",
  },

  //MIS -> Mail Reports
  MAIL_REPORTS_SEND_MAIL: {
    url: "/api/MailReport/SendMail",
    failureMessage: "Could not send mail.",
  },
  MAIL_REPORTS_GET_REPORTS: {
    url: "/api/MailReport/GetMISReports",
    failureMessage: "Could not list reports.",
  },
  MAIL_REPORTS_GET_DYNAMIC_CONTENTS: {
    url: "/api/MailReport/GetDynamicContents",
    failureMessage: "Could not list dynamic contents.",
  },
  MAIL_REPORTS_DELETE_AUTOMATED_MAIL: (emailTemplateId: number) => ({
    url: `/api/MailReport/EmailTemplate/${emailTemplateId}`,
    failureMessage: "Could not delete automated email.",
  }),
  MAIL_REPORTS_GET_AUTOMATED_MAILS_LIST: {
    url: "/api/MailReport/EmailTemplate",
    failureMessage: "Could not list automated mails.",
  },
  MAIL_REPORTS_UPDATE_AUTOMATED_MAIL: (templateId: number) => ({
    url: `/api/MailReport/EmailTemplate/${templateId}`,
    failureMessage: "Could not update automated mail.",
  }),
  MAIL_REPORTS_SETUP_AUTOMATED_MAIL: {
    url: "/api/MailReport/EmailTemplate",
    failureMessage: "Could not setup automated mail.",
  },
  MAIL_REPORTS_GET_AUTOMATED_MAIL_DETAILS: (templateId: number) => ({
    url: `/api/MailReport/EmailTemplate/${templateId}`,
    failureMessage: "Could not get automated mail details.",
  }),
  //Task board
  GET_ALL_MAIN_PAGE_DATA: {
    url: "/api/tickets/ListAllDataOnMainPage",
    failureMessage: "Could not get main page data.",
  },
  GET_ALL_TASKS_DATA: {
    url: "/api/tickets/ListAllTasks",
    failureMessage: "Could not get tasks data.",
  },

  // Automated Recon APIs
  UPDATE_CLOSING_BALANCE_FOR_RECON: {
    url: "/api/UpdateClosingBalanceForRecon",
    failureMessage: "There was a problem updating the closing balance for reconciliation.",
  },
  FETCH_TICKETS: {
    url: "/api/tickets/FetchTickets",
    failureMessage: "Could not fetch tickets.",
  },
  LIST_RU_CONFIG_TEMPLATES: {
    url: "/api/ListRuConfigTemplates",
    failureMessage: "Could not fetch RU config templates.",
  },
  LIST_ALL_LEDGER_CONFIG_TEMPLATE_FOR_RECON_USER: {
    url: "/api/listAllLedgerConfigTemplateForReconUser",
    failureMessage: "Could not fetch ledger config templates for recon user.",
  },
  LIST_ALL_LEDGER_CONFIG_TEMPLATE: {
    url: "/api/ListAllLedgerConfigTemplate",
    failureMessage: "Could not fetch all ledger config templates.",
  },
  LIST_ALL_BUSINESS_PARTNERS: {
    url: "/api/ListAllBusinessPartners",
    failureMessage: "Could not fetch the list of business partners.",
  },
  LIST_OWN_LEDGER_CONFIG: {
    url: "/api/ListOwnLedgerConfig",
    failureMessage: "Could not fetch the own ledger configuration.",
  },
  LIST_BUSINESS_PARTNER_LEDGER_CONFIG: {
    url: "/api/ListBusinessPartnerLedgerConfig",
    failureMessage: "Could not fetch the business partner ledger configuration.",
  },
  CREATE_TICKET: {
    url: "/api/tickets/CreateTicket",
    failureMessage: "Could not create the ticket.",
  },
  UPLOAD_LEDGER_V2: {
    url: "/api/manualUpload/v2/uploadLedger",
    failureMessage: "Could not upload the ledger.",
  },
  REUPLOAD_LEDGER_V2: {
    url: "/api/manualUpload/v2/reuploadLedger",
    failureMessage: "Could not reupload the ledger.",
  },
  CANCEL_UPLOAD_REQUEST_V2: {
    url: "/api/manualUpload/v2/cancelUploadRequest",
    failureMessage: "Could not cancel the upload request.",
  },
  CANCEL_RECON: {
    url: "/api/ProcessRecon/CancelRecon",
    failureMessage: "Could not cancel the reconciliation process.",
  },
  CANCEL_POLLING_REQUEST_V2: {
    url: "/api/manualUpload/v2/cancelPollingRequest",
    failureMessage: "Could not cancel the polling request.",
  },
  UPLOAD_STATUS_V2: {
    url: "/api/manualUpload/v2/uploadStatus",
    failureMessage: "Could not fetch the upload status.",
  },
  GET_CLOSING_BALANCE: {
    url: "/api/manualUpload/v2/getClosingBalance",
    failureMessage: "Could not fetch the closing balance.",
  },
  UPLOAD_PDF_AS_EXCEL: {
    url: "/api/UploadPDFAsExcel",
    failureMessage: "Could not upload the PDF as Excel.",
  },
  FETCH_ERP_DATA: {
    url: "/api/integration/erp/fetchData",
    failureMessage: "Could not fetch ERP data.",
  },
  INITIATE_RECON: {
    url: "/api/ProcessRecon/InitiateRecon",
    failureMessage: "Could not initiate reconciliation.",
  },
  FETCH_RECON_STATUS: {
    url: "/api/ProcessRecon/FetchReconStatus",
    failureMessage: "Could not fetch the reconciliation status.",
  },
  GET_LAST_RECON_STATUS: {
    url: "/api/GetLastReconciliationStatus",
    failureMessage: "Could not fetch the last reconciliation status.",
  },
  RECON_META_DATA: {
    url: "/api/integration/recon/metaData",
    failureMessage: "Could not fetch the reconciliation metadata.",
  },
  GET_RECON_DETAILS_BP: {
    url: "/api/GetReconRelatedDetailsOfBusinessPartner",
    failureMessage: "Could not fetch the reconciliation details of the business partner.",
  },
  INSERT_BP_LEDGER_CONFIG: {
    url: "/api/InsertBusinessPartnerLedgerConfig",
    failureMessage: "Could not insert the business partner ledger configuration.",
  },
  INSERT_OWN_LEDGER_CONFIG: {
    url: "/api/InsertOwnLedgerConfig",
    failureMessage: "Could not insert the own ledger configuration.",
  },
  GET_UNACCOUNTED_MISMATCH_ENTRIES: {
    url: "/api/GetUnaccountedAndMisMatchEntries",
    failureMessage: "Could not fetch the unaccounted and mismatch entries.",
  },
  GET_SUMMARY_FOR_UI: {
    url: "/api/GetSummaryForUI",
    failureMessage: "Could not fetch the summary for the UI.",
  },
  GET_EXCEL_WORKBOOK: {
    url: "/api/GetExcelWorkbook",
    failureMessage: "Could not fetch the Excel workbook.",
  },
  GET_SUMMARY_FOR_RECONCILIATION: {
    url: "/api/GetSummaryForReconciliation",
    failureMessage: "Could not fetch the summary for reconciliation.",
  },
  UPDATE_CLOSING_BALANCES_RECON_RUN: {
    url: "/api/UpdateClosingBalancesOnReconRun",
    failureMessage: "Could not update the closing balances on reconciliation run.",
  },
  GENERATE_SUMMARY_EXCEL: {
    url: "/api/GenerateSummaryExcel",
    failureMessage: "Could not generate the summary Excel.",
  },
  GET_RECON_PERIOD: {
    url: "/api/GetReconPeriod",
    failureMessage: "Could not fetch the reconciliation period.",
  },
  POST_RECON_PERIOD: {
    url: "/api/PostReconPeriod",
    failureMessage: "Could not post the reconciliation period.",
  },
  UPDATE_CLOSING_BALANCE_RECON: {
    url: "/api/ProcessRecon/UpdateClosingBalance",
    failureMessage: "Could not update the closing balance for reconciliation.",
  },
  CHECKLIST_LIST: {
    url: "/api/checklist/list",
    failureMessage: "Could not fetch the checklist list.",
  },
  CHECKLIST_DELETE: (checklistCategory, selectedChecklistID) => ({
    url: `/api/checklist/delete/${checklistCategory}/${selectedChecklistID}`,
    failureMessage: "Could not delete the checklist.",
  }),
  CHECKLIST_ADD: {
    url: "/api/checklist/add",
    failureMessage: "Could not add the checklist.",
  },
  CHECKLIST_EDIT: (type, id) => ({
    url: `/api/checklist/edit/${type}/${id}`,
    failureMessage: "Could not edit the checklist.",
  }),
  CHECKLIST_RESET: {
    url: "/api/checklist/reset",
    failureMessage: "Could not reset the checklist.",
  },
  NOTES_LIST: {
    url: "/api/notes/list",
    failureMessage: "Could not fetch the list of notes.",
  },
  NOTES_ADD: {
    url: "/api/notes/add",
    failureMessage: "Could not add the note.",
  },
  NOTES_DELETE: (noteId) => ({
    url: `/api/notes/delete/${noteId}`,
    failureMessage: "Could not delete the note.",
  }),
  GET_RECON_LINK_INFO: {
    url: "/api/ProcessRecon/GetReconLinkInfo",
    failureMessage: "Could not fetch the reconciliation link info.",
  },
  LINK_RECON_TICKET: {
    url: "/api/ProcessRecon/LinkReconTicket",
    failureMessage: "Could not link the reconciliation ticket.",
  },
  DELETE_CURRENT_LINK: {
    url: "/api/ProcessRecon/DeleteCurrentLink",
    failureMessage: "Could not delete the current reconciliation link.",
  },
  GET_PRE_RECON_LEDGER_SCORE: {
    url: "/api/ProcessRecon/GetPreReconLedgerScore",
    failureMessage: "Could not fetch the pre-reconciliation ledger score.",
  },
  GET_UPLOADED_FILES: {
    url: "/api/manualUpload/v2/getUploadedFiles",
    failureMessage: "Could not fetch the uploaded files.",
  },
  DOWNLOAD_LEDGERS: {
    url: "/api/manualUpload/v2/downloadLedgers",
    failureMessage: "Could not download the ledgers.",
  },
  POLL_DOWNLOAD_LEDGERS_STATUS: {
    url: "/api/manualUpload/v2/pollDownloadLedgersStatus",
    failureMessage: "Could not poll the download ledgers status.",
  },
  CREATE_AND_FETCH_MANUAL_RECON_SUGGESTIONS: {
    url: "/api/recon/manual/CreateAndFetchManualReconSuggestions",
    failureMessage: "Could not create and fetch manual reconciliation suggestions.",
  },
  UPLOAD_RECON_REPORT: {
    url: "/api/uploadReconReport",
    failureMessage: "Could not upload the reconciliation report.",
  },
  GET_CLOSING_BALANCE_BREAKDOWN: {
    url: "/api/GetClosingBalanceBreakdown",
    failureMessage: "Could not fetch the closing balance breakdown.",
  },
  GET_MISSING_ENTRY_NUMBERS: {
    url: "/api/GetMissingEntryNumbers",
    failureMessage: "Could not fetch the missing entry numbers.",
  },
  UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_RU: {
    url: "/api/uploadBusinessPartnerLedgerFromRU",
    failureMessage: "Could not upload the business partner ledger from RU.",
  },
  UPLOAD_BUSINESS_PARTNER_LEDGER_FROM_BP: {
    url: "/api/uploadBusinessPartnerLedgerFromBusinessPartner",
    failureMessage: "Could not upload the business partner ledger from Business Partner.",
  },
  MARK_PDF_INCORRECT: {
    url: "/api/markPdfIncorrect",
    failureMessage: "Could not mark the PDF as incorrect.",
  },
  ADD_RU_CONFIG_TEMPLATE: {
    url: "/api/AddRuConfigTemplate",
    failureMessage: "Could not add the RU config template.",
  },
  CREATE_TEMPLATE_FOR_REUSE_CONFIG: {
    url: "/api/CreateTemplateForReuseConfig",
    failureMessage: "Could not create the template for reuse config.",
  },
  UPDATE_BUSINESS_PARTNER_CONFIG_TEMPLATE: {
    url: "/api/UpdateBusinessPartnerConfigTemplate",
    failureMessage: "Could not update the business partner config template.",
  },
  INTEGRATION_CONFIG_COLUMN_HEADERS: {
    url: "/api/integration/config/columnHeaders",
    failureMessage: "Could not fetch the integration config column headers.",
  },
  UPDATE_TDS_PERCENTAGE: {
    url: "/api/UpdateTdsPercentage",
    failureMessage: "Could not update TDS percentage.",
  },
  INSERT_DEFAULT_TEMPLATE: {
    url: "/api/InsertDefaultTemplate",
    failureMessage: "Could not insert the default template.",
  },
  ANNOTATE: {
    url: "/api/annotate",
    failureMessage: "Could not annotate the data.",
  },
  RETURN_LEDGER_DATA_AS_EXCEL: {
    url: "/api/ReturnLedgerDataAsExcel",
    failureMessage: "Could not return ledger data as Excel.",
  },
  SEND_RECON_RESULT_THROUGH_EMAIL: {
    url: "/api/SendReconResultThroughEmail",
    failureMessage: "Could not send reconciliation result through email.",
  },
  PROCESS_RECON_GET_CURRENT_ACCOUNTING_BALANCE: {
    url: "/api/ProcessRecon/GetCurrentAccountingBalance",
    failureMessage: "Could not fetch the current accounting balance. Please try again.",
  },

  ADD_BP_USER: {
    url: "/api/AddBPUser",
    failureMessage: "Could not add business partner user.",
  },
  LIST_ALL_BP_CONTACTS: {
    url: "/api/ListAllBPContacts",
    failureMessage: "Could not fetch the list of business partner contacts.",
  },
  GET_EMAIL_CREDENTIALS_AUTHENTICATED: {
    url: "/api/partnerCommunication/GetEmailCredentialsAuthenticated",
    failureMessage: "Could not verify email credentials authentication.",
  },
  PARTNER_MASTER_ADD_BUSINESS_PARTNER_IN_BULK: {
    url: "/api/PartnerMaster/AddBusinessPartnerInBulk",
    failureMessage: "Could not add business partners in bulk.",
  },
  PARTNER_MASTER_ADD_BUSINESS_PARTNER: {
    url: "/api/PartnerMaster/AddBusinessPartner",
    failureMessage: "Could not add business partner.",
  },
  PARTNER_MASTER_ADD_PARTNER_EXCEL_TEMPLATE: {
    url: "/api/PartnerMaster/AddPartnerExcelTemplate",
    failureMessage: "Could not add partner from Excel template.",
  },
  GET_BUSINESS_PARTNER_DETAILS: {
    url: "/api/GetBusinessPartnerDetails",
    failureMessage: "Could not fetch business partner details.",
  },
  PARTNER_MASTER_UPDATE_BUSINESS_PARTNER_DETAILS: {
    url: "/api/PartnerMaster/UpdateBusinessPartnerDetails",
    failureMessage: "Could not update business partner details.",
  },
  PARTNER_MASTER_DELETE_BUSINESS_PARTNER: {
    url: "/api/PartnerMaster/DeleteBusinessPartner",
    failureMessage: "Could not delete business partner.",
  },

  // DisputeResolution
  UPDATE_TICKET_META_STATUS: {
    url: "/api/tickets/UpdateTicketMetaStatus",
    failureMessage: "Could not update ticket meta status.",
  },

  DISPUTE_RESOLUTION_EMAIL_SEND_BULK: {
    url: "/api/DisputeResolutionEmail/SendBulkEmail",
    failureMessage: "Could not send bulk dispute resolution email.",
  },
  DISPUTE_RESOLUTION_EMAIL_VIEW_THREAD: {
    url: "/api/DisputeResolutionEmail/ViewEmailThread",
    failureMessage: "Could not view the dispute resolution email thread.",
  },
  DISPUTE_RESOLUTION_EMAIL_SEND: {
    url: "/api/DisputeResolutionEmail/SendEmail",
    failureMessage: "Could not send the dispute resolution email.",
  },
  DISPUTE_RESOLUTION_EMAIL_GET_ATTACHMENTS_ZIP: {
    url: "/api/DisputeResolutionEmail/GetAttachmentsZip",
    failureMessage: "Could not fetch the dispute resolution email attachments zip.",
  },
  TICKETS_UPDATE_INVOICE_DETAILS_FOR_SINGLE_TASK: {
    url: "/api/tickets/UpdateInvoiceDetailsForSingleTask",
    failureMessage: "Could not update invoice details for the single task.",
  },
  TICKETS_UPDATE_INVOICE_DETAILS: {
    url: "/api/tickets/UpdateInvoiceDetails",
    failureMessage: "Could not update invoice details.",
  },

  // LedgerPreProcessing
  LEDGER_PREPROCESSING: {
    url: "/api/Ledger-Preprocessing/LedgerPreProcessing",
    failureMessage: "Could not preprocess the ledger.",
  },
  GET_LEDGER_PREPROCESSING_JOB_STATUS: {
    url: "/api/Ledger-Preprocessing/GetLedgerPreProcessingJobStatus",
    failureMessage: "Could not fetch ledger preprocessing job status.",
  },
  DOWNLOAD_LEDGER_PROCESSED_FILE: {
    url: "/api/Ledger-Preprocessing/DownloadLedgerProcessedFile",
    failureMessage: "Could not download the processed ledger file.",
  },

  // MsmePartnerPortal
  PARTNER_PORTAL_GET_MSME_DETAILS_STATUS: {
    url: "/api/partnerPortal/GetMsmeDetailsStatus",
    failureMessage: "Could not fetch data.",
  },
  PARTNER_PORTAL_GET_PARTNER_NAME_AND_LOGO: {
    url: "/api/partnerPortal/GetPartnerNameAndLogo",
    failureMessage: "Could not fetch data.",
  },
  PARTNER_PORTAL_GET_DETAILS_AND_UPLOADED_FILES: {
    url: "/api/partnerPortal/GetDetailsAndUploadedFiles",
    failureMessage: "Could not fetch data.",
  },
  PARTNER_PORTAL_GET_DETAILS_FOR_FINAL_PAGE: {
    url: "/api/partnerPortal/GetDetailsForFinalPage",
    failureMessage: "Could not fetch details. Please try again.",
  },

  // Manual Recon
  RECON_MANUAL_GET_ENTRIES: {
    url: "/api/recon/manual/GetEntries",
    failureMessage: "Could not fetch manual reconciliation entries.",
  },
  RECON_MANUAL_UNRECONCILE: {
    url: "/api/recon/manual/Unreconcile",
    failureMessage: "Could not perform manual unreconciliation.",
  },
  RECON_MANUAL_RECONCILE: {
    url: "/api/recon/manual/Reconcile",
    failureMessage: "Could not perform manual reconciliation.",
  },
  RECON_MANUAL_CREATE_FETCH_SUGGESTIONS: {
    url: "/api/recon/manual/CreateAndFetchManualReconSuggestions",
    failureMessage: "Could not create and fetch manual reconciliation suggestions.",
  },
  RECON_MANUAL_RECONCILE_SUGGESTED_MATCH: {
    url: "/api/recon/manual/ReconcileSuggestedMatch",
    failureMessage: "Could not reconcile the suggested match.",
  },
  RECON_MANUAL_GET_HISTORY: {
    url: "/api/recon/manual/GetHistory",
    failureMessage: "Could not fetch manual reconciliation history.",
  },
  RECON_MANUAL_REAPPLY_PREVIOUS_CHANGES: {
    url: "/api/recon/manual/ReapplyPreviousChanges",
    failureMessage: "Could not reapply previous changes.",
  },
  RECON_MANUAL_REVERT_CHANGE: {
    url: "/api/recon/manual/RevertChange",
    failureMessage: "Could not revert the changes in manual reconciliation.",
  },
  RECON_MANUAL_GET_POSSIBLE_STATUSES: {
    url: "/api/recon/manual/GetPossibleReconStatuses",
    failureMessage: "Could not fetch possible reconciliation statuses.",
  },
  RECON_MANUAL_UPDATE_STATUS: {
    url: "/api/recon/manual/UpdateStatus",
    failureMessage: "Could not update the reconciliation status.",
  },
  RECON_MANUAL_DELETE_NOT_APPLIED_HISTORY: {
    url: "/api/recon/manual/DeleteNotAppliedHistory",
    failureMessage: "Could not delete the not applied reconciliation history.",
  },

  // setting
  // UserEmailSetting
  UPDATE_USER_EMAIL_SETTING: {
    url: "/api/UpdateUserEmailSetting",
    failureMessage: "Could not update the user email settings.",
  },
  UPLOAD_SIGNATURE_IMAGE: {
    url: "/api/UploadSignatureImage",
    failureMessage: "Could not upload the signature image.",
  },
  ADD_CLIENT_OTHER_EMAIL_IDS: {
    url: "/api/partnerCommunication/AddClientOtherEmailIds",
    failureMessage: "Could not add the client other email IDs.",
  },
  GET_CLIENT_OTHER_EMAIL_IDS: {
    url: "/api/partnerCommunication/GetClientOtherEmailIds",
    failureMessage: "Could not fetch the client other email IDs.",
  },

  WORKFLOW_CUSTOMIZATION_DATA: {
    url: "/api/Workflow/Customisation/Status/CustomizationData",
    failureMessage: "Could not fetch workflow customization data.",
  },
  GET_ALL_WORKFLOW_STATUS: {
    url: "/api/Workflow/Customisation/Status",
    failureMessage: "Could not fetch workflow status.",
  },
  ADD_NEW_WORKFLOW_STATUS: {
    url: "/api/Workflow/Customisation/Status",
    failureMessage: "Could not add new workflow status.",
  },
  UPDATE_WORKFLOW_STATUS: (statusId: number) => ({
    url: `/api/Workflow/Customisation/Status/${statusId}`,
    failureMessage: "Could not update workflow status.",
  }),
  DELETE_WORKFLOW_STATUS: (statusId: number) => ({
    url: `/api/Workflow/Customisation/Status/${statusId}`,
    failureMessage: "Could not delete workflow status.",
  }),
  TOGGLE_WORKFLOW_STATUS: (statusId: number) => ({
    url: `/api/Workflow/Customisation/Status/${statusId}/toggle`,
    failureMessage: "Could not toggle workflow status.",
  }),
  SAVE_CUSTOMISED_REPORT_COLUMNS: {
    url: "/api/tickets/SaveCustomisedTaskRepoColumns",
    failureMessage: "Could not save customised report columns.",
  },
  GET_CUSTOMISED_REPORT_COLUMNS: {
    url: "/api/tickets/CustomiseTaskRepoColumns",
    failureMessage: "Could not get customised report columns.",
  },
  GET_ALL_CUSTOM_FIELDS: {
    url: "/api/Workflow/Customisation/Field",
    failureMessage: "Could not get custom field",
  },
  TOGGLE_CUSTOM_FIELD_STATUS: (fieldId: number) => ({
    url: `/api/Workflow/Customisation/Field/${fieldId}/toggle`,
    failureMessage: "Could not toggle custom field status",
  }),
  ADD_NEW_CUSTOM_FIELD: {
    url: "/api/Workflow/Customisation/Field",
    failureMessage: "Could not add new custom field",
  },
  UPDATE_CUSTOM_FIELD: (fieldId: number) => ({
    url: `/api/Workflow/Customisation/Field/${fieldId}`,
    failureMessage: "Could not update custom field",
  }),
  GET_ALL_VIEWS: {
    url: "/api/tickets/ListAllUserViews",
    failureMessage: "Could not get all views.",
  },
  DELETE_TABLE_VIEW: (viewId: number) => ({
    url: `/api/tickets/DeleteViewDetails/${viewId}`,
    failureMessage: "Could not delete table view.",
  }),
  SAVE_TABLE_VIEW: {
    url: "/api/tickets/CreateViewDetails",
    failureMessage: "Could not save table view",
  },
  UPDATE_TABLE_VIEW: {
    url: "/api/tickets/UpdateViewDetails",
    failureMessage: "Could not update table view.",
  },
  SAVE_LAST_SELECTED_VIEW: {
    url: "/api/tickets/UpdateLastSelectedView",
    failureMessage: "Could not save last selected view.",
  },
} as const;
