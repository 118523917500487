import { ContentPaste } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Loader from "../../Common/Loader";

const ReconRuleConfigurations = () => {
  const { actor } = useContext(userContext);
  const [loader, setLoader] = useState<boolean>(true);
  const [showLoadingIcon, setShowLoadingIcon] = useState(false);

  // Define GST and TDS values
  const gstValues = [0, 5, 12, 18, 28] as const;
  const tdsValues = [0.1, 1, 2, 5, 10] as const;
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [cellToggles, setCellToggles] = useState([]);
  const [inputError, setInputError] = useState(false);
  const [allCombinations, setAllCombinations] = useState([]);
  const [tdsArr, setTdsArr] = useState<{ value: any }[]>([]);

  useEffect(() => {
    getAllCombinations();
    getDefaultTDSForAccount();
    // eslint-disable-next-line
    }, []);

  const getDefaultTDSForAccount = () => {
    useFetch(API_ENDPOINTS.GET_DEFAULT_TDS_FOR_ACCOUNT.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DEFAULT_TDS_FOR_ACCOUNT.failureMessage,
      config: {
        params: {
          ownId: actor.id,
        },
      },
      thenCallBack: (response) => {
        setTdsArr(response.data.data?.defaultTds || []);
        updateCellToggles(removeDuplicates(response.data.data?.defaultTds || []));
        setLoader(false);
      },
      catchCallBack: () => {
        setLoader(false);
      },
    });
  };

  const setDefaultTDSForAccount = () => {
    setShowLoadingIcon(true);
    const tdsArrFilt = getUniqueList(tdsArr)?.filter((item: any) => {
      return item.value.toString().trim() !== "";
    });

    const convertapplicableTDSPercentagesToString = tdsArrFilt.map((item: any) => {
      return `${item.value}%`;
    });
    useFetch(API_ENDPOINTS.SET_DEFAULT_TDS_FOR_ACCOUNT.url, "POST", {
      failureMessage: API_ENDPOINTS.SET_DEFAULT_TDS_FOR_ACCOUNT.failureMessage,
      showSuccessToast: true,
      data: { ownId: actor.id, tdsPercentage: convertapplicableTDSPercentagesToString?.toString() },
      thenCallBack: () => {
        setShowLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowLoadingIcon(false);
      },
    });
  };
  const getUniqueList = (originalList: { value: string }[]) => {
    const replacedList = originalList?.map((obj) => ({
      value: parseFloat(obj?.value) === 0 || obj?.value === "" ? 0.1 : parseFloat(obj?.value),
    }));

    const uniqueList = replacedList?.filter(
      (obj, index) => replacedList?.findIndex((o) => o.value === obj.value) === index
    );

    return uniqueList;
  };

  const getAllCombinations = () => {
    const combinationsArr = gstValues.flatMap((gst) =>
      tdsValues.map((tds) => ({
        gst,
        tds,
        combination: (tds / (1 + gst / 100)).toFixed(5),
      }))
    );

    setAllCombinations(combinationsArr);
  };

  // Modify the toggleCell function to update tdsArr based on the selected combination values
  const toggleCell = (rowIndex, colIndex) => {
    const newCellToggles = [...cellToggles];
    newCellToggles[rowIndex][colIndex] = !newCellToggles[rowIndex][colIndex];

    const selectedGST = gstValues[rowIndex];
    const selectedTDS = tdsValues[colIndex];
    const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

    if (newCellToggles[rowIndex][colIndex]) {
      // If the cell is toggled (checked), add the combination value to tdsArr
      const newTdsArr = [...tdsArr, { value: combination }];
      setTdsArr(newTdsArr);
    } else {
      // If the cell is unselected (unchecked), remove the corresponding combination value from tdsArr
      const newTdsArr = tdsArr.filter((item) => Number(item.value) !== Number(combination));
      setTdsArr(newTdsArr);
    }

    setCellToggles(newCellToggles);
  };

  const handleCheckboxChange = () => {
    setShowInput(!showInput);
  };

  const handleInputBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    if (e.target.value.trim().length < 1) return;
    const newValue = e.target.value.split(",");
    const outputValues: number[] = [];
    let inpError = false;

    newValue.forEach((value) => {
      const floatVal = parseFloat(value);
      if (floatVal >= 0 && floatVal <= 100) {
        outputValues.push(floatVal);
      } else inpError = true;
    });

    setInputError(inpError);
    if (!inpError) setInputValue(outputValues.join(", "));
  };
  const handleAddTag = () => {
    let uniqArr: { value: string }[] = [...tdsArr];

    inputValue.split(" ").forEach((val) => {
      if (val.trim() !== "") {
        const numericValue = parseFloat(val);
        if (numericValue >= 0 && numericValue <= 100) {
          const stringValue = numericValue.toString(); // Convert to a fixed-format string
          uniqArr = removeDuplicates([...uniqArr, { value: stringValue }]);

          // Check if stringValue exists in allCombinations
          const existsInCombinations = allCombinations.some(
            (combination) => Number(combination.combination) === Number(stringValue)
          );

          // Check if stringValue already exists in tdsArr
          const existsInTdsArr = tdsArr.some((tds) => Number(tds.value) === Number(stringValue));
          if (existsInTdsArr) {
            toast.warning(<CustomToast message="TDS % already selected" />);
          } else if (existsInCombinations) {
            toast.success(<CustomToast message="TDS % Value present in existing combinations selected" />);
          }
          // setShowInput(false);
        } else {
          alert("Please enter a number between 0 and 100.");
        }
      }
    });
    setTdsArr(Array.from(uniqArr));
    setInputValue("");
    // Update cellToggles after adding the tag
    updateCellToggles(uniqArr);
  };
  const handleDeleteTag = (value) => {
    const updatedTdsArr = tdsArr.filter((tds) => tds.value !== value);
    const deduplicatedArr = removeDuplicates(updatedTdsArr);
    setTdsArr(deduplicatedArr);
    updateCellToggles(deduplicatedArr);
  };

  const removeDuplicates = (arr: { value: string }[]) => {
    const uniqueValuesMap = new Map();
    const uniqueValuesArray: { value: string }[] = [];

    arr.forEach((tag) => {
      const numericValue = parseFloat(tag.value);

      if (!isNaN(numericValue)) {
        const stringValue = numericValue.toFixed(5).toString();

        if (!uniqueValuesMap.has(stringValue)) {
          uniqueValuesMap.set(stringValue, true);
          uniqueValuesArray.push(tag);
        }
      }
    });

    return uniqueValuesArray;
  };

  // Function to update cellToggles based on tdsArr and combination
  const updateCellToggles = (arr) => {
    const rows = gstValues.length;
    const cols = tdsValues.length;

    const updatedCellToggles = Array.from({ length: rows }, (_, rowIndex) =>
      Array.from({ length: cols }, (__, colIndex) => {
        const selectedGST = gstValues[rowIndex];
        const selectedTDS = tdsValues[colIndex];
        const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

        // Check if the combination value exists in tdsArr

        return arr.some((item) => Number(item.value) === Number(combination));
      })
    );

    setCellToggles(updatedCellToggles);
  };
  return (
    <div>
      {loader ? (
        <Loader />
      ) : (
        <Grid sx={{ mt: 2 }}>
          <Paper>
            <Typography variant="h6" sx={{ p: 2, borderBottom: "1px solid #DADADA" }}>
              TDS/TCS Percentage
            </Typography>
            <Box sx={{ pt: 2, pb: 2, borderBottom: "1px solid #DADADA" }}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={12} sx={{ pt: 0, m: "0 20px" }}>
                  <table className="tds_gst_table mt_10">
                    <thead>
                      <tr>
                        <th className="text_center" colSpan={tdsValues.length + 2}>
                          TDS
                        </th>
                      </tr>
                      <tr>
                        <th />
                        <th />
                        {tdsValues.map((tds, index) => (
                          <th key={index} className="text_center">
                            {tds}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {gstValues.map((gst, rowIndex) => (
                        <tr key={rowIndex}>
                          {rowIndex === 0 ? (
                            <th
                              rowSpan={gstValues.length}
                              className="text_center"
                              style={{
                                verticalAlign: "middle",
                                borderTop: "1px solid #53526c",
                                borderRight: "1px solid #53526c",
                                borderBottom: "none",
                                borderBottomLeftRadius: 12,
                              }}
                            >
                              GST
                            </th>
                          ) : null}
                          <th className="text_center" style={{ borderBottomLeftRadius: 0 }}>
                            {gst}
                          </th>
                          {tdsValues.map((tds, colIndex) => {
                            const selectedGST = gstValues[rowIndex];
                            const selectedTDS = tdsValues[colIndex];
                            const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

                            return (
                              <td
                                key={`${colIndex}-${tds}`}
                                className={
                                  cellToggles?.[rowIndex]?.[colIndex]
                                    ? "toggled text_center cursor_pointer"
                                    : "text_center cursor_pointer"
                                }
                                onClick={() => toggleCell(rowIndex, colIndex)}
                              >
                                {cellToggles?.[rowIndex]?.[colIndex] ? (
                                  <div className="check" title={`${combination}%`} style={{ width: "30px" }} />
                                ) : (
                                  <div
                                    className="check unchecked"
                                    title={`${combination}%`}
                                    style={{ width: "30px" }}
                                  />
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
                <Grid item={true} xs={12} sm={12} className="ml_20">
                  <div className="mt_20">
                    <FormControlLabel
                      control={<Checkbox checked={showInput} onChange={handleCheckboxChange} />}
                      label="Add TDS % Manually (if it doesn't fit in the table)"
                      sx={{ "& > span": { fontWeight: 600 } }}
                    />
                    {/* <Checkbox onChange={handleCheckboxChange} /> */}
                    {/* <label className="fw_600">Add TDS % Manually (if it doesn't fit in the table)</label> */}
                  </div>
                  {showInput && (
                    <div className="d_flex gap_10" style={{ alignItems: "flex-start" }}>
                      <TextField
                        error={inputError}
                        variant="outlined"
                        style={{ minWidth: "50%" }}
                        label="TDS/TCS Percentage"
                        name="value"
                        value={inputValue}
                        type="number"
                        multiline={true}
                        maxRows={4}
                        onChange={(e) =>
                          setInputValue(
                            e.target.value
                              .replace(/[^0-9.,\s]*/g, "") // non digits, dots or spaces
                              .replace(/(\.){2,}/g, ".") // decimal points
                          )
                        }
                        onBlur={handleInputBlur}
                        placeholder="Between 0 - 100, comma separated values"
                        helperText={inputError ? "Please Enter Correct Values." : ""}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="start">
                              <Typography mx={1}>%</Typography>
                              <IconButton
                                edge="end"
                                title="Paste from clipboard"
                                onClick={async () => {
                                  const str = await navigator?.clipboard?.readText();
                                  setInputValue(str.replace(/[^0-9.\s]*/g, ""));
                                }}
                              >
                                <ContentPaste />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <IconButton disabled={inputError} color="primary" onClick={handleAddTag} sx={{ top: 8 }}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  <div className="tag-container mt_20">
                    {tdsArr
                      .filter(
                        (tds) =>
                          !allCombinations.some((combination) => Number(combination.combination) === Number(tds.value))
                      )
                      .map((tds, index) => (
                        <Chip
                          key={index}
                          label={tds.value}
                          onDelete={() => handleDeleteTag(tds.value)}
                          color="primary"
                          variant="outlined"
                          style={{ marginRight: "8px", marginBottom: "8px" }}
                        />
                      ))}
                  </div>
                </Grid>
              </Grid>
            </Box>
            <Grid sx={{ p: 2 }} className="right_align">
              <Button
                className={showLoadingIcon ? "grey_btn" : "green_btn"}
                sx={{ width: "140px" }}
                onClick={setDefaultTDSForAccount}
                startIcon={<LoadingIcon loading={showLoadingIcon} />}
                disabled={showLoadingIcon}
              >
                Save
              </Button>
            </Grid>
          </Paper>
        </Grid>
      )}
    </div>
  );
};

export default ReconRuleConfigurations;
