import { PlayCircleOutline } from "@mui/icons-material";
import { Alert, AlertColor, AlertTitle, Box, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import { CounterState } from "src/slices/partnerCommunication/bulkMailsSlice";
import { RequireAtLeastOne } from "../../CommonLegacy/CommonComponents";
import { NdButton, NdButtonOutlined } from "../../MsmePartnerPortal/CommonComponents";
import BcNewContext from "../Components/BcNewContext";
import ContainerSvg from "/src/Graphics/Container.svg";

export const NoCommunication = () => {
  const { setOpenBcNewMailing, isSyncingMailStatus } = useContext(BcNewContext);
  const { isSendingBulkMailsBCB } = useSelector((state: any) => state.bulkMailsStatus as CounterState);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = isSendingBulkMailsBCB || isSyncingMailStatus;

  return (
    <Stack className="center_align_ver_horiz" m={"auto"} sx={{ p: 5 }}>
      <img src={ContainerSvg} alt="Graphic" width={300} height={300} />
      <Typography variant="h6" gutterBottom>
        You haven't started any communication yet.
      </Typography>
      <Typography variant="body1" color="textSecondary">
        Click the button below to get started!
      </Typography>
      <Box className="d_flex" mx={"auto"} mt={3} gap={2}>
        <NdButtonOutlined variant="outlined" startIcon={<PlayCircleOutline />}>
          DEMO
        </NdButtonOutlined>
        <NdButton
          variant="contained"
          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
          onClick={() => setOpenBcNewMailing(true)}
        >
          Send a new mail
        </NdButton>
      </Box>
    </Stack>
  );
};

interface ColorToggleButtonProps<T = string> {
  allQFValues: T[];
  setSelectedQF: StateDispatch<T>;
}

export function ColorToggleButton<T extends string>({ allQFValues, setSelectedQF }: ColorToggleButtonProps<T>) {
  const [value, setValue] = useState<string | T>("all");

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue === null) return;
    setValue(newValue);
    setSelectedQF(newValue as T);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={value}
      exclusive
      onChange={handleChange}
      sx={{
        gap: 1,
        px: 2,
        py: 1.5,
        "& .MuiToggleButton-root": {
          borderRadius: "100px !important",
          padding: "4px 12px",
          borderLeft: "1px solid #0002 !important",
          textTransform: "Capitalize",

          "&.Mui-selected": {
            background: "#EEE8ED",
            color: "#531c4c",

            "&:hover": {
              background: "#EEE8F4",
            },
          },
        },
      }}
    >
      {allQFValues.map((value: string) => (
        <ToggleButton key={value} value={value}>
          {value}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

export type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

type CustomSeverity = "mediumpurple" | "lightgray";

interface CommonCustomBulkActionAlertProps {
  alertTitle: string;
  alertText: string;
  severity: AlertColor | CustomSeverity;
  icon: React.ReactNode;
}

interface ActionNamePropsAndFnProps {
  actionName: string;
  actionFn: () => void;
}

interface ActionNodeProps {
  actionNode: React.ReactNode;
}

type CustomBulkActionAlert = CommonCustomBulkActionAlertProps &
  Partial<ActionNamePropsAndFnProps> &
  Partial<ActionNodeProps>;

type CustomBulkActionAlertProps = RequireAtLeastOne<CustomBulkActionAlert, "actionName" | "actionNode">;

// interface CustomBulkActionAlertProps extends CommonCustomBulkActionAlertProps {}

export const CustomBulkActionAlert = ({
  alertTitle,
  alertText,
  severity,
  icon,
  actionName,
  actionFn,
  actionNode,
}: CustomBulkActionAlertProps) => {
  const CustomSeverities = ["mediumpurple", "lightgray"] as const;
  const isCustomSeverity = CustomSeverities.includes(severity as any);
  const realSeverity = CustomSeverities.includes(severity as any) ? "info" : (severity as AlertColor);

  const customColorMap: Record<CustomSeverity, { mainBg: string; iconBg: string }> = {
    mediumpurple: {
      mainBg: "#EDE7F6",
      iconBg: "#7E57C2",
    },
    lightgray: {
      mainBg: "#ECEFF1",
      iconBg: "#37474F",
    },
  } as const;

  return (
    <Alert
      iconMapping={{
        [realSeverity]: (
          <Box
            sx={{
              height: 48,
              width: 48,
              p: "12px",
              background: (theme) =>
                isCustomSeverity ? customColorMap?.[severity]?.iconBg : theme.palette?.[severity]?.main,
              borderRadius: "5px",
            }}
          >
            {icon}
          </Box>
        ),
      }}
      action={
        actionNode || (
          <NdButtonOutlined
            color="inherit"
            variant="outlined"
            sx={{ my: "auto", background: "transparent" }}
            disabled={!actionFn}
            onClick={actionFn}
          >
            {actionName}
          </NdButtonOutlined>
        )
      }
      severity={realSeverity}
      sx={{
        alignItems: "center",
        "& .MuiAlert-action": { mr: 0 },
        border: "1px solid rgba(0, 0, 0, 0.12)",
        "& *": { fontFamily: "inherit" },
        background: (_theme) => (isCustomSeverity ? customColorMap?.[severity]?.mainBg : "auto"),
      }}
    >
      <AlertTitle>{alertTitle}</AlertTitle>
      {alertText}
    </Alert>
  );
};

interface TabPanelProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  children?: React.ReactNode;
  index: number;
  value: number;
  transition?: boolean;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      className={props.transition && value === index ? "fade_up_t" : ""}
      {...other}
    >
      {children}
    </div>
  );
};

export const QuickFilterRows = (originalRows: BusinessPartnerListForBcBeta[], selectedQF: ReceivedQF) => {
  const filterOn = selectedQF === "Issue Raised" ? "issueRaised" : "statusOverview";

  if (filterOn === "issueRaised") {
    return originalRows.filter((row) => (selectedQF === "Issue Raised" ? row.issueRaised !== "-" : true));
  }

  return originalRows.filter((row) =>
    selectedQF === "Balance Confirmed"
      ? row.statusOverview === "Confirmed"
      : selectedQF === "Balance not Confirmed"
      ? row.statusOverview === "Not Confirmed"
      : true
  );
};

export type ReceivedQF = "all" | "Balance Confirmed" | "Balance not Confirmed" | "Issue Raised";

export const GetQuickFilterOptions = (rows: BusinessPartnerListForBcBeta[]) => {
  const options = ["all"] as Array<ReceivedQF>;
  const balanceConfirmed = rows.filter((row) => row.statusOverview === "Confirmed");
  if (balanceConfirmed.length) options.push("Balance Confirmed");
  const balanceNotConfirmed = rows.filter((row) => row.statusOverview === "Not Confirmed");
  if (balanceNotConfirmed.length) options.push("Balance not Confirmed");
  options.push("Issue Raised");
  return options;
};

export type keys = "company" | "branch" | "partnerNames" | "category" | "status" | "vendorCodes" | "taskLabel";

export enum FilterKeysNameMap {
  "Company" = "company",
  "Branch" = "branch",
  "Partner Names" = "partnerNames",
  "Selected Category" = "category",
  "Selected Status" = "status",
  "Vendor Codes" = "vendorCodes",
  "Task Label" = "taskLabel",
}
