import AddIcon from "@mui/icons-material/Add";
import { Button, MenuItem, Select } from "@mui/material";
import { FormControl, FormHelperText, InputAdornment, Modal, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import BusinessDialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { AxiosError } from "axios";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ConfigTemplateType } from "../../../entity/recon-entity/ReconInterfaces";
import { Currencies } from "../../../Utils/Common/Constants";
import { DefaultCurrency } from "../../../Utils/MoneyUtils";
import { userContext } from "../../Contexts/userContext";
import PartialFieldUpdateDialog from "../PartnerMaster/PartialFieldUpdateDialog";
import "./AddNewBusinessPartner.scss";
import TabPanel from "./TabPanel";

export interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

const options = [
  {
    value: "Customer",
    label: "Customer",
  },
  {
    value: "Vendor",
    label: "Vendor",
  },
];

type Base64String = string & NonNullable<unknown>;

interface BusinessPartnerInBulkRes {
  response: string;
  message: string;
  errorFile: Base64String;
  updatableColumns: string[];
}
interface AddNewBPProps {
  from: string;
  className?: string;
  listAllOwnBusinessPartnerMapping?: () => void;
  getPartnerUserBulkUploadExcelTemplate?: () => void;
  listAllBusinessPartnersForPartnerUseCaseMaster?: () => void;
  listAllBusinessPartnersForPartnerUserMaster?: () => void;
  component?: JSX.Element; // component to use as root element
  onClick?: (f: () => void) => void; // onClick callback
}

const AddNewBusinessPartner = (props: AddNewBPProps) => {
  const { actor } = useContext(userContext);
  const [openBusinessPopup, setOpenBusinessPopup] = useState(false);
  const [tabvalue, setTabValue] = useState(0);
  const [configList, setConfigList] = useState<any[]>([]);
  const [selectedAccountingSoftware, setSelectedAccountingSoftware] = useState<any>();
  // const [otherAccountingSoftware, setOtherAccountingSoftware] = useState<string>("");
  const [selectedRelationship, setSelectedRelationship] = useState<any>();
  const [companyName, setCompanyName] = useState<string | null>("");
  const [partnerNickname, setPartnerNickname] = useState<string>("");
  const [companyGstNumber, setCompanyGstNumber] = useState<any>("");

  const [errSelectedRelationship, setErrSelectedRelationship] = useState<boolean>(false);
  const [errCompanyName, setErrCompanyName] = useState<boolean>(false);
  const [errorPartnerNickname, setErrorPartnerNickname] = useState<boolean>(false);
  const [errCompanyGstNumber, setErrCompanyGstNumber] = useState<boolean>(false);
  const [errPinCode, setErrPinCode] = useState<boolean>(false);
  const [pinCode, setPinCode] = useState<string>("");

  const [errorBulkUpload, setErrorBulkUpload] = useState<boolean>(false);
  const [errorBulkUploadMsg, setErrorBulkUploadMsg] = useState<string>("");
  const [errorBulkUploadDownload, setErrorBulkUploadDownload] = useState<string>(null);

  const [vendorCode, setVendorCode] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [pocName, setPocName] = useState<string>("");
  const [pocEmail, setPocEmail] = useState<string>("");
  const [invalidEmails, setInvalidEmails] = useState([]);
  const [pocPhoneNumber, setPocPhoneNumber] = useState<string>("");
  const [invalidPhoneNumbers, setInvalidPhoneNumbers] = useState([]);
  const [showLoadingIconSubmit, setShowLoadingIconSubmit] = useState<boolean>(false);
  const [showLoadingIconUpload, setShowLoadingIconUpload] = useState<boolean>(false);
  const element: React.MutableRefObject<HTMLDivElement> = useRef(null);
  const [maxHeight, setMaxHeight] = useState(0);
  const [currency, setCurrency] = useState<string>(DefaultCurrency.INR);
  const selectedFile = useRef<File>(null);
  const [openFieldUpdate, setOpenFieldUpdate] = useState(false);
  const [columns, setColumns] = useState<string[]>([]);
  const [getAddPartnerExcelTemplateLoading, setGetAddPartnerExcelTemplateLoading] = useState<boolean>(false);

  // Define GST and TDS values
  const gstValues = [0, 5, 12, 18, 28];
  const tdsValues = [0.1, 1, 2, 5, 10];
  const [allCombinations, setAllCombinations] = useState([]);

  const [tdsArr, setTdsArr] = useState<any>([]);
  const [showInput, setShowInput] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
  };

  // Initialize state for cell toggles
  const [cellToggles, setCellToggles] = useState([]);
  useEffect(() => {
    getAllCombinations();
    getDefaultTDSForAccount();
    // eslint-disable-next-line
  }, [openBusinessPopup]);

  const getDefaultTDSForAccount = () => {
    useFetch(API_ENDPOINTS.GET_DEFAULT_TDS_FOR_ACCOUNT.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DEFAULT_TDS_FOR_ACCOUNT.failureMessage,
      config: {
        params: {
          ownId: actor.id,
        },
      },
      thenCallBack: (response) => {
        setTdsArr(response.data.data?.defaultTds || []);
        updateCellToggles(removeDuplicates(response.data.data?.defaultTds || []));
      },
    });
  };

  const getAllCombinations = () => {
    const combinationsArr = gstValues.flatMap((gst) =>
      tdsValues.map((tds) => ({
        gst,
        tds,
        combination: (tds / (1 + gst / 100)).toFixed(5),
      }))
    );

    setAllCombinations(combinationsArr);
  };

  // Modify the toggleCell function to update tdsArr based on the selected combination values
  const toggleCell = (rowIndex, colIndex) => {
    const newCellToggles = [...cellToggles];
    newCellToggles[rowIndex][colIndex] = !newCellToggles[rowIndex][colIndex];

    const selectedGST = gstValues[rowIndex];
    const selectedTDS = tdsValues[colIndex];
    const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

    if (newCellToggles[rowIndex][colIndex]) {
      // If the cell is toggled (checked), add the combination value to tdsArr
      const newTdsArr = [...tdsArr, { value: combination }];
      setTdsArr(removeDuplicates(newTdsArr));
    } else {
      // If the cell is unselected (unchecked), remove the corresponding combination value from tdsArr
      const newTdsArr = tdsArr.filter((item) => Number(item.value) !== Number(combination));
      setTdsArr(removeDuplicates(newTdsArr));
    }

    setCellToggles(newCellToggles);
  };

  const handleCheckboxChange = () => {
    setShowInput(!showInput);
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    if (newValue >= 0 && newValue <= 100) {
      setInputValue(newValue);
    }
  };
  const handleAddTag = () => {
    if (inputValue.trim() !== "") {
      const numericValue = parseFloat(inputValue);
      if (numericValue >= 0 && numericValue <= 100) {
        const stringValue = numericValue.toString(); // Convert to a fixed-format string
        const uniqArr = removeDuplicates([...tdsArr, { value: stringValue }]);
        setTdsArr(uniqArr);
        setInputValue("");
        // Update cellToggles after adding the tag
        updateCellToggles(uniqArr);

        // Check if stringValue exists in allCombinations
        const existsInCombinations = allCombinations.some(
          (combination) => Number(combination.combination) === Number(stringValue)
        );

        // Check if stringValue already exists in tdsArr
        const existsInTdsArr = tdsArr.some((tds) => Number(tds.value) === Number(stringValue));
        if (existsInTdsArr) {
          toast.warning(<CustomToast message="TDS % already selected" />);
        } else if (existsInCombinations) {
          toast.success(<CustomToast message="TDS % Value present in existing combinations selected" />);
        }
        // setShowInput(false);
      } else {
        alert("Please enter a number between 0 and 100.");
      }
    }
  };

  const handleDeleteTag = (value) => {
    const updatedTdsArr = tdsArr.filter((tds) => tds.value !== value);
    const deduplicatedArr = removeDuplicates(updatedTdsArr);
    setTdsArr(deduplicatedArr);
    updateCellToggles(deduplicatedArr);
  };

  const removeDuplicates = (arr) => {
    const uniqueValuesMap = new Map();
    const uniqueValuesArray = [];

    arr.forEach((tag) => {
      const numericValue = parseFloat(tag.value);

      if (!isNaN(numericValue)) {
        const stringValue = numericValue.toFixed(5).toString();

        if (!uniqueValuesMap.has(stringValue)) {
          uniqueValuesMap.set(stringValue, true);
          uniqueValuesArray.push(tag);
        }
      }
    });

    return uniqueValuesArray;
  };

  // Function to update cellToggles based on tdsArr and combination
  const updateCellToggles = (arr) => {
    const rows = gstValues.length;
    const cols = tdsValues.length;

    const updatedCellToggles = Array.from({ length: rows }, (_, rowIndex) =>
      Array.from({ length: cols }, (__, colIndex) => {
        const selectedGST = gstValues[rowIndex];
        const selectedTDS = tdsValues[colIndex];
        const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

        // Check if the combination value exists in tdsArr
        return arr.some((item) => Number(item.value) === Number(combination));
      })
    );

    setCellToggles(updatedCellToggles);
  };

  const getLedgerConfigList = async () => {
    await useFetch(API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_LEDGER_CONFIG_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: ConfigTemplateType.ERP,
        },
      },
      thenCallBack: (response) => {
        setConfigList(response.data.ledgerConfigTemplates);
      },
    });
  };

  useEffect(() => {
    getLedgerConfigList();
    // eslint-disable-next-line
  }, []);

  if (element.current && element.current.clientHeight > maxHeight) {
    setMaxHeight(element.current.clientHeight);
  }

  const AddBusinessPartnerInBulk = async (file: File, columns: string[] = null) => {
    const filePayload = file;
    const bodyFormData = new FormData();
    bodyFormData.append("file", filePayload);
    bodyFormData.append("updatableColumns", JSON.stringify(columns));
    setShowLoadingIconUpload(true);
    await useFetch(API_ENDPOINTS.PARTNER_MASTER_ADD_BUSINESS_PARTNER_IN_BULK.url, "POST", {
      failureMessage: API_ENDPOINTS.PARTNER_MASTER_ADD_BUSINESS_PARTNER_IN_BULK.failureMessage,
      showSuccessToast: true,
      data: bodyFormData,
      config: {
        headers: { "Content-Type": "multipart/form-data" },
      },
      thenCallBack: (response) => {
        setShowLoadingIconUpload(false);
        // handleClose();
        if (props.from === "Recon360") {
          props?.listAllOwnBusinessPartnerMapping();
        } else if (props.from === "Partner Master") {
          props.getPartnerUserBulkUploadExcelTemplate();
          props.listAllBusinessPartnersForPartnerUseCaseMaster();
          props.listAllBusinessPartnersForPartnerUserMaster();
        }
        if (response.data.updatableColumns) {
          setColumns(response.data.updatableColumns);
          setOpenFieldUpdate(true);
        }
        if (Array.isArray(columns)) {
          handleClose();
        }
      },
      catchCallBack: (error: AxiosError<BusinessPartnerInBulkRes>) => {
        setOpenBusinessPopup(true);
        setShowLoadingIconUpload(false);

        if (error.response.data.response === "failShowModal") {
          if (props.from === "Recon360") {
            props?.listAllOwnBusinessPartnerMapping();
          } else if (props.from === "Partner Master") {
            props.getPartnerUserBulkUploadExcelTemplate();
            props.listAllBusinessPartnersForPartnerUseCaseMaster();
            props.listAllBusinessPartnersForPartnerUserMaster();
          }
          setErrorBulkUploadMsg(error.response.data.message);
          setErrorBulkUploadDownload(error.response.data?.errorFile);
          setErrorBulkUpload(true);
        }
      },
    });
  };

  const resetState = () => {
    setSelectedRelationship(null);
    setSelectedAccountingSoftware(null);
    setCompanyName("");
    setPartnerNickname("");
    setCompanyGstNumber("");
    setVendorCode("");
    setLocation("");
    setCategory("");
    setPocName("");
    setPocEmail("");
    setPocPhoneNumber("");
    setTdsArr([]);
    setPinCode("");
    setErrSelectedRelationship(false);
    setErrorPartnerNickname(false);
    setErrCompanyName(false);
    setErrCompanyGstNumber(false);
    setErrPinCode(false);
    setInvalidEmails([]);
    setInvalidPhoneNumbers([]);
    setShowInput(false);
    setInputValue("");
  };

  const handleClickOpen = () => {
    setOpenBusinessPopup(true);
  };
  const handleChange = (_, newValue: number) => {
    setTabValue(newValue);
  };
  const handleClose = () => {
    setOpenBusinessPopup(false);
    resetState();
  };

  const validateEmail = (email: string) => {
    const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailPattern.test(email);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phonePattern = /^\d{10}$/; // Assuming a 10-digit phone number
    return phonePattern.test(phoneNumber);
  };

  const removeEmptyValueFromCommaSeparated = (valueStr: string) => {
    return valueStr
      ?.split(",")
      ?.map((name) => name?.trim())
      ?.filter((name) => name !== "")
      ?.join(", ");
  };

  const saveNewPartener = async () => {
    let noErrorPresent = true;
    if (selectedRelationship === undefined) {
      setErrSelectedRelationship(true);
    }
    if (companyName.trim() === "") {
      setErrCompanyName(true);
    }
    if (partnerNickname.trim().length > 16) {
      noErrorPresent = false;
    }
    if (
      companyGstNumber?.trim()?.length > 0 &&
      !companyGstNumber.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")
    ) {
      setErrCompanyGstNumber(true);
      noErrorPresent = false;
    }
    if (pinCode?.trim()?.length > 0 && !pinCode.match("^[1-9][0-9]{5}$")) {
      setErrPinCode(true);
      noErrorPresent = false;
    }
    if (pocEmail?.trim()?.length > 0) {
      const emails = pocEmail?.split(",")?.map((email) => email?.trim());
      const invalidEmailsCheck = emails?.filter((email) => !validateEmail(email));
      setInvalidEmails(invalidEmailsCheck);
      if (invalidEmailsCheck?.length > 0) {
        noErrorPresent = false;
      }
    }
    if (pocPhoneNumber?.trim()?.length > 0) {
      const phoneNumbers = pocPhoneNumber?.split(",")?.map((phoneNumber) => phoneNumber?.trim());
      const invalidPhoneNumbersCheck = phoneNumbers?.filter((phoneNumber) => !validatePhoneNumber(phoneNumber));
      setInvalidPhoneNumbers(invalidPhoneNumbersCheck);
      if (invalidPhoneNumbersCheck?.length > 0) {
        noErrorPresent = false;
      }
    }

    if (selectedRelationship !== undefined && companyName.trim() !== "" && noErrorPresent) {
      const tdsArrFilt = tdsArr.filter((item: any) => {
        return item.value.toString().trim() !== "";
      });
      const convertapplicableTDSPercentagesToString = tdsArrFilt.map((item: any) => {
        return `${item.value}%`;
      });
      setShowLoadingIconSubmit(true);
      await useFetch(API_ENDPOINTS.PARTNER_MASTER_ADD_BUSINESS_PARTNER.url, "POST", {
        failureMessage: API_ENDPOINTS.PARTNER_MASTER_ADD_BUSINESS_PARTNER.failureMessage,
        showSuccessToast: true,
        data: {
          relationship: selectedRelationship,
          partnerName: companyName,
          bpCommonName: partnerNickname,
          vendorCode,
          gstin: companyGstNumber,
          pincode: pinCode,
          erpSoftware: selectedAccountingSoftware,
          applicableTdsTcsPercentage: convertapplicableTDSPercentagesToString?.toString(),
          category: removeEmptyValueFromCommaSeparated(category),
          pocName: removeEmptyValueFromCommaSeparated(pocName),
          location,
          pocEmail,
          pocPhoneNumber,
          currency,
        },
        thenCallBack: () => {
          resetState();
          setShowLoadingIconSubmit(false);
          handleClose();
          if (props.from === "Recon360") {
            props?.listAllOwnBusinessPartnerMapping();
          } else if (props.from === "Partner Master") {
            props.getPartnerUserBulkUploadExcelTemplate();
            props.listAllBusinessPartnersForPartnerUseCaseMaster();
            props.listAllBusinessPartnersForPartnerUserMaster();
          }
        },
        catchCallBack: (error) => {
          setShowLoadingIconSubmit(false);
          if (error.response.data.response === "failShowModal") {
            toast.error(<CustomToast message={error.response.data.debugMessage[0].Error} />);
          }
        },
      });
    }
  };

  const getAddPartnerExcelTemplate = () => {
    setGetAddPartnerExcelTemplateLoading(true);
    useFetch(API_ENDPOINTS.PARTNER_MASTER_ADD_PARTNER_EXCEL_TEMPLATE.url, "GET", {
      failureMessage: API_ENDPOINTS.PARTNER_MASTER_ADD_PARTNER_EXCEL_TEMPLATE.failureMessage,
      thenCallBack: (response) => {
        const excelData = Buffer.from(response.data?.addPartnerExcelTemplateBase64, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, "business-partner-file-format.xlsx");
        setGetAddPartnerExcelTemplateLoading(false);
      },
      catchCallBack: () => {
        setGetAddPartnerExcelTemplateLoading(false);
      },
    });
  };

  const downloadBulkUploadErrorFile = () => {
    const excelData = Buffer.from(errorBulkUploadDownload, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "business-partner-file-format-error.xlsx");
  };

  if (props.onClick) props.onClick(handleClickOpen);

  // const SortedCurrencies = Object.keys(PopularCurrencies).map(k => Currencies.find(v => v.code === k));
  // SortedCurrencies.push(...Currencies);

  return (
    <>
      {!actor.integration && !props.component ? (
        <MenuItem
          className={props.className}
          onClick={() => {
            handleClickOpen();
          }}
        >
          Add Partner
        </MenuItem>
      ) : !actor.integration && props.component ? (
        <>{props.component}</>
      ) : null}
      <BusinessDialog
        fullWidth={true}
        maxWidth="md"
        open={openBusinessPopup}
        onClose={handleClose}
        aria-labelledby="add-business-partner"
        className="businesspartner_popup"
      >
        <DialogContent ref={element} sx={{ height: maxHeight || "auto" }}>
          <Box sx={{ width: "100%", height: "calc(100% - 24px)" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs value={tabvalue} onChange={handleChange} aria-label="Business Partner" className="business_tabs">
                <Tab label="Add Business Partner" />
                <Tab label="Bulk Upload" />
              </Tabs>
            </Box>
            <TabPanel value={tabvalue} index={0}>
              <Grid container={true} spacing={2}>
                <Grid item={true} xs={12} sm={12}>
                  <FormControl fullWidth={true} error={errSelectedRelationship}>
                    <Select
                      name="relationship"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      displayEmpty={true}
                      error={errSelectedRelationship}
                      onChange={(e) => {
                        setSelectedRelationship(e.target.value);
                        setErrSelectedRelationship(false);
                      }}
                      value={selectedRelationship}
                    >
                      <MenuItem selected={true} disabled={true}>
                        Select User Type
                      </MenuItem>
                      {options.map((item) => (
                        <MenuItem key={item.value} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>{errSelectedRelationship ? "please select user type" : ""}</FormHelperText>
                  </FormControl>
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required={true}
                    fullWidth={true}
                    label="Partner Name"
                    name="companyName"
                    error={errCompanyName}
                    helperText={errCompanyName ? "please enter partner name" : ""}
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                      if (e.target.value.trim() !== "") {
                        setErrCompanyName(false);
                      } else {
                        setErrCompanyName(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    required={false}
                    fullWidth={true}
                    label="Partner Common Name"
                    name="partnerNickname"
                    error={errorPartnerNickname}
                    helperText={errorPartnerNickname ? "Maximum 16 chars allowed" : ""}
                    value={partnerNickname}
                    onChange={(e) => {
                      setPartnerNickname(e.target.value);
                      if (e.target.value.trim().length <= 16) {
                        setErrorPartnerNickname(false);
                      } else {
                        setErrorPartnerNickname(true);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="companyGstNumber"
                    label="GSTIN"
                    type="text"
                    error={errCompanyGstNumber}
                    helperText={errCompanyGstNumber ? "please enter correct company GST number" : ""}
                    value={companyGstNumber}
                    onChange={(e) => {
                      setCompanyGstNumber(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")) {
                          setErrCompanyGstNumber(true);
                        } else {
                          setErrCompanyGstNumber(false);
                        }
                      } else {
                        setErrCompanyGstNumber(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="vendorCode"
                    label="Vendor Code"
                    type="text"
                    value={vendorCode}
                    onChange={(e) => {
                      setVendorCode(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="location"
                    label="Location"
                    type="text"
                    value={location}
                    onChange={(e) => {
                      setLocation(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="category"
                    label="Category"
                    type="text"
                    value={category}
                    onChange={(e) => {
                      setCategory(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="pocName"
                    label="POC Name"
                    type="text"
                    value={pocName}
                    onChange={(e) => {
                      setPocName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="pocEmail"
                    label="POC Email"
                    type="text"
                    value={pocEmail}
                    onChange={(e) => {
                      // setPocEmail(e.target.value);
                      // setInvalidEmails([]); // Reset the invalid emails array on each change

                      const enteredEmail = e.target.value;
                      setPocEmail(enteredEmail);

                      // Split the entered email addresses by commas and trim each email
                      const emails = enteredEmail.split(",").map((email) => email.trim());

                      // Remove duplicates from the emails array using Set
                      const uniqueEmails = [...new Set(emails)];

                      // Check if there are any duplicate emails
                      const hasDuplicates = emails.length !== uniqueEmails.length;

                      if (hasDuplicates) {
                        setInvalidEmails(["Duplicate emails found"]);
                      } else {
                        setInvalidEmails([]);
                      }
                    }}
                    error={invalidEmails.length > 0} // Set error state based on the presence of invalid emails
                    helperText={invalidEmails.length > 0 && `Invalid email(s): ${invalidEmails.join(", ")}`} // Show invalid email(s) in the error message
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    name="pocPhoneNumber"
                    label="POC Phone no."
                    type="text"
                    value={pocPhoneNumber}
                    onChange={(e) => {
                      setPocPhoneNumber(e.target.value);
                      setInvalidPhoneNumbers([]); // Reset the invalid phone numbers array on each change
                    }}
                    error={invalidPhoneNumbers.length > 0} // Set error state based on the presence of invalid phone numbers
                    helperText={
                      invalidPhoneNumbers.length > 0 && `Invalid phone number(s): ${invalidPhoneNumbers.join(", ")}`
                    } // Show invalid phone number(s) in the error message
                  />
                </Grid>

                <Grid item={true} xs={12} sm={6}>
                  <Select
                    name="accountingSoftware"
                    variant="outlined"
                    fullWidth={true}
                    displayEmpty={true}
                    onChange={(e) => {
                      setSelectedAccountingSoftware(e.target.value);
                      // if (e.target.value === "Others") {
                      //   setOtherSoftware(true);
                      //   setSelectedAccountingSoftware("Others");
                      // } else {
                      //   setOtherSoftware(false);
                      // }
                    }}
                    value={selectedAccountingSoftware}
                  >
                    <MenuItem selected={true} disabled={true}>
                      Select Accouting software
                    </MenuItem>
                    {configList.map((item) => (
                      <MenuItem key={item.templateId} value={item.templateName}>
                        {item.templateName}
                      </MenuItem>
                    ))}
                    <MenuItem key="other" value="Others" className="dropdown_other_highlight">
                      Other
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    variant="outlined"
                    fullWidth={true}
                    // required={true}
                    name="PINCode"
                    label="PIN Code"
                    type="text"
                    error={errPinCode}
                    helperText={errPinCode ? "please enter correct PIN Code" : ""}
                    value={pinCode}
                    onChange={(e) => {
                      setPinCode(e.target.value);
                      if (e.target.value.length > 0) {
                        if (!e.target.value.match("^[1-9][0-9]{5}$")) {
                          setErrPinCode(true);
                        } else {
                          setErrPinCode(false);
                        }
                      } else {
                        setErrPinCode(false);
                      }
                    }}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={6}>
                  <TextField
                    select={true}
                    variant="outlined"
                    fullWidth={true}
                    name="Currency"
                    label="Currency"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    {Currencies.map((_cur) => (
                      <MenuItem key={_cur.code} value={_cur.code}>
                        {_cur.code} - {_cur.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <div>
                    <label>TDS/TCS Percentage</label>
                  </div>
                  <table className="tds_gst_table mt_10">
                    <thead>
                      <tr>
                        <th className="text_center" colSpan={tdsValues.length + 2}>
                          TDS
                        </th>
                      </tr>
                      <tr>
                        <th />
                        <th />
                        {tdsValues.map((tds, index) => (
                          <th key={index} className="text_center">
                            {tds}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {gstValues.map((gst, rowIndex) => (
                        <tr key={rowIndex}>
                          {rowIndex === 0 ? (
                            <th
                              rowSpan={gstValues.length}
                              className="text_center"
                              style={{ verticalAlign: "middle", border: "1px solid #53526c" }}
                            >
                              GST
                            </th>
                          ) : null}
                          <th className="text_center">{gst}</th>
                          {tdsValues.map((tds, colIndex) => {
                            const selectedGST = gstValues[rowIndex];
                            const selectedTDS = tdsValues[colIndex];
                            const combination = (selectedTDS / (1 + selectedGST / 100)).toFixed(5);

                            return (
                              <td
                                key={`${colIndex}-${tds}`}
                                className={
                                  cellToggles?.[rowIndex]?.[colIndex]
                                    ? "toggled text_center cursor_pointer"
                                    : "text_center cursor_pointer"
                                }
                                onClick={() => toggleCell(rowIndex, colIndex)}
                              >
                                {cellToggles?.[rowIndex]?.[colIndex] ? (
                                  <div className="check" title={`${combination}%`} style={{ width: "30px" }} />
                                ) : (
                                  <div
                                    className="check unchecked"
                                    title={`${combination}%`}
                                    style={{ width: "30px" }}
                                  />
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Grid>
                <Grid item={true} xs={12} sm={12}>
                  <div className="mt_20">
                    <Checkbox onChange={handleCheckboxChange} />
                    <label className="fw_600">Add TDS % Manually (if it doesn't fit in the table)</label>
                  </div>
                  {showInput && (
                    <div>
                      <TextField
                        variant="outlined"
                        style={{ minWidth: "50%" }}
                        label="TDS/TCS Percentage"
                        name="value"
                        value={inputValue}
                        onChange={handleInputChange}
                        InputProps={{
                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                        }}
                      />
                      <IconButton color="primary" onClick={handleAddTag} sx={{ top: 8 }}>
                        <AddIcon />
                      </IconButton>
                    </div>
                  )}
                  <div className="tag-container mt_20">
                    {tdsArr
                      .filter(
                        (tds) =>
                          !allCombinations.some((combination) => Number(combination.combination) === Number(tds.value))
                      )
                      .map((tds, index) => (
                        <Chip
                          key={index}
                          label={tds.value}
                          onDelete={() => handleDeleteTag(tds.value)}
                          color="primary"
                          variant="outlined"
                          style={{ marginRight: "8px", marginBottom: "8px" }}
                        />
                      ))}
                  </div>
                </Grid>
              </Grid>
              <DialogActions className="businessmodal_footer">
                <button onClick={handleClose} className="businesspartner_btn theme_outline_btn">
                  Cancel
                </button>
                <button
                  disabled={showLoadingIconSubmit}
                  onClick={() => saveNewPartener()}
                  className="businesspartner_btn theme_btn center_align_ver_horiz"
                >
                  <LoadingIcon loading={showLoadingIconSubmit} />
                  Submit
                </button>
              </DialogActions>
            </TabPanel>
            <TabPanel value={tabvalue} index={1} style={{ height: "inherit" }}>
              <Grid
                container={true}
                spacing={2}
                sx={{ height: "inherit" }}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Grid item={true} xs={12} sm={6} display={"flex"} justifyContent={"center"}>
                  <div className="closingblc_input">
                    <label id="file-js-example" className="field file has-name" style={{ marginBottom: "0" }}>
                      <input
                        className="file-input"
                        type="file"
                        name="invoicefile"
                        value={""}
                        onChange={(e) => {
                          selectedFile.current = e.target.files[0];
                          AddBusinessPartnerInBulk(e.target.files[0]);
                        }}
                        required={true}
                        multiple={false}
                        accept=".xls,.xlsx,.csv,.xlsm,.xlsb,.xltx,.xltm,.xlt,.xml,.xla,.xlw,.xlr,.pdf"
                      />
                      <span
                        className="file-cta closingblc_input businesspartner_btn theme_btn"
                        style={{
                          minWidth: "300px",
                          justifyContent: "center",
                        }}
                      >
                        {showLoadingIconUpload ? null : (
                          <span className="file-icon">
                            <i className="fas fa-upload" />
                          </span>
                        )}
                        {showLoadingIconUpload ? (
                          <span className="file-icon">
                            <i className="fas fa-circle-notch fa-spin" />
                          </span>
                        ) : null}
                        <span className="file-label">Upload Business Partners</span>
                      </span>
                    </label>
                  </div>
                </Grid>
                <Grid item={true} xs={12} sm={6} display={"flex"} justifyContent={"center"}>
                  <Button
                    disabled={getAddPartnerExcelTemplateLoading}
                    startIcon={<LoadingIcon loading={getAddPartnerExcelTemplateLoading} />}
                    onClick={() => {
                      getAddPartnerExcelTemplate();
                    }}
                    className="businesspartner_btn theme_btn"
                  >
                    Download Format
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
          </Box>

          <Modal open={errorBulkUpload} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2" className="errorBulkUploadTitle">
                Bulk upload error
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 8 }} className="errorBulkUploadMsg">
                {errorBulkUploadMsg}
              </Typography>
              <Box className="mt_15 space_between">
                <button
                  className="businesspartner_btn theme_btn "
                  onClick={() => {
                    setErrorBulkUpload(false);
                  }}
                >
                  Close
                </button>
                <button
                  onClick={() => {
                    downloadBulkUploadErrorFile();
                  }}
                  className="businesspartner_btn theme_btn "
                >
                  Download
                </button>
              </Box>
            </Box>
          </Modal>
        </DialogContent>
      </BusinessDialog>
      <PartialFieldUpdateDialog
        open={openFieldUpdate}
        setOpen={setOpenFieldUpdate}
        fields={columns}
        afterFieldSelect={(fields) => AddBusinessPartnerInBulk(selectedFile.current, fields)}
      />
    </>
  );
};

export default AddNewBusinessPartner;
