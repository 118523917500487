/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ArrowDropDown,
  CachedOutlined,
  CallMade,
  CheckCircle,
  Close,
  Delete,
  FilterList,
  FilterListOff,
  ForwardToInbox,
  MailOutline,
  MoreVert,
  Save,
  TaskAlt,
  Unsubscribe,
  Warning,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  CircularProgress,
  createFilterOptions,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import { Currency } from "dinero.js";
import { saveAs } from "file-saver";
import { MRT_Column, MRT_Row, MRT_TableInstance, MRT_TableState } from "material-react-table";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
// import { Dialog } from "src/Components/Dialog/Dialog";
import TasksHistoryModal, { NAKAD_SERVICE } from "../../Tickets/TasksHistoryModal";
// import BpDashboardTable from "src/Components/ReactTable/BpDashboardTable";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";

import dayjs from "dayjs";
import { toast } from "react-toastify";
import CustomToast from "src/Components/CustomToast";
import {
  BulkMailStatusResponse,
  //   BcBetaMailThread,
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaRes,
  EmailSendCount,
  EmailTemplate,
  ListOwnTallyCompanies,
  ListPDFTemplatesResponse,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import { Currencies } from "src/Utils/Common/Constants";
import { exists } from "src/Utils/Common/Validators";
import { DateDifference, filterDateBetweenStartAndEndDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { uiLoggerName, uiLoggerNamesPartnerCommunication } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import ListBusinessPartnerUsers from "../../PartnerMaster/ListBusinessPartnerUsers";
import { TallyClosingBalanceForAllBpResponse } from "../BalanceConfirmation/BalanceConfirmation";
import { PageSelectModeToggle } from "../Common/CommonComponents";
import {
  ApproveResponseDialog,
  DeleteTableView,
  MailStatusRefreshDialog,
  ResetStatusDialog,
  SaveTableView,
  StopAutoReminder,
  UpdateResponseDialog,
} from "../Common/CommonDialogs";
import BulkMailReportDialog from "../CommonLegacy/BulkMailReportDialog";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../CommonLegacy/CommonComponents";
// import MailThreadDialog from "../CommonLegacy/MailThreadDialog";
import CreateEmailTemplate from "../Communication/CreateEmailTemplate";
import SelectEmailTemplate from "../Communication/SelectEmailTemplate";
import SendEmailTemplateBeta from "../Communication/SendEmailTemplateBeta";
import { PartnerCommsContext } from "../Context/PartnerCommunicationContext";
import { RaisedIssueBc } from "../LedgerRequestPortal/CommonComponents";
import { NdAsyncButton } from "../MsmePartnerPortal/CommonComponents";
import BalanceConfirmationBetaFilter from "./BalanceConfirmationBetaFilter";
import BalanceConfirmationHistory from "./BalanceConfirmationHistory";
import PartnerRequests from "./PartnerRequests";
// import BcBetaConfirmationFilter from "./BcBetaConfirmationFilter";
import HistoryIcon from "@mui/icons-material/History";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { useDispatch, useSelector } from "react-redux";
import { isOverFlowing } from "src/Components/Common/SideBar";
import {
  CounterState,
  updateBulkMailedTimeBCB,
  updateEmailResponseMsgBCB,
  updateIsSendingBulkMailsBCB,
  updateMailFailedUsersBCB,
  updateMailFailedWorkbookBCB,
  updateMailSentPartnerCountBCB,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { ENV_NAME } from "src/Utils/Recon/ENV/Constants";
import { DRNewCommunicationDialog } from "../../DisputeResolution/Common/CommonDialogs";
import TaskAttachmentsModal from "../../Tickets/TaskAttachmentsModal";
import ActiveAutoReminder from "../Common/BulkMail/AutoReminder/ActiveAutoReminder";
import BulkMail from "../Common/BulkMail/BulkMail";
import DownloadReport, { ReportDetailsCustomisationRes } from "../Common/DownloadReport";
import SoloMail from "../Common/SoloMail/SoloMail";
import ViewReplyMailThread from "../Common/ViewReplyMailThread";
import "../Styles/PartnerCommunication.scss";
import { ManageCommunicationType } from "./CommunicationTypes/CommunicationTypes";
import MultipleCommunicationTypes from "./CommunicationTypes/MultipleCommunicationTypes";
import ConfirmCloseAndResetModal from "./ConfirmCloseAndResetModal";
import MoveTaskToReconModal from "./MoveTaskToReconModal";
import ReviewCloseAndResetTasksModal from "./ReviewCloseAndResetTasksModal";
import ReviewMoveBulkTasksModal from "./ReviewMoveBulkTasksModal";
// import { ManageCommunicationType } from "./CommunicationTypes";

let intervalId: ReturnType<typeof setTimeout>;
let newIntervalId: ReturnType<typeof setTimeout>;

export enum BcBetaFileType {
  confirmationDocument = "confirmationDocument",
  ledgers = "ledgers",
  openItems = "openItems",
}

export type ModifiedPresetData = Pick<
  MRT_TableState<BusinessPartnerListForBcBeta>,
  "columnSizing" | "columnOrder" | "columnVisibility" | "columnPinning"
>;

interface IBcBetaContext {
  rowsDataBcBeta: BusinessPartnerListForBcBeta[];
  storeRowsDataBcBeta: BusinessPartnerListForBcBeta[];
  setRowsDataBcBeta: StateDispatch<BusinessPartnerListForBcBeta[]>;
  setStoreRowsDataBcBeta: StateDispatch<BusinessPartnerListForBcBeta[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
  listUserEmailTemplates: () => Promise<void>;
  selectedRow: BusinessPartnerListForBcBeta[];
  setSelectedRow?: StateDispatch<BusinessPartnerListForBcBeta[]>;
  currentRows: BusinessPartnerListForBcBeta[];
  setCurrentRows: StateDispatch<BusinessPartnerListForBcBeta[]>;
  withoutTemplateCheck: boolean;
  setWithoutTemplateCheck: StateDispatch<boolean>;
  setIntervalBCBeta: () => void;
  lastMailSyncStatusDate: Date;
  setIntervalMailSyncStatus: () => void;
  companyId: string;
  branchCode: string;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const BcBetaContext = createContext<IBcBetaContext>(null);
const filter = createFilterOptions<any>();

export const getBlankData = (count = 20) => {
  const dataArray = [];
  for (let i = 0; i < count; i++) {
    dataArray.push({
      businessPartnerName: ` `,
      businessPartnerId: 0,
      category: [],
      status:
        BalanceConfirmationBetaStatuses[
          Object.keys(BalanceConfirmationBetaStatuses)[
            Math.floor(Math.random() * Object.keys(BalanceConfirmationBetaStatuses).length)
          ]
        ],
      ledgers: false,
      signedPdf: false,
      vendorCode: " ",
      location: " ",
      currency: "INR",
      ownClosingBalance: null,
      businessPartnerClosingBalance: null,
      closingBalanceDifference: null,
      closingBalanceDate: undefined,
      statusOverview: "-",
      balanceConfirmationStatusWithReminderCount: "1st Reminder Sent",
    } as BusinessPartnerListForBcBeta);
  }
  return dataArray;
};

export const MuiSliderFilterSteps = (column: MRT_Column<BusinessPartnerListForBcBeta>) => {
  const minMaxValues = column.getFacetedMinMaxValues();

  let step = 1000;
  if (minMaxValues?.length > 0) {
    const maxVal = minMaxValues[1] || 0;
    const minVal = minMaxValues[0] || 0;
    const calculatedStep = Math.floor((maxVal - minVal) / 20);
    step = calculatedStep ? calculatedStep : step;
  }

  return {
    //no need to specify min/max/step if using faceted values
    marks: true,
    step: step,
  };
};

const BalanceConfirmationBeta = () => {
  const location = useLocation<any>();
  const { actor } = useContext(userContext);
  const history = useHistory();
  const [loader, setLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [rowsDataBcBeta, setRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>(getBlankData());
  const [storeRowsDataBcBeta, setStoreRowsDataBcBeta] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [openBcBetaSettings, setOpenBcBetaSettings] = useState<boolean>(false);
  const [openBcBetaFilter, setOpenBcBetaFilter] = useState<boolean>(false);
  const [openBcBetaHistory, setOpenBcBetaHistory] = useState<boolean>(false);
  const [openSingleMailing, setOpenSingleMailing] = useState<boolean>(false);
  const [openBcBetaBulkMailing, setOpenBcBetaBulkMailing] = useState<boolean>(false);
  const [openBcBetaNewCommsDialog, setOpenBcBetaNewCommsDialog] = useState<boolean>(false);
  const storeAllCategories = useRef<string[]>([]);
  const storeBcBetaData = useRef<BusinessPartnerListForBcBeta[]>([]);
  const [isFilterAppliedBcBeta, setIsFilterAppliedBcBeta] = useState<boolean>(false);
  const [tempLoader, setTempLoader] = useState<boolean>(false);
  const [reFilter, setReFilter] = useState<boolean>(false);
  // ----//

  const [signatureImgBase64, setSignatureImgBase64] = useState<string>(null);
  const [signature, setSignature] = useState<string>("");
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [lastSelectedTemplateID, setLastSelectedTemplateID] = useState(0);

  const [activeStep, setActiveStep] = useState(0);
  const [activeStepSolo, setActiveStepSolo] = useState(0);

  const storeClosingBalanceValueFlag = useRef<boolean>(false);
  const storeClosingBalanceDateFlag = useRef<boolean>(false);
  const storeClosingBalanceRowID = useRef<number>(null);
  const [storeClosingBalanceDateValue, setStoreClosingBalanceDateValue] = useState<any>(null);

  // Tally CB async
  const [bulkTallyCbDate, setBulkTallyCbDate] = useState<string>("");
  const [defaultTallyCbDate, setDefaultTallyCbDate] = useState<string>("");
  const [updatingTallyCbDate, setUpdatingTallyCbDate] = useState(false);
  const [loadingTallyCbAll, setLoadingTallyCbAll] = useState(false);
  const lastTallyInterval = useRef(null);

  // Mail Status Async Refresh
  const [lastMailSyncStatusDate, setLastMailSyncStatusDate] = useState<Date>(null);
  const [isSyncingMailStatus, setIsSyncingMailStatus] = useState<boolean>(false);
  const [openStatusRefreshDialog, setOpenStatusRefreshDialog] = useState(false);
  const [differenceTime, setDifferenceTime] = useState(0);

  const [openRaiseReq, setOpenRaiseReq] = useState<boolean>(false);
  const [raisedIssues, setRaisedIssues] = useState<
    { businessPartnerName: string; vendorCode: string; issues: RaisedIssueBc[] }[]
  >([]);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const isApprovingInBulk = useRef<boolean>(false);
  const [currentSelectedRows, setCurrentSelectedRows] = useState<BusinessPartnerListForBcBeta[]>([]);
  const [openResetStatusDialog, setOpenResetStatusDialog] = useState(false);
  const [openUpdateResDialog, setOpenUpdateResDialog] = useState(false);
  const [openActAutoReminder, setOpenActAutoReminder] = useState(false);
  const [openStopReminder, setOpenStopReminder] = useState(false);

  // const [loadingPartnerRequests, setLoadingPartnerRequests] = useState(false);
  const [withoutTemplateCheck, setWithoutTemplateCheck] = useState(false);
  const [pageSelectMode, setPageSelectMode] = useState(false);

  const [openEditCommsType, setOpenEditCommsType] = useState(false);
  const [openMultipleCommsType, setOpenMultipleCommsType] = useState(false);

  const [anchorElOpts, setAnchorElOpts] = React.useState<null | HTMLElement>(null);
  const [anchorElBulk, setAnchorElBulk] = React.useState<null | HTMLElement>(null);

  const [clearRows, setClearRows] = useState(false);
  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);

  const handleMenuCloseOpts = () => {
    setAnchorElOpts(null);
  };

  const handleMenuCloseBulk = () => {
    setAnchorElBulk(null);
  };

  // Email States BEGIN
  const {
    setOpenSelectEmailTemplate,
    setOpenSendEmailTemplate,
    openBcBetaMailing,
    setOpenBcBetaMailing,
    hideSendEmailTemplate,
    setHideSendEmailTemplate,
    setEmailTemplates,
    setEmailTemplateContent,
    setEmailBusinessPartnerId,
    setMappingId,
    setCommunicationTypeId,
    setBusinessPartnerName,
    setEmailDisplayName,
    setFromEmailAddress,
    ResetContext,
    setPdfTemplates,
    setUserEmailSetting,
  } = useContext(PartnerCommsContext);

  const dispatch = useDispatch();
  const {
    isSendingBulkMailsBCB,
    emailResponseMsgBCB,
    mailFailedUsersBCB,
    mailFailedWorkbookBCB,
    mailSentPartnerCountBCB,
    bulkMailedTimeBCB,
  } = useSelector((state: any) => state.bulkMailsStatus as CounterState);
  const [openBulkMailReportDialog, setOpenBulkMailReportDialog] = useState<boolean>(false);

  const [sendSoleOrBulkMail, setSendSoleOrBulkMail] = useState<EmailSendCount>(null);
  // Email States END

  const [tableInstance, setTableInstance] = useState<MRT_TableInstance<BusinessPartnerListForBcBeta>>(null);
  const [selectedTablePreset, setSelectedTablePreset] = useState<string>("");
  const [allTablePresets, setAllTablePresets] = useState<{ [k: string]: ModifiedPresetData }>({});
  const [tablePresetState, setTablePresetState] = useState<ModifiedPresetData>({} as ModifiedPresetData);
  const [openTablePresetDlg, setOpenTablePresetDlg] = useState<boolean>(false);
  const [openTablePresetDelDlg, setOpenTablePresetDelDlg] = useState<boolean>(false);
  const currentPresetRef = useRef<string>("");

  const [lastCompany, setLastCompany] = useState<CompanyInfo>(null);
  const companyId = useRef<string>(DEFAULT_COMPANY_ID);

  const [lastBranch, setLastBranch] = useState<BranchInfo>(null);
  const branchCode = useRef<string>(DEFAULT_BRANCH_CODE);

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<MRT_Row<BusinessPartnerListForBcBeta>>(null);

  // reset status
  const [openDownloadReportDlg, setOpenDownloadReportDlg] = useState<boolean>(false);
  const [isHistoryReport, setIsHistoryReport] = useState<boolean>(false);
  const [downloadBcBetaReportBase64, setDownloadBcBetaReportBase64] = useState<string>("");

  // delete email template
  const { setShowDeleteLoadingIcon, handleCloseDeleteModal } = useContext(PartnerCommsContext);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = isSendingBulkMailsBCB || isSyncingMailStatus;

  const columnDefinitionBcBeta = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
              title={row.original.businessPartnerName}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.vendorCode || "",
        enableSorting: true,
        size: 200,
        filterVariant: "autocomplete",
      },
      {
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "",
        size: 200,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
              loading={tempLoader}
            />
          </div>
        ),
      },
      {
        header: "Email Status",
        accessorFn: (row) => row?.status || "",
        size: 280,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <>
            {row.original.status ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: BalanceConfirmationBetaColorMap[row.original.status]?.color || "#000",
                    backgroundColor: BalanceConfirmationBetaColorMap[row.original.status]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.status === BalanceConfirmationBetaStatuses.ReminderSent
                    ? row.original.balanceConfirmationStatusWithReminderCount || row.original.status
                    : row.original.status === BalanceConfirmationBetaStatuses.EscalationSent
                    ? row.original.escalationSentStatusWithReminderCount || row.original.status
                    : row.original.status}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },

      {
        id: "lastStatusChangeDate",
        header: "Last Status Change date",
        accessorFn: (row) => new Date(row.lastStatusChangeDate || null),
        enableSorting: true,
        minSize: 280,
        size: 350,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastStatusChangeDate);
          }
        },
        Cell: ({ row }) =>
          row.original.lastStatusChangeDate
            ? moment(row.original.lastStatusChangeDate)?.format("DD-MM-YYYY")
            : (row.original.lastStatusChangeDate as any),
      },

      {
        id: "closingBalanceDate",
        header: "Closing Balance date",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance date
            {actor.integration && (
              <div className="textField_height">
                <TextField
                  key="bulkCBDate"
                  type="date"
                  size="small"
                  disabled={updatingTallyCbDate}
                  defaultValue={bulkTallyCbDate || defaultTallyCbDate}
                  // onChange={(_e) => setBulkTallyCbDate(_e.target.value)}
                  onBlur={(_e) => {
                    setBulkTallyCbDate(_e.target.value);
                    if (_e.target.value && bulkTallyCbDate !== _e.target.value)
                      UpdateErpClosingBalanceForAllBp(_e.target.value);
                  }}
                  sx={{
                    div: { borderColor: "white !important", borderRadius: 16 },
                    input: { background: "white", p: "1px 8px", borderRadius: 16 },
                  }}
                  onClick={(_e) => _e.stopPropagation()}
                />
                {(updatingTallyCbDate || loadingTallyCbAll) && (
                  <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
                )}
              </div>
            )}
          </div>
        ),
        accessorFn: (row) => new Date(row.closingBalanceDate || null),
        enableSorting: true,
        minSize: 280,
        size: 350,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.closingBalanceDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.closingBalanceDate?.toString()}
              variant="outlined"
              disabled={
                ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                (actor.integration
                  ? loadingTallyCbAll
                  : row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation)
              }
              // placeholder="yyyy-mm-dd"
              type="date"
              size="small"
              defaultValue={
                storeClosingBalanceDateFlag.current &&
                storeClosingBalanceRowID.current === row.original.businessPartnerId
                  ? storeClosingBalanceDateValue !== null
                    ? moment(storeClosingBalanceDateValue)?.format("YYYY-MM-DD")
                    : storeClosingBalanceDateValue
                  : row.original.closingBalanceDate !== null
                  ? moment(row.original.closingBalanceDate)?.format("YYYY-MM-DD")
                  : row.original.closingBalanceDate
              }
              onClick={() => {
                storeClosingBalanceDateFlag.current = true;
                storeClosingBalanceRowID.current = row.original.businessPartnerId;
                setStoreClosingBalanceDateValue(row.original.closingBalanceDate);
              }}
              onChange={(e) => {
                setStoreClosingBalanceDateValue(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (actor.integration === true) {
                    // updateClosingBalanceByClosingBalanceDate(row, e.target.value);
                    UpdateErpClosingBalance(row, e.target.value);
                    setBulkTallyCbDate("");
                  } else UpdateClosingBalanceDate(row, e.target.value);
                }
              }}
            />
          </div>
        ),
      },
      {
        id: "ownClosingBalance",
        header: "Closing Balance Own",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance Own
            {(updatingTallyCbDate || loadingTallyCbAll) && (
              <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
            )}
          </div>
        ),
        accessorFn: (row) => (isNaN(Number(row.ownClosingBalance)) ? 0 : Number(row.ownClosingBalance)),
        enableSorting: true,
        size: 250,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.ownClosingBalance}
              returnNull={true}
              value={
                !isNaN(parseFloat(row.original.ownClosingBalance?.toString()))
                  ? parseFloat(row.original.ownClosingBalance?.toString())
                  : row.original.ownClosingBalance
              }
              // placeholder={row.original.ownClosingBalance as string}
              setValue={(value) => {
                if (row.original.ownClosingBalance === null && value === null) return;
                UpdateClosingBalance(row, value);
              }}
              disabled={
                ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                actor.integration ||
                row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                    {Currencies.find((cur) => cur.code === row.original.currency)?.code || DefaultCurrency.INR}
                  </InputAdornment>
                ),
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
              currency={(row.original.currency as Currency) || "INR"}
            />
          </div>
        ),
      },
      {
        header: "Partner Replied on Mail",
        id: "lastPartnerReplyDate",
        accessorFn: (row) => new Date(row.lastPartnerReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastPartnerReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastPartnerReplyDate ? moment(row.original.lastPartnerReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "CB Partner",
        accessorFn: (row) => Number(row.businessPartnerClosingBalance) || 0,
        enableSorting: true,
        size: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      row.original.businessPartnerClosingBalance,
                      (row.original.currency as Currency) || "INR"
                    )
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "CB difference",
        accessorFn: (row) => Number(row.closingBalanceDifference) || 0,
        enableSorting: true,
        size: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.closingBalanceDifference === null
                ? null
                : formatMoney(
                    ToDineroObj(row.original.closingBalanceDifference, (row.original.currency as Currency) || "INR")
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "Attachments",
        accessorFn: (row) => (row.ledgers || row.signedPdf || row.openItems ? "true" : "false"),
        size: 180,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {(row.original.signedPdf || row.original.ledgers || row.original.openItems) && row.original.ticketId ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => {
                  currentSelectedTaskDetailsForAttachments.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.ticketId,
                    mappingId: row.original.mappingId,
                  };
                  setShowTaskAttachmentsModal(true);
                }}
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      // delete individual download columns as attachment column added
      {
        header: "Issue Raised",
        accessorFn: (row) => row.issueRaised || "",
        size: 200,
        filterVariant: "select",
      },
      {
        id: "responseStatus",
        header: "Response Status",
        accessorFn: (row) => row.statusOverview || "",
        size: 240,
        filterVariant: "select",
      },
      {
        header: "Response Approved",
        accessorFn: (row) => row.approvedStatus || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => <div className="textOverflow_hidden">{row.original.approvedStatus}</div>,
      },
      {
        header: "Approved Date",
        accessorFn: (row) => new Date(row.approvedAt || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.approvedAt);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.approvedAt ? moment(row.original.approvedAt)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },

      {
        id: "communicationType",
        header: "Communication Type",
        accessorFn: (row) => row.communicationTypeName || "",
        size: 240,
        filterVariant: "select",
      },
      {
        header: "Additional Remarks",
        accessorFn: (row) => row.remarks || "",
        size: 240,
        filterVariant: "text",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : ""}
          </div>
        ),
      },
      {
        header: "Task ID",
        accessorFn: (row) => row.ticketId?.toString() || "",
        id: "ticketId",
        size: 180,
        filterVariant: "autocomplete",
      },
      {
        header: "Partner ID",
        accessorFn: (row) => row.businessPartnerId?.toString() || "",
        id: "businessPartnerId",
        size: 180,
        filterVariant: "autocomplete",
      },
      {
        header: "Relationship",
        accessorFn: (row) => row?.relationship || "",
        id: "relationship",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.relationship ? row.original.relationship : ""}
          </div>
        ),
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: "equals",
      },
      {
        header: "Email Initiation Date",
        id: "emailInitiationDate",
        accessorFn: (row) => new Date(row.emailInitiationDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.emailInitiationDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.emailInitiationDate ? moment(row.original.emailInitiationDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "Partner Response Date",
        id: "responseSubmittedDate",
        accessorFn: (row) => new Date(row.responseSubmittedDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.responseSubmittedDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.responseSubmittedDate
              ? moment(row.original.responseSubmittedDate)?.format("DD-MM-YYYY")
              : "-"}
          </div>
        ),
      },
      {
        header: "Team Reply Date",
        id: "teamReplyDate",
        accessorFn: (row) => new Date(row.lastTeamReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastTeamReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastTeamReplyDate ? moment(row.original.lastTeamReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "Team Mail Sent Date",
        id: "teamMailSentDate",
        accessorFn: (row) => new Date(row.lastTeamMailSentDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastTeamMailSentDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastTeamMailSentDate ? moment(row.original.lastTeamMailSentDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "taskLabel",
        header: "Task Label",
        accessorFn: (row) => row.label || "",
        size: 240,
        filterVariant: "text",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.label ? row.original.label : ""}
          </div>
        ),
      },
      {
        id: "escalationStartDate",
        header: "Escalation Start Date",
        accessorFn: (row) => new Date(row.escalationStartDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.escalationStartDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.escalationStartDate ? moment(row.original.escalationStartDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "escalationReplyDate",
        header: "Escalation Reply Date",
        accessorFn: (row) => new Date(row.escalationReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.escalationReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.escalationReplyDate ? moment(row.original.escalationReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [actor, history, updatingTallyCbDate, bulkTallyCbDate, loadingTallyCbAll, ASYNC_INTERVAL_OPs_IN_PROGRESS]
  );

  const PartnerCommunicationContextValue: IBcBetaContext = {
    rowsDataBcBeta,
    storeRowsDataBcBeta,
    setRowsDataBcBeta,
    setStoreRowsDataBcBeta,
    listAllBusinessPartnersWSR: () => ListAllBusinessPartnerForBcBeta(),
    listUserEmailTemplates: () => listUserEmailTemplateForBcBeta(""),
    selectedRow,
    setSelectedRow,
    currentRows: currentSelectedRows,
    setCurrentRows: setCurrentSelectedRows,
    withoutTemplateCheck,
    setWithoutTemplateCheck,
    setIntervalBCBeta: () => setIntervalBCBeta(),
    lastMailSyncStatusDate,
    setIntervalMailSyncStatus: () => setIntervalMailSyncStatus(),
    companyId: companyId.current,
    branchCode: branchCode.current,
  };

  const fetchData = async () => {
    // listAllBusinessPartnersForBcBeta();
    listUserEmailTemplateForBcBeta("");
    // pdf templates api auto called after user email templates successfully called
    // listPDFTemplates();
    getListUserEmailSetting();
    await ListAllBusinessPartnerForBcBeta();
    await ListAllPresets();
  };

  useEffect(() => {
    if (!actor.integration) fetchData();

    // clear All context state on component mount
    ResetContext();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (actor.branchLevelReconcilation ? lastCompany && lastBranch : lastCompany) {
      PollErpClosingBalanceStatus();
    }
    // eslint-disable-next-line
  }, [actor, lastCompany, lastBranch]);

  // Main ROWs API
  const ListAllBusinessPartnerForBcBeta = async () => {
    setLoader(true);
    useFetch<BusinessPartnersListForBcBetaRes>(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_BCB.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_BCB.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        // Extract the businessPartnerId values and store them in a new array
        if (!response.data?.businessPartnerList)
          response.data = { businessPartnerList: getBlankData(), allCategories: ["a", "b", "c"], response: "ok" };
        storeBcBetaData.current = response.data?.businessPartnerList || [];
        storeAllCategories.current = response.data?.allCategories || [];
        setRowsDataBcBeta(response.data.businessPartnerList || []);
        setStoreRowsDataBcBeta(response.data.businessPartnerList || []);
        setLoader(false);
        if (Object.keys(appliedFilters).length) setReFilter(true);
        if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
      },
    });
  };

  // User Email Templates for Mail Dialog
  const listUserEmailTemplateForBcBeta = async (createTemplateName = "") => {
    return useFetch<ListUserEmailTemplateResponse>(
      API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
        config: {
          params: {
            templateType: UseCaseType.balanceConfirmationBeta,
            companyId: companyId.current,
          },
        },
        thenCallBack: (response) => {
          const { emailTemplates, lastEmailTemplateSelected } = response.data;
          setEmailTemplates(emailTemplates);
          listPDFTemplates();

          if (lastEmailTemplateSelected) setLastSelectedTemplateID(lastEmailTemplateSelected);
          if (createTemplateName.trim() !== "") {
            if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
              const filteredSelectedTemplate = response.data.emailTemplates?.filter((item: EmailTemplate) => {
                return item.templateName === createTemplateName;
              })[0];
              if (filteredSelectedTemplate) setEmailTemplateContent(filteredSelectedTemplate);
            }
            setOpenSelectEmailTemplate(false);
            setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
            // setSelectedTemplate("");
            setHideSendEmailTemplate(false);
          }
        },
      }
    );
  };

  const listPDFTemplates = (createTemplateName = "") => {
    useFetch<ListPDFTemplatesResponse>(API_ENDPOINTS.LIST_PDF_TEMPLATES.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_PDF_TEMPLATES.failureMessage,
      config: {
        params: {
          templateType: UseCaseType.balanceConfirmation,
        },
      },
      thenCallBack: (response) => {
        setPdfTemplates(response.data.templates || []);
        if (createTemplateName.trim() !== "") {
          setOpenSelectEmailTemplate(false);
          setOpenSendEmailTemplate(hideSendEmailTemplate ? false : true);
          setHideSendEmailTemplate(false);
        }
      },
    });
  };

  // User Display Name and Email Id for Mail Dialog
  const getListUserEmailSetting = async () => {
    useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }

        if (exists(response.data.userEmailSetting?.display_name)) {
          setEmailDisplayName(response.data.userEmailSetting.display_name);
        }
        if (exists(response.data.userEmailSetting?.from_email)) {
          setFromEmailAddress(response.data.userEmailSetting.from_email);
        }

        if (exists(response.data.userEmailSetting?.signature?.image)) {
          setSignatureImgBase64(`data:image/*;base64,${response.data.userEmailSetting.signature.image}`);
        }
        if (exists(response.data.userEmailSetting?.signature?.text)) {
          setSignature(response.data.userEmailSetting.signature.text);
        }
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (
    row: MRT_Row<BusinessPartnerListForBcBeta>,
    value: string[]
  ) => {
    useFetch(API_ENDPOINTS.UPDATE_OWN_AND_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setTempLoader(true);
        updateCategory(row, value);
      },
    });
  };

  // update Rows Category
  const updateCategory = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: string[]) => {
    storeBcBetaData.current.find((v) => v.businessPartnerId === row.original.businessPartnerId).category = value;

    setTempLoader(false);
  };

  // Company Select
  const companyNameSelect = (e: Record<string, any>, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;
      setLastCompany(option);
      if (!actor.branchLevelReconcilation) {
        fetchData();
        getBulkMailStatus();

        if (!intervalId) setIntervalBCBeta();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      setLastBranch(option);
      if (actor.branchLevelReconcilation) {
        fetchData();
        getBulkMailStatus();
        if (!intervalId) setIntervalBCBeta();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setLastBranch(null);
    setRowsDataBcBeta([]);
    setStoreRowsDataBcBeta([]);
  };

  const getDownloadBcBetaReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_BALANCE_CONFIRMATION_REPORT.failureMessage,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        customisation: customization,
      },
      thenCallBack: (response) => {
        setDownloadBcBetaReportBase64(response.data?.base64string);
        downloadBcBetaReport(response.data?.base64string);
      },
    });
  };

  // Download BcBeta Rows Report
  const downloadBcBetaReport = (reportBase64: string) => {
    const excelData = Buffer.from(reportBase64 || downloadBcBetaReportBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, `${actor.name} Partner Communication Report.xlsx`);
  };

  // Delete User Email Templates, View API Method Above Update Categories
  const deleteUserEmailTemplate = (templateId: number) => {
    setShowDeleteLoadingIcon(true);
    useFetch(API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.url, "DELETE", {
      failureMessage: API_ENDPOINTS.DELETE_USER_EMAIL_TEMPLATE.failureMessage,
      config: {
        data: {
          templateId: templateId,
          useCaseType: UseCaseType.balanceConfirmationBeta,
        },
      },
      thenCallBack: (_res) => {
        listUserEmailTemplateForBcBeta("");
        handleCloseDeleteModal();
        setShowDeleteLoadingIcon(false);
      },
      catchCallBack: () => {
        setShowDeleteLoadingIcon(false);
      },
      errorCallback: () => {
        setShowDeleteLoadingIcon(false);
      },
    });
  };

  // View Rows Partner Uploaded Files removed

  // Bulk Mail Status
  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{
        emailResponseMessage: string;
        isCompleted: boolean;
        listOfFailedUsers: { reason: string; businessPartnerName: string; businessPartnerId: number }[];
        failedEmailsWorkbook: string;
        partnerCount: number;
        timeStamp: Date;
      }>(API_ENDPOINTS.GET_BULK_EMAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.GET_BULK_EMAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted && res.data.partnerCount === 0) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            return;
          }

          dispatch(updateBulkMailedTimeBCB(res.data.timeStamp));
          dispatch(updateMailSentPartnerCountBCB(res.data.partnerCount));
          if (res.data.isCompleted) {
            ListAllBusinessPartnerForBcBeta();

            if (res.data?.emailResponseMessage !== "") {
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (intervalId !== null) {
              clearInterval(intervalId);
              intervalId = null;
            }
            dispatch(updateIsSendingBulkMailsBCB(false));

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });
              dispatch(updateEmailResponseMsgBCB(res.data.emailResponseMessage || ""));
              dispatch(updateMailFailedUsersBCB(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookBCB(res.data?.failedEmailsWorkbook || ""));
              // reload templates after mail sent
              listUserEmailTemplateForBcBeta("");
            } else dispatch(updateMailFailedUsersBCB([]));
          } else {
            dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const UpdateClosingBalance = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: number) => {
    useFetch<{ message: string }>(API_ENDPOINTS.UPDATE_CLOSING_BALANCE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLOSING_BALANCE.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        closingBalance: value,
      },
      thenCallBack: (_res) => {
        row.original.ownClosingBalance = value;
      },
    });
  };

  const UpdateClosingBalanceDate = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: string) => {
    useFetch<{ message: string }>(API_ENDPOINTS.UPDATE_CLOSING_BALANCE_DATE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLOSING_BALANCE_DATE.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        closingBalanceDate: value,
      },
      thenCallBack: (_res) => {
        row.original.closingBalanceDate = value as any;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  const listAllBusinessPartnerRequest = () => {
    // setLoadingPartnerRequests(true);
    return useFetch<{ requestMap: Record<string, RaisedIssueBc[]> }>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNER_REQUESTS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (_res) => {
          // setLoadingPartnerRequests(false);
          const { requestMap } = _res.data;
          if (Object.keys(requestMap)?.length > 0) {
            setRaisedIssues(
              Object.keys(requestMap)?.map((item) => {
                const { businessPartnerName, vendorCode } = JSON.parse(item);
                return {
                  businessPartnerName: businessPartnerName,
                  vendorCode: vendorCode,
                  issues: requestMap[item],
                };
              })
            );
          } else setRaisedIssues([]);
        },
        catchCallBack: () => {
          // setLoadingPartnerRequests(false);
        },
      }
    );
  };

  const markIssueResolved = async (_businessPartnerId: number, _mappingId: number, issueId: number) => {
    uiLogger(
      uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_RESOLVED_CLICK.functionName,
      companyId.current,
      branchCode.current,
      { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_RESOLVED_CLICK.message }
    );
    return new Promise<{ success: boolean }>((resolve, reject) =>
      useFetch(API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.url, "POST", {
        failureMessage: API_ENDPOINTS.MARK_ISSUE_AS_RESOLVED.failureMessage,
        showSuccessToast: true,
        data: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          businessPartnerId: _businessPartnerId,
          mappingId: _mappingId,
          issueId,
        },
        thenCallBack: (_res) => {
          listAllBusinessPartnerRequest();
          resolve({ success: true });
        },
        catchCallBack: reject,
        errorCallback: reject,
      })
    );
  };
  const approveResponse = async (rows: BusinessPartnerListForBcBeta[]) => {
    useFetch<{ message: string }>(API_ENDPOINTS.APPROVE_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.APPROVE_STATUS.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerIds: rows.map((row) => row.businessPartnerId),
        mappingIds: rows.map((row) => row.mappingId),
        businessPartnerDetails: rows.map((row) => ({
          businessPartnerId: row.businessPartnerId,
          mappingId: row.mappingId,
        })),
      },
      thenCallBack: (_res) => {
        setClearRows(true);
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  const StopAutoReminderForPartner = async (businessPartnerId: number, mappingId: number) => {
    return useFetch<{ message: string }>("/api/AutoReminder/StopActiveAutoReminderForGivenPartner", "POST", {
      failureMessage: "Failed to stop auto reminder",
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId,
        mappingId,
      },
      thenCallBack: (_res) => {
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  const resetStatus = async (rows: BusinessPartnerListForBcBeta[]) => {
    return await useFetch<{ message: string }>(API_ENDPOINTS.RESET_STATES_ON_PARTNER_LEVEL.url, "POST", {
      failureMessage: API_ENDPOINTS.RESET_STATES_ON_PARTNER_LEVEL.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        businessPartnerId: rows.map((row) => row.businessPartnerId),
        mappingId: rows.map((row) => row.mappingId),
      },
      thenCallBack: (_res) => {
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  const downloadPastCommunicationReport = (customization: ReportDetailsCustomisationRes) => {
    return useFetch<{ base64string: string }>(API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_EXCEL_REPORT_FOR_HISTORY.failureMessage,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        customisation: customization,
      },
      thenCallBack: (response) => {
        const { base64string } = response.data;
        if (base64string) {
          const excelData = Buffer.from(base64string, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${actor.name} Past Communication.xlsx`);
        }
      },
    });
  };

  // START Space for Bulk Tasks

  const [showReviewCloseAndResetTasksModal, setShowReviewCloseAndResetTasksModal] = useState<boolean>(false);
  const [showConfirmCloseAndResetTasksModal, setShowConfirmCloseAndResetTasksModal] = useState<boolean>(false);
  const taskDetailsForSingleCloseAndReset = useRef<{ ticketId: number; mappingId: number }>(null);

  const closeAndResetTasks = async (taskDetails) => {
    await useFetch(API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.url, "POST", {
      failureMessage: API_ENDPOINTS.CLOSE_TICKET_AND_RESET_COMMUNICATION.failureMessage,
      data: { taskData: taskDetails, companyId: companyId.current, branchCode: branchCode.current },
      showSuccessToast: true,
      thenCallBack: () => {
        ListAllBusinessPartnerForBcBeta();
      },
      catchCallBack: () => {
        ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  const [showReviewMoveBulkTasksModal, setShowReviewMoveBulkTasksModal] = useState<boolean>(false);
  const [showMoveTaskToReconModal, setShowMoveTaskToReconModal] = useState<boolean>(false);
  const [moveTaskToReconModalTitle, setMoveTaskToReconModalTitle] = useState<string>("");
  const [tasksToMoveCount, setTasksToMoveCount] = useState<number>(0);

  const [moveToReconTaskDetails, setMoveToReconTaskDetails] = useState<
    { ticketId: number; businessPartnerId: number; mappingId: number }[]
  >([]);
  const bpIdForCheckingAlreadyExistingTask = useRef<number>(null);
  const mappingIdForCheckingAlreadyExistingTask = useRef<number>(null);
  const [isMovingBulkTasks, setIsMovingBulkTasks] = useState<boolean>(false);
  const [businessPartnerDetailsForWhichTaskAlreadyExists, setBusinessPartnerDetailsForWhichTaskAlreadyExists] =
    useState<
      {
        ticketId: number;
        businessPartnerId: number;
      }[]
    >([]);
  const [isLoadingAlreadyExistingTasks, setIsLoadingAlreadyExistingTasks] = useState(false);
  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const currentSelectedTaskDetails = useRef<{ businessPartnerName: string; taskId: number; mappingId: number }>(null);
  const currentSelectedTaskDetailsForAttachments = useRef<{
    businessPartnerName: string;
    taskId: number;
    mappingId: number;
  }>(null);
  const taskAlreadyExistForSingleMoveToRecon = useRef<boolean>(false);

  const getAlreadyExistingTasks = async (forBulk: boolean) => {
    setIsLoadingAlreadyExistingTasks(true);

    let businessPartnerData = [];
    if (forBulk === false)
      businessPartnerData = [
        {
          businessPartnerId: bpIdForCheckingAlreadyExistingTask.current,
          mappingId: mappingIdForCheckingAlreadyExistingTask.current,
        },
      ];
    else {
      businessPartnerData = selectedRow.map((task) => {
        return {
          businessPartnerId: task.businessPartnerId,
          mappingId: task.mappingId,
        };
      });
    }
    await useFetch(API_ENDPOINTS.GET_EXISTING_TICKETS.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_EXISTING_TICKETS.failureMessage,
      data: {
        businessPartnerData,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (response) => {
        setBusinessPartnerDetailsForWhichTaskAlreadyExists(response.data.businessPartnerWiseTickets);
        if (forBulk === false && response.data.count === 1) taskAlreadyExistForSingleMoveToRecon.current = true;

        setIsLoadingAlreadyExistingTasks(false);
      },
      catchCallBack: () => {
        setIsLoadingAlreadyExistingTasks(false);
      },
    });
  };

  // END Space for Bulk Tasks

  // Api:  /syncMail
  // Type: post;

  const syncMail = async () => {
    setIsSyncingMailStatus(true);
    return useFetch(API_ENDPOINTS.SYNC_MAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.SYNC_MAIL.failureMessage,
      showSuccessToast: true,
      thenCallBack: () => {
        setIntervalMailSyncStatus();
        // ListAllBusinessPartnerForBcBeta();
      },
    });
  };

  interface MailThreadRefreshState {
    isCompleted: boolean;
    isSuccessful: boolean;
    // errorList: ErrorDetailsFetchStatus[];
    lastSyncedAt: Date;
  }

  // Bulk Mail Status
  const getSyncMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{ status: MailThreadRefreshState }>(API_ENDPOINTS.SYNC_MAIL_STATUS.url, "GET", {
        failureMessage: API_ENDPOINTS.SYNC_MAIL_STATUS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data?.status?.isCompleted) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            if (newIntervalId !== null) {
              clearInterval(newIntervalId);
              newIntervalId = null;
            }
          }

          setLastMailSyncStatusDate(res.data.lastSyncedAt);
          if (res.data?.status?.isCompleted) {
            if (res.data?.emailResponseMessage) {
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }

            resolve({ isCompleted: true });

            ListAllBusinessPartnerForBcBeta();
            setIsSyncingMailStatus(false);
            // dispatch(updateIsSendingBulkMailsBCB(false));
          } else {
            setIsSyncingMailStatus(true);
            // dispatch(updateIsSendingBulkMailsBCB(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(newIntervalId);
          newIntervalId = null;
          setIsSyncingMailStatus(false);
          // dispatch(updateIsSendingBulkMailsBCB(false));
          reject();
        },
      });
    });
  };

  const setIntervalMailSyncStatus = () => {
    newIntervalId = setInterval(async () => {
      const { isCompleted } = await getSyncMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(newIntervalId);
      }
    }, 15_000);
  };

  const setIntervalBCBeta = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30_000);
  };

  useEffect(() => {
    if (actor.integration === false) {
      getBulkMailStatus();
      getSyncMailStatus();
      if (!intervalId) {
        setIntervalBCBeta();
      }
      if (!newIntervalId) {
        setIntervalMailSyncStatus();
      }
    }
  }, []);

  useEffect(() => {
    if (updatingTallyCbDate) {
      const intervalsInMs = [10_000, 20_000, 30_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            PollErpClosingBalanceStatus();
          } finally {
            if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastTallyInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastTallyInterval.current);
    }

    return () => window.clearInterval(lastTallyInterval.current);
    // eslint-disable-next-line
  }, [updatingTallyCbDate]);

  const PollErpClosingBalanceStatus = async () => {
    interface Response {
      isClosingBalanceUpdating: boolean;
      error: string;
      closingBalanceDate: string;
    }

    useFetch<Response>(API_ENDPOINTS.GET_ERP_CLOSING_BALANCE_STATUS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ERP_CLOSING_BALANCE_STATUS.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          service: "BalanceConfirmation",
        },
      },
      thenCallBack: (res) => {
        const { isClosingBalanceUpdating, closingBalanceDate } = res.data;
        let TallyCbDate = "";
        if (!isClosingBalanceUpdating) {
          if (closingBalanceDate) TallyCbDate = moment(closingBalanceDate).format("YYYY-MM-DD");
          else TallyCbDate = moment().format("YYYY-MM-DD");

          setDefaultTallyCbDate(TallyCbDate);
          setUpdatingTallyCbDate(false);
          if (rowsDataBcBeta && rowsDataBcBeta.length) GetErpClosingBalanceForAllBp(rowsDataBcBeta);

          if (closingBalanceDate) setBulkTallyCbDate(TallyCbDate);
        } else if (isClosingBalanceUpdating) {
          setUpdatingTallyCbDate(true);
          if (closingBalanceDate && !bulkTallyCbDate)
            setBulkTallyCbDate(moment(closingBalanceDate).format("YYYY-MM-DD"));
          else if (!closingBalanceDate) setDefaultTallyCbDate(moment().format("YYYY-MM-DD"));
        }
      },
    });
  };

  const GetErpClosingBalanceForAllBp = (rowsBalanceConfirmation: BusinessPartnerListForBcBeta[]) => {
    useFetch<TallyClosingBalanceForAllBpResponse>(API_ENDPOINTS.GET_ERP_CLOSING_BALANCE_FOR_ALL_BP.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ERP_CLOSING_BALANCE_FOR_ALL_BP.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          closingBalanceDate: moment(defaultTallyCbDate).format("YYYY-MM-DD"),
          service: "BalanceConfirmation",
        },
      },
      thenCallBack: (res) => {
        const { closingBalanceOfAllBp } = res.data;
        if (closingBalanceOfAllBp?.length > 0) {
          rowsBalanceConfirmation?.forEach((row) => {
            const closingBalObj = closingBalanceOfAllBp.find((cb) => cb.mappingId === row.mappingId);
            if (closingBalObj) {
              row.ownClosingBalance = closingBalObj.closingBalance as number;
              row.closingBalanceDate = closingBalObj.closingBalanceDate as any;
            }
          });

          if (rowsBalanceConfirmation && rowsBalanceConfirmation[0]?.mappingId !== 0)
            setRowsDataBcBeta([...rowsBalanceConfirmation]);

          const firstRowCBDate = closingBalanceOfAllBp[0].closingBalanceDate;
          const allRowCBDateSame = closingBalanceOfAllBp.every((row) => row.closingBalanceDate === firstRowCBDate);

          // const arr = Object.entries(count)?.sort((a, b) => b[1] - a[1]);
          if (allRowCBDateSame) setBulkTallyCbDate(firstRowCBDate);
          else setBulkTallyCbDate(null);
        }

        setLoadingTallyCbAll(false);
      },
    });
  };

  const UpdateErpClosingBalanceForAllBp = (date: string) => {
    setUpdatingTallyCbDate(true);
    setLoadingTallyCbAll(true);
    const momentDate = moment(date).isValid() ? moment(date) : moment();
    useFetch<TallyClosingBalanceForAllBpResponse>(API_ENDPOINTS.UPDATE_ERP_CLOSING_BALANCE_FOR_ALL_BP.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_ERP_CLOSING_BALANCE_FOR_ALL_BP.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: momentDate.format("YYYY-MM-DD"),
        service: "BalanceConfirmation",
      },
    });
  };

  const UpdateErpClosingBalance = (row: MRT_Row<BusinessPartnerListForBcBeta>, date: string) => {
    const Response = {
      businessPartnerId: 0,
      closingBalance: 0,
      closingBalanceDate: "",
      openingBalance: 0,
    };

    useFetch<typeof Response>(API_ENDPOINTS.UPDATE_ERP_CLOSING_BALANCE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_ERP_CLOSING_BALANCE.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        closingBalanceDate: moment(date).format("YYYY-MM-DD"),
        service: "BalanceConfirmation",
      },
      thenCallBack: (res) => {
        if (res.data.mappingId === row.original.mappingId) row.original.ownClosingBalance = res.data.closingBalance;
        storeClosingBalanceValueFlag.current = false;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  // Table State and Presets
  const ListAllPresets = async () => {
    return useFetch<{ data: { presetName: string; presetData: ModifiedPresetData }[] }>(
      API_ENDPOINTS.LIST_ALL_PRESETS.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_PRESETS.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: async (response) => {
          const { data } = response.data;
          if (data) {
            const tempData = {};
            data.forEach((preset) => {
              tempData[preset.presetName] = preset.presetData;
            });
            setAllTablePresets(tempData);

            await GetActivePreset(tempData);
          }
        },
      }
    );
  };

  const CreateOrUpdatePreset = async (
    _oldName: string,
    _name: string,
    _presetData: ModifiedPresetData,
    isUpdating: boolean
  ) => {
    return useFetch(API_ENDPOINTS.CREATE_OR_UPDATE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.CREATE_OR_UPDATE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        presetName: _oldName,
        presetData: _presetData,
        updateExisting: isUpdating,
        updatedPresetName: _name,
      },
      thenCallBack: async () => {
        await ListAllPresets();
        // setAllTablePresets((prev) => ({
        //   ...prev,
        //   [_name]: _presetData,
        // }));

        // setSelectedTablePreset(_name);
        // setTablePresetState(_presetData);
      },
    });
  };

  const handlePresetAddUpdate = async (_oldName: string, _name: string, isUpdating?: boolean) => {
    if (!_name) return;
    const currentState = tableInstance?.getState();
    const { columnOrder, columnSizing, columnVisibility, columnPinning } = currentState;
    const presetData: ModifiedPresetData = { columnOrder, columnSizing, columnVisibility, columnPinning };

    await CreateOrUpdatePreset(_oldName, _name, presetData, isUpdating);
  };

  const DeletePreset = async (presetName: string) => {
    return useFetch(API_ENDPOINTS.DELETE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.DELETE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        presetName,
      },
      thenCallBack: async () => {
        const tempData = { ...allTablePresets };
        delete tempData[presetName];
        setAllTablePresets(tempData);
        await ListAllPresets();
      },
    });
  };

  const SaveActivePreset = async (_presetName: string) => {
    return useFetch<{ response: string; message: string }>(API_ENDPOINTS.SAVE_ACTIVE_PRESET.url, "POST", {
      failureMessage: API_ENDPOINTS.SAVE_ACTIVE_PRESET.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        presetName: _presetName,
      },
    });
  };

  const GetActivePreset = async (_allTablePresets: { [k: string]: ModifiedPresetData }) => {
    return useFetch<{ presetName: string }>(API_ENDPOINTS.GET_ACTIVE_PRESET.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_ACTIVE_PRESET.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        const { presetName } = response.data;
        // if (!presetName || !_allTablePresets[presetName]) return;

        setSelectedTablePreset(presetName);
        setTablePresetState(_allTablePresets[presetName]);
      },
    });
  };

  const GREEN = "#27B27C";

  const NotHistoryAndSettingsPages = !openBcBetaHistory && !openBcBetaSettings;
  const NotMailingPages = !openBcBetaMailing && !openBcBetaBulkMailing && !openSingleMailing;

  const noInternalPagesOpen = NotHistoryAndSettingsPages && NotMailingPages;
  const openBcBetaHistoryPage = openBcBetaHistory && !openBcBetaSettings && NotMailingPages;
  const openBcBetaMailPage =
    openBcBetaMailing && !openBcBetaBulkMailing && !openSingleMailing && NotHistoryAndSettingsPages;
  const openBcBetaBulkMailPage =
    openBcBetaBulkMailing && !openBcBetaMailing && !openSingleMailing && NotHistoryAndSettingsPages;
  const openBcBetaSingleMailingPage =
    openSingleMailing && !openBcBetaBulkMailing && !openBcBetaMailing && NotHistoryAndSettingsPages;

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <BcBetaContext.Provider value={PartnerCommunicationContextValue}>
        {
          <Grid className="">
            <Grid position="relative">
              <Grid className="vertical_center_align" sx={{ gap: 2, flexWrap: "wrap" }}>
                {noInternalPagesOpen && (
                  <Box
                    className="vertical_center_align"
                    justifyContent="space-between"
                    sx={{ px: 2, py: 1, bgcolor: "#fff", borderRadius: 1, boxShadow: 1, width: "100%" }}
                  >
                    {/* Left */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <Button
                        variant="outlined"
                        endIcon={<ArrowDropDown />}
                        sx={{ borderColor: "#541c4c80 !important", color: "#541c4c", borderRadius: "4px !important" }}
                        onClick={(ev) => {
                          setAnchorElBulk(ev.currentTarget);
                        }}
                      >
                        BULK ACTION
                      </Button>
                      {/* Menu for Bulk Action */}
                      <Menu
                        anchorEl={anchorElBulk}
                        open={Boolean(anchorElBulk)}
                        onClose={handleMenuCloseBulk}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {/* "Bulk Send Email" */}
                        <MenuItem
                          onClick={async () => {
                            /* Bulk Send Email logic */
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_CLICK.message,
                              }
                            );
                            setSendSoleOrBulkMail(EmailSendCount.Bulk);
                            setCurrentSelectedRows(selectedRow);

                            // setOpenSelectEmailTemplate(true);
                            const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
                            const allRowsSameCommsType = selectedRow.every(
                              (row) => row?.communicationTypeId === firstFoundCommType?.communicationTypeId
                            );

                            // set to default step to 0, to reset the stepper, stepper page set by MultipleCommType Dialog
                            setActiveStep(0);

                            const dateDiff = DateDifference(moment(), moment(lastMailSyncStatusDate), "minutes");

                            // lmao
                            if (Math.abs(dateDiff) > 60) {
                              setOpenStatusRefreshDialog(true);
                              setDifferenceTime(dateDiff || differenceTime);
                            } else if (firstFoundCommType && allRowsSameCommsType === false) {
                              setOpenMultipleCommsType(true);
                            } else {
                              // next page in the Bulk Mailing stepper if the communication type is preset else first page to select comms type
                              setCommunicationTypeId(firstFoundCommType?.communicationTypeId || null);
                              setActiveStep(firstFoundCommType?.communicationTypeId ? 1 : 0);
                              setOpenBcBetaBulkMailing(true);
                            }
                            // close menu
                            handleMenuCloseBulk();
                          }}
                          disabled={selectedRow.length < 1 ? true : ASYNC_INTERVAL_OPs_IN_PROGRESS}
                        >
                          Bulk Send Email
                        </MenuItem>
                        {/* "Bulk Approve Response" */}
                        <MenuItem
                          onClick={() => {
                            /* Bulk Approve Response logic */
                            setCurrentSelectedRows(selectedRow);
                            setOpenApproveDialog(true);
                            isApprovingInBulk.current = true;
                            // close menu
                            handleMenuCloseBulk();
                          }}
                          disabled={selectedRow.length < 2}
                        >
                          Bulk Approve Response
                        </MenuItem>
                        {/* "Bulk Move Task to Recon" */}
                        <MenuItem
                          onClick={() => {
                            /* Bulk Move Task to Recon logic */
                            // close menu
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CLICK
                                .functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKMOVETASKTORECON_CLICK
                                    .message,
                              }
                            );
                            setShowReviewMoveBulkTasksModal(true);
                            setMoveTaskToReconModalTitle("Move Bulk Tasks");
                            setIsMovingBulkTasks(true);
                            // close menu
                            handleMenuCloseBulk();
                          }}
                          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS || selectedRow.length < 1}
                        >
                          Bulk Move Task to Recon
                        </MenuItem>
                        {/* Bulk Close and Reset */}
                        <MenuItem
                          disabled={selectedRow.length < 1}
                          onClick={() => {
                            // close menu
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKCLOSEANDRESET_CLICK
                                .functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULKACTION_BULKCLOSEANDRESET_CLICK
                                    .message,
                              }
                            );
                            setShowReviewCloseAndResetTasksModal(true);
                            // close menu
                            handleMenuCloseBulk();
                          }}
                        >
                          Bulk Close and Reset
                        </MenuItem>
                      </Menu>
                      {/* Filter */}
                      <Button
                        startIcon={isFilterAppliedBcBeta ? <FilterList /> : <FilterListOff />}
                        onClick={() => {
                          uiLogger(uiLoggerName.ui_ClickedOnFilter, companyId.current, branchCode.current);
                          setOpenBcBetaFilter(true);
                        }}
                      >
                        FILTER
                      </Button>
                      {/* Partner Requests Async Button */}
                      <NdAsyncButton
                        onClick={async () => {
                          uiLogger(
                            uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_CLICK.functionName,
                            companyId.current,
                            branchCode.current,
                            {
                              message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_PARTNER_REQUESTS_CLICK.message,
                            }
                          );
                          await listAllBusinessPartnerRequest();
                          setOpenRaiseReq(true);
                        }}
                        color="inherit"
                        buttonComponent={Button}
                        loadingIcon={<LoadingIcon loading={true} />}
                        startIcon={
                          <svg className="icon" style={{ height: "30px", width: "30px", fill: "#1976d2" }}>
                            <use xlinkHref="#icon-person-alert" />
                          </svg>
                        }
                        sx={{ color: "primary.main", minHeight: 42 }}
                      >
                        PARTNER REQUESTS
                      </NdAsyncButton>
                      {/* Active Auto Reminders Async Button */}
                      {import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.test && (
                        <NdAsyncButton
                          onClick={async () => {
                            setOpenActAutoReminder(true);
                          }}
                          color="inherit"
                          buttonComponent={Button}
                          loadingIcon={<LoadingIcon loading={true} />}
                          startIcon={<ForwardToInbox />}
                          sx={{ color: "primary.main", minHeight: 42 }}
                        >
                          Active Auto-reminders
                        </NdAsyncButton>
                      )}
                    </Box>
                    {/* Right */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <Button
                        endIcon={<MoreVert />}
                        sx={{ color: "rgba(0,0,0,0.6)" }}
                        onClick={(ev) => {
                          setAnchorElOpts(ev.currentTarget);
                        }}
                      >
                        MORE OPTIONS
                      </Button>
                      {/* Menu for More Options */}
                      <Menu
                        anchorEl={anchorElOpts}
                        open={Boolean(anchorElOpts)}
                        onClose={handleMenuCloseOpts}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        {/* Download Report */}
                        <MenuItem
                          onClick={() => {
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_DOWNLOAD_REPORT_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_DOWNLOAD_REPORT_CLICK.message }
                            );
                            setIsHistoryReport(false);
                            setOpenDownloadReportDlg(true);
                            // close the menu
                            handleMenuCloseOpts();
                          }}
                          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
                        >
                          Download Report
                        </MenuItem>
                        {/* Manage Communication Type */}
                        <MenuItem
                          onClick={() => {
                            setOpenEditCommsType(true);
                            // close the menu
                            handleMenuCloseOpts();
                          }}
                        >
                          Manage Communication Type
                        </MenuItem>
                        {/* Past Communications */}
                        {/* <MenuItem
                          onClick={() => {
                            setOpenBcBetaHistory(true);
                            // close the menu
                            handleMenuCloseOpts();
                          }}
                        >
                          Past Communication
                        </MenuItem> */}
                        {/* Balance Confirmation Settings */}
                        {/* Removed Bc Beta Setting */}
                        <MenuItem
                          onClick={() => {
                            setIsHistoryReport(true);
                            setOpenDownloadReportDlg(true);
                            // downloadPastCommunicationReport();
                            // close the menu
                            handleMenuCloseOpts();
                          }}
                        >
                          Download Past Communication Report
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                )}

                {/* Left Side Dropdowns */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {noInternalPagesOpen && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={null}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={null}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={null}
                          isDisabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Settings Back Button */}
                {openBcBetaSettings && (
                  <Tooltip title="Back" arrow={true}>
                    <Avatar sx={{ background: GREEN, ml: "auto", mr: 5, zIndex: 3 }}>
                      <IconButton onClick={() => setOpenBcBetaSettings(false)} color="inherit">
                        <span className="fa-icon">
                          <i className="fas fa-arrow-left" />
                        </span>
                      </IconButton>
                    </Avatar>
                  </Tooltip>
                )}

                {/* Right Side Buttons */}
                {/* <span className="file-icon" style={{ position: "absolute", transform: "scale(2.5)", left: 8, color: "white" }}></span> */}
              </Grid>

              {/* Filter Applied Bar */}
              {noInternalPagesOpen && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              {/* Bulk Mailing Bar */}
              {noInternalPagesOpen && (isSendingBulkMailsBCB || mailSentPartnerCountBCB > 0) && (
                <Alert
                  color={isSendingBulkMailsBCB ? "info" : mailFailedUsersBCB.length > 0 ? "error" : "success"}
                  icon={
                    isSendingBulkMailsBCB ? (
                      <CircularProgress size={16} />
                    ) : mailFailedUsersBCB.length > 0 ? (
                      <Warning />
                    ) : (
                      <CheckCircle />
                    )
                  }
                  sx={{
                    mb: 2,
                    border: "1px solid #0288D1",
                    borderColor: isSendingBulkMailsBCB
                      ? "#0288D1"
                      : mailFailedUsersBCB.length > 0
                      ? "#D32F2F"
                      : "#2E7D32",
                    alignItems: "center",
                  }}
                  action={
                    !isSendingBulkMailsBCB && (
                      <Box className="right">
                        {mailFailedUsersBCB.length > 0 && (
                          <Button
                            variant="text"
                            onClick={() => {
                              setOpenBulkMailReportDialog(true);
                            }}
                          >
                            View Issues
                          </Button>
                        )}
                        <IconButton
                          onClick={() => {
                            dispatch(updateEmailResponseMsgBCB(""));
                            dispatch(updateMailFailedUsersBCB([]));
                            dispatch(updateMailFailedWorkbookBCB(""));
                            dispatch(updateMailSentPartnerCountBCB(0));
                            dispatch(updateBulkMailedTimeBCB(null));
                          }}
                          sx={{ ml: "auto" }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    )
                  }
                >
                  <Box className="d_flex" sx={{ alignItems: "center" }}>
                    <Typography className="vertical_center_align" fontWeight="medium" fontSize={16}>
                      {isSendingBulkMailsBCB
                        ? `Sending mail to ${mailSentPartnerCountBCB} Partners | Initiated on: 
                        ${formatDateTime24Hours(bulkMailedTimeBCB)}`
                        : mailFailedUsersBCB.length > 0
                        ? `Action required for ${mailSentPartnerCountBCB} Partners`
                        : `Bulk Email Sent to ${mailSentPartnerCountBCB} Partners | Sent on:   ${formatDateTime24Hours(
                            bulkMailedTimeBCB
                          )}`}
                    </Typography>
                  </Box>
                </Alert>
              )}

              <div
                className="bpDashboard"
                style={{
                  minHeight: "60vh",
                  position: "relative",
                  width: "100%",
                  top: openBcBetaSettings ? "-50px" : "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                    height: "inherit",
                  }}
                >
                  {noInternalPagesOpen && (
                    <ReactTable
                      key={JSON.stringify(tablePresetState)}
                      columns={columnDefinitionBcBeta}
                      rows={rowsDataBcBeta}
                      setSelectedRow={setSelectedRow}
                      loading={loader}
                      enableGrouping={true}
                      positionToolbarAlertBanner={"top"}
                      clearRowsSelection={clearRows}
                      setClearRowsSelection={setClearRows}
                      additionalInitialState={{ showColumnFilters: true, ...tablePresetState }}
                      internalActionsOrder={[
                        "globalFilterToggle",
                        "filterToggle",
                        "showHideColumns",
                        "fullScreenToggle",
                      ]}
                      initialStateColumnVisibility={{
                        ticketId: false,
                        businessPartnerId: false,
                        emailInitiationDate: false,
                        responseSubmittedDate: false,
                        relationship: false,
                        teamReplyDate: false,
                        teamMailSentDate: false,
                        taskLabel: false,
                        responseStatus: false,
                        communicationType: false,
                      }}
                      renderRowActionMenuItems={({ row, closeMenu }) => [
                        // {/* Send/View Email Button */}
                        <MenuItem
                          disabled={ASYNC_INTERVAL_OPs_IN_PROGRESS}
                          key={"sendViewMail"}
                          onClick={async () => {
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              { message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_SEND_VIEW_MAIL_CLICK.message }
                            );
                            // logic
                            setEmailBusinessPartnerId(row.original.businessPartnerId as number);
                            setMappingId(row.original.mappingId || null);
                            setBusinessPartnerName(row.original.businessPartnerName);
                            setSendSoleOrBulkMail(EmailSendCount.Sole);
                            // getListUserEmailSetting();
                            setCommunicationTypeId(row.original.communicationTypeId);
                            setCurrentSelectedRows([row.original]);

                            if (
                              row.original &&
                              row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                            ) {
                              setOpenBcBetaMailing(true);
                            } else if (row.original) {
                              setSelectedRow([row.original]);
                              setOpenBcBetaNewCommsDialog(true);
                            }

                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <MailOutline sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Send/View Email
                        </MenuItem>,

                        // {/* View Recon */}
                        // Button Removed
                        // {/* Approve Response */}
                        <MenuItem
                          disabled={
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"approveResponse"}
                          onClick={async () => {
                            // logic
                            setCurrentSelectedRows([row.original]);
                            setOpenApproveDialog(true);
                            isApprovingInBulk.current = false;
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <TaskAlt sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Approve Response
                        </MenuItem>,
                        // {/* Update Response */}
                        <MenuItem
                          disabled={
                            !row.original.portalLink ||
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"updatedResponse"}
                          onClick={async () => {
                            // logic
                            setCurrentSelectedRows([row.original]);
                            setOpenUpdateResDialog(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <CallMade sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Update Response
                        </MenuItem>,
                        // {/* Stop Auto Reminder */}

                        <MenuItem
                          hidden={import.meta.env.VITE_APP_NAKAD_ENV === ENV_NAME.test ? false : true}
                          disabled={
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"stopAutoReminder"}
                          onClick={async () => {
                            // logic
                            setCurrentSelectedRows([row.original]);
                            setOpenStopReminder(true);
                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Unsubscribe sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Stop Auto-reminder
                        </MenuItem>,
                        // {/* Move Task to Recon */}
                        <MenuItem
                          disabled={
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            isLoadingAlreadyExistingTasks ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"moveTaskToRecon"}
                          onClick={async () => {
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_MOVETASKTORECON_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_MOVETASKTORECON_CLICK.message,
                              }
                            );
                            if (row.original.statusOverview === "-") {
                              toast.error(
                                <CustomToast message="Unable to move task to recon as no mail sent to the partner" />
                              );
                              return;
                            }
                            if (row.original.statusOverview === "Pending") {
                              toast.error(
                                <CustomToast message="Unable to move the task to recon as Partner reply is pending" />
                              );
                              return;
                            }
                            if (row.original.statusOverview === "Confirmed") {
                              toast.error(
                                <CustomToast message="Unable to move the task to recon as balance is confirmed by the partner" />
                              );
                              return;
                            }
                            if (
                              row.original.isApproved === false &&
                              (row.original.statusOverview === "Not Confirmed" ||
                                row.original.statusOverview === "Ledger Submitted")
                            ) {
                              toast.error(
                                <CustomToast message="Unable to move the task to recon as response is not approved" />
                              );
                              return;
                            }

                            bpIdForCheckingAlreadyExistingTask.current = row.original.businessPartnerId;
                            mappingIdForCheckingAlreadyExistingTask.current = row.original.mappingId;

                            await getAlreadyExistingTasks(false);

                            if (taskAlreadyExistForSingleMoveToRecon.current === true) {
                              toast.error(<CustomToast message="Task already present for this business partner" />);
                              taskAlreadyExistForSingleMoveToRecon.current = false;
                              return;
                            }
                            setMoveToReconTaskDetails([
                              {
                                ticketId: row.original.ticketId,
                                businessPartnerId: row.original.businessPartnerId,
                                mappingId: row.original.mappingId,
                              },
                            ]);
                            setMoveTaskToReconModalTitle(`Move Task for ${row.original.businessPartnerName}`);
                            setTasksToMoveCount(1);
                            setIsMovingBulkTasks(false);
                            setShowMoveTaskToReconModal(true);
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            {isLoadingAlreadyExistingTasks ? (
                              <LoadingIcon loading={true} />
                            ) : (
                              <KeyboardTabIcon sx={{ fill: "#541c4c" }} />
                            )}
                          </ListItemIcon>
                          Move Task To Recon
                        </MenuItem>,
                        // {/* Close and Reset Task */}
                        <MenuItem
                          disabled={
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"closeAndResetTask"}
                          onClick={async () => {
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message:
                                  uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_CLOSEANDRESET_CLICK.message,
                              }
                            );
                            closeMenu();

                            const { isApproved, statusOverview } = row.original;
                            if (statusOverview === "Confirmed" && isApproved === false) {
                              toast.error(
                                <CustomToast message="Unable to close and reset the task as Partner response is not approved" />
                              );
                              closeMenu();
                              return;
                            }
                            if (
                              (statusOverview === "Not Confirmed" || statusOverview === "Ledger Submitted") &&
                              isApproved === false
                            ) {
                              toast.error(
                                <CustomToast message="Unable to close and reset the task as Partner response is not approved" />
                              );
                              closeMenu();
                              return;
                            }
                            if (statusOverview === "-") {
                              toast.error(
                                <CustomToast message="Unable to close and reset the task as no mail sent to the partner" />
                              );
                              closeMenu();
                              return;
                            }
                            if (
                              ((statusOverview === "Not Confirmed" || statusOverview === "Ledger Submitted") &&
                                isApproved) ||
                              (statusOverview === "Confirmed" && isApproved) ||
                              statusOverview === "Pending"
                            ) {
                              taskDetailsForSingleCloseAndReset.current = {
                                ticketId: row.original.ticketId,
                                mappingId: row.original.mappingId,
                              };
                              setShowConfirmCloseAndResetTasksModal(true);
                              closeMenu();
                            }

                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <KeyboardTabIcon sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          Close and Reset
                        </MenuItem>,
                        // {/* View History */}
                        <MenuItem
                          disabled={
                            ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                            row.original.ticketId === null ||
                            row.original.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
                          }
                          key={"viewHistory"}
                          onClick={() => {
                            uiLogger(
                              uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_VIEWHISTORY_CLICK.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_ACTIONS_VIEWHISTORY_CLICK.message,
                              }
                            );
                            currentSelectedTaskDetails.current = {
                              businessPartnerName: row.original.businessPartnerName,
                              taskId: row.original.ticketId,
                              mappingId: row.original.mappingId,
                            };
                            setShowTasksHistoryModal(true);
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <HistoryIcon sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View History
                        </MenuItem>,
                      ]}
                      renderAdditionalBottomToolbarCustomActions={() => {
                        return (
                          <PageSelectModeToggle
                            pageSelectMode={pageSelectMode}
                            setPageSelectMode={setPageSelectMode}
                            useCaseType={UseCaseType.balanceConfirmationBeta}
                            companyId={companyId.current}
                            branchCode={branchCode.current}
                          />
                        );
                      }}
                      renderTopToolbarCustomActions={() => {
                        return (
                          <Stack direction="row" flex={1}>
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <TextField
                                select
                                value={selectedTablePreset}
                                onChange={async (e) => {
                                  setTablePresetState(allTablePresets[e.target.value]);
                                  console.log({ allTablePresets });
                                  setSelectedTablePreset(e.target.value);
                                  if (!e.target.value) return;
                                  await SaveActivePreset(e.target.value);
                                }}
                                SelectProps={{
                                  renderValue: (_value) => selectedTablePreset,
                                }}
                                size="small"
                                label="Select Table View"
                                sx={{ minWidth: 220, ".MuiInputBase-root": { borderRadius: 1 } }}
                              >
                                <MenuItem value="">
                                  <em>Default</em>
                                </MenuItem>
                                {Object.keys(allTablePresets).map((presetName, _i) => (
                                  <MenuItem className="vertical_center_align" key={presetName + _i} value={presetName}>
                                    {presetName}
                                    <NdAsyncButton
                                      variant="text"
                                      size="small"
                                      onClick={async (_e) => {
                                        _e.stopPropagation();
                                        currentPresetRef.current = presetName;
                                        setOpenTablePresetDelDlg(true);
                                        // return await DeletePreset(presetName);
                                      }}
                                      buttonComponent={IconButton}
                                      sx={{ ml: "auto" }}
                                    >
                                      <Delete />
                                    </NdAsyncButton>
                                  </MenuItem>
                                ))}
                              </TextField>
                              <Button
                                startIcon={<Save />}
                                onClick={() => {
                                  console.log("Save Table View", allTablePresets);
                                  setOpenTablePresetDlg(true);
                                  // const name = window.prompt("Enter Table View Name", tablePresetName);
                                }}
                              >
                                Save Table View
                              </Button>
                            </Box>
                            <Box sx={{ display: "flex", ml: "auto" }}>
                              <Tooltip title="Load Mail Replies" arrow placement="left">
                                <IconButton color="info" onClick={syncMail} disabled={isSyncingMailStatus}>
                                  <CachedOutlined className={isSyncingMailStatus ? "spin" : ""} />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Stack>
                        );
                      }}
                      pageSelectMode={pageSelectMode}
                      setTableInstance={setTableInstance}
                    />
                  )}

                  {openBcBetaHistoryPage && (
                    <BalanceConfirmationHistory
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                      setOpen={setOpenBcBetaHistory}
                    />
                  )}

                  {openBcBetaMailPage && (
                    <ViewReplyMailThread
                      setOpen={setOpenBcBetaMailing}
                      branchCode={branchCode.current}
                      companyId={companyId.current}
                    />
                  )}
                  {
                    openBcBetaSingleMailingPage && (
                      <SoloMail
                        setOpenSingleMailing={setOpenSingleMailing}
                        activeStep={activeStepSolo}
                        setActiveStep={setActiveStepSolo}
                      />
                    )
                    //  <SingleMailing handleBack={() => setOpenSingleMailing(false)} />}
                  }
                  {openBcBetaBulkMailPage && (
                    <BulkMail
                      setOpenBcBetaBulkMailing={setOpenBcBetaBulkMailing}
                      activeStep={activeStep}
                      setActiveStep={setActiveStep}
                      companyId={companyId.current}
                      branchCode={branchCode.current}
                    />
                  )}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <BalanceConfirmationBetaFilter
          openBalanceConfirmationFilter={openBcBetaFilter}
          setOpenBalanceConfirmationFilter={setOpenBcBetaFilter}
          allCategories={storeAllCategories.current}
          rowsDataBalanceConfirmation={rowsDataBcBeta}
          setRowsDataBalanceConfirmation={setRowsDataBcBeta}
          storeRowsDataBalanceConfirmation={storeRowsDataBcBeta}
          setIsFilterAppliedBalanceConfirmation={setIsFilterAppliedBcBeta}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />

        {/* Balance Confirmation Beta*/}
        <SelectEmailTemplate
          deleteUserEmailTemplate={(templateID) => {
            deleteUserEmailTemplate(templateID);
          }}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          lastSelectedTemplateID={lastSelectedTemplateID}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
        <SendEmailTemplateBeta
          companyId={companyId.current}
          branchCode={branchCode.current} // wot
          base64Image={signatureImgBase64}
          signature={signature}
          sendSoleOrBulkMail={sendSoleOrBulkMail}
          selectedRow={selectedRow}
          // after sending mail to update date
          listAllBusinessPartnersWSR={ListAllBusinessPartnerForBcBeta}
          // setLoader={setLoader}
          templateType={"BCB"}
          setIntervalBulkMails={setIntervalBCBeta}
          listUserEmailTemplate={listUserEmailTemplateForBcBeta}
          withoutTemplateCheck={withoutTemplateCheck}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
        />
        <CreateEmailTemplate // AddUserEmailTemplate ---- inside this create email temp separate api for ledger and bal
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplate={listUserEmailTemplateForBcBeta}
          templateType={"BCB"}
          beta={true}
        />

        {/* ListBusinessPartnerUsers */}
        <Dialog
          open={openListBusinessPartnerUsers}
          onClose={() => setOpenListBusinessPartnerUsers(true)}
          maxWidth="xl"
          fullWidth
        >
          <ListBusinessPartnerUsers
            setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
            storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers as any}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        </Dialog>

        {/* <MailThreadDialog
          open={openMailThread}
          setOpen={setOpenMailThread}
          mailThreads={mailThreads}
          s3Attachments={[]}
          businessPartnerName={bpName}
          loading={mailLoading}
          msme={true}
          companyId={companyId.current}
          branchCode={branchCode.current}
          useCaseType={null}
          emailSubject={null}
          listAllBusinessPartnersWSR={null}
        /> */}

        <PartnerRequests
          open={openRaiseReq}
          setOpen={setOpenRaiseReq}
          allIssues={raisedIssues}
          markIssueResolved={markIssueResolved}
          setSendSoleOrBulkMail={setSendSoleOrBulkMail}
          setWithoutTemplateCheck={setWithoutTemplateCheck}
          rowsDataBcBeta={rowsDataBcBeta}
          useCaseType={UseCaseType.balanceConfirmationBeta}
        />
        <BulkMailReportDialog
          open={openBulkMailReportDialog}
          setOpen={setOpenBulkMailReportDialog}
          emailResponseMsg={emailResponseMsgBCB}
          mailFailedUsers={mailFailedUsersBCB}
          workbook={mailFailedWorkbookBCB}
        />
        <ApproveResponseDialog
          open={openApproveDialog}
          setOpen={setOpenApproveDialog}
          partnerCount={currentSelectedRows.length}
          approveAction={async () => {
            uiLogger(
              isApprovingInBulk.current
                ? uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_APPROVE_YES_CLICK.functionName
                : uiLoggerNamesPartnerCommunication.UI_PC_BCB_APPROVERESPONSE_YES_CLICK.functionName,
              companyId.current,
              branchCode.current,
              {
                message: isApprovingInBulk.current
                  ? uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_APPROVE_YES_CLICK.message
                  : uiLoggerNamesPartnerCommunication.UI_PC_BCB_APPROVERESPONSE_YES_CLICK.message,
              }
            );
            await approveResponse(currentSelectedRows);
          }}
        />
        <ResetStatusDialog
          open={openResetStatusDialog}
          setOpen={setOpenResetStatusDialog}
          partnerCount={currentSelectedRows.length}
          partnerName={currentSelectedRows.length === 1 ? currentSelectedRows[0].businessPartnerName : ""}
          resetAction={async () => await resetStatus(currentSelectedRows)}
        />
        <ManageCommunicationType
          open={openEditCommsType}
          setOpen={setOpenEditCommsType}
          companyId={companyId.current}
          branchCode={branchCode.current}
          listUserEmailTemplates={listUserEmailTemplateForBcBeta}
        />
        <MultipleCommunicationTypes
          open={openMultipleCommsType}
          setOpen={setOpenMultipleCommsType}
          setOpenBcBetaBulkMailing={setOpenBcBetaBulkMailing}
          setActiveStep={setActiveStep}
        />
        <UpdateResponseDialog
          open={openUpdateResDialog}
          setOpen={setOpenUpdateResDialog}
          link={currentSelectedRows[0]?.portalLink}
        />
        <DRNewCommunicationDialog
          open={openBcBetaNewCommsDialog}
          setOpen={setOpenBcBetaNewCommsDialog}
          yesAction={async () => {
            /* Bulk Send Email logic */
            setOpenSingleMailing(true);
          }}
        />
        <MailStatusRefreshDialog
          open={openStatusRefreshDialog}
          setOpen={setOpenStatusRefreshDialog}
          approveAction={async () => {
            const firstFoundCommType = selectedRow.find((row) => row.communicationTypeId);
            const allRowsMatched = selectedRow.every(
              (row) => row?.communicationTypeId !== firstFoundCommType?.communicationTypeId
            );
            if (firstFoundCommType && allRowsMatched === false) {
              setOpenMultipleCommsType(true);
            } else setOpenBcBetaBulkMailing(true);
          }}
        />

        <ActiveAutoReminder
          open={openActAutoReminder}
          setOpen={setOpenActAutoReminder}
          branchCode={branchCode.current}
          companyId={companyId.current}
        />

        <StopAutoReminder
          open={openStopReminder}
          setOpen={setOpenStopReminder}
          stopAction={() =>
            StopAutoReminderForPartner(currentSelectedRows?.[0]?.businessPartnerId, currentSelectedRows[0]?.mappingId)
          }
          partnerName={currentSelectedRows.length === 1 ? currentSelectedRows?.[0]?.businessPartnerName : ""}
        />
        <DownloadReport
          open={openDownloadReportDlg}
          setOpen={setOpenDownloadReportDlg}
          downloadAction={async (customization) =>
            isHistoryReport
              ? await downloadPastCommunicationReport(customization)
              : await getDownloadBcBetaReport(customization)
          }
        />
        <SaveTableView
          open={openTablePresetDlg}
          setOpen={setOpenTablePresetDlg}
          initialName={selectedTablePreset}
          saveAction={async (_name) => await handlePresetAddUpdate(selectedTablePreset, _name)}
          updateAction={async (_name) => await handlePresetAddUpdate(selectedTablePreset, _name, true)}
        />
        <DeleteTableView
          open={openTablePresetDelDlg}
          setOpen={setOpenTablePresetDelDlg}
          initialName={currentPresetRef.current}
          delAction={async () => await DeletePreset(currentPresetRef.current || "")}
        />
      </BcBetaContext.Provider>
      {showReviewMoveBulkTasksModal && (
        <ReviewMoveBulkTasksModal
          open={showReviewMoveBulkTasksModal}
          setOpen={setShowReviewMoveBulkTasksModal}
          selectedTasks={selectedRow}
          setShowMoveTaskToReconModal={setShowMoveTaskToReconModal}
          setMoveToReconTaskDetails={setMoveToReconTaskDetails}
          setTasksToMoveCount={setTasksToMoveCount}
          businessPartnerDetailsForWhichTaskAlreadyExists={businessPartnerDetailsForWhichTaskAlreadyExists}
          getAlreadyExistingTasks={getAlreadyExistingTasks}
          isLoadingAlreadyExistingTasks={isLoadingAlreadyExistingTasks}
          companyId={companyId.current}
          branchCode={branchCode.current}
        />
      )}
      {showReviewCloseAndResetTasksModal && (
        <ReviewCloseAndResetTasksModal
          open={showReviewCloseAndResetTasksModal}
          setOpen={setShowReviewCloseAndResetTasksModal}
          selectedTasks={selectedRow}
          closeAndResetTasks={closeAndResetTasks}
          companyId={companyId.current}
          branchCode={branchCode.current}
          setClearRows={setClearRows}
        />
      )}
      {showMoveTaskToReconModal && (
        <MoveTaskToReconModal
          open={showMoveTaskToReconModal}
          setOpen={setShowMoveTaskToReconModal}
          title={moveTaskToReconModalTitle}
          tasksToMoveCount={tasksToMoveCount}
          isMovingBulkTasks={isMovingBulkTasks}
          moveToReconTaskDetails={moveToReconTaskDetails}
          companyId={companyId.current}
          branchCode={branchCode.current}
          ListAllBusinessPartnerForBcBeta={ListAllBusinessPartnerForBcBeta}
          setClearRows={setClearRows}
        />
      )}
      {showConfirmCloseAndResetTasksModal && (
        <ConfirmCloseAndResetModal
          open={showConfirmCloseAndResetTasksModal}
          setOpen={setShowConfirmCloseAndResetTasksModal}
          closeAndResetTasks={closeAndResetTasks}
          companyId={companyId.current}
          branchCode={branchCode.current}
          taskDetailsForSingleCloseAndReset={taskDetailsForSingleCloseAndReset.current}
          setClearRows={setClearRows}
        />
      )}
      {showTasksHistoryModal && (
        <TasksHistoryModal
          showTasksHistoryModal={showTasksHistoryModal}
          setShowTasksHistoryModal={setShowTasksHistoryModal}
          taskId={currentSelectedTaskDetails.current.taskId}
          businessPartnerName={currentSelectedTaskDetails.current.businessPartnerName}
          mappingId={currentSelectedTaskDetails.current.mappingId}
        />
      )}
      {showTaskAttachmentsModal && (
        <TaskAttachmentsModal
          open={showTaskAttachmentsModal}
          setOpen={setShowTaskAttachmentsModal}
          businessPartnerName={currentSelectedTaskDetailsForAttachments.current.businessPartnerName}
          ticketId={currentSelectedTaskDetailsForAttachments.current.taskId}
          companyId={companyId.current}
          branchCode={branchCode.current}
          serviceType={NAKAD_SERVICE.PartnerCommunication}
          mappingId={currentSelectedTaskDetailsForAttachments.current.mappingId}
        />
      )}
    </LoggedInSkeleton>
  );
};

export default BalanceConfirmationBeta;
