/* eslint-disable @typescript-eslint/no-unused-vars */
import { AlternateEmail, CheckCircleOutline, CurrencyRupee, EventRounded, Groups, Person } from "@mui/icons-material";
import { Box, Card, Stack, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import {
  BusinessPartnerListForBcBeta,
  BusinessPartnersListForBcBetaRes,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { PageSelectModeToggle } from "../../../Common/CommonComponents";
import ColumnDefinitions from "../../Common/ColumnDefinitions";
import BcNewContext from "../BcNewContext";

const SelectPartners = () => {
  const { actor } = useContext(userContext);

  const {
    companyId,
    branchCode,
    mailingRows,
    setMailingRows,
    rowsDataBcNewMailing,
    setRowsDataBcNewMailing,
    setStoreRowsDataBcNewMailing,
  } = useContext(BcNewContext);
  const [loader, setLoader] = useState(false);

  const [columnDefinitions, setColumnDefinitions] = useState<ColDef<BusinessPartnerListForBcBeta>[]>([]);
  const [hiddenColumns, setHiddenColumns] = useState<Record<string, boolean>>({});
  const [pageSelectMode, setPageSelectMode] = useState<boolean>(false);

  const pendingRows: BusinessPartnerListForBcBeta[] = rowsDataBcNewMailing.filter(
    (row) => row.status === BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
  );

  const fetchData = async () => {
    await ListAllBusinessPartners();
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Unsent Mail ROWs API
  const ListAllBusinessPartners = async () => {
    setLoader(true);

    return useFetch<BusinessPartnersListForBcBetaRes>(
      API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_SENDING_MAIL.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_FOR_SENDING_MAIL.failureMessage,
        config: {
          params: {
            isIntegrated: actor.integration,
          },
        },
        thenCallBack: (response) => {
          // storeBcBetaData.current = response.data?.businessPartnerList || [];
          setRowsDataBcNewMailing(response.data?.businessPartnerList || []);
          setStoreRowsDataBcNewMailing(response.data.businessPartnerList || []);
          setLoader(false);
          // if (Object.keys(appliedFilters).length) setReFilter(true);
          // if (rowsDataBcBeta && defaultTallyCbDate) GetErpClosingBalanceForAllBp(response.data.businessPartnersList);
        },
      }
    );
  };

  // Use useCallback to memoize the callback function
  const handleColDefsData = useCallback(
    (_data: React.SetStateAction<ColDef<BusinessPartnerListForBcBeta>[]>, _hiddenColumns: string[]) => {
      setColumnDefinitions(_data); // Update state with data from child
      setHiddenColumns(_hiddenColumns?.reduce((acc, column) => ({ ...acc, [column]: false }), {}));
    },
    []
  );

  const ownCBmailPartners = mailingRows.filter(
    (row) => row.contactSetting?.filter((c) => c.balanceConfirmation !== "N/A").length > 0
  ).length;
  const ownCBpartners = mailingRows.filter((row) => row.ownClosingBalance).length;
  const ownCBDatePartners = mailingRows.filter((row) => row.closingBalanceDate).length;

  return (
    <>
      <ColumnDefinitions
        props={{
          mailingDeps: [],
          setMailingDefs: handleColDefsData, //
          type: "mailing",
        }}
      />
      {/* Top */}
      <Box
        className="d_flex fade_up_t"
        sx={{
          flexDirection: "column",
          padding: "16px 24px",
          bgcolor: "#fff",
          minWidth: "600px",
          zIndex: 1,
          gap: 3,
          boxShadow:
            "0px 1px 3px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.07), 0px 2px 1px -1px rgba(0, 0, 0, 0.10)",
        }}
      >
        <Stack direction="row" gap={2} alignItems="center">
          <Box className="center_align_ver_horiz" bgcolor={"#2196F3"} height={40} width={40} borderRadius={1}>
            <Groups sx={{ color: "white" }} />
          </Box>

          {/* Main Text and Sub Text */}
          <Box className="main_text_sub_text">
            <Typography
              variant="h6"
              sx={{
                color: "rgba(0, 0, 0, 0.87)",
              }}
            >
              Select Business Partners
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              You can select single or multiple partners to send mail
            </Typography>
          </Box>
        </Stack>
        {/* Top mail Info Cards */}
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > .MuiCard-root:first-of-type": {
              borderRadius: "4px 0 0 4px",
              borderLeftColor: "rgba(0, 0, 0, 0.12)",
            },
            "& > .MuiCard-root:last-of-type": {
              borderRadius: "0 4px 4px 0",
              borderRightColor: "rgba(0, 0, 0, 0.12)",
            },
          }}
        >
          <BeforeMailInfoCard
            partnerCard
            titleText="Partners Selected"
            bottomText={mailingRows.length || "-"}
            icon={<Person sx={{ color: "#014361" }} />}
          />
          <BeforeMailInfoCard
            titleText="Email Address"
            bottomText={
              mailingRows.length === 0
                ? "Select at least one partner"
                : ownCBmailPartners < mailingRows.length
                ? `Missing for ${mailingRows.length - ownCBmailPartners}/${mailingRows.length} selected partners`
                : "Present for all selected partners"
            }
            icon={<AlternateEmail />}
            severity={mailingRows.length === 0 ? "" : ownCBmailPartners < mailingRows.length ? "warning" : "success"}
          />
          <BeforeMailInfoCard
            titleText="Closing Balance"
            bottomText={
              mailingRows.length === 0
                ? "Select at least one partner"
                : ownCBpartners < mailingRows.length
                ? `Missing for ${mailingRows.length - ownCBpartners}/${mailingRows.length} selected partners`
                : "Present for all selected partners"
            }
            icon={<CurrencyRupee />}
            severity={mailingRows.length === 0 ? "" : ownCBpartners < mailingRows.length ? "warning" : "success"}
          />
          <BeforeMailInfoCard
            titleText="Closing Balance Date"
            bottomText={
              mailingRows.length === 0
                ? "Select at least one partner"
                : ownCBDatePartners < mailingRows.length
                ? `Missing for ${mailingRows.length - ownCBDatePartners}/${mailingRows.length} selected partners`
                : "Present for all selected partners"
            }
            icon={<EventRounded />}
            severity={mailingRows.length === 0 ? "" : ownCBDatePartners < mailingRows.length ? "warning" : "success"}
          />
        </Box>
      </Box>

      {/* Bottom */}
      <Box p={3} bgcolor="#FAFAFA" className="fade_up_t">
        <ReactTable
          key={JSON.stringify(hiddenColumns)}
          columns={columnDefinitions}
          rows={pendingRows}
          setSelectedRow={setMailingRows}
          loading={loader}
          enableGrouping={true}
          positionToolbarAlertBanner={"top"}
          initialStateColumnVisibility={hiddenColumns}
          internalActionsOrder={[
            "globalFilterToggle",
            "filterToggle",
            "customDownloadButton",
            "showHideColumns",
            "fullScreenToggle",
          ]}
          renderAdditionalBottomToolbarCustomActions={() => {
            return (
              <PageSelectModeToggle
                pageSelectMode={pageSelectMode}
                setPageSelectMode={setPageSelectMode}
                useCaseType={UseCaseType.balanceConfirmationBeta}
                companyId={companyId}
                branchCode={branchCode}
              />
            );
          }}
          pageSelectMode={pageSelectMode}
        />
      </Box>
    </>
  );
};

const palette = {
  info: {
    text1: "#014361",
    text2: "#014361",
    light: "#E5F6FD",
  },
  warning: {
    text1: "#000a",
    text2: "#663C00",
    light: "#FFF4E5",
  },
  success: {
    text1: "#1E4620",
    text2: "#1E4620",
    light: "#EDF7ED",
  },
} as const;

export const BeforeMailInfoCard = ({
  partnerCard,
  titleText,
  bottomText,
  icon,
  severity,
}: {
  partnerCard?: boolean;
  titleText: string;
  bottomText: React.ReactNode;
  icon: React.ReactNode;
  severity?: "" | "info" | "warning" | "success";
}) => {
  return (
    <>
      <Card
        className="d_flex"
        sx={{
          flexFlow: "column",
          minWidth: "300px",
          height: "100px",
          p: 2,
          gap: 1,
          backgroundColor: partnerCard ? palette.info.light : severity ? palette?.[severity]?.light : "#f5f5f5",
          borderRadius: 0,
          border: "1px solid  rgba(0, 0, 0, 0.12)",
          borderLeftColor: "transparent",
          borderRightColor: "rgba(0, 0, 0, 0.12)",
          flex: "1 1 0px",
        }}
        elevation={0}
      >
        {/* Top */}
        <Box className="d_flex" sx={{ alignItems: "center", gap: 1 }}>
          {severity === "success" ? <CheckCircleOutline color="success" /> : icon}
          <Typography
            variant="subtitle1"
            fontWeight={700}
            color={partnerCard ? palette.info.text1 : severity ? palette?.[severity]?.text1 : "auto"}
          >
            {titleText}
          </Typography>
        </Box>
        {/* Bottom */}
        {partnerCard ? (
          <Typography variant="h5" color={"#014361"}>
            {bottomText}
          </Typography>
        ) : (
          <Typography
            variant="body2"
            sx={{
              color: partnerCard ? palette.info.text2 : severity ? palette?.[severity]?.text2 : "#0004",
            }}
          >
            {bottomText}
          </Typography>
        )}
      </Card>
    </>
  );
};

export default SelectPartners;
