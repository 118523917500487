import DragHandleIcon from "@mui/icons-material/DragHandle";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Menu,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";

export default function CustomisedShowHideColumns({
  anchorEl,
  setAnchorEl,
  columns,
  setColumnVisibility,
  setColumnOrder,
}) {
  const [selectedCategory, setSelectedCategory] = useState("All");
  // Store the complete ordered columns state
  const [masterColumnsState, setMasterColumnsState] = useState(columns() || []);
  // Store the currently displayed columns
  const [displayedColumns, setDisplayedColumns] = useState(columns() || []);
  // Search term for filtering columns
  const [searchTerm, setSearchTerm] = useState("");
  // Flag to track if search is active
  const [isSearchActive, setIsSearchActive] = useState(false);

  useEffect(() => {
    let filteredColumns;

    if (selectedCategory === "All") {
      filteredColumns = [...masterColumnsState];
    } else {
      filteredColumns = masterColumnsState.filter((column) => column.category === selectedCategory);
    }

    if (searchTerm.trim()) {
      filteredColumns = filteredColumns.filter((column) =>
        column.header.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setIsSearchActive(true);
    } else {
      setIsSearchActive(false);
    }

    setDisplayedColumns(filteredColumns);
  }, [selectedCategory, masterColumnsState, searchTerm]);

  const handleDragEnd = (result) => {
    if (!result.destination || selectedCategory !== "All" || isSearchActive) return;

    const updatedColumns = Array.from(displayedColumns);
    const [movedItem] = updatedColumns.splice(result.source.index, 1);
    updatedColumns.splice(result.destination.index, 0, movedItem);

    setDisplayedColumns(updatedColumns);
    setMasterColumnsState(updatedColumns);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setSelectedCategory(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchKeyDown = (event) => {
    // Prevent the 's' key from triggering other shortcuts
    if (event.key === "s" || event.key === "S") {
      event.stopPropagation();
    }
  };

  // Prevent menu from closing on keydown events in the search field
  const handleSearchKeyDownInMenu = (event) => {
    event.stopPropagation();
  };

  const handleVisibilityChange = (itemId) => {
    // Update visibility in both displayed columns and master state
    setDisplayedColumns((prev) => {
      const updatedColumns = [...prev];
      const index = updatedColumns.findIndex((c) => c.id === itemId);
      if (index !== -1) {
        updatedColumns[index] = {
          ...updatedColumns[index],
          isVisible: !updatedColumns[index].isVisible,
        };
      }
      return updatedColumns;
    });

    setMasterColumnsState((prev) => {
      const updatedColumns = [...prev];
      const index = updatedColumns.findIndex((c) => c.id === itemId);
      if (index !== -1) {
        updatedColumns[index] = {
          ...updatedColumns[index],
          isVisible: !updatedColumns[index].isVisible,
        };
      }
      return updatedColumns;
    });
  };

  return (
    <Menu
      anchorEl={anchorEl}
      disableScrollLock
      onClose={() => setAnchorEl(null)}
      open={!!anchorEl}
      onKeyDown={handleSearchKeyDownInMenu}
    >
      <Box sx={{ padding: 2 }}>
        <TextField
          size="small"
          fullWidth
          label="Search columns"
          placeholder="Search columns"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={handleSearchKeyDown}
          InputProps={{
            sx: { borderRadius: "4px !important" },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Divider />

      <Box sx={{ padding: 2 }}>
        <FormControl fullWidth>
          <InputLabel>Category</InputLabel>
          <Select
            size="small"
            sx={{ borderRadius: " 4px !important" }}
            label="Category"
            value={selectedCategory}
            onChange={handleCategoryChange}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Task and Partner Level Details">Task and Partner Level Details</MenuItem>
            <MenuItem value="Recon Details">Recon Details</MenuItem>
            <MenuItem value="Recon Details - Count of Entries">Recon Details - Count of Entries</MenuItem>
            <MenuItem value="Ledger Summary">Ledger Summary</MenuItem>
            <MenuItem value="Partner Communication Details">Partner Communication Details</MenuItem>
            <MenuItem value="Invoicing Details">Invoicing Details</MenuItem>
            <MenuItem value="Other Details">Other Details</MenuItem>
            <MenuItem value="Custom Columns">Custom Columns</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Divider />
      <Box
        sx={{
          overflowY: "auto",
          height: "52vh",
          width: "400px",
        }}
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{ display: "flex", flexDirection: "column", gap: 1 }}
              >
                {displayedColumns.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={selectedCategory !== "All" || isSearchActive}
                  >
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        sx={{
                          border: snapshot.isDragging
                            ? "2px dashed grey" // Show border only when dragging
                            : "none",
                          transition: "border 0.2s ease",
                          userSelect: "none",
                          paddingX: 1,
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {selectedCategory === "All" && !isSearchActive && (
                              <IconButton
                                {...provided.dragHandleProps}
                                sx={{
                                  cursor: selectedCategory === "All" && !isSearchActive ? "grab" : "default",
                                  opacity: selectedCategory === "All" && !isSearchActive ? 1 : 0.5,
                                }}
                              >
                                <DragHandleIcon />
                              </IconButton>
                            )}
                            <Typography variant="body1">{item.header}</Typography>
                          </Box>
                          <Switch checked={item.isVisible} onClick={() => handleVisibilityChange(item.id)}></Switch>
                        </Box>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      <Box
        sx={{
          padding: 2,
          borderTop: "1px solid #e0e0e0",
          textAlign: "right",
        }}
      >
        <Button variant="text" onClick={() => setAnchorEl(null)}>
          Cancel
        </Button>
        <NdButton
          variant="contained"
          onClick={() => {
            const columnOrderState = masterColumnsState.map((column) => column.id);
            const columnVisibilityState = masterColumnsState
              .filter((column) => column.isVisible === false)
              .reduce((acc, column) => {
                acc[column.id] = column.isVisible;
                return acc;
              }, {});
            setColumnOrder(columnOrderState);
            setColumnVisibility(columnVisibilityState);
            setAnchorEl(null);
          }}
        >
          Save
        </NdButton>
      </Box>
    </Menu>
  );
}
