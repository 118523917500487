import { NavigateNext, Notifications } from "@mui/icons-material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import MoreIcon from "@mui/icons-material/MoreVert";
import { AppBar, Avatar, Breadcrumbs, Button, Link, Typography } from "@mui/material";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Link as LinkTo } from "react-router-dom";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { userContext } from "../Contexts/userContext";
import { NavBarStructure } from "./Constants";
import { getTopBarButtons, redirect } from "./SideBar";
import "./SideBar.scss";
import useFetch from "./useFetch";

export interface LoggedInSkeletonProps {
  topBarButtons: NavBarStructure[];
}

const getShortName = (name: string) => {
  const MAX_CHARS = 24;

  if (name.length > MAX_CHARS) {
    name = name.substring(0, MAX_CHARS / 2) + "..." + name.substring(name.length - MAX_CHARS / 2);
  }
  return name;
};

const HOME_ROUTE = "#home";

// This class encapsulate the common UI which is present in all the logged in pages.
export const LoggedInSkeleton = (props: React.PropsWithChildren<LoggedInSkeletonProps>) => {
  const history = useHistory();
  const { actor } = useContext(userContext);
  const location = useLocation<any>();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  localStorage.setItem("actor", JSON.stringify(actor));
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const logout = () => {
    localStorage.clear();
    window.USETIFUL?.user?.setId(null);
    useFetch(API_ENDPOINTS.LOGOUT.url, "GET", {
      failureMessage: API_ENDPOINTS.LOGOUT.failureMessage,
      thenCallBack: () => {
        history.push("/");
      },
    });
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const renderMenu = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      keepMounted={true}
      transformOrigin={{
        vertical: -5,
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PopoverClasses={{ paper: "br_32" }}
      classes={{ paper: "br_32" }}
      sx={{
        "& ul": {
          pt: "0",
          minWidth: 250,
          maxWidth: 350,
        },
      }}
    >
      <Box px={3} py={2} sx={{ background: "rgba(84, 28, 76, 0.1)" }}>
        <Box className="AccountCircle mb_20" sx={{ display: { xs: "none", md: "flex" } }}>
          <div className="hue-wheel hue-wheel-1" />
          <IconButton
            size="large"
            color="primary"
            sx={{
              height: 44,
              width: 44,
              pointerEvents: "none",
            }}
          >
            <Avatar
              title={actor.name}
              sx={{
                background: "#fafafa",
                color: "#777",
              }}
            >
              {actor.name[0].toUpperCase()}
            </Avatar>
          </IconButton>
        </Box>
        <Typography
          sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis", textAlign: "center" }}
          title={actor.name}
        >
          Hi, {actor.name}!
        </Typography>
      </Box>
      <Box px={2} py={3} display={"flex"} justifyContent={"center"}>
        <Button
          startIcon={<i className="fa-solid fa-arrow-right-from-bracket" />}
          variant="text"
          onClick={logout}
          sx={{
            background: "rgba(25, 118, 210, 0.08)",
            px: 2,
            borderRadius: 5,
          }}
        >
          Log Out
        </Button>
      </Box>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted={true}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <MailIcon />
        </IconButton>
        <p>Support</p>
      </MenuItem>
      <MenuItem onClick={logout}>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <LogoutIcon />
        </IconButton>
        <p> {JSON.parse(localStorage.getItem("actor")).name}</p>
      </MenuItem>
      <MenuItem>
        <IconButton size="large" aria-label="show 17 new notifications" color="inherit">
          <Badge badgeContent={17} color="error">
            <Notifications />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleMobileMenuClose}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  const breadcrumbs = [
    <LinkTo
      key="1"
      to={HOME_ROUTE}
      onClick={() => redirect(actor, history)}
      style={{ display: "flex", alignItems: "center" }}
    >
      <svg className="icon" style={{ height: "30px", width: "30px", fill: "#3273dc" }}>
        <use xlinkHref="#icon-home-2-svgrepo" />
      </svg>
    </LinkTo>,
  ];

  const buttons = getTopBarButtons(actor);

  const nonSubTabButton = buttons?.find((button) => location.pathname.toLowerCase().includes(button.to?.toLowerCase()));

  if (nonSubTabButton) {
    breadcrumbs.push(
      <Link underline="none" key="2" sx={{ cursor: "default" }}>
        {nonSubTabButton.name}
      </Link>
    );

    document.title = "NAKAD | " + nonSubTabButton.name;
  } else {
    const subMenuFind = (_subBtn: { to: string }) =>
      location.pathname.toLowerCase().includes(_subBtn.to?.toLowerCase());
    const subBtn = buttons?.find((button) => button?.subMenu?.find(subMenuFind));

    if (subBtn) {
      const subMenu = subBtn?.subMenu?.find(subMenuFind);
      breadcrumbs.push(
        <Link underline="none" key="2" sx={{ cursor: "default" }}>
          {subBtn.name}
        </Link>,
        <Link underline="none" key="3" sx={{ cursor: "default" }}>
          {subMenu?.name}
        </Link>
      );

      document.title = "NAKAD | " + subMenu.name;
    }
  }

  const accountName = actor?.actorInfo.name.replace(/[^a-z\s\d]+/gi, "");

  const accName = actor.name.toLowerCase().replace(/[^a-z\d]+/gi, "");
  const foundName = location.pathname?.split("/")[1] || "";

  return (
    <>
      <div className="layout_wrapper">
        <div className="vertical_nav" onMouseOver={() => setOpen(true)} />
        <div className="layout_content">
          <AppBar
            position="sticky"
            sx={{
              top: 0,
              borderRadius: "32px !important",
              width: "calc(100% - 32px)",
              marginInline: 16,
              background: "#fff",
              color: "#333",
              gap: 2,
              px: 2,
              py: 0.5,
              mx: 2,
              mt: 1,
              height: 56,
            }}
            className="header"
            component={"div"}
          >
            <Toolbar disableGutters={true} sx={{ position: "relative", minHeight: "auto !important", height: 48 }}>
              <Breadcrumbs separator={<NavigateNext fontSize="small" />} sx={{ userSelect: "none" }}>
                {breadcrumbs}
              </Breadcrumbs>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  marginLeft: "30px",
                  ...(open && { display: "none" }),
                }}
                className="menu_open"
              >
                <MenuIcon />
              </IconButton>
              <Box sx={{ flexGrow: 1 }} />
              <Box sx={{ display: { xs: "none", md: "flex" }, position: "relative", right: 4, gap: 0.25 }}>
                <LinkTo
                  to="/faq"
                  className="vertical_center_align"
                  hidden={accName === "admin" || accName !== foundName}
                >
                  <IconButton size="large" color="primary" sx={{ width: 44, height: 44 }}>
                    <i className="fa-solid fa-question" style={{ fontSize: "inherit", color: "#531c4c" }} />
                  </IconButton>
                </LinkTo>
                <IconButton
                  size="large"
                  aria-label="show 4 new mails"
                  color="primary"
                  sx={{
                    height: 44,
                    width: 44,
                  }}
                >
                  <Badge color="error" sx={{ fontSize: "inherit" }}>
                    <i className="fa-solid fa-envelope" style={{ fontSize: "inherit", color: "#531c4c" }} />
                  </Badge>
                </IconButton>
              </Box>
              <Button
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                startIcon={<AccountCircle />}
                onClick={handleProfileMenuOpen}
                variant="contained"
                color="inherit"
                sx={{
                  display: { xs: "none", md: "flex" },
                  borderRadius: 4,
                  background: "rgba(84, 28, 76, 0.1)",
                  color: "#531c4c",
                  "&:hover": {
                    background: "rgba(84, 28, 76, 0.15)",
                  },
                  maxWidth: 250,
                  left: 6,
                }}
                title={accountName}
              >
                <Typography component={"span"} sx={{ textTransform: "capitalize", maxWidth: 200 }}>
                  {getShortName(accountName)}
                </Typography>
              </Button>
              <Box sx={{ display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="show more"
                  aria-controls={mobileMenuId}
                  aria-haspopup="true"
                  onClick={handleMobileMenuOpen}
                  color="inherit"
                >
                  <MoreIcon />
                </IconButton>
              </Box>
            </Toolbar>
          </AppBar>
          <Box component="main" sx={{ flexGrow: 1 }} className="main-rightbar">
            {props.children}
          </Box>
          {renderMobileMenu}
          {renderMenu}
        </div>
      </div>
    </>
  );
};
