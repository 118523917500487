import { Autocomplete, Button, Grid, MenuItem, Tab, Tabs, TextField, Tooltip } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { useContext, useEffect, useState } from "react";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { userContext } from "../../Contexts/userContext";
import { Dialog } from "../../Dialog/Dialog";
import TabPanel from "../NewBusinessPartner/TabPanel";
import {
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  TallyOrSapCompanyId,
} from "../PartnerCommunication/CommonLegacy/CommonComponents";

type StateDispatch<T> = React.Dispatch<React.SetStateAction<T>>;

interface AddNewBpProps {
  getListAllDesignation: () => void;
  getPartnerUserBulkUploadFailedList?: () => void;
  listAllBusinessPartnersForPartnerUseCaseMaster: () => void;
  listAllBusinessPartnersForPartnerUserMaster: () => void;
  listAllDesignation: any[];
  partnerUsersTemplateBase64: string;
  rowsDataPartnerUseCaseMaster: any[];
  setErrorListsPartnerUsersBase64: StateDispatch<string>;
  setLoader: StateDispatch<boolean>;
  setNumberOfUsersFailed: StateDispatch<number>;
  setNumberOfUsersSaved: StateDispatch<number>;
  setOpenPartnerUserBulkUploadErrorReport: StateDispatch<boolean>;
  setRowsDataPartnerUserBulkUploadErrorReport: StateDispatch<any[]>;
  companyId: TallyOrSapCompanyId;
  branchCode: string;
}

const AddNewPartnerUser = (props: AddNewBpProps) => {
  const [openBusinessPopup, setOpenBusinessPopup] = useState<boolean>(false);
  const [tabvalue, setTabValue] = useState(0);
  const optionsUseCase = ["to", "cc", "bcc", "NA"];
  // const [ledgerRequestSelected, setLedgerRequestSelected] = useState<string>("to");
  const [balanceConfirmationSelected, setBalanceConfirmationSelected] = useState<string>("to");
  // const [paymentAdviceSelected, setPaymentAdviceSelected] = useState<string>("to");
  const [msmeConfirmationSelected, setMsmeConfirmationSelected] = useState<string>("to");

  const [name, setName] = useState<string>("");
  const [designation, setDesignation] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [errEmail, setErrEmail] = useState<boolean>(false);
  const [businessPartner, setBusinessPartners] = useState<number>(null);
  const [mappingId, setMappingId] = useState<number>(null);
  const [errBusinessPartner, setErrBusinessPartner] = useState<boolean>(false);
  const [errOptionsUseCase, setErrOptionsUseCase] = useState<boolean>(false);
  const [value, setValue] = useState<any>(null);
  const { actor } = useContext(userContext);
  const [savingPartnerUser, setSavingPartnerUser] = useState(false);

  useEffect(() => {
    setValue({
      actorId: actor.id,
      designationId: null,
      designationName: designation,
      // ledgerRequest: ledgerRequestSelected,
      balanceConfirmation: balanceConfirmationSelected,
      // paymentAdvice: paymentAdviceSelected,
      msmeConfirmation: msmeConfirmationSelected,
    });
  }, []);
  const handleClickOpen = () => {
    setOpenBusinessPopup(true);
  };

  const clearAllState = () => {
    setName("");
    setEmail("");
    setBusinessPartners(null);
    setMappingId(null);
    setValue(null);
    setDesignation("");
    // setLedgerRequestSelected("to");
    setBalanceConfirmationSelected("to");
    // setPaymentAdviceSelected("to");
    setMsmeConfirmationSelected("to");
    setErrEmail(false);
    setErrBusinessPartner(false);
    setErrOptionsUseCase(false);
  };

  const handleClose = () => {
    setOpenBusinessPopup(false);
    clearAllState();
  };
  const handleChange = (_, newValue: number) => {
    setTabValue(newValue);
  };

  const addNewPartnerUser = async () => {
    if (!email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
      setErrEmail(true);
    }

    if (businessPartner === null) {
      setErrBusinessPartner(true);
    }

    let emailSettingErr = false;
    if (balanceConfirmationSelected === "NA") {
      emailSettingErr = true;
      setErrOptionsUseCase(true);
    }

    if (
      email.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$") &&
      businessPartner !== null &&
      !emailSettingErr
    ) {
      setSavingPartnerUser(true);
      await useFetch(API_ENDPOINTS.ADD_BUSINESS_PARTNER_USER_WITH_EMAIL_SETTING.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_BUSINESS_PARTNER_USER_WITH_EMAIL_SETTING.failureMessage,
        showSuccessToast: true,
        data: {
          businessPartnerId: businessPartner,
          mappingId,
          contact: {
            name,
            email,
            designationName: designation,
            balanceConfirmation: balanceConfirmationSelected === null ? "NA" : balanceConfirmationSelected,
            msmeConfirmation: msmeConfirmationSelected === null ? "NA" : msmeConfirmationSelected,
          },
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: () => {
          setSavingPartnerUser(false);
          handleClose();
          props.setLoader(true);
          props.listAllBusinessPartnersForPartnerUserMaster();
          props.listAllBusinessPartnersForPartnerUseCaseMaster();
        },
        catchCallBack: () => {
          setSavingPartnerUser(false);
        },
      });
    }
  };

  const downloadPartnerUsersTemplate = () => {
    const excelData = Buffer.from(props.partnerUsersTemplateBase64, "base64");
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([excelData], { type: fileType });
    saveAs(blob, "Partner User addition template.xlsx");
  };

  const addBusinessPartnerUsersInBulk = (e: any) => {
    const reader = new FileReader();
    let data = null;
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = (event) => {
      data = event.target.result;
      useFetch<any>(API_ENDPOINTS.ADD_BUSINESS_PARTNER_USERS_IN_BULK.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_BUSINESS_PARTNER_USERS_IN_BULK.failureMessage,
        showSuccessToast: true,
        data: {
          businessPartnerUserDataBase64: data
            .toString()
            .split("data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,")[1],
          companyId: props.companyId || DEFAULT_COMPANY_ID,
          branchCode: props.branchCode || DEFAULT_BRANCH_CODE,
        },
        thenCallBack: (response) => {
          handleClose();
          props.setLoader(true);
          props.listAllBusinessPartnersForPartnerUserMaster();
          props.listAllBusinessPartnersForPartnerUseCaseMaster();

          // failed entries ---- case -> modal
          if (response.data.response.numberOfUsersFailed > 0) {
            props.setRowsDataPartnerUserBulkUploadErrorReport(response.data.response.listOfFailedUsers);
            props.setNumberOfUsersFailed(response.data.response.numberOfUsersFailed);
            props.setNumberOfUsersSaved(response.data.response.numberOfUsersSaved);
            // props.getPartnerUserBulkUploadFailedList();
            props.setErrorListsPartnerUsersBase64(response.data.response.finalFailedListWorkbookBase64);
            props.setOpenPartnerUserBulkUploadErrorReport(true);
          }
        },
      });
    };
  };

  const businessPartners: any[] = [];
  props.rowsDataPartnerUseCaseMaster?.forEach((valueData: any) => {
    businessPartners.push({
      name: valueData.businessPartnerName,
      id: valueData.businessPartnerId,
      maapingId: valueData.mappingId,
    });
  });
  const options = businessPartners?.map((option) => {
    const firstLetter = option.name[0]?.toUpperCase();
    return {
      firstLetter,
      ...option,
    };
  });

  const businessPartnerSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      setBusinessPartners(valueData.id);
      setMappingId(valueData.maapingId);
      setErrBusinessPartner(false);
    } else {
      setBusinessPartners(null);
      setMappingId(null);
    }
  };
  // const ledgerRequestSelect = (e: any, valueData: any) => {
  //   if (valueData !== null) {
  //     e.persist();
  //     setLedgerRequestSelected(valueData);
  //     value.ledgerRequest = valueData;
  //     setErrOptionsUseCase(false);
  //   } else {
  //     value.ledgerRequest = null;
  //     setLedgerRequestSelected(null);
  //   }
  // };
  const balanceConfirmationSelect = (e: any, valueData: any) => {
    if (valueData !== null) {
      e.persist();
      value.balanceConfirmation = valueData;
      setBalanceConfirmationSelected(valueData);
      setErrOptionsUseCase(false);
    } else {
      value.balanceConfirmation = null;
      setBalanceConfirmationSelected(null);
    }
  };

  const msmeConfirmationSelect = (e: React.SyntheticEvent<Element, Event>, valueData: string) => {
    if (valueData !== null) {
      e.persist();
      value.msmeConfirmation = valueData;
      setMsmeConfirmationSelected(valueData);
      setErrOptionsUseCase(false);
    } else {
      value.msmeConfirmation = null;
      setMsmeConfirmationSelected(null);
    }
  };

  const changeDesignation = (newValue: any) => {
    setValue({
      actorId: actor.id,
      designationId: null,
      designationName: newValue,
      balanceConfirmation: balanceConfirmationSelected,
      msmeConfirmation: msmeConfirmationSelected,
    });

    setDesignation(newValue);
  };

  return (
    <>
      <MenuItem
        className=""
        onClick={() => {
          handleClickOpen();
        }}
      >
        Add Partner User
      </MenuItem>

      <Dialog open={openBusinessPopup}>
        <>
          <header className="modal-card-head">
            <div className="modal-card-title">
              <Tabs value={tabvalue} onChange={handleChange} aria-label="Business Partner" className="business_tabs">
                <Tab label="Add Partner User" />
                <Tab label="Bulk Upload Partner User" />
              </Tabs>
            </div>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                handleClose();
              }}
            />
          </header>
          <section className="modal-card-body">
            <TabPanel value={tabvalue} index={0}>
              <Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Name : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <TextField
                      size="small"
                      variant="outlined"
                      fullWidth={true}
                      label="Name"
                      name="name"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Email ID : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <TextField
                      size="small"
                      variant="outlined"
                      required={true}
                      fullWidth={true}
                      label="Email"
                      name="email"
                      error={errEmail}
                      helperText={errEmail ? "please enter valid email" : ""}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        if (e.target.value.length > 0) {
                          if (!e.target.value.match("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,4}$")) {
                            setErrEmail(true);
                          } else {
                            setErrEmail(false);
                          }
                        } else {
                          setErrEmail(false);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Business partner : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => businessPartnerSelect(e, valueData)}
                      id="businessPartnerAddBpUser"
                      size="small"
                      options={options.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required={true}
                          label="Select Business partner"
                          error={errBusinessPartner}
                          helperText={errBusinessPartner ? "please select business partner" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Designation : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      size="small"
                      fullWidth={true}
                      value={designation}
                      onChange={(_, newValue) => {
                        changeDesignation(newValue);
                      }}
                      selectOnFocus={true}
                      clearOnBlur={true}
                      handleHomeEndKeys={true}
                      id="free-solo-with-text-demo"
                      options={["{Blank}", "Accountant", "Partner POC", "Own POC", "Escalation POC"]}
                      freeSolo={true}
                      renderInput={(params) => <TextField {...params} label="Designation" />}
                    />
                  </Grid>
                </Grid>

                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Balance Confirmation & Ledger Request : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => balanceConfirmationSelect(e, valueData)}
                      id="balanceConfirmationSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={balanceConfirmationSelected}
                      value={balanceConfirmationSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Grid className="d_flex mt_15">
                  <Grid className="width_180 vertical_center_align">
                    <label className="addBpUser_label">Msme confirmation : </label>
                  </Grid>
                  <Grid className="textfeild_width">
                    <Autocomplete
                      onChange={(e, valueData) => msmeConfirmationSelect(e, valueData)}
                      id="msmeConfirmationSelect"
                      options={optionsUseCase}
                      size="small"
                      getOptionLabel={(option) => option}
                      defaultValue={msmeConfirmationSelected}
                      value={msmeConfirmationSelected}
                      disableClearable={true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errOptionsUseCase}
                          helperText={errOptionsUseCase ? "please select" : ""}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabvalue} index={1}>
              <Grid>
                <div>
                  <label
                    id="file-js-example"
                    className="field file has-name uploadSection center_align_ver_horiz"
                    style={{ marginBottom: "0" }}
                  >
                    <input
                      className="file-input"
                      type="file"
                      name="invoicefile"
                      value={""}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => addBusinessPartnerUsersInBulk(e)}
                      required={true}
                      multiple={true}
                      accept=".xlsx"
                    />
                    <div>
                      <div className="center_align m_20">
                        <span className="file-icon">
                          <i className="fas fa-upload" style={{ fontSize: "25px" }} />
                        </span>
                      </div>
                      <div>
                        <span
                          className="file-cta closingblc_input businesspartner_btn theme_btn"
                          style={{
                            minWidth: "300px",
                            justifyContent: "center",
                          }}
                        >
                          <span className="file-label">Upload Business Partners</span>
                        </span>
                      </div>
                    </div>
                  </label>
                </div>
              </Grid>
            </TabPanel>
          </section>
          <footer className="modal-card-foot space_between">
            {tabvalue === 0 && (
              <>
                <div className="space_between">
                  <Button
                    className="theme_outline_btn"
                    onClick={() => {
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Button
                    className="theme_btn"
                    onClick={() => {
                      addNewPartnerUser();
                    }}
                    startIcon={<LoadingIcon loading={savingPartnerUser} />}
                    disabled={savingPartnerUser}
                  >
                    Save
                  </Button>
                </div>
              </>
            )}
            {tabvalue === 1 && (
              <Tooltip
                title="No Data Available to Download. Verify Company name is Selected."
                arrow={true}
                open={!props.partnerUsersTemplateBase64}
                placement="top"
              >
                <Button
                  disabled={!props.partnerUsersTemplateBase64}
                  onClick={() => {
                    downloadPartnerUsersTemplate();
                  }}
                  className="theme_btn"
                >
                  Download Format
                </Button>
              </Tooltip>
            )}
          </footer>
        </>
      </Dialog>
    </>
  );
};

export default AddNewPartnerUser;
