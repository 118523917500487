import { uiLoggerNamesTaskBoard } from "src/Utils/Recon/UiLogger/Constants";

export interface TaskBoardTableView {
  id: number;
  name: string;
  isDefault: boolean;
}

export interface TaskTrackerFilters {
  businessPartnerName?: string[];
  vendorCode?: string[];
  taskId?: string[];
}

export const formatCurrencyCommaSeparated = (number) => {
  if (typeof number !== "number" || isNaN(number)) {
    return number;
  }

  // Handle decimal places
  const roundedNumber = Number(number.toFixed(2));

  // Split number into integer and decimal parts
  const [integerPart, decimalPart] = Math.abs(roundedNumber).toString().split(".");

  // Add commas to integer part
  let result = "";
  const len = integerPart.length;

  // Handle first part (rightmost 3 digits)
  result = integerPart.slice(-3);

  // Handle remaining digits in groups of 2
  for (let i = len - 3; i > 0; i -= 2) {
    result = integerPart.slice(Math.max(0, i - 2), i) + "," + result;
  }

  // Add negative sign if needed
  if (number < 0) {
    result = "-" + result;
  }

  // Add decimal part if exists
  if (decimalPart) {
    result += "." + decimalPart;
  }

  return result;
};

export const mappingForTaskBoardStatuses = {
  "Both Side Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_BOTHSIDELEDGERPENDING_CLICK,
  "Partner Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_PARTNERLEDGERPENDING_CLICK,
  "Own Ledger Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_OWNLEDGERPENDING_CLICK,
  "Issue in Ledger": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_ISSUEINLEDGER_CLICK,
  "Yet to Start": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_YETTOSTART_CLICK,
  "Recon in Progress": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONINPROGRESS_CLICK,
  "Maker Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_MAKERPASSED_CLICK,
  "Checker Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CHECKERPASSED_CLICK,
  "Checker Rejected": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_CHECKERREJECTED_CLICK,
  "Sent to Approver": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SENTTOAPPROVER_CLICK,
  "Approver Passed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_APPROVERPASSED_CLICK,
  "Approver Rejected": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_APPROVERREJECTED_CLICK,
  "Technical Issue": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_TECHNICALISSUE_CLICK,
  Dropped: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_DROPPED_CLICK,
  Saved: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SAVETICKET_CLICK,
  Discarded: uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_DISCARDTICKET_CLICK,
  "Recon Sent to Partner": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONSENTTOPARTNER_CLICK,
  "Work in Progress- Action items": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_WORKINPROGRESSACTIONITEMS_CLICK,
  "Sign-off Pending": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SIGNOFFPENDINGWITHPARTNER_CLICK,
  "Signed-off with partner": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_SIGNEDOFFWITHPARTNER_CLICK,
  "On-Hold": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_ONHOLD_CLICK,
  "Recon Finished by user": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_RECONFINISHEDBYUSER_CLICK,
  "Balance Confirmed": uiLoggerNamesTaskBoard.UI_WF_TB_RECONSTATUS_BALANCECONFIRMED_CLICK,
};

export const mappingForTaskBoardViews = {
  "Default view": uiLoggerNamesTaskBoard.UI_WF_TB_DEFAULTVIEW_CLICK,
  "Recon tasks": uiLoggerNamesTaskBoard.UI_WF_TB_RECONTASKS_CLICK,
  "My tasks": uiLoggerNamesTaskBoard.UI_WF_TB_MY_TASKS_CLICK,
  "Status summary": uiLoggerNamesTaskBoard.UI_WF_TB_STATUSSUMMARY_CLICK,
};

export interface CustomisationKey {
  name: string;
  isVisible: boolean;
}

export interface ReportCustomisationSettings {
  taskAndPartnerLevelDetails: CustomisationKey[];
  reconDetails: CustomisationKey[];
  reconDetailsCountOfEntries: CustomisationKey[];
  ledgerSummary: CustomisationKey[];
  documentsRepository: CustomisationKey[];
  partnerCommunicationDetails: CustomisationKey[];
  otherDetails: CustomisationKey[];
  customColumns: CustomisationKey[];
}

export enum CUSTOMISATION_KEYS_UI_MAPPING {
  taskAndPartnerLevelDetails = "Task and Partner Level Details",
  reconDetails = "Recon Details",
  reconDetailsCountOfEntries = "Recon Details - Count of Entries",
  ledgerSummary = "Ledger Summary",
  documentsRepository = "Documents Repository",
  partnerCommunicationDetails = "Partner Communication Details",
  otherDetails = "Other Details",
  customColumns = "Custom Columns",
}

export enum TASK_PHASES {
  recon = "recon",
  partnerCommunication = "partnerCommunication",
  pcRecon = "pcRecon",
}
