import { Close } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { FormLabel, Grid, IconButton } from "@mui/material";
import { ColumnDef } from "@tanstack/react-table";
import { Currency } from "dinero.js";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import FormatDate from "../../../Utils/DateUtils";
import { gapAccountedAsName as gapAccounted } from "../../../Utils/Recon/Recon360/Constants";
import BpDashboardTable from "../../ReactTable/BpDashboardTable";
import { Recon360Context } from "../Recon360";

interface ManualReconEntry {
  Amount: number;
  date: string;
  documentType: string;
  entryNumber: number;
  referenceNumber: string;
}

export interface ManualReconChanges {
  ownEntry: ManualReconEntry[];
  businessPartnerEntry: ManualReconEntry[];
}

export interface ManualMatchProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  manualReconChanges: ManualReconChanges[];
}

const ManualMatchDialog = ({ open, setOpen, manualReconChanges }: ManualMatchProps) => {
  const [currentMatch, setCurrentMatch] = useState(0);
  const [totalMatches, setTotalMatches] = useState(0);
  const [rowsDataOwn, setRowsDataOwn] = useState<ManualReconEntry[]>([]);
  const [rowsDataBp, setRowsDataBp] = useState<ManualReconEntry[]>([]);

  const rowsOwnRef = useRef<ManualReconEntry[]>([]);
  const rowsBpRef = useRef<ManualReconEntry[]>([]);

  const bpSum = useRef(0);
  const ownSum = useRef(0);
  const gapSum = useRef(0);
  const gapPer = useRef("0");

  const { currency, businessPartnerSelected, companyId, amountTolerance, branchCode, mappingId } =
    useContext(Recon360Context);

  useEffect(() => {
    if (open) {
      setCurrentMatch(0);
    }
  }, [open]);

  useEffect(() => {
    if (currentMatch < manualReconChanges?.length) {
      setTotalMatches(manualReconChanges.length);
      setRowsDataOwn(manualReconChanges[currentMatch].ownEntry);
      setRowsDataBp(manualReconChanges[currentMatch].businessPartnerEntry);
    }
  }, [currentMatch, manualReconChanges]);

  const columnDefsOwnAndBp = useCallback(
    (own: boolean): ColumnDef<ManualReconEntry>[] => [
      {
        header: "Entry no.",
        accessorFn: (row) => row.entryNumber,
        size: 100,
      },
      {
        header: () => <div className="borderRight">{own ? "Own" : "Bp"}</div>,
        id: "reconciledOwn",
        size: null,
        accessorKey: "",
        columns: [
          {
            header: "Reference",
            accessorKey: "referenceNumber",
            size: 180,
          },
          {
            header: "Date",
            accessorKey: "date",
            accessorFn: (row) => moment(row.date).format("YYYYMMDD"),
            size: 120,
            cell: ({ row }) => (row.original.date ? FormatDate(row.original.date) : ""),
          },
          {
            header: "Doc Type",
            accessorKey: "documentType",
            size: 100,
          },
          {
            header: "Amount",
            accessorKey: "amount",
            size: 140,
            cell: ({ row }) => (
              <div>
                {row.original.Amount !== null
                  ? formatMoney(ToDineroObj(row.original.Amount, currency.current as Currency))
                  : "-"}
              </div>
            ),
          },
        ],
      },
    ],
    []
  );

  const conditionalButtons = useCallback(
    (_rowsDataOwn: ManualReconEntry[], _rowsDataBp: ManualReconEntry[]) => {
      let amountSumOwn = 0;
      let amountSumBp = 0;

      _rowsDataOwn.map((row) => (amountSumOwn += row.Amount));
      _rowsDataBp.map((row) => (amountSumBp += row.Amount));

      rowsOwnRef.current = _rowsDataOwn;
      rowsBpRef.current = _rowsDataBp;

      const absoluteSum = Math.abs(amountSumOwn + amountSumBp);
      const overallSum = amountSumOwn + amountSumBp;

      const overallPercentage = (Math.abs(overallSum) * 100) / Math.max(Math.abs(amountSumOwn), Math.abs(amountSumBp));

      ownSum.current = amountSumOwn;
      bpSum.current = amountSumBp;
      gapSum.current = absoluteSum;
      gapPer.current = overallPercentage.toFixed(2);

      if (_rowsDataOwn.length > 0 && _rowsDataBp.length > 0) {
        if (absoluteSum > amountTolerance)
          return (
            <>
              <Button
                size="small"
                className="theme_btn"
                onClick={() => reconcileEntries(gapAccounted.tds, currentMatch, totalMatches)}
              >
                TDS Match
              </Button>
              <Button
                size="small"
                className="theme_btn"
                onClick={() => reconcileEntries(gapAccounted.mismatchOthers, currentMatch, totalMatches)}
              >
                Amount Mis-match
              </Button>
            </>
          );
        else if (absoluteSum < amountTolerance)
          return (
            <Button
              size="small"
              className="theme_btn"
              onClick={() => reconcileEntries(gapAccounted.roundingError, currentMatch, totalMatches)}
            >
              Match
            </Button>
          );
      } else if (_rowsDataOwn.length === 0 || _rowsDataBp.length === 0) {
        if (absoluteSum < amountTolerance)
          return (
            <Button
              size="small"
              className="theme_btn"
              onClick={() => reconcileEntries(gapAccounted.roundingError, currentMatch, totalMatches)}
            >
              Reverse
            </Button>
          );
        else if (absoluteSum > amountTolerance)
          return (
            <>
              <Button
                size="small"
                className="theme_btn"
                onClick={() => reconcileEntries(gapAccounted.roundingError, currentMatch, totalMatches)}
              >
                Rounding Off
              </Button>
              <Button
                size="small"
                className="theme_btn"
                onClick={() => reconcileEntries(gapAccounted.miscellaneous, currentMatch, totalMatches)}
              >
                Miscellaneous
              </Button>
            </>
          );
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [amountTolerance, totalMatches, currentMatch]
  );

  const ResetData = () => {
    bpSum.current = 0;
    ownSum.current = 0;
    gapSum.current = 0;
    gapPer.current = "0";
    setCurrentMatch(0);
    setTotalMatches(0);
    setRowsDataOwn([]);
    setRowsDataBp([]);
  };

  const NextMatch = (_currentMatch: number, _totalMatch: number) => {
    if (_currentMatch + 1 < _totalMatch) {
      setCurrentMatch((current) => current + 1);
    } else if (_currentMatch + 1 === _totalMatch) {
      ResetData();
      setOpen(false);
    }
  };

  const reconcileEntries = async (gapAccountedAs: gapAccounted, _currentMatch: number, _totalMatches: number) => {
    useFetch(API_ENDPOINTS.RECON_MANUAL_RECONCILE.url, "POST", {
      failureMessage: API_ENDPOINTS.RECON_MANUAL_RECONCILE.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerId: businessPartnerSelected,
        companyId: companyId.current,
        branchCode: branchCode.current,
        mappingId: mappingId.current,
        ownEntries: rowsOwnRef.current.map((row) => row.entryNumber),
        bpEntries: rowsBpRef.current.map((row) => row.entryNumber),
        gapAccountedAs,
      },
      thenCallBack: () => {
        NextMatch(_currentMatch, _totalMatches);
      },
      catchCallBack: () => {
        NextMatch(_currentMatch, _totalMatches);
      },
    });
  };

  return (
    <Dialog open={open} maxWidth="lg" fullWidth={true} sx={{ ".MuiPaper-root": { maxWidth: 1300 } }}>
      <Box className="dialog_header space_between" alignItems="center" pr={1}>
        <DialogTitle>Where Would you like to put this match?</DialogTitle>
        <IconButton
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
      </Box>
      <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1, py: 1 }}>
        <Box className="col" sx={{ display: "flex", flexFlow: "column" }}>
          <Box className="center_align_ver_horiz" sx={{ gap: 1 }}>
            <FormLabel sx={{ textAlign: "center" }}>
              <b>
                ({currentMatch + 1}/{totalMatches})
              </b>
            </FormLabel>
            {(rowsDataOwn.length > 0 || rowsDataBp.length > 0) && conditionalButtons(rowsDataOwn, rowsDataBp)}
            <Button
              size="small"
              variant="contained"
              className="theme_btn_no_bg"
              color="error"
              onClick={() => NextMatch(currentMatch, totalMatches)}
            >
              Reject
            </Button>
          </Box>
        </Box>

        <Box className="center_align" sx={{ gap: 2, "&>div": { minWidth: 100, borderRadius: 5 } }}>
          <div className="center_align matchId_heading">
            Own Sum:{formatMoney(ToDineroObj(rowsDataOwn.length ? ownSum.current : 0, currency.current as Currency))}
          </div>
          <div className="center_align matchId_heading">
            Gap:
            {formatMoney(
              ToDineroObj(!rowsDataOwn.length && !rowsDataBp.length ? 0 : gapSum.current, currency.current as Currency)
            )}
            ({!rowsDataOwn.length && !rowsDataBp.length ? 0 : gapPer.current}%)
          </div>
          <div className="center_align matchId_heading">
            BP Sum: {formatMoney(ToDineroObj(rowsDataBp.length ? bpSum.current : 0, currency.current as Currency))}
          </div>
        </Box>

        <Grid display="grid" gridTemplateColumns={"1fr 1fr"} gap={2}>
          <BpDashboardTable
            columns={columnDefsOwnAndBp(true)}
            rows={rowsDataOwn}
            sortEnable={true}
            fromTab="ManualRecon"
            actorType="BPDashboard"
          />

          <BpDashboardTable
            columns={columnDefsOwnAndBp(false)}
            rows={rowsDataBp}
            sortEnable={true}
            fromTab="ManualRecon"
            actorType="BPDashboard"
          />
        </Grid>
      </DialogContent>
      <DialogActions className="dialog_footer">
        <Button
          onClick={() => {
            setOpen(false);
          }}
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ManualMatchDialog;
