import { Close, Download } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableContainer,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { ScheduledMailStatusResponse } from "src/entity/recon-entity/ReconInterfaces";
import FormatDate from "src/Utils/DateUtils";
import { NdAsyncButton } from "../../../MsmePartnerPortal/CommonComponents";
import $ from "../../../MsmePartnerPortal/PartnerPortal.module.scss";
import { StateDispatch } from "../../Common/Components";
import BcNewContext from "../BcNewContext";

interface RowStructure {
  batchNo: number;
  partnersCount: number;
  mailSendTime: Date;
  status?: ScheduledMailStatusResponse["bulkEmailStatus"]["batchStatus"][number]["status"];
}

interface CommonProps {
  open: boolean;
  setOpen: StateDispatch<boolean>;
}

interface MailSchedulerDialogProps extends CommonProps {
  proceedAction?: () => Promise<void>;
}

const MailSchedulerDialog = ({ open, setOpen, proceedAction }: MailSchedulerDialogProps) => {
  const [waiting, setWaiting] = useState(false);
  const [schedulerRows, setSchedulerRows] = useState<RowStructure[]>([]);
  const { mailingRows } = useContext(BcNewContext);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const batches: RowStructure[] = [];
    const time = moment();

    for (let index = 0; index < mailingRows.length; index += 200) {
      const batchNo = batches.length + 1;
      const partnersCount = Math.min(200, mailingRows.length - index);

      batches.push({
        batchNo: batchNo,
        partnersCount: partnersCount,
        mailSendTime: time.add(batches.length * 2, "hours").toDate(),
      });
    }

    const rows: RowStructure[] = [...batches];

    setSchedulerRows(rows);
  }, [mailingRows]);

  return (
    <>
      <Dialog open={open} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"90%"}>
            Mail Scheduler
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1, p: 2 }}>
          <Alert severity="info" sx={{ "& *": { fontFamily: "inherit" } }}>
            These mails will go in batches, please review and proceed.
          </Alert>

          <Typography variant="subtitle2">Total number of partners selected = {mailingRows.length} partners</Typography>

          {/* table  */}
          <TableContainer className="client-table">
            <Table sx={{ width: "100%", borderCollapse: "collapse", "& *": { borderRadius: "0 !important" } }}>
              <thead>
                <tr>
                  <th>Batch no.</th>
                  <th>No. of Partners</th>
                  <th>Mail send time</th>
                </tr>
              </thead>
              <tbody>
                {schedulerRows.map((row) => (
                  <tr key={row.batchNo}>
                    <td>{row.batchNo}</td>
                    <td>{row.partnersCount}</td>
                    <td>{FormatDate(row.mailSendTime, "DD-MM-YYYY hh:mm:ss A").toString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions className="dialog_footer">
          <Button disabled={waiting} onClick={() => handleClose()}>
            Cancel
          </Button>
          <NdAsyncButton
            className={$.BR_fix}
            variant="contained"
            disabled={waiting}
            onClick={async () => {
              setWaiting(true);
              await proceedAction();
              setWaiting(false);
              setOpen(false);
            }}
          >
            Proceed
          </NdAsyncButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const MailSchedulerReportDialog = ({
  open,
  setOpen,
  bulkEmailStatus,
}: CommonProps & {
  bulkEmailStatus: ScheduledMailStatusResponse["bulkEmailStatus"];
}) => {
  const [rows, setRows] = useState<RowStructure[]>([]);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const batches: RowStructure[] = [];

    bulkEmailStatus?.batchStatus?.forEach((batch, _index) => {
      const batchNo = batches.length + 1;

      batches.push({
        batchNo: batchNo,
        partnersCount: batch.partnerCount,
        mailSendTime: batch.mailSendTime
          ? moment(batch.mailSendTime || new Date()).toDate()
          : moment(batches[0].mailSendTime)
              .add(batches.length * 2, "hours")
              .toDate(),
        status: bulkEmailStatus?.batchStatus[batchNo - 1]?.status || "Pending",
      });
    });

    const rows: RowStructure[] = [...batches];

    setRows(rows);
  }, [bulkEmailStatus]);

  const StatusNode = (row: RowStructure) => {
    return (
      <Box className="d_flex" gap={1}>
        <Chip
          label={row.status}
          variant="outlined"
          size="small"
          color={row.status === "Successful" ? "success" : row.status === "Error Report" ? "error" : "info"}
          sx={{ "& *": { font: "inherit" } }}
        />
        {row.status === "Error Report" ? (
          <IconButton
            size="small"
            onClick={() => {
              const excelData = Buffer.from(
                bulkEmailStatus?.batchStatus?.[row.batchNo - 1]?.errorReport || "",
                "base64"
              );
              const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              const blob = new Blob([excelData], { type: fileType });
              saveAs(
                blob,
                `Batch ${row.batchNo} ${FormatDate(row.mailSendTime, "MMMM Do YYYY, h-mm-ss a")} Report.xlsx`
              );
            }}
            sx={{ padding: 0 }}
            color="error"
            disableRipple
          >
            <Download />
          </IconButton>
        ) : null}
      </Box>
    );
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} classes={{ paper: $.BR_fix }} maxWidth="md" fullWidth>
        <Box className="dialog_header space_between" alignItems="center" pr={1}>
          <DialogTitle component={"div"} width={"90%"}>
            Mail Scheduler
          </DialogTitle>
          <IconButton
            onClick={() => {
              handleClose();
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <DialogContent sx={{ display: "flex", flexFlow: "column", gap: 1, p: 2 }}>
          <Alert severity="info" sx={{ "& *": { fontFamily: "inherit" } }}>
            Mail Scheduler in progress, all other mail sending will be disabled.
          </Alert>

          <Typography variant="subtitle2">
            Total number of partners selected = {bulkEmailStatus?.totalPartnerCount} partners
          </Typography>

          {/* table  */}
          <TableContainer className="client-table">
            <Table sx={{ width: "100%", borderCollapse: "collapse", "& th, & td": { borderRadius: "0 !important" } }}>
              <thead>
                <tr>
                  <th>Batch no.</th>
                  <th>No. of Partners</th>
                  <th>Mail send time</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row) => (
                  <tr key={row.batchNo}>
                    <td>{row.batchNo}</td>
                    <td>{row.partnersCount}</td>
                    <td>{FormatDate(row.mailSendTime, "DD-MM-YYYY hh:mm:ss A").toString()}</td>
                    <td>{StatusNode(row)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MailSchedulerDialog;
