import {
  ArrowDropDown,
  Cached,
  CheckCircle,
  Close,
  FilterList,
  FilterListOff,
  MailOutline,
  MoreVert,
  Sync,
  Warning,
} from "@mui/icons-material";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import EditIcon from "@mui/icons-material/Edit";
import HistoryIcon from "@mui/icons-material/History";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  createFilterOptions,
  Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Buffer } from "buffer";
import { Currency } from "dinero.js";
import saveAs from "file-saver";
import { MRT_Column } from "material-react-table";
import moment from "moment";
import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import FilterAppliedBar from "src/Components/Common/FilterAppliedBar";
import { LoggedInSkeleton } from "src/Components/Common/LoggedInSkeleton";
import { getReconTopBarButtons } from "src/Components/Common/TopNavBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import CustomToast from "src/Components/CustomToast";
import ReactTable, { ColDef } from "src/Components/ReactTable/ReactTable";
import {
  allTaskCategories,
  BulkMailStatusResponse,
  ListOwnTallyCompanies,
  ListUserEmailSettingRes,
  ListUserEmailTemplateResponse,
  TaskStatus,
  UseCaseType,
} from "src/entity/recon-entity/ReconInterfaces";
import {
  CounterState,
  updateBulkMailedTimeClosureTracker,
  updateEmailResponseMsgClosureTracker,
  updateIsSendingBulkMailsClosureTracker,
  updateMailFailedUsersClosureTracker,
  updateMailFailedWorkbookClosureTracker,
  updateMailSentPartnerCountClosureTracker,
} from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { isOverFlowing } from "src/Utils/Common/CheckTextOverflow";
import { exists } from "src/Utils/Common/Validators";
import { filterDateBetweenStartAndEndDate, formatDate, formatDateTime24Hours } from "src/Utils/DateUtils";
import { formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { dynamicContentOptionsDR } from "src/Utils/PartnerCommunication";
import { uiLoggerNameDisputeResolution } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import BulkMailReportDialog from "../../PartnerCommunication/CommonLegacy/BulkMailReportDialog";
import {
  BranchInfo,
  CompanyInfo,
  DEFAULT_BRANCH_CODE,
  DEFAULT_COMPANY_ID,
  IntegratedDropDown,
} from "../../PartnerCommunication/CommonLegacy/CommonComponents";
import { PartnerCommsContext } from "../../PartnerCommunication/Context/PartnerCommunicationContext";
import { NdAsyncButton } from "../../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import EditTaskModal from "../../Tickets/EditTaskModal";
import TaskAttachmentsModal from "../../Tickets/TaskAttachmentsModal";
import TasksHistoryModal, { NAKAD_SERVICE } from "../../Tickets/TasksHistoryModal";
import { renderGroupedOptions, TicketMetaStatuses } from "../../Tickets/ViewTickets";
import BulkMailing from "../Common/BulkMailing";
import { DRNewCommunicationDialog } from "../Common/CommonDialogs";
import SingleMailing from "../Common/SingleMailing";
import { ViewReplyMailThread } from "../Common/ViewReplyMailThread";
import {
  DisputeResolutionEmailStatuses,
  DisputeResolutionEmailStatusesColorMap,
  ListForDRClosureTracker,
} from "../DRInterfaces";
import ClosureTrackerFilter from "./ClosureTrackerFilter";
import DiscardTaskModal from "./DiscardTaskModal";
import SaveTaskModal from "./SaveTaskModal";

let intervalId: ReturnType<typeof setTimeout>;

interface IClosureTrackerContext {
  rowDataClosureTracker: ListForDRClosureTracker[];
  setRowDataClosureTracker: StateDispatch<ListForDRClosureTracker[]>;
  listAllBusinessPartnersWSR: () => Promise<void>;
  listUserEmailTemplates: () => Promise<void>;
  selectedRow: ListForDRClosureTracker[];
  setSelectedRow?: StateDispatch<ListForDRClosureTracker[]>;
  currentRow: ListForDRClosureTracker;
  setCurrentRow: StateDispatch<ListForDRClosureTracker>;
  setIntervalClosureTracker: () => void;
  businessPartnerId: number;
  mappingId: number;
  businessPartnerName: string;
  companyId: string;
  branchCode: string;
}

type StateDispatch<T = any> = React.Dispatch<React.SetStateAction<T>>;

export const ClosureTrackerContext = createContext<IClosureTrackerContext>(null);

const filter = createFilterOptions<any>();

const MuiSliderFilterSteps = (column: MRT_Column<ListForDRClosureTracker>) => {
  const minMaxValues = column.getFacetedMinMaxValues();

  let step = 1000;
  if (minMaxValues?.length > 0) {
    const maxVal = minMaxValues[1];
    const minVal = minMaxValues[0];
    const calculatedStep = Math.floor((maxVal - minVal) / 20);
    step = calculatedStep ? calculatedStep : step;
  }

  return {
    //no need to specify min/max/step if using faceted values
    marks: true,
    step: step,
  };
};

export default function ClosureTracker() {
  //context state
  const { actor } = useContext(userContext);

  //anchor elements states
  const [anchorElBulk, setAnchorElBulk] = React.useState<null | HTMLElement>(null);
  const handleMenuCloseBulk = () => {
    setAnchorElBulk(null);
  };
  const [anchorElOpts, setAnchorElOpts] = React.useState<null | HTMLElement>(null);
  const handleMenuCloseOpts = () => {
    setAnchorElOpts(null);
  };

  //filter states
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<{ [key: string]: string }>({});
  const [reFilter, setReFilter] = useState<boolean>(false);

  //states for companyid and branchcode
  const companyId = useRef<string>(null);
  const branchCode = useRef<string>(null);

  //States for editing the task
  const [showEditTaskModal, setShowEditTaskModal] = useState<boolean>(false);
  const [selectedRowForEdit, setSelectedRowForEdit] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  //states for task attachment
  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);
  const currentSelectedTaskDetailsForAttachments = useRef<{
    businessPartnerName: string;
    taskId: number;
    mappingId: number;
  }>(null);

  //states for task history
  const [showTasksHistoryModal, setShowTasksHistoryModal] = useState<boolean>(false);
  const currentSelectedTaskDetailsForHistory = useRef<{
    businessPartnerName: string;
    taskId: number;
    mappingId: number;
  }>(null);

  const storeAllCategories = useRef<string[]>([]);
  const location = useLocation<any>();
  const [rowDataClosureTracker, setRowDataClosureTracker] = useState<ListForDRClosureTracker[]>([]);
  const [storeRowsDataClosureTracker, setStoreRowsDataClosureTracker] = useState<ListForDRClosureTracker[]>([]);
  const [isLoadingRawsData, setIsLoadingRawsData] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<ListForDRClosureTracker[]>([]);
  const [allStatuses, setAllStatuses] = useState<TaskStatus[]>([]);
  const [allUsers, setAllUsers] = useState([]);
  const [taskCategories, setTaskCategories] = useState(allTaskCategories);
  const currentTaskDetails = useRef<{ taskId: number; mappingId: number }>(null);
  const [showSaveTaskModal, setShowSaveTaskModal] = useState<boolean>(false);
  const [showDiscardTaskModal, setShowDiscardTaskModal] = useState<boolean>(false);

  // Mailing Stuffs
  const dynamicContentOpts = [...dynamicContentOptionsDR];

  const { setEmailTemplates, setDynamicContentOptions, setUserEmailSetting } = useContext(PartnerCommsContext);
  const [businessPartnerId, setBusinessPartnerId] = useState<number>(null);
  const [mappingId, setMappingId] = useState<number>(null);
  const [businessPartnerName, setBusinessPartnerName] = useState<string>("");
  const [openViewReplyMail, setOpenViewReplyMail] = useState<boolean>(false);
  const [openBulkMailing, setOpenBulkMailing] = useState<boolean>(false);
  const [openSingleMailing, setOpenSingleMailing] = useState<boolean>(false);
  const [currentSelectedRow, setCurrentSelectedRow] = useState<ListForDRClosureTracker>({} as ListForDRClosureTracker);
  const [openDrNewCommunicationDialog, setOpenDrNewCommunicationDialog] = useState<boolean>(false);
  // const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);

  const dispatch = useDispatch();
  const {
    isSendingBulkMailsClosureTracker,
    bulkMailedTimeClosureTracker,
    emailResponseMsgClosureTracker,
    mailFailedUsersClosureTracker,
    mailFailedWorkbookClosureTracker,
    mailSentPartnerCountClosureTracker,
  } = useSelector((state: any) => state.bulkMailsStatus as CounterState);
  const [openBulkMailErrorReport, setOpenBulkMailErrorReport] = useState<boolean>(false);

  // Refresh Status
  const lastInterval = useRef(null);
  // const [statusDataReady, setStatusDataReady] = useState(false);
  const [statusDataFetching, setStatusDataFetching] = useState(false);
  // const [statusButtonClicked, setStatusButtonClicked] = useState(false);
  const [statusText, setStatusText] = useState<string>("Never");

  const ClosureTrackerContextValue: IClosureTrackerContext = {
    rowDataClosureTracker,
    setRowDataClosureTracker,
    listAllBusinessPartnersWSR: () => listAllRowsForDisputeResolutionEmail(),
    listUserEmailTemplates: () => listUserEmailTemplateForDr(""),
    selectedRow,
    setSelectedRow,
    businessPartnerId,
    mappingId,
    businessPartnerName,
    currentRow: currentSelectedRow,
    setCurrentRow: setCurrentSelectedRow,
    setIntervalClosureTracker: () => setIntervalClosureTracker(),
    companyId: companyId.current || DEFAULT_COMPANY_ID,
    branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
  };

  const listAllStatuses = () => {
    useFetch(API_ENDPOINTS.GET_DR_TICKET_STATUS.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DR_TICKET_STATUS.failureMessage,
      thenCallBack: (response) => {
        setAllStatuses(response.data?.statuses || []);
        setTaskCategories(response.data?.ticketCategories || allTaskCategories);
      },
    });
  };

  const listAllUsers = () => {
    useFetch(API_ENDPOINTS.LIST_ALL_USERS_DR_EMAIL.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_USERS_DR_EMAIL.failureMessage,
      thenCallBack: (response) => {
        setAllUsers(response.data?.allUsers || []);
      },
    });
  };

  const listAllRowsForDisputeResolutionEmail = async () => {
    setIsLoadingRawsData(true);
    useFetch(API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_DR_EMAIL.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_ALL_BUSINESS_PARTNERS_DR_EMAIL.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        setStoreRowsDataClosureTracker(response.data?.businessPartnerList || []);
        setRowDataClosureTracker(response.data?.businessPartnerList || []);
        storeAllCategories.current = response.data?.allCategories || [];
        setIsLoadingRawsData(false);
        setStatusDataFetching(false);
        if (Object.keys(appliedFilters).length) setReFilter(true);
      },
      catchCallBack: () => {
        setIsLoadingRawsData(false);
      },
    });
  };

  useEffect(() => {
    if (!actor.integration) {
      listAllRowsForDisputeResolutionEmail();
    }
    listAllStatuses();
    listAllUsers();
    listUserEmailTemplateForDr("");
    ListDynamicContent();
    IsStatusFetched();
    getListUserEmailSetting();
  }, []);

  const downloadDisputeResolutionReport = () => {
    useFetch(API_ENDPOINTS.GET_DISPUTE_RESOLUTION_REPORT.url, "GET", {
      failureMessage: API_ENDPOINTS.GET_DISPUTE_RESOLUTION_REPORT.failureMessage,
      showSuccessToast: true,
      thenCallBack: (response) => {
        const { excelBuffer, filename } = response.data;
        const excelData = Buffer.from(excelBuffer, "base64");
        const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        const blob = new Blob([excelData], { type: fileType });
        saveAs(blob, filename);
      },
    });
  };

  const updateOwnAndBusinessPartnerMappingCategories = async (row, value: string[]) => {
    useFetch(API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_BUSINESS_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        category: value,
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: () => {
        listAllRowsForDisputeResolutionEmail();
      },
    });
  };

  const updateTaskMetaStatus = async (status: string, taskDetails: { taskId: number; mappingId: number }) => {
    await useFetch(API_ENDPOINTS.UPDATE_TICKET_META_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_META_STATUS.failureMessage,
      showSuccessToast: true,
      data: {
        ticketId: taskDetails.taskId,
        mappingId: taskDetails.mappingId,
        metaStatus: status,
      },
      thenCallBack: () => {
        listAllRowsForDisputeResolutionEmail();
        setShowDiscardTaskModal(false);
        setShowSaveTaskModal(false);
      },
      catchCallBack: () => {
        listAllRowsForDisputeResolutionEmail();
        setShowDiscardTaskModal(false);
        setShowSaveTaskModal(false);
      },
    });
  };

  const updateTicketStatus = async (statusId: number, ticketId: number, mappingId: number) => {
    await useFetch(API_ENDPOINTS.UPDATE_TICKET_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_TICKET_STATUS.failureMessage,
      data: {
        ticketId,
        mappingId,
        statusId,
      },
      thenCallBack: () => {
        toast.success(<CustomToast message="Successfully changed ticket status" />);
        listAllRowsForDisputeResolutionEmail();
        setShowDiscardTaskModal(false);
        setShowSaveTaskModal(false);
      },
      catchCallBack: (error) => {
        const message = error?.response?.data?.message || "Error in changing ticket status";
        toast.error(<CustomToast message={message} />);
        listAllRowsForDisputeResolutionEmail();
        setShowDiscardTaskModal(false);
        setShowSaveTaskModal(false);
      },
    });
  };

  const companyNameSelect = (e: Record<string, any>, option: ListOwnTallyCompanies | CompanyInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      companyId.current = option.companyId;

      if (!actor.branchLevelReconcilation) {
        listAllRowsForDisputeResolutionEmail(); // Rows API
        getBulkMailStatus();
        if (!intervalId) setIntervalClosureTracker();
      }
    } else if (option === null) {
      companyId.current = null;
    }
  };

  const branchNameSelect = (e: Record<string, any>, option: BranchInfo) => {
    if (option !== null) {
      if (e?.persist) e.persist();
      branchCode.current = option.branchCode;
      if (actor.branchLevelReconcilation) {
        listAllRowsForDisputeResolutionEmail(); // Rows API
        getBulkMailStatus();
        if (!intervalId) setIntervalClosureTracker();
      }
    } else if (option === null) {
      ResetOnCompanyChange();
    }
  };

  const ResetOnCompanyChange = () => {
    branchCode.current = null;
    setStoreRowsDataClosureTracker([]);
    setRowDataClosureTracker([]);
  };

  const ListDynamicContent = () => {
    useFetch<{ dynamicContents: string[] }>(API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_DYNAMIC_CONTENT_PARTNER_COMMUNICATION.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (res) => {
        dynamicContentOpts.push(...res.data.dynamicContents.map((_content) => `{${_content}}`));
        setDynamicContentOptions(dynamicContentOpts);
      },
    });
  };

  // User Email Templates for Mail Dialog
  const listUserEmailTemplateForDr = (createTemplateName = "") => {
    return useFetch<ListUserEmailTemplateResponse>(
      API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.url,
      "GET",
      {
        failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_TEMPLATE_PARTNER_COMMUNICATION.failureMessage,
        config: {
          params: {
            templateType: UseCaseType.disputeResolution,
            companyId: companyId.current,
          },
        },
        thenCallBack: (response) => {
          const { emailTemplates } = response.data;
          setEmailTemplates(emailTemplates);
          // listPDFTemplates();

          // if (lastEmailTemplateSelected) setLastSelectedTemplateID(lastEmailTemplateSelected);
          if (createTemplateName.trim() !== "") {
            if (response.data?.emailTemplates !== null && response.data?.emailTemplates?.length > 0) {
              const filteredSelectedTemplate = response.data.emailTemplates?.filter((item) => {
                return item.templateName === createTemplateName;
              })[0];

              filteredSelectedTemplate;
            }
          }
        },
      }
    );
  };

  // User Display Name and Email Id
  const getListUserEmailSetting = async () => {
    return useFetch<ListUserEmailSettingRes>(API_ENDPOINTS.LIST_USER_EMAIL_SETTING.url, "GET", {
      failureMessage: API_ENDPOINTS.LIST_USER_EMAIL_SETTING.failureMessage,
      config: {
        params: {
          userId: actor.userId,
        },
      },
      thenCallBack: (response) => {
        if (exists(response.data.userEmailSetting)) {
          setUserEmailSetting(response.data.userEmailSetting);
        }
      },
    });
  };

  const columnDefinitionTickets = useMemo(
    (): ColDef<ListForDRClosureTracker>[] => [
      {
        header: "Task ID",
        accessorFn: (row) => row?.ticketId?.toString() || "",
        id: "ticketId",
        size: 145,
        minSize: 145,
        sticky: "left",
        enablePinning: false,
        enableColumnDragging: false,
        enableResizing: false,
        filterVariant: "autocomplete",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            <span>{row.original.ticketId}</span>
          </div>
        ),
      },
      {
        header: "Partner Name",
        accessorFn: (row) => row?.businessPartnerName || "",
        id: "businessPartnerName",
        size: 250,
        minSize: 250,
        sticky: "left",
        enablePinning: false,
        filterVariant: "autocomplete",
        Cell: ({ row }) => (
          <div
            style={{ width: "inherit" }}
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
          >
            <span>{row.original.businessPartnerName}</span>
          </div>
        ),
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.vendorCode || "",
        id: "vendorCode",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.vendorCode ? row.original.vendorCode : ""}
          </div>
        ),
        size: 200,
        minSize: 200,
        filterVariant: "autocomplete",
        filterFn: "contains",
      },
      {
        header: "Location",
        accessorFn: (row) => row?.location || "",
        id: "location",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.location ? row.original.location : ""}
          </div>
        ),
        filterVariant: "autocomplete",
        size: 200,
        minSize: 200,
      },
      {
        header: "Relationship",
        accessorFn: (row) => row?.relationship || "",
        id: "relationship",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.relationship ? row.original.relationship : ""}
          </div>
        ),
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
      },
      {
        header: "Category",
        size: 200,
        minSize: 200,
        accessorFn: (row) => row.category?.join(", ") || "",
        id: "category",
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
            />
          </div>
        ),
      },
      {
        header: "Status",
        accessorFn: (row) => row?.status || "",
        id: "status",
        filterVariant: "multi-select",
        Cell: ({ row, column }) => {
          const [anchorElDispute, setAnchorElDispute] = React.useState(null);
          const openDispute = Boolean(anchorElDispute);

          function handleClickDispute(event) {
            setAnchorElDispute(event.currentTarget);
          }
          function handleCloseDispute() {
            setAnchorElDispute(null);
          }
          const onChangeHandler = (e) => {
            const newValue = e.target.value;
            row.original.statusId = newValue;
            updateTicketStatus(newValue, row.original.ticketId, row.original.mappingId);
          };

          return (
            <Select
              renderValue={() => row.original.status}
              displayEmpty={true}
              open={openDispute}
              onOpen={handleClickDispute}
              onClose={handleCloseDispute}
              disableUnderline={true}
              sx={{
                width: "inherit",
              }}
              disabled={
                column.getIsGrouped() ||
                row.original.metaStatus !== TicketMetaStatuses.InProgress ||
                selectedRow.length > 0
              }
              variant="standard"
              value={row.original.statusId}
              onChange={onChangeHandler}
              MenuProps={{
                PaperProps: {
                  sx: {
                    "& .MuiMenu-list": {
                      paddingBottom: 0,
                      paddingTop: 0,
                      maxHeight: 450,
                    },
                  },
                },
              }}
            >
              {renderGroupedOptions(taskCategories, allStatuses)}

              <div
                style={{
                  position: "sticky",
                  bottom: "0",
                  backgroundColor: "#fff",
                  zIndex: 100,
                  display: "flex",
                  flexDirection: "column",
                  padding: "16px",
                  gap: "16px",
                  borderTop: "2px solid #e7e7e7",
                  marginTop: "8px",
                }}
              >
                <Button
                  variant="contained"
                  className="theme_btn fs_14 fw_500"
                  sx={{ borderRadius: "4px !important", padding: "7px 16px" }}
                  onClick={() => {
                    currentTaskDetails.current = { taskId: row.original.ticketId, mappingId: row.original.mappingId };
                    setAnchorElDispute(null);
                    setShowSaveTaskModal(true);
                  }}
                >
                  SAVE/SIGN OFF TASK
                </Button>
                <Button
                  className="fs_14 fw_500"
                  variant="outlined"
                  color="error"
                  sx={{ borderRadius: "4px !important", padding: "6px 16px" }}
                  onClick={() => {
                    currentTaskDetails.current = { taskId: row.original.ticketId, mappingId: row.original.mappingId };
                    setAnchorElDispute(null);
                    setShowDiscardTaskModal(true);
                  }}
                >
                  DISCARD TASK
                </Button>
              </div>
            </Select>
          );
        },
        size: 240,
        minSize: 240,
      },
      {
        header: "Time of Last Status Change",
        accessorFn: (row) => new Date(row.lastStatusChangeTime),
        id: "lastStatusChangeTime",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.lastStatusChangeTime);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastStatusChangeTime ? formatDateTime24Hours(row.original.lastStatusChangeTime) : "-"}
          </div>
        ),
      },
      {
        header: "Email Status",
        accessorFn: (row) => row?.emailStatus || "",
        id: "emailStatus",
        size: 300,
        minSize: 300,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <>
            {row.original.emailStatus ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: DisputeResolutionEmailStatusesColorMap[row.original.emailStatus]?.color || "#000",
                    backgroundColor:
                      DisputeResolutionEmailStatusesColorMap[row.original.emailStatus]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.emailStatus === DisputeResolutionEmailStatuses.ReminderSent
                    ? row.original.emailStatusWithReminderCount || row.original.emailStatus
                    : row.original.emailStatus}
                </Button>
              </div>
            ) : (
              <img alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },
      {
        header: "Date as of last email status change",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.emailStatusChangeDate);
        },
        accessorFn: (row) => new Date(row.emailStatusChangeDate),
        id: "emailStatusChangeDate",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.emailStatusChangeDate ? formatDate(row.original.emailStatusChangeDate) : "-"}
          </div>
        ),
        size: 350,
        minSize: 350,
      },
      {
        header: "Priority",
        accessorFn: (row) => row.priority || "",
        id: "priority",
        size: 180,
        minSize: 180,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.priority ? row.original.priority : ""}</div>
        ),
      },
      {
        header: "Remarks",
        id: "remarks",
        accessorFn: (row) => row.remarks || "",
        filterFn: "contains",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : "-"}
          </div>
        ),
        size: 250,
        minSize: 250,
      },
      {
        header: "Task Creation Date",
        accessorFn: (row) => new Date(row.ticketCreationDate),
        id: "ticketCreationDate",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.ticketCreationDate);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.ticketCreationDate ? formatDate(row.original.ticketCreationDate) : "-"}
          </div>
        ),
      },
      {
        header: "Ledger Received Date (Latest)",
        accessorFn: (row) => new Date(row.ledgerReceivedDate),
        id: "ledgerReceivedDate",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.ledgerReceivedDate);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.ledgerReceivedDate ? formatDate(row.original.ledgerReceivedDate) : "-"}
          </div>
        ),
      },
      // {
      //   header: "Recon Start Date",
      //   accessorFn: (row) => new Date(row.reconStartDate),
      //   id: "reconStartDate",
      //   size: 350,
      //   minSize: 350,
      //   filterVariant: "date-range",
      //   filterFn: (row, _, filterValue) => {
      //     const startDate = filterValue[0] && filterValue[0].$d;
      //     const endDate = filterValue[1] && filterValue[1].$d;
      //     return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconStartDate);
      //   },
      //   Cell: ({ row }) => (
      //     <div className="textOverflow_hidden">
      //       {row.original.reconStartDate ? formatDate(row.original.reconStartDate) : "-"}
      //     </div>
      //   ),
      // },
      // {
      //   header: "Recon Sent to Partner Date",
      //   accessorFn: (row) => new Date(row.reconSentToPartnerDate),
      //   id: "reconSentToPartnerDate",
      //   size: 350,
      //   minSize: 350,
      //   filterVariant: "date-range",
      //   filterFn: (row, _, filterValue) => {
      //     const startDate = filterValue[0] && filterValue[0].$d;
      //     const endDate = filterValue[1] && filterValue[1].$d;
      //     return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconSentToPartnerDate);
      //   },
      //   Cell: ({ row }) => (
      //     <div className="textOverflow_hidden">
      //       {row.original.reconSentToPartnerDate ? formatDate(row.original.reconSentToPartnerDate) : "-"}
      //     </div>
      //   ),
      // },

      {
        header: "Recon period-From",
        accessorFn: (row) => new Date(row.reconPeriodFrom),
        id: "reconPeriodFrom",
        size: 350,
        minSize: 350,
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconPeriodFrom);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.reconPeriodFrom ? formatDate(row.original.reconPeriodFrom) : "-"}
          </div>
        ),
      },

      {
        header: "Recon Period-To",
        accessorFn: (row) => new Date(row.reconPeriodTo),
        id: "reconPeriodTo",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.reconPeriodTo);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.reconPeriodTo ? formatDate(row.original.reconPeriodTo) : "-"}
          </div>
        ),
        size: 350,
        minSize: 350,
      },
      {
        header: "Client CB",
        accessorFn: (row) => Number(row?.clientClosingBalance) || "",
        id: "clientClosingBalance",
        size: 200,
        minSize: 200,
        enableSorting: true,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) =>
          row.original.clientClosingBalance === null
            ? null
            : formatMoney(ToDineroObj(row.original.clientClosingBalance, (row.original.currency as Currency) || "INR")),
      },
      {
        header: "Partner CB",
        accessorFn: (row) => row?.partnerClosingBalance || "",
        id: "partnerClosingBalance",
        size: 200,
        minSize: 200,
        enableSorting: true,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) =>
          row.original.partnerClosingBalance === null
            ? null
            : formatMoney(
                ToDineroObj(row.original.partnerClosingBalance, (row.original.currency as Currency) || "INR")
              ),
      },
      {
        header: "CB Difference",
        accessorFn: (row) => row?.closingBalanceDifference || "",
        id: "closingBalanceDifference",
        size: 200,
        minSize: 200,
        enableSorting: true,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) =>
          row.original.closingBalanceDifference === null
            ? null
            : formatMoney(
                ToDineroObj(row.original.closingBalanceDifference, (row.original.currency as Currency) || "INR")
              ),
      },
      {
        header: "Opening Balance Gap",
        accessorFn: (row) => row?.openingBalanceGap || "",
        id: "openingBalanceGap",
        size: 250,
        minSize: 250,
        enableSorting: true,
        filterVariant: "range-slider",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) =>
          row.original.openingBalanceGap === null
            ? null
            : formatMoney(ToDineroObj(row.original.openingBalanceGap, (row.original.currency as Currency) || "INR")),
      },

      {
        header: "Maker",
        accessorFn: (row) => row?.maker || "",
        id: "maker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.maker);
        },
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.maker ? row.original.maker : ""}
            </div>
          );
        },
        size: 200,
        minSize: 200,
      },
      {
        header: "Checker",
        accessorFn: (row) => row?.checker || "-",
        id: "checker",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.checker ? row.original.checker : "-");
        },
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.checker ? row.original.checker : "-"}
            </div>
          );
        },
        size: 200,
        minSize: 200,
      },
      {
        header: "Approver",
        id: "approver",
        accessorFn: (row) => row?.approver || "-",
        filterVariant: "multi-select",
        filterFn: (row, _, filterValue) => {
          if (filterValue.length === 0) return true;
          return filterValue.includes(row.original.maker);
        },
        Cell: ({ row }) => {
          return (
            <div
              className="textOverflow_hidden"
              ref={(th) => {
                if (th && isOverFlowing(th)) th.title = th.innerText;
              }}
              style={{ width: "inherit" }}
            >
              {row.original.approver ? row.original.approver : "-"}
            </div>
          );
        },
        size: 200,
        minSize: 200,
      },

      {
        header: "Due Date",
        accessorFn: (row) => new Date(row.dueDate),
        id: "dueDate",
        filterVariant: "date-range",
        filterFn: (row, _, filterValue) => {
          const startDate = filterValue[0] && filterValue[0].$d;
          const endDate = filterValue[1] && filterValue[1].$d;
          return filterDateBetweenStartAndEndDate(startDate, endDate, row.original.dueDate);
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">{row.original.dueDate ? formatDate(row.original.dueDate) : ""}</div>
        ),
        size: 350,
        minSize: 350,
      },
    ],
    [selectedRow, allStatuses, taskCategories]
  );

  // Bulk Mail Status
  const getBulkMailStatus = async () => {
    return new Promise<{ isCompleted: boolean }>((resolve, reject) => {
      useFetch<{
        emailResponseMessage: string;
        isCompleted: boolean;
        listOfFailedUsers: { reason: string; businessPartnerName: string; businessPartnerId: number }[];
        failedEmailsWorkbook: string;
        partnerCount: number;
        timeStamp: Date;
      }>(API_ENDPOINTS.GET_BULK_EMAIL_STATUS_DR_EMAIL.url, "GET", {
        failureMessage: API_ENDPOINTS.GET_BULK_EMAIL_STATUS_DR_EMAIL.failureMessage,
        config: {
          params: {
            companyId: companyId.current || DEFAULT_COMPANY_ID,
            branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
          },
        },
        thenCallBack: (res) => {
          if (res.data.isCompleted && res.data.partnerCount === 0) {
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            return;
          }
          dispatch(updateMailSentPartnerCountClosureTracker(res.data.partnerCount));
          dispatch(updateBulkMailedTimeClosureTracker(res.data.timeStamp));
          if (res.data.isCompleted) {
            if (res.data?.emailResponseMessage !== "") {
              listAllRowsForDisputeResolutionEmail();
              toast.success(<CustomToast message={res.data.emailResponseMessage} />);
            }
            resolve({ isCompleted: true });
            // Stop the interval and cleanup
            clearInterval(intervalId);
            intervalId = null;
            dispatch(updateIsSendingBulkMailsClosureTracker(false));

            if (res.data.listOfFailedUsers && res.data.listOfFailedUsers?.length) {
              const listOfFailedUsers: BulkMailStatusResponse["listOfFailedUsers"] = [];
              res.data.listOfFailedUsers.forEach((failedUser) => {
                const found = listOfFailedUsers.find((user) => user.reason === failedUser.reason);
                if (found) {
                  found.businessPartnerNames.push(failedUser.businessPartnerName);
                } else
                  listOfFailedUsers.push({
                    reason: failedUser.reason,
                    businessPartnerNames: [failedUser.businessPartnerName],
                  });
              });
              dispatch(updateMailFailedUsersClosureTracker(listOfFailedUsers || []));
              dispatch(updateMailFailedWorkbookClosureTracker(res.data?.failedEmailsWorkbook || ""));
              dispatch(updateEmailResponseMsgClosureTracker(res.data.emailResponseMessage || ""));
              // reload templates after mail sent
              listUserEmailTemplateForDr("");
              // setBulkMailedRows(failedUsersRows);
            } else dispatch(updateMailFailedUsersClosureTracker([]));
          } else {
            dispatch(updateIsSendingBulkMailsClosureTracker(true));
            resolve({ isCompleted: res.data.isCompleted });
          }
        },
        catchCallBack: () => {
          clearInterval(intervalId);
          intervalId = null;
          dispatch(updateIsSendingBulkMailsClosureTracker(false));
          reject();
        },
      });
    });
  };

  useEffect(() => {
    if (actor.integration === false) {
      getBulkMailStatus();
      if (!intervalId) setIntervalClosureTracker();
    }
  }, []);

  const setIntervalClosureTracker = () => {
    intervalId = setInterval(async () => {
      const { isCompleted } = await getBulkMailStatus();
      if (isCompleted) {
        // Bulk email operation is complete, stop the interval and cleanup
        clearInterval(intervalId);
      }
    }, 30_000);
  };

  //NITIN START SPACE

  const refreshStatus = async () => {
    return useFetch(API_ENDPOINTS.REFRESH_STATUS_DR_EMAIL.url, "POST", {
      failureMessage: API_ENDPOINTS.REFRESH_STATUS_DR_EMAIL.failureMessage,
      data: {
        companyId: companyId.current || DEFAULT_COMPANY_ID,
        branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setStatusDataFetching(true);
      },
      catchCallBack: () => {
        setStatusDataFetching(false);
      },
    });
  };

  const IsStatusFetched = async () => {
    return useFetch<{
      response: string;
      errorOccurred: boolean;
      isFetched: boolean;
      lastSyncDate: string;
    }>(API_ENDPOINTS.IS_STATUS_FETCHED_DR_EMAIL.url, "GET", {
      failureMessage: API_ENDPOINTS.IS_STATUS_FETCHED_DR_EMAIL.failureMessage,
      config: {
        params: {
          companyId: companyId.current || DEFAULT_COMPANY_ID,
          branchCode: branchCode.current || DEFAULT_BRANCH_CODE,
        },
      },
      thenCallBack: (response) => {
        if (response.data.isFetched || response.data.errorOccurred) {
          setStatusDataFetching(false);
          if (response.data.lastSyncDate) setStatusText(moment(response.data.lastSyncDate).format("DD-MM-YY hh:mmA"));
        }
        if (!response.data.isFetched && !response.data.errorOccurred) setStatusDataFetching(true);

        if (response.data.errorOccurred) {
          toast.error(<CustomToast message={response.data.response} />);
        }
      },
      catchCallBack: () => {
        setStatusDataFetching(false);
      },
    });
  };

  useEffect(() => {
    if (statusDataFetching) {
      setStatusText("Syncing Now...");
      IsStatusFetched();
      const intervalsInMs = [10_000];

      intervalsInMs.forEach((interval) => {
        const intervalId = setInterval(() => {
          try {
            IsStatusFetched();
          } finally {
            // if (interval !== 30_000) clearInterval(intervalId);
          }
        }, interval);
        lastInterval.current = intervalId;
      });
    } else {
      window.clearInterval(lastInterval.current);
      if (actor.integration) {
        if (companyId.current) listAllRowsForDisputeResolutionEmail();
      } else listAllRowsForDisputeResolutionEmail();
    }

    return () => window.clearInterval(lastInterval.current);
    // eslint-disable-next-line
  }, [statusDataFetching, actor]);

  //NITIN END SPACE

  const noInternalPagesOpen = !openViewReplyMail && !openBulkMailing && !openSingleMailing;
  const openViewReplyMailPage = openViewReplyMail && !openBulkMailing && !openSingleMailing;
  const openBulkMailingPage = openBulkMailing && !openViewReplyMail && !openSingleMailing;
  const openSingleMailingPage = openSingleMailing && !openBulkMailing && !openViewReplyMail;

  return (
    <LoggedInSkeleton topBarButtons={getReconTopBarButtons(" ", actor.name, location?.state?.openCollapseObj, actor)}>
      <ClosureTrackerContext.Provider value={ClosureTrackerContextValue}>
        {
          <Grid className="">
            <Grid position="relative">
              <Grid className="vertical_center_align" sx={{ gap: 2, flexWrap: "wrap" }}>
                {noInternalPagesOpen && (
                  <Box
                    className="vertical_center_align"
                    justifyContent="space-between"
                    sx={{ px: 2, py: 1, bgcolor: "#fff", borderRadius: 1, boxShadow: 1, width: "100%" }}
                  >
                    {/* Left Side of Top Bar */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <Button
                        variant="outlined"
                        endIcon={<ArrowDropDown />}
                        sx={{ borderColor: "#541c4c80 !important", color: "#541c4c", borderRadius: "4px !important" }}
                        onClick={(ev) => {
                          setAnchorElBulk(ev.currentTarget);
                        }}
                      >
                        BULK ACTION
                      </Button>
                      {/* Menu for Bulk Actions */}
                      <Menu
                        anchorEl={anchorElBulk}
                        open={Boolean(anchorElBulk)}
                        onClose={handleMenuCloseBulk}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                      >
                        {/* "Bulk Send Email" */}
                        <MenuItem
                          onClick={async () => {
                            /* Bulk Send Email logic */
                            setOpenBulkMailing(true);
                            // close menu
                            handleMenuCloseBulk();
                          }}
                          disabled={selectedRow.length < 1 ? true : isSendingBulkMailsClosureTracker}
                        >
                          Bulk Send Email
                        </MenuItem>
                      </Menu>
                      {/* Filter */}
                      <Button
                        startIcon={isFilterApplied ? <FilterList /> : <FilterListOff />}
                        onClick={() => {
                          uiLogger(
                            uiLoggerNameDisputeResolution.UI_DR_CT_Filter_Apply_Click.functionName,
                            companyId.current,
                            branchCode.current,
                            {
                              message: uiLoggerNameDisputeResolution.UI_DR_CT_Filter_Apply_Click.message,
                            }
                          );

                          setShowFilter(true);
                        }}
                      >
                        FILTER
                      </Button>
                    </Box>
                    {/* Right side of top bar */}
                    <Box display="flex" alignItems="center" gap={2}>
                      <Button
                        endIcon={<MoreVert />}
                        sx={{ color: "rgba(0,0,0,0.6)" }}
                        onClick={(ev) => {
                          setAnchorElOpts(ev.currentTarget);
                        }}
                      >
                        MORE OPTIONS
                      </Button>
                      {/* Menu for More Options */}
                      <Menu
                        anchorEl={anchorElOpts}
                        open={Boolean(anchorElOpts)}
                        onClose={handleMenuCloseOpts}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                      >
                        {/* Download Report */}
                        <MenuItem
                          onClick={() => {
                            uiLogger(
                              uiLoggerNameDisputeResolution.UI_DR_CT_DownloadReport_Click.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNameDisputeResolution.UI_DR_CT_DownloadReport_Click.message,
                              }
                            );

                            downloadDisputeResolutionReport();
                            // close the menu
                            handleMenuCloseOpts();
                          }}
                          //keep it diabled while sending bulk mails
                          disabled={isSendingBulkMailsClosureTracker}
                        >
                          Download Report
                        </MenuItem>
                      </Menu>
                    </Box>
                  </Box>
                )}

                {/* Left Side Dropdowns for integrated users */}
                <div className="vertical_center_align" style={{ gap: "16px", flexWrap: "wrap" }}>
                  {noInternalPagesOpen && (
                    <>
                      {actor.integration === true && (
                        <IntegratedDropDown
                          tallyCompanyNameSelect={companyNameSelect}
                          AfterListOwnTallyCompanies={null}
                          companyNameSelect={companyNameSelect}
                          AfterListCompanies={null}
                          branchNameSelect={branchNameSelect}
                          AfterListBranches={null}
                          isDisabled={isSendingBulkMailsClosureTracker}
                        />
                      )}
                    </>
                  )}
                </div>

                {/* Right Side Buttons */}
              </Grid>

              {/* Filter Applied Bar */}
              {noInternalPagesOpen && Object.keys(appliedFilters).length > 0 && (
                <FilterAppliedBar appliedFilters={appliedFilters} />
              )}

              {/* Bulk Mailing Bar */}
              {noInternalPagesOpen && (isSendingBulkMailsClosureTracker || mailSentPartnerCountClosureTracker > 0) && (
                <Alert
                  color={
                    isSendingBulkMailsClosureTracker
                      ? "info"
                      : mailFailedUsersClosureTracker.length > 0
                      ? "error"
                      : "success"
                  }
                  icon={
                    isSendingBulkMailsClosureTracker ? (
                      <CircularProgress size={16} />
                    ) : mailFailedUsersClosureTracker.length > 0 ? (
                      <Warning />
                    ) : (
                      <CheckCircle />
                    )
                  }
                  sx={{
                    mb: 2,
                    border: "1px solid #0288D1",
                    borderColor:
                      mailFailedUsersClosureTracker.length > 0
                        ? "#D32F2F"
                        : isSendingBulkMailsClosureTracker
                        ? "#0288D1"
                        : "#2E7D32",
                    alignItems: "center",
                  }}
                  action={
                    !isSendingBulkMailsClosureTracker && (
                      <Box className="right">
                        {mailFailedUsersClosureTracker.length > 0 && (
                          <Button
                            variant="text"
                            onClick={() => {
                              setOpenBulkMailErrorReport(true);
                            }}
                          >
                            View Issues
                          </Button>
                        )}
                        <IconButton
                          onClick={() => {
                            dispatch(updateEmailResponseMsgClosureTracker(""));
                            dispatch(updateMailFailedUsersClosureTracker([]));
                            dispatch(updateMailFailedWorkbookClosureTracker(""));
                            dispatch(updateMailSentPartnerCountClosureTracker(0));
                          }}
                          sx={{ ml: "auto" }}
                        >
                          <Close />
                        </IconButton>
                      </Box>
                    )
                  }
                >
                  <Box className="d_flex" sx={{ alignItems: "center" }}>
                    <Typography className="vertical_center_align" fontWeight="medium" fontSize={16}>
                      {isSendingBulkMailsClosureTracker
                        ? `Sending mail to ${mailSentPartnerCountClosureTracker} Partners | Initiated on: 
                        ${formatDateTime24Hours(bulkMailedTimeClosureTracker)}`
                        : mailFailedUsersClosureTracker.length > 0
                        ? `Action required for ${mailSentPartnerCountClosureTracker} Partners`
                        : `Bulk Email Sent to ${mailSentPartnerCountClosureTracker} Partners | Sent on:   ${formatDateTime24Hours(
                            bulkMailedTimeClosureTracker
                          )}`}
                    </Typography>
                  </Box>
                </Alert>
              )}

              <div
                className="bpDashboard"
                style={{
                  minHeight: "60vh",
                  position: "relative",
                  width: "100%",
                  // top: openBcBetaSettings ? "-50px" : "unset",
                  top: "unset",
                }}
              >
                <Grid
                  sx={{
                    "& > div": {
                      animation: "fade-in 0.25s ease-out",
                    },
                    height: "inherit",
                  }}
                >
                  {noInternalPagesOpen && (
                    <ReactTable
                      enableFacetedValues={true}
                      enableRowVirtualization={true}
                      setSelectedRow={setSelectedRow}
                      columns={columnDefinitionTickets}
                      rows={rowDataClosureTracker}
                      enableAggregations={true}
                      loading={isLoadingRawsData}
                      initialStateColumnVisibility={{
                        maker: false,
                        checker: false,
                        approver: false,
                        dueDate: false,
                        location: false,
                        relationship: false,
                      }}
                      renderRowActionMenuItems={({ closeMenu, row }) => [
                        // Send Mail
                        <MenuItem
                          key={"sendMail"}
                          disabled={false}
                          onClick={() => {
                            // logic here
                            setBusinessPartnerId(row.original.businessPartnerId);
                            setMappingId(row.original.mappingId);
                            setBusinessPartnerName(row.original.businessPartnerName);
                            setCurrentSelectedRow(row.original);

                            uiLogger(
                              uiLoggerNameDisputeResolution.UI_DR_CT_SendViewMail_Click.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNameDisputeResolution.UI_DR_CT_SendViewMail_Click.message,
                              }
                            );

                            if (
                              row.original.threadId ||
                              row.original.emailStatus !== DisputeResolutionEmailStatuses.EmailNotSent
                            ) {
                              setOpenViewReplyMail(true);
                            } else {
                              setSelectedRow([row.original]);
                              setOpenDrNewCommunicationDialog(true);
                            }

                            // close menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <MailOutline />
                          </ListItemIcon>
                          Send/View Email
                        </MenuItem>,
                        // {/* View Recon */}
                        <MenuItem
                          key={"viewRecon"}
                          onClick={async () => {
                            // logic

                            const { businessPartnerId, businessPartnerName } = row.original;
                            const params = `bpId=${businessPartnerId}&bpName=${btoa(
                              encodeURIComponent(businessPartnerName)
                            )}`;
                            const uri = `/${actor.name}/recon360/Summary/Ledger?${params}`;
                            window.open(uri, "_blank")?.focus();

                            uiLogger(
                              uiLoggerNameDisputeResolution.UI_DR_CT_ViewRecon_Click.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNameDisputeResolution.UI_DR_CT_ViewRecon_Click.message,
                              }
                            );

                            // close Menu
                            closeMenu();
                          }}
                          sx={{ m: 0 }}
                        >
                          <ListItemIcon>
                            <Cached sx={{ fill: "#541c4c" }} />
                          </ListItemIcon>
                          View Recon
                        </MenuItem>,
                        // {/* Edit Task */}
                        <MenuItem
                          key="editTask"
                          disabled={row.original.metaStatus === TicketMetaStatuses.Saved}
                          onClick={() => {
                            setShowEditTaskModal(true);
                            setSelectedRowForEdit(row.original);
                            closeMenu();
                          }}
                        >
                          <ListItemIcon>
                            <EditIcon fontSize="small" />
                          </ListItemIcon>
                          Edit Task
                        </MenuItem>,
                        // {/* View Attachments */}
                        <MenuItem
                          key="viewAttachments"
                          onClick={() => {
                            currentSelectedTaskDetailsForAttachments.current = {
                              businessPartnerName: row.original.businessPartnerName,
                              taskId: row.original.ticketId,
                              mappingId: row.original.mappingId,
                            };

                            uiLogger(
                              uiLoggerNameDisputeResolution.UI_DR_CT_Attachments_Click.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNameDisputeResolution.UI_DR_CT_Attachments_Click.message,
                              }
                            );

                            setShowTaskAttachmentsModal(true);
                            closeMenu();
                          }}
                        >
                          <ListItemIcon>
                            <AttachFileIcon />
                          </ListItemIcon>
                          View Attachments
                        </MenuItem>,
                        // {/* View History */}
                        <MenuItem
                          key={"viewHistory"}
                          onClick={() => {
                            currentSelectedTaskDetailsForHistory.current = {
                              businessPartnerName: row.original.businessPartnerName,
                              taskId: row.original.ticketId,
                              mappingId: row.original.mappingId,
                            };

                            uiLogger(
                              uiLoggerNameDisputeResolution.UI_DR_CT_History_Click.functionName,
                              companyId.current,
                              branchCode.current,
                              {
                                message: uiLoggerNameDisputeResolution.UI_DR_CT_History_Click.message,
                              }
                            );

                            setShowTasksHistoryModal(true);
                            closeMenu();
                          }}
                        >
                          <ListItemIcon>
                            <HistoryIcon />
                          </ListItemIcon>
                          View History
                        </MenuItem>,
                      ]}
                      renderTopToolbarCustomActions={() => (
                        <Box className="d_flex" gap={1}>
                          {/* Refresh Status */}
                          <NdAsyncButton
                            variant="contained"
                            className="theme_btn"
                            disabled={statusDataFetching || isSendingBulkMailsClosureTracker}
                            onClick={async () => {
                              uiLogger(
                                uiLoggerNameDisputeResolution.UI_DR_CT_RefreshStatus_Click.functionName,
                                companyId.current,
                                branchCode.current,
                                {
                                  message: uiLoggerNameDisputeResolution.UI_DR_CT_RefreshStatus_Click.message,
                                }
                              );
                              await refreshStatus();
                            }}
                            startIcon={<Sync className={statusDataFetching ? "loading-active" : ""} />}
                          >
                            Refresh status
                          </NdAsyncButton>
                          {/* Last status updated at */}
                          <Box>
                            <div style={{ fontSize: "10px" }}>Last Status Updated at:</div>
                            <Chip label={statusText} size="small" />
                          </Box>
                        </Box>
                      )}
                      internalActionsOrder={[
                        "globalFilterToggle",
                        "filterToggle",
                        "showHideColumns",
                        "fullScreenToggle",
                      ]}
                    />
                  )}
                  {openViewReplyMailPage && <ViewReplyMailThread setOpen={setOpenViewReplyMail} />}
                  {openBulkMailingPage && <BulkMailing handleBack={() => setOpenBulkMailing(false)} />}
                  {openSingleMailingPage && <SingleMailing handleBack={() => setOpenSingleMailing(false)} />}
                </Grid>
              </div>
            </Grid>
          </Grid>
        }
        <ClosureTrackerFilter
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          allCategories={storeAllCategories.current}
          allStatuses={allStatuses.map((taskStatus) => taskStatus.status)}
          setRowDataClosureTracker={setRowDataClosureTracker}
          storeRowsDataClosureTracker={storeRowsDataClosureTracker}
          setIsFilterApplied={setIsFilterApplied}
          reFilter={reFilter}
          setReFilter={setReFilter}
          setAppliedFilters={setAppliedFilters}
        />
        {showEditTaskModal && (
          <EditTaskModal
            type="disputeResolution"
            open={showEditTaskModal}
            setOpen={setShowEditTaskModal}
            allUsers={allUsers}
            row={selectedRowForEdit}
            fetchRowsData={listAllRowsForDisputeResolutionEmail}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            companyId={companyId.current}
            branchCode={branchCode.current}
          />
        )}
        {showTaskAttachmentsModal && (
          <TaskAttachmentsModal
            open={showTaskAttachmentsModal}
            setOpen={setShowTaskAttachmentsModal}
            businessPartnerName={currentSelectedTaskDetailsForAttachments.current.businessPartnerName}
            ticketId={currentSelectedTaskDetailsForAttachments.current.taskId}
            companyId={companyId.current}
            branchCode={branchCode.current}
            serviceType={NAKAD_SERVICE.DisputeResolution}
            mappingId={currentSelectedTaskDetailsForAttachments.current.mappingId}
          />
        )}
        {showTasksHistoryModal && (
          <TasksHistoryModal
            showTasksHistoryModal={showTasksHistoryModal}
            setShowTasksHistoryModal={setShowTasksHistoryModal}
            businessPartnerName={currentSelectedTaskDetailsForHistory.current.businessPartnerName}
            taskId={currentSelectedTaskDetailsForHistory.current.taskId}
            mappingId={currentSelectedTaskDetailsForHistory.current.mappingId}
          />
        )}
        {showSaveTaskModal && (
          <SaveTaskModal
            open={showSaveTaskModal}
            setOpen={setShowSaveTaskModal}
            currentTaskDetails={currentTaskDetails.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}
        {showDiscardTaskModal && (
          <DiscardTaskModal
            open={showDiscardTaskModal}
            setOpen={setShowDiscardTaskModal}
            currentTaskDetails={currentTaskDetails.current}
            changeStatus={updateTaskMetaStatus}
          />
        )}

        <BulkMailReportDialog
          open={openBulkMailErrorReport}
          setOpen={setOpenBulkMailErrorReport}
          emailResponseMsg={emailResponseMsgClosureTracker}
          mailFailedUsers={mailFailedUsersClosureTracker}
          workbook={mailFailedWorkbookClosureTracker}
        />

        <DRNewCommunicationDialog
          open={openDrNewCommunicationDialog}
          setOpen={setOpenDrNewCommunicationDialog}
          yesAction={async () => {
            /* Bulk Send Email logic */
            setOpenSingleMailing(true);
          }}
        />
      </ClosureTrackerContext.Provider>
    </LoggedInSkeleton>
  );
}
